import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CreateAddons from "./CreateAddons";
import { Toolbar, Typography } from "@mui/material";
import { deleteAddon, fetchAddOns, updateAddon } from "../../../sevice/group";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField, Select } from "formik-mui";
import { toast } from "react-hot-toast";
import { getAllSubUnits, getRawMaterials } from "../../../sevice/inventory";
import { addAddonSchema } from "../../../schemas";
import AlertButton from "../../../Alert/Alert";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Autocomplete,
} from "@mui/material";

export default function ListAllAddons({ anchor, toggleDrawer, items }) {
  const { POSadmin } = useSelector((state) => state);
  const [allAddons, setAllAddons] = useState([]);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [editInitialData, seteEitInitialData] = useState();
  const [isEdit, setisEdit] = useState(false);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    getallAddons();
  }, [items, isEdit]);

  const getallAddons = async () => {
    const data = await fetchAddOns(POSadmin.token);
    setAllAddons(data);
  };
  const deleteAddonFunction = async (id) => {
    const data = await deleteAddon(id, POSadmin.token);
    if (data.status) {
      toast.success(data.message);
      getallAddons(data);
    }
  };
  const getUnits = async () => {
    const data = await getAllSubUnits();
    setUnits(data);
  };
  useEffect(() => {
    getUnits();
  }, []);

  const findInitialValue = (addon) => {
    const recipe = addon?.recipe?.map((item) => {
      const subUnit = units.find((unit) => unit._id == item.units);
      return {
        rawMaterial: item.rawMaterial,
        quantity: item.quantity,
        units: item.units,
        unitId: subUnit.unit,
      };
    });
    seteEitInitialData({
      _id: addon?._id,
      title: addon?.title,
      foodLabel: addon?.foodLabel,
      price: addon?.price,
      recipe: recipe,
    });
  };
  const fetchRawMaterials = async () => {
    const data = await getRawMaterials(POSadmin.token);
    setRawMaterials(data.docs);
  };
  useEffect(() => {
    fetchRawMaterials();
  }, []);
  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        width: "50vw",
        p: "20px",
      }}
      role="presentation"
    >
      <Toolbar />
      {!isEdit ? (
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography>All Add-ons ({allAddons.length} Add-ons )</Typography>
            </Box>
            <>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "var(--red)",
                  mr: "20px",
                }}
                onClick={toggleDrawer(["create"], true)}
              >
                Create
              </Button>
              <Drawer
                anchor="right"
                open={items["create"]}
                onClose={toggleDrawer("create", false)}
              >
                <CreateAddons anchor="create" toggleDrawer={toggleDrawer} />
              </Drawer>
            </>
          </Box>

          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              mt: "20px",
            }}
          >
            {allAddons.map((item) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "40px",
                  border: "solid var(--border-gray) 1px",
                  alignItems: "center",
                  px: "20px",
                }}
                key={item._id}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ModeStandbyIcon
                    sx={{
                      width: "20px",
                      color:
                        item._id == "Veg"
                          ? "var(--green-color)"
                          : item._id == "NonVeg"
                          ? "var(--red)"
                          : item._id == "Egg"
                          ? "var(--green-yellow)"
                          : "var(--bg-blue)",
                    }}
                  />
                  &nbsp;&nbsp;
                  <Typography fontSize={"12px"}>{item.title}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    onClick={() => {
                      findInitialValue(item);
                      setisEdit(true);
                    }}
                    sx={{ color: "black" }}
                  >
                    <SaveAsIcon sx={{ width: "20px" }} />
                  </Button>
                  <AlertButton
                    element={<DeleteOutlineIcon />}
                    type={"confirmation"}
                    message={"Are you sure? You won't be able to revert this!"}
                    onConfirm={deleteAddonFunction}
                    data={item._id}
                    style={{ color: "black" }}
                  />
                </Box>
              </Box>
            ))}
          </List>
        </Box>
      ) : (
        <EditAddon
          editInitialData={editInitialData}
          rawMaterials={rawMaterials}
          toggleDrawer={toggleDrawer}
          back={setisEdit}
        />
      )}
    </Box>
  );
}

const emptyRecipe = { rawMaterial: "", quantity: "", units: "" };
function EditAddon({ editInitialData, rawMaterials, toggleDrawer, back }) {
  const { POSadmin } = useSelector((state) => state);
  const [units, setUnits] = useState([]);

  const submitdata = async (values) => {
    const data = await updateAddon(POSadmin.token, editInitialData._id, values);
    if (data.status) {
      back(false);
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };

  const getUnits = async () => {
    const data = await getAllSubUnits();
    setUnits(data);
  };
  useEffect(() => {
    getUnits();
  }, []);
  return (
    <Box
      sx={{
        width: "50vw",
        p: "20px",
      }}
      role="presentation"
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography>Create Add-ons</Typography>
        </Box>
        <>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              mr: "20px",
            }}
            onClick={() => back(false)}
          >
            Back
          </Button>
        </>
      </Box>

      <Box>
        <Formik
          initialValues={editInitialData}
          validationSchema={addAddonSchema}
          onSubmit={submitdata}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form autoComplete="off">
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "75%",
                  }}
                >
                  <Typography>Title</Typography>
                  <Field
                    size="small"
                    fullWidth
                    name="title"
                    component={TextField}
                  />
                </Box>
                <Box>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={values.foodLabel}
                      row
                      aria-label="position"
                      name="foodLabel"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            variant="solid"
                            value={"Veg"}
                            onChange={(e) => {
                              setFieldValue("foodLabel", e.target.value);
                            }}
                          />
                        }
                        label="Veg"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            variant="solid"
                            value={"NonVeg"}
                            onChange={(e) => {
                              setFieldValue("foodLabel", e.target.value);
                            }}
                          />
                        }
                        label="Non Veg"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            variant="solid"
                            value={"Other"}
                            onChange={(e) => {
                              setFieldValue("foodLabel", e.target.value);
                            }}
                          />
                        }
                        label="Other"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "75%",
                  }}
                >
                  <Typography>
                    Additional Price (Enter Zero if it is complimentry)
                  </Typography>
                  <Field
                    size="small"
                    fullWidth
                    name="price"
                    component={TextField}
                  />
                </Box>

                <Box>
                  <Typography variant="h6">Add Recipe</Typography>
                  <FieldArray name="recipe">
                    {({ push, remove }) => (
                      <>
                        {values.recipe.map((_, index) => (
                          <Box
                            sx={{ display: "flex", gap: "10px", my: "10px" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "40%",
                              }}
                            >
                              <Typography>Raw Material</Typography>
                              <FormControl sx={{ minWidth: 250 }}>
                                <Autocomplete
                                  options={rawMaterials}
                                  value={
                                    rawMaterials.length &&
                                    rawMaterials.find(
                                      (item) =>
                                        values.recipe[index].rawMaterial ===
                                        item._id
                                    )
                                  }
                                  size="small"
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <Field
                                      component={TextField}
                                      {...params}
                                      value="643f87515914fa9e4fcdf4f3"
                                      onChange={(e) => {}}
                                      name="643f87515914fa9e4fcdf4f3"
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue(
                                        `recipe.${index}.unitId`,
                                        newValue?.purchaseUnit._id
                                      );
                                      setFieldValue(
                                        `recipe.${index}.rawMaterial`,
                                        newValue?._id
                                      );
                                      setFieldValue(
                                        `recipe.${index}.units`,
                                        ""
                                      );
                                    } else {
                                      setFieldValue(
                                        `recipe.${index}.unitId`,
                                        ""
                                      );
                                      setFieldValue(
                                        `recipe.${index}.rawMaterial`,
                                        ""
                                      );
                                      setFieldValue(
                                        `recipe.${index}.units`,
                                        ""
                                      );
                                    }
                                  }}
                                />
                              </FormControl>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "30%",
                              }}
                            >
                              <Typography>Quantity</Typography>
                              <Field
                                size="small"
                                fullWidth
                                name={`recipe.${index}.quantity`}
                                component={TextField}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "30%",
                              }}
                            >
                              <Typography>Units</Typography>
                              <FormControl sx={{ minWidth: 250 }}>
                                <Field
                                  component={Select}
                                  name={`recipe.${index}.units`}
                                  value={values.recipe[index].units}
                                  size="small"
                                  sx={{ mb: 0 }}
                                >
                                  {units?.map((item) => {
                                    return (
                                      item.unit ==
                                        values?.recipe[index]?.unitId && (
                                        <MenuItem value={item._id}>
                                          {item.name}
                                        </MenuItem>
                                      )
                                    );
                                  })}
                                </Field>
                              </FormControl>
                            </Box>
                            <Box sx={{ mt: "25px" }}>
                              <Button onClick={() => remove(index)}>
                                <DeleteIcon />
                              </Button>
                            </Box>
                          </Box>
                        ))}
                        <Button
                          onClick={() => push(emptyRecipe)}
                          variant="contained"
                          sx={{
                            m: 3,
                            textTransform: "none",
                            background: "var(--red)",
                            "&:hover": {
                              backgroundColor: "var(--red)",
                            },
                          }}
                        >
                          + Add Row
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pr: "20px",
                  }}
                >
                  <Box></Box>
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var( --color-secondary)",
                        "&:hover": {
                          backgroundColor: "var( --color-secondary)",
                        },
                      }}
                      onClick={() => back(false)}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var(--red)",
                        "&:hover": {
                          backgroundColor: "var(--red)",
                        },
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
