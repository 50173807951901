import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const kotProductsChangeStatus = async (kotId, status, token) => {
  try {
    const { data } = await axios.put(
      `${host}/posKotProductsChangeStatus/${kotId}`,
      { status: status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const posKotProductsCancel = async (kotId, status, token) => {
  try {
    const { data } = await axios.put(
      `${host}/posKotProductsCancel/${kotId}`,
      { status: status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllKotProducts = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetAllKotProducts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};


export const createKot = async (value, token) => {
  try {
    const { data } = await axios.post(`${host}/posCreateKot`, value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllKot = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetAllKot`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
