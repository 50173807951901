import * as Yup from "yup";
import { array, number, object, string } from "yup";

export const loginSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const findUserSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const codeVerifySchema = Yup.object({
  code: Yup.number()
    .required("Code is required")
    .typeError("field must be a number"),
});

export const changePasswordSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Passwords must match"
  ),
});

export const passwordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string().required("New password is required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("newPassword")],
    "Passwords must match"
  ),
});

export const addGroupSchema = Yup.object({
  groupName: Yup.string().required("Group Title is required"),
  image: Yup.string().required("Image is required"),
});
export const addGroupArSchema = Yup.object({
  groupName: Yup.string().required("Group Title is required"),
  groupNameAr: Yup.string().required("Group Title is required"),
  image: Yup.string().required("image is required"),
});

export const addRecipeSchema = object({
  product: string().required("Your name is required"),
  rawMaterial: array(
    object({
      name: string().required("name is requird"),
      quantity: string().required(" quantity is requird"),
      unit: string().required("unit is requird"),
    })
  ).min(1, "You need to provide at least 1 raw  material"),
});

export const changeOrderStatusSchema = Yup.object({
  DeliveryBoyId: Yup.string().required("Delivery Boy is required"),
  DeliveryTime: Yup.number().required("Time is required"),
});

export const addKotSchema = Yup.object({
  kotName: Yup.string().required("Kot Name is required"),
  selectPrinter: Yup.string(),
});

export const addTableSchema = Yup.object({
  tableNo: Yup.number()
    .required("Table No is required")
    .typeError("field must be a number"),
  noOfPeople: Yup.string().required("No Of People is required"),
  additionalInfo: Yup.string(),
});

export const addAreaSchema = Yup.object({
  areaType: Yup.string().required("Area Type is required"),
  areaName: Yup.string().required("Area Name is required"),
  tables: Yup.array().min(1, "The array must have at least one element"),
  tables: Yup.array().min(1, "The array must have at least one element"),
});

export const purchaseSchema = object({
  supplier: Yup.string().required("Supplier is required"),
  invoicefile: Yup.string().required("invoicefile is required"),
  products: array(
    object({
      name: string().required("name is requird"),
      quantity: number()
        .typeError("field must be a number")
        .required("requird"),
      unit: string().required("requird"),
      price: number().typeError("field must be a number").required("requird"),
      amount: number().typeError("field must be a number").required("requird"),
      tax: number().typeError("field must be a number").required("requird"),
    })
  ).min(1, "You need to provide at least 1 item"),
});

export const billerSchema = Yup.object({
  first_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "First name must contain only alphabets")
    .required("First name is required"),
  last_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Last name must contain only alphabets")
    .required("Last name is required"),
  phone: Yup.string()
    .matches(
      /^(?:\+971|00971|0)?(?:5[024-9]|50|5[56]|6|7|9)\d{7}$|^(?:\+91[\-\s]?)?[0]?(?:[789]\d{9})$/,
      "Invalid phone number"
    )
    .required("Phone number is required"),
  password: Yup.string()
    // .matches(
    //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
    //   "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, and one number"
    // )
    .required("Password is required"),
  userPasscode: Yup.string(),
});

export const editBillerSchema = Yup.object({
  first_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "First name must contain only alphabets")
    .required("First name is required"),
  last_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Last name must contain only alphabets")
    .required("Last name is required"),
  phone: Yup.string()
    .matches(
      /^(?:\+971|00971|0)?(?:5[024-9]|50|5[56]|6|7|9)\d{7}$|^(?:\+91[\-\s]?)?[0]?(?:[789]\d{9})$/,
      "Invalid phone number"
    )
    .required("Phone number is required"),
  userPasscode: Yup.string(),
  password: Yup.string()
    // .matches(
    //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
    //   "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, and one number"
    // )
    .optional("Password is required"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const deliverySchema = Yup.object({
  first_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "First name must contain only alphabets")
    .required("First name is required"),
  last_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Last name must contain only alphabets")
    .required("Last name is required"),
  phone: Yup.string()
    .matches(
      /^(?:\+971|00971|0)?(?:5[024-9]|50|5[56]|6|7|9)\d{7}$|^(?:\+91[\-\s]?)?[0]?(?:[789]\d{9})$/,
      "Invalid phone number"
    )
    .required("Phone number is required"),
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, and one number"
    )
    .required("Password is required"),
  userPasscode: Yup.string(),
  addressLine1: Yup.string().required("address line 1 is required"),
  addressLine2: Yup.string().required("address line 2 is required"),
  dateOfBirth: Yup.string().required("date of birth is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("email is required"),
  city: Yup.string().required("city is required"),
  zipCode: Yup.number()
    .typeError("field must be a number")
    .required("zip Code is required"),
  document: Yup.string()
    .required("Document is required")
    .test(
      "fileType",
      "Unsupported file format, Supported formats are pdf, jpg, peg, png",
      (value) => {
        if (!value) return true; // If no file is uploaded, it's valid
        const acceptedFormats = ["pdf", "jpg", "jpeg", "png"];
        const extension = value.split(".").pop().toLowerCase();
        return acceptedFormats.includes(extension);
      }
    ),
  geoFence: Yup.string().required("geo fence is required"),
  payout: Yup.string().required("payout is required"),
  time: Yup.string().required("time is required"),
});

export const editDeliverySchema = Yup.object({
  first_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "First name must contain only alphabets")
    .required("First name is required"),
  last_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Last name must contain only alphabets")
    .required("Last name is required"),
  phone: Yup.string()
    .matches(
      /^(?:\+971|00971|0)?(?:5[024-9]|50|5[56]|6|7|9)\d{7}$|^(?:\+91[\-\s]?)?[0]?(?:[789]\d{9})$/,
      "Invalid phone number"
    )
    .required("Phone number is required"),
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, and one number"
    )
    .optional("Password is required"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  userPasscode: Yup.string(),
  addressLine1: Yup.string().required("address line 1 is required"),
  addressLine2: Yup.string().required("address line 2 is required"),
  dateOfBirth: Yup.string().required("date of birth is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("email is required"),
  city: Yup.string().required("city is required"),
  zipCode: Yup.number()
    .typeError("field must be a number")
    .required("zip Code is required"),
  document: Yup.string()
    .required("Document is required")
    .test(
      "fileType",
      "Unsupported file format, Supported formats are pdf, jpg, peg, png",
      (value) => {
        if (!value) return true; // If no file is uploaded, it's valid
        const acceptedFormats = ["pdf", "jpg", "jpeg", "png"];
        const extension = value.split(".").pop().toLowerCase();
        return acceptedFormats.includes(extension);
      }
    ),
  payout: Yup.string().required("payout is required"),
  time: Yup.string().required("time is required"),
});

export const currentStockEditSchema = Yup.object({
  currentStock: Yup.number()
    .typeError("field must be a number")
    .required("currentStock is required"),
});

export const addAddonSchema = object({
  title: string().required("Title is required"),
  foodLabel: string().required("food Label is required"),
  price: Yup.number()
    .typeError("field must be a number")
    .required("Price is required"),
  recipe: array(
    object({
      rawMaterial: string().required("Raw Material is requird"),
      quantity: Yup.number()
        .typeError("field must be a number")
        .required("Quantity is requird"),
      units: string().required("Units is requird"),
    })
  ).min(1, "You need to provide at least 1 raw  material"),
});
export const addVariationSchema = object({
  title: string().required("Title is required"),
  items: array(
    object({
      option: string().required("Variation Name is requird"),
    })
  ).min(1, "You need to provide at least 1 Variation Name"),
});
export const addVariationArSchema = object({
  title: string().required("Title is required"),
  items: array(
    object({
      option: string().required("Variation Name is requird"),
      optionAr: string().required("Variation Name is requird"),
    })
  ).min(1, "You need to provide at least 1 Variation Name"),
});
export const selectVariaton = object({
  title: string().required("please select a option"),
});

export const selectAddonSchema = object({
  title: string().required("Title is required"),
  minQty: Yup.number()
    .typeError("field must be a number")
    .required("Min Qty is required"),
  maxQty: Yup.number()
    .typeError("field must be a number")
    .required("Max Qty is required"),
  items: array(
    object({
      item: string().required("Value is requird"),
    })
  ).min(1, "You need to provide at least 1 raw  material"),
});
export const quickAddProductSchema = object({
  title: string().required("Title is required"),
  group: string().required("Group is required"),
  image: Yup.string().required("Image is Requird"),
  category: string().required("Category is required"),
  vegOrNonVeg: string().required("This field is required"),
});

export const addEntrySchema = object({
  particular: string().required("Particular is required"),
});

export const variationTableSchema = object({
  varients: array(
    object({
      productName: string().required("Name is requird"),
      image: Yup.array().required("Image is requird"),
      // stock: string().required("Requird"),
      salePrice: number().required("Requird").typeError("must be a number"),
    })
  ).min(1, "You need to provide at least 1 raw  material"),
});

export const editRawMaterialSchema = Yup.object({
  name: Yup.string().required("name is required"),
  purchaseUnit: Yup.string().required("purchaseUnit is required"),
});

export const wastageSchema = object({
  wastageFor: Yup.string().required("wastageFor is required"),
  items: array(
    object({
      name: Yup.object().required("name is requird"),
      quantity: number()
        .required("unit is requird")
        .typeError("field must be a number"),
      unit: Yup.string().required("unit is requird"),
    })
  ).min(1, "You need to provide at least 1 item"),
});
export const wastageItem = object({
  wastageFor: Yup.string().required("wastageFor is required"),
  items: array(
    object({
      name: Yup.object().required("name is requird"),
      quantity: number()
        .required("unit is requird")
        .typeError("field must be a number"),
    })
  ).min(1, "You need to provide at least 1 item"),
});
export const editWastageItem = object({
  wastageFor: Yup.string().required("wastageFor is required"),
  items: array(
    object({
      productId: Yup.string().required("name is requird"),
      quantity: number()
        .required("unit is requird")
        .typeError("field must be a number"),
    })
  ).min(1, "You need to provide at least 1 item"),
});

export const EditWastageSchemaRowMaterial = object({
  wastageFor: Yup.string().required("wastageFor is required"),
  items: array(
    object({
      RawMaterialId: Yup.string().required("name is requird"),
      quantity: number()
        .required("unit is requird")
        .typeError("field must be a number"),
      unit: Yup.string().required("unit is requird"),
    })
  ).min(1, "You need to provide at least 1 item"),
});

export const addMenuSchema = object({
  title: string().required("title is required."),
  days: Yup.array()
    .min(1, "At least one day must be selected")
    .required("Days are required"),
  products: array(
    object({
      item: string().required("name is requird"),
      days: Yup.array()
        .min(1, "At least one day must be selected")
        .required("Days are required"),
      quantity: number()
        .typeError("field must be a number")
        .required(" quantity is requird"),
    })
  ).min(1, "You need to provide at least 1 raw  material"),
});

export const editRecipeSchema = object({
  product: string().required("a product must be selected."),
  rawMaterial: array(
    object({
      name: string().required("name is requird"),
      quantity: number()
        .typeError("field must be a number")
        .required(" quantity is requird"),
      unit: string().required("unit is requird"),
    })
  ).min(1, "You need to provide at least 1 raw  material"),
});

export const updateProfileSchema = Yup.object({
  logo: Yup.string().required("Image is required"),
  businessName: Yup.string().required("businessName is required"),
  businessAddress: Yup.string().required("businessAddress is required"),
  addressLine1: Yup.string().required("addressLine1 is required"),
  addressLine2: Yup.string().required("addressLine2 is required"),
  city: Yup.string().required("city is required"),
  postalCode: Yup.number()
    .typeError("field must be a number")
    .required("postalCode is required"),
  TRN: Yup.number().typeError("field must be a number"),
  businessStartTime: Yup.string().required("businessStartTime is required"),
  businessEndTime: Yup.string().required("businessEndTime is required"),
  currency: Yup.string().required("Currency is required"),
  language: Yup.string().required("Language is required"),
});

export const printingIpSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  ip: Yup.string().required("Ip is required"),
});

export const MerchantSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
    first_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "First name must contain only alphabets")
    .required("First name is required"),
  last_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Last name must contain only alphabets")
    .required("Last name is required"),
  phone: Yup.string()
    .matches(
      /^(?:\+971|00971|0)?(?:5[024-9]|50|5[56]|6|7|9)\d{7}$|^(?:\+91[\-\s]?)?[0]?(?:[789]\d{9})$/,
      "Invalid phone number"
    )
    .required("Phone number is required"),
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, and one number"
    )
    .optional("Password is required"),
  userPasscode: Yup.string(),
});
