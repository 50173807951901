import { useState } from "react";
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Modal,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import SearchBar from "../../common/SearchBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { addMenu, getAllMenu, getProductName } from "../../../sevice/inventory";
import EditMenu from "./EditMenu";
import { toast } from "react-hot-toast";
import { addMenuSchema } from "../../../schemas";
import { CheckboxWithLabel } from "formik-material-ui";
import { useEffect } from "react";
import LoadingImage from "../../../assets/loader.gif";

const style = {
  width: "90%",
  position: "absolute",
  top: "54%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
const StockAvailable = styled(Box)({
  display: "flex",
  width: 150,
  height: 40,
  color: "var(--bg-white)",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));
const options = [
  {
    label: "Sun",
    value: "Sun",
  },
  {
    label: "Mon",
    value: "Mon",
  },
  {
    label: "Tue",
    value: "Tue",
  },
  {
    label: "Wed",
    value: "Wed",
  },
  {
    label: "Thu",
    value: "Thu",
  },
  {
    label: "Fri",
    value: "Fri",
  },
  {
    label: "Sat",
    value: "Sat",
  },
];

function Menu({ state, setState }) {
  const { POSadmin } = useSelector((state) => state);
  const [products, setProducts] = useState([]);
  const [menu, setMenu] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [editItem, setEditItem] = useState({});
  const [IsLoading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [pageNo, setPageNo] = useState(1);
  const [isSubmit, setIsSubmit] = useState(false);

  const initialValues = {
    vendor: POSadmin.id,
    title: "",
    autoPurchase: "No",
    days: [],
    products: [{ item: "", days: [], quantity: "" }],
  };
  const emptyMenu = { item: "", days: [], quantity: "" };
  const submitdata = async (values, action) => {
    setIsSubmit(true);
    const data = await addMenu(POSadmin.token, values);
    if (data.status) {
      toast.success(data.message);
      action.resetForm();
      setState(true);
    } else {
      toast.error(data.message);
    }
  };

  const getDatas = async () => {
    const data = await getProductName(POSadmin.token);
    const data1 = await getAllMenu(POSadmin.token, searchQuery, pageNo);
    setProducts(data);
    setMenu(data1);
    setLoading(false);
  };
  useEffect(() => {
    getDatas();
  }, [state, searchQuery, pageNo]);

  const handleChangePageNo = (event, value) => {
    setPageNo(value);
  };
  return (
    <>
      {state && !IsLoading && (
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            searchBarWidth={"200px"}
            placeholder={"Search"}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setPageNo(1);
            }}
          />
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={() => {
              setState(false);
              setIsSubmit(false);
            }}
          >
            + Add Menu
          </Button>
        </Box>
      )}
      {IsLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img style={{ height: "300px" }} src={LoadingImage} alt="Loading" />
        </Box>
      ) : state ? (
        <Box sx={{ marginTop: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead sx={{ background: "var(--table-head)" }}>
                <TableRow>
                  <StyledTableCell align="center">SL No</StyledTableCell>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">
                    Stock availability
                  </StyledTableCell>
                  <StyledTableCell align="center">Days</StyledTableCell>
                  <StyledTableCell align="center">
                    Auto Purchase
                  </StyledTableCell>
                  <StyledTableCell align="center">Active</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {menu.docs?.map((item, index) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">
                      {menu.pagingCounter + index}
                    </TableCell>
                    <TableCell align="center">{item.title}</TableCell>
                    <TableCell align="center">
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <StockAvailable
                          style={{ background: "var(--green-color)" }}
                        >
                          In stock
                        </StockAvailable>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <Box
                            sx={{
                              display: "flex",
                              paddingTop: 3,
                              width: 350,
                            }}
                          >
                            <Grid container spacing={0}>
                              {item.days.map((item, index) => (
                                <Grid xs={3} sm={3} md={3} lg={3}>
                                  <FormControlLabel
                                    checked={item}
                                    control={<Checkbox />}
                                    label={item}
                                    labelPlacement="end"
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </FormGroup>
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        sx={{ marginTop: "0px" }}
                        inputProps={{ "aria-label": "controlled" }}
                        color="error"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        sx={{ marginTop: "0px" }}
                        inputProps={{ "aria-label": "controlled" }}
                        color="error"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <VisibilityIcon />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <SaveAsIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setEditItem(item);
                          handleOpen();
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            component={Paper}
            sx={{
              marginTop: "10px",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <Stack>
              <Pagination
                count={menu?.totalPages}
                page={pageNo}
                onChange={handleChangePageNo}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <EditMenu
                editItem={editItem}
                handleClose={handleClose}
                products={products}
              />
            </Box>
          </Modal>
        </Box>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={addMenuSchema}
            onSubmit={submitdata}
          >
            {({ values, setFieldValue, errors }) => (
              <Form autoComplete="off">
                <Box>
                  <Typography sx={{ p: 3 }} variant="h6">
                    Add Menu
                  </Typography>
                  <Card
                    elevation={0}
                    sx={{
                      width: "100%",
                      height: "280px",
                      marginRight: "15px",
                      marginBottom: "15px",
                      padding: 3,
                      border: "1px solid #ccc",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "40%",
                        }}
                      >
                        <InputLabel>Title</InputLabel>
                        <Field name={"title"} component={TextField} />
                        <Box sx={{ p: 5 }}>
                          <FormGroup>
                            <Grid container spacing={3}>
                              {options.map((opt) => (
                                <Grid xs={2} sm={4} md={3} lg={3}>
                                  <Field
                                    type="checkbox"
                                    component={CheckboxWithLabel}
                                    name="days"
                                    key={opt.value}
                                    value={opt.value}
                                    onChange={(e) => {
                                      const arr = values.days;
                                      if (e.target.checked) {
                                        arr.push(opt.value);
                                      } else {
                                        const index = arr.indexOf(opt.value);
                                        if (index !== -1) {
                                          arr.splice(index, 1);
                                        }
                                      }
                                      setFieldValue(`days`, arr);
                                    }}
                                    checked={values.days.includes(opt.value)}
                                    Label={{ label: opt.label }}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </FormGroup>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <InputLabel>Auto Purchase</InputLabel>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="autoPurchase"
                            value={values.autoPurchase}
                            onChange={(e) => {
                              setFieldValue("autoPurchase", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                            <FormControlLabel
                              value="Daily"
                              control={<Radio />}
                              label="Daily"
                            />
                            <FormControlLabel
                              value="Weekly"
                              control={<Radio />}
                              label="Weekly"
                            />
                            <FormControlLabel
                              value="Monthly"
                              control={<Radio />}
                              label="Monthly"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Card>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead sx={{ background: "var(--table-head)" }}>
                        <TableRow>
                          <StyledTableCell align="center">Item</StyledTableCell>
                          <StyledTableCell align="center">Days</StyledTableCell>
                          <StyledTableCell align="center">Qty</StyledTableCell>
                          <StyledTableCell align="center">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>{" "}
                      <FieldArray name="products">
                        {({ push, remove }) => (
                          <>
                            <TableBody>
                              {values.products.map((_, index) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {/* <Field
                                        size="small"
                                        name={`products.${index}.item`}
                                        component={TextField}
                                      /> */}
                                    <Autocomplete
                                      size="small"
                                      options={products}
                                      getOptionLabel={(option) => option.item}
                                      renderInput={(params) => (
                                        <Field
                                          component={TextField}
                                          {...params}
                                          value={params.id}
                                          name={`products.${index}.item`}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      )}
                                      onChange={(event, newValue) => {
                                        if (newValue) {
                                          setFieldValue(
                                            `products.${index}.item`,
                                            newValue?.id
                                          );
                                        } else {
                                          setFieldValue(
                                            `products.${index}.item`,
                                            ""
                                          );
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <FormControl component="fieldset">
                                      <FormGroup>
                                        <Grid
                                          container
                                          spacing={3}
                                          sx={{ padding: 4 }}
                                        >
                                          {options.map((opt) => (
                                            <Grid xs={2} sm={4} md={3} lg={3}>
                                              <Field
                                                disabled={
                                                  !values.days.includes(
                                                    opt.value
                                                  )
                                                }
                                                type="checkbox"
                                                component={CheckboxWithLabel}
                                                name={`products.${index}.days`}
                                                key={opt.value}
                                                value={opt.value}
                                                onChange={(e) => {
                                                  const arr =
                                                    values.products[index].days;
                                                  if (e.target.checked) {
                                                    arr.push(opt.value);
                                                  } else {
                                                    const index = arr.indexOf(
                                                      opt.value
                                                    );
                                                    if (index !== -1) {
                                                      arr.splice(index, 1);
                                                    }
                                                  }
                                                  setFieldValue(
                                                    `products.${index}.days`,
                                                    arr
                                                  );
                                                }}
                                                checked={values.products[
                                                  index
                                                ].days.includes(opt.value)}
                                                Label={{ label: opt.label }}
                                              />
                                            </Grid>
                                          ))}
                                        </Grid>
                                      </FormGroup>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Field
                                      size="small"
                                      name={`products.${index}.quantity`}
                                      component={TextField}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <DeleteIcon onClick={() => remove()} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            <Button
                              onClick={() => push(emptyMenu)}
                              variant="contained"
                              sx={{
                                m: 3,
                                width: 105,
                                textTransform: "none",
                                background: "var(--red)",
                                "&:hover": {
                                  backgroundColor: "var(--red)",
                                },
                              }}
                            >
                              + Add Row
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var( --color-secondary)",
                        "&:hover": {
                          backgroundColor: "var( --color-secondary)",
                        },
                      }}
                      onClick={() => setState(true)}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      disabled={isSubmit}
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var(--red)",
                        "&:hover": {
                          backgroundColor: "var(--red)",
                        },
                      }}
                      type="submit"
                    >
                      {isSubmit ? "Loading.." : "Save"}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
}

export default Menu;
