import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Button, Toolbar, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";

export default function FlowDirection({
  items,
  backFunction,
  showBack,
  showArabic,
}) {
  const { isLanguageSwitched } = useSelector((state) => state);
  const [isLanguageChanged, setIsLanguageChanged] =
    useState(isLanguageSwitched);
  const dispatch = useDispatch();

  const { i18 } = useTranslation();
  const handleLanguageChange = () => {
    setIsLanguageChanged(!isLanguageChanged);
    Cookies.set("isLanguageSwitched", !isLanguageChanged);
    dispatch({
      type: "isLanguageSwitched",
      payload: !isLanguageChanged,
    });
  };
  return (
    <Box sx={{}}>
      <AppBar
        sx={{
          background: "var(--bg-white)",

          borderBottom: "solid var(--border-gray) 1px",
        }}
        elevation={0}
      >
        <Toolbar />
        <div
          style={{
            marginLeft: "90px",
            marginRight: "60px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {showBack && (
            <Button
              sx={{
                color: "var(--red)",
                borderColor: "var(--red)",
                "&:hover": {
                  color: "var(--red)",
                  borderColor: "var(--red)",
                },
                mx: "5px",
                height: "30px",
              }}
              onClick={backFunction}
            >
              <ArrowBackIcon />
            </Button>
          )}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {items?.map((row, index) => (
              <>
                {row.show && (
                  <>
                    <Typography
                      key={index}
                      sx={{
                        color: "var(--font-black)",
                        fontSize: 15,
                        fontWeight: 500,

                        marginRight: "10px",
                        textTransform: "none",
                      }}
                    >
                      {row.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--font-black)",
                        fontSize: 15,
                        fontWeight: 500,
                        marginRight: "10px",
                        textTransform: "none",
                      }}
                    >
                      {items[index + 1]?.show && "＞"}
                    </Typography>
                  </>
                )}
              </>
            ))}
          </Box>
          {showArabic && (
            <FormGroup sx={{ marginLeft: "auto", color: "var(--font-black)" }}>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={isLanguageSwitched ? true : false}
                    onChange={handleLanguageChange}
                  />
                }
                label="Arabic"
              />
            </FormGroup>
          )}
        </div>
      </AppBar>
    </Box>
  );
}
