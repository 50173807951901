import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { updateProfileSchema } from "../schemas";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { posAdminData, posUpdateProfile } from "../sevice/admin";
import FlowDirection from "./../components/posAdminPanel/navBarAndSideBar/FlowDirection";

export default function Profile() {
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    title: "",
    first_name: "",
    last_name: "",
    businessName: "",
    businessAddress: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postalCode: "",
    TRN: "",
    instagram: "",
    youTube: "",
    googleAccount: "",
    businessStartTime: "",
    businessEndTime: "",
    currency: "indianRupee",
    language: "english",
    logo: "",
    minOrderValue: "",
    deliveryChargePerKm: "",
    deliveryRadius: "",
    freeDeliveryValue: "",
    freeDeliveryKm: "",
  });
  const { POSadmin } = useSelector((state) => state);
  useEffect(() => {
    userData();
  }, []);
  const userData = async () => {
    const data = await posAdminData(POSadmin.token);
    setInitialValues({
      title: data.title,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      mobile: data.mobile,
      businessName: data.businessName,
      businessAddress: data.businessAddress,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      postalCode: data.postalCode,
      TRN: data.TRN,
      instagram: data.instagram,
      youTube: data.youTube,
      googleAccount: data.googleAccount,
      businessStartTime: data.businessStartTime,
      businessEndTime: data.businessEndTime,
      currency: "indianRupee",
      language: "english",
      logo: data.logo,
      oldLogo: data.logo,
      minOrderValue: data.minOrderValue,
      deliveryChargePerKm: data.deliveryChargePerKm,
      deliveryRadius: data.deliveryRadius,
      freeDeliveryValue: data.freeDeliveryValue,
      freeDeliveryKm: data.freeDeliveryKm,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: updateProfileSchema,
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append("data", JSON.stringify(values));
      formData.append("image", values.logo);
      const data = await posUpdateProfile(
        formData,
        POSadmin.id,
        POSadmin.token
      );
      if (data.status) {
        userData();
        toast.success(data.message);
      }
    },
  });
  const FlowDirectionItems = [
    {
      name: "business Profile",
      show: true,
    },
  ];

  const backFunction = () => {
    navigate("/settings");
  };
  return (
    <Box>
      <FlowDirection
        items={FlowDirectionItems}
        showBack={true}
        backFunction={backFunction}
      />
      <Box
        sx={{
          marginTop: "100px",
          padding: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
            }}
          >
            <FormLabel
              sx={{ color: "#000", fontWeight: "bold", fontSize: "15px" }}
              id="logo-label"
            >
              Logo
            </FormLabel>
            <Box
              sx={{
                width: "90px",
                height: "90px",
                border: "1px dashed #000",
                borderRadius: "50%",
              }}
            >
              <input
                name="logo"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    formik.setFieldValue("logo", e.target.files[0]);
                  }
                }}
                type="file"
                accept="image/*"
                id="logo"
                style={{ display: "none" }}
              />
              <label htmlFor="logo">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={
                      typeof formik?.values?.logo == "string"
                        ? formik?.values?.logo
                        : (window.URL || window.webkitURL).createObjectURL(
                            formik?.values?.logo
                          )
                    }
                    alt=""
                    style={{
                      width: "90px",
                      height: "90px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    bgcolor: "red",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    position: "relative",
                    top: -60,
                    left: 30,
                  }}
                >
                  +
                </Box>
              </label>
            </Box>
            <Typography sx={{ fontFamily: "poppins", fontWeight: "bold" }}>
              Primary Contact
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <FormLabel
                    sx={{ color: "#000", fontFamily: "poppins" }}
                    id="title-label"
                  >
                    Title
                  </FormLabel>
                  <Select
                    name="title"
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    error={!!formik.touched.title && !!formik.errors.title}
                    helperText={formik.touched.title && formik.errors.title}
                    onChange={formik.handleChange}
                    id="title-label"
                    size="small"
                    sx={{ width: "290px", bgcolor: "#fff" }}
                  >
                    <MenuItem value={"Mr."}>Mr.</MenuItem>
                    <MenuItem value={"Mrs."}>Mrs.</MenuItem>
                    <MenuItem value={"Ms."}>Ms.</MenuItem>
                    <MenuItem value={"Miss"}>Miss</MenuItem>
                  </Select>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <FormLabel
                    sx={{ color: "#000", fontFamily: "poppins" }}
                    id="firstName-label"
                  >
                    First Name
                  </FormLabel>
                  <TextField
                    name="first_name"
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                    error={
                      !!formik.touched.first_name && !!formik.errors.first_name
                    }
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                    onChange={formik.handleChange}
                    type="text"
                    variant="outlined"
                    size="small"
                    placeholder="First Name"
                    sx={{ width: "290px", bgcolor: "#fff" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <FormLabel
                    sx={{ color: "#000", fontFamily: "poppins" }}
                    id="lastName-label"
                  >
                    Last Name
                  </FormLabel>
                  <TextField
                    name="last_name"
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                    error={
                      !!formik.touched.last_name && !!formik.errors.last_name
                    }
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                    onChange={formik.handleChange}
                    type="text"
                    variant="outlined"
                    size="small"
                    placeholder="Last Name"
                    sx={{ width: "290px", bgcolor: "#fff" }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="phone-label"
                >
                  Phone
                </FormLabel>
                <TextField
                  name="phone"
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  error={!!formik.touched.phone && !!formik.errors.phone}
                  helperText={formik.touched.phone && formik.errors.phone}
                  onChange={formik.handleChange}
                  type="number"
                  variant="outlined"
                  size="small"
                  placeholder="Phone Number"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="mobile-label"
                >
                  Mobile
                </FormLabel>
                <TextField
                  name="mobile"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  error={!!formik.touched.mobile && !!formik.errors.mobile}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                  onChange={formik.handleChange}
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Mobile Number"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                />
              </Box>
            </Box>
            <Typography sx={{ fontFamily: "poppins", fontWeight: "bold" }}>
              Business Contact
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Business Name
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Country"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                  name="businessName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.businessName}
                  error={
                    !!formik.touched.businessName &&
                    !!formik.errors.businessName
                  }
                  helperText={
                    formik.touched.businessName && formik.errors.businessName
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Business Address
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="City"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                  name="businessAddress"
                  onBlur={formik.handleBlur}
                  value={formik.values.businessAddress}
                  error={
                    !!formik.touched.businessAddress &&
                    !!formik.errors.businessAddress
                  }
                  helperText={
                    formik.touched.businessAddress &&
                    formik.errors.businessAddress
                  }
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Address Line 1
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Address Line 1"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                  name="addressLine1"
                  onBlur={formik.handleBlur}
                  value={formik.values.addressLine1}
                  error={
                    !!formik.touched.addressLine1 &&
                    !!formik.errors.addressLine1
                  }
                  helperText={
                    formik.touched.addressLine1 && formik.errors.addressLine1
                  }
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Address Line 2
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Address Line 2"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                  name="addressLine2"
                  onBlur={formik.handleBlur}
                  value={formik.values.addressLine2}
                  error={
                    !!formik.touched.addressLine2 &&
                    !!formik.errors.addressLine2
                  }
                  helperText={
                    formik.touched.addressLine2 && formik.errors.addressLine2
                  }
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  City
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="City"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                  name="city"
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  error={!!formik.touched.city && !!formik.errors.city}
                  helperText={formik.touched.city && formik.errors.city}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Postal Code
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Postal Code"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                  name="postalCode"
                  onBlur={formik.handleBlur}
                  value={formik.values.postalCode}
                  error={
                    !!formik.touched.postalCode && !!formik.errors.postalCode
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  TRN
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Tax Registration Number"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                  name="TRN"
                  onBlur={formik.handleBlur}
                  value={formik.values.TRN}
                  error={!!formik.touched.TRN && !!formik.errors.TRN}
                  helperText={formik.touched.TRN && formik.errors.TRN}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Instagram
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Instagram"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                  name="instagram"
                  onBlur={formik.handleBlur}
                  value={formik.values.instagram}
                  error={
                    !!formik.touched.instagram && !!formik.errors.instagram
                  }
                  helperText={
                    formik.touched.instagram && formik.errors.instagram
                  }
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  You Tube
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="You Tube"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                  name="youTube"
                  onBlur={formik.handleBlur}
                  value={formik.values.youTube}
                  error={!!formik.touched.youTube && !!formik.errors.youTube}
                  helperText={formik.touched.youTube && formik.errors.youTube}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Google Account
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Google Account"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                  name="googleAccount"
                  onBlur={formik.handleBlur}
                  value={formik.values.googleAccount}
                  error={
                    !!formik.touched.googleAccount &&
                    !!formik.errors.googleAccount
                  }
                  helperText={
                    formik.touched.googleAccount && formik.errors.googleAccount
                  }
                  onChange={formik.handleChange}
                />
              </Box>
            </Box>
            <Typography sx={{ fontFamily: "poppins", fontWeight: "bold" }}>
              Delivery Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Min Order Value
                </FormLabel>
                <TextField
                  name="minOrderValue"
                  onBlur={formik.handleBlur}
                  value={formik.values.minOrderValue}
                  error={
                    !!formik.touched.minOrderValue &&
                    !!formik.errors.minOrderValue
                  }
                  helperText={
                    formik.touched.minOrderValue && formik.errors.minOrderValue
                  }
                  onChange={formik.handleChange}
                  size="small"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Delivery Charge Per Kilometer
                </FormLabel>
                <TextField
                  name="deliveryChargePerKm"
                  onBlur={formik.handleBlur}
                  value={formik.values.deliveryChargePerKm}
                  error={
                    !!formik.touched.deliveryChargePerKm &&
                    !!formik.errors.deliveryChargePerKm
                  }
                  helperText={
                    formik.touched.deliveryChargePerKm &&
                    formik.errors.deliveryChargePerKm
                  }
                  onChange={formik.handleChange}
                  size="small"
                  placeholder=" Delivery Charge Per Kilometer"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Free Delivery Kilometer
                </FormLabel>
                <TextField
                  name="freeDeliveryKm"
                  onBlur={formik.handleBlur}
                  value={formik.values.freeDeliveryKm}
                  error={
                    !!formik.touched.freeDeliveryKm &&
                    !!formik.errors.freeDeliveryKm
                  }
                  helperText={
                    formik.touched.freeDeliveryKm &&
                    formik.errors.freeDeliveryKm
                  }
                  onChange={formik.handleChange}
                  size="small"
                  placeholder="Free Delivery Kilometer"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Delivery Radius
                </FormLabel>
                <TextField
                  name="deliveryRadius"
                  onBlur={formik.handleBlur}
                  value={formik.values.deliveryRadius}
                  error={
                    !!formik.touched.deliveryRadius &&
                    !!formik.errors.deliveryRadius
                  }
                  helperText={
                    formik.touched.deliveryRadius &&
                    formik.errors.deliveryRadius
                  }
                  onChange={formik.handleChange}
                  placeholder="Delivery Radius"
                  size="small"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Free Delivery Value
                </FormLabel>
                <TextField
                  name="freeDeliveryValue"
                  onBlur={formik.handleBlur}
                  value={formik.values.freeDeliveryValue}
                  error={
                    !!formik.touched.freeDeliveryValue &&
                    !!formik.errors.freeDeliveryValue
                  }
                  helperText={
                    formik.touched.freeDeliveryValue &&
                    formik.errors.freeDeliveryValue
                  }
                  onChange={formik.handleChange}
                  placeholder="Free Delivery Value"
                  size="small"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                />
              </Box>
            </Box>
            <Typography sx={{ fontFamily: "poppins", fontWeight: "bold" }}>
              General Info
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Business Timing
                </FormLabel>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <TextField
                    name="businessStartTime"
                    onBlur={formik.handleBlur}
                    value={formik.values.businessStartTime}
                    error={
                      !!formik.touched.businessStartTime &&
                      !!formik.errors.businessStartTime
                    }
                    helperText={
                      formik.touched.businessStartTime &&
                      formik.errors.businessStartTime
                    }
                    onChange={formik.handleChange}
                    type="time"
                    variant="outlined"
                    size="small"
                    sx={{ width: "133px", bgcolor: "#fff" }}
                  />
                  <FormLabel sx={{ color: "#000", fontFamily: "poppins" }}>
                    To
                  </FormLabel>
                  <TextField
                    name="businessEndTime"
                    onBlur={formik.handleBlur}
                    value={formik.values.businessEndTime}
                    error={
                      !!formik.touched.businessEndTime &&
                      !!formik.errors.businessEndTime
                    }
                    helperText={
                      formik.touched.businessEndTime &&
                      formik.errors.businessEndTime
                    }
                    onChange={formik.handleChange}
                    type="time"
                    variant="outlined"
                    size="small"
                    sx={{ width: "133px", bgcolor: "#fff" }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="sortby-label"
                >
                  Day
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Mon - Sat"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="currency-label"
                >
                  Currency
                </FormLabel>
                <Select
                  name="currency"
                  onBlur={formik.handleBlur}
                  value={formik.values.currency}
                  error={!!formik.touched.currency && !!formik.errors.currency}
                  helperText={formik.touched.currency && formik.errors.currency}
                  onChange={formik.handleChange}
                  id="currency-label"
                  size="small"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                >
                  <MenuItem value={"indianRupee"}>₹ Indian Rupee</MenuItem>
                </Select>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormLabel
                  sx={{ color: "#000", fontFamily: "poppins" }}
                  id="language-label"
                >
                  Language
                </FormLabel>
                <Select
                  name="language"
                  onBlur={formik.handleBlur}
                  value={formik.values.language}
                  error={!!formik.touched.language && !!formik.errors.language}
                  helperText={formik.touched.language && formik.errors.language}
                  onChange={formik.handleChange}
                  id="language-label"
                  size="small"
                  sx={{ width: "290px", bgcolor: "#fff" }}
                >
                  <MenuItem value={"english"}>English</MenuItem>
                </Select>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
                maxWidth: "1220px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#F40000",
                  "&:hover": {
                    backgroundColor: "#F40000",
                  },
                  width: "100px",
                }}
                type="submit"
              >
                Save
              </Button>
              <Button
                onClick={() => formik.resetForm()}
                variant="contained"
                sx={{
                  bgcolor: "#8B8B8B",
                  "&:hover": {
                    backgroundColor: "#8B8B8B",
                  },
                  width: "100px",
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
