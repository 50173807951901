import React from "react";
import "./index.css";
import App from "./App";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./store/rootReducer";
import { Provider, useSelector } from "react-redux";
import i18nNext from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import languageEn from "../src/translation/en/en_US_Language.json";
import languageAr from "../src/translation/ar/ar_SA_Language.json";
import { EditDataProvider } from "./context/EditDataContext";
const store = createStore(rootReducer, composeWithDevTools());

const root = ReactDOM.createRoot(document.getElementById("root"));



function MainApp() {

  const { isLanguageSwitched } = useSelector((state) => state);

  
  i18nNext
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: {
          translation: languageEn,
        },
        ar: {
          translation: languageAr,
        },
      },
      lng: isLanguageSwitched ? "ar" : "en",
      // fallbackLng: "en",

      interpolation: {
        escapeValue: false,
      },
    });

  return (
    <React.StrictMode>
      <BrowserRouter>
      <EditDataProvider>
        <I18nextProvider i18n={i18nNext}>
          <App />
        </I18nextProvider>
        </EditDataProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

root.render(
  <Provider store={store}>
    <MainApp />
  </Provider>
);
