import { Box, TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FlowDirection from "../components/posAdminPanel/navBarAndSideBar/FlowDirection";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { changePasswordByOldPassword } from "../sevice/admin";
import { passwordSchema } from "../schemas/index";

function ChangePassword() {
  const { POSadmin } = useSelector((state) => state);
  const navigate = useNavigate();

  const FlowDirectionItems = [
    {
      name: "Change Password",
      show: true,
    },
  ];
  const backFunction = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: passwordSchema,
    onSubmit: async (values) => {
      const data = await changePasswordByOldPassword(POSadmin.token, values);
      if (data.status) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    },
  });

  return (
    <Box>
      <FlowDirection
        items={FlowDirectionItems}
        showBack={true}
        backFunction={backFunction}
      />
      <Box sx={{ marginTop: "100px", padding: 2 }}>
        <Box
          sx={{ padding: "20px", display: "flex" }}
          component="form"
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              sx={{ marginLeft: "30px" }}
              id="outlined-basic"
              label="Enter old password"
              variant="outlined"
              name="oldPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.oldPassword}
              error={
                !!formik.touched.oldPassword && !!formik.errors.oldPassword
              }
              helperText={
                formik.touched.oldPassword && formik.errors.oldPassword
              }
            />
            <TextField
              sx={{ marginLeft: "30px" }}
              id="outlined-basic"
              label="Enter new password"
              variant="outlined"
              type="password"
              name="newPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.newPassword}
              error={
                !!formik.touched.newPassword && !!formik.errors.newPassword
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
            />
            <TextField
              sx={{ marginLeft: "30px" }}
              id="outlined-basic"
              label="Confirm new password"
              variant="outlined"
              type="password"
              name="confirmPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              error={
                !!formik.touched.confirmPassword &&
                !!formik.errors.confirmPassword
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              <Button
                sx={{
                  background: "var(--red)",
                  "&:hover": {
                    background: "var(--red)",
                  },
                  marginTop: "10px",
                  width: 150,
                }}
                type="submit"
                variant="contained"
              >
                Reset
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ChangePassword;
