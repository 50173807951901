import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  Toolbar,
  RadioGroup,
  Typography,
  Autocomplete,
} from "@mui/material";
import { Box } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField, Select } from "formik-mui";
import { toast } from "react-hot-toast";
import { getAllSubUnits, getRawMaterials } from "../../../sevice/inventory";
import { addAddonSchema } from "../../../schemas";
import { createAddon } from "../../../sevice/group";
import { useTranslation } from "react-i18next";

const initialValues = {
  title: "",
  titleAr: "",
  foodLabel: "Veg",
  price: "",
  recipe: [{ rawMaterial: "", quantity: "", units: "", unitId: "" }],
};
const emptyRecipe = { rawMaterial: "", quantity: "", units: "" };

export default function CreateAddons({ anchor, toggleDrawer }) {
  const { POSadmin, isLanguageSwitched } = useSelector((state) => state);
  const { t } = useTranslation();

  const [rawMaterials, setRawMaterials] = useState([]);
  const [units, setUnits] = useState([]);

  const submitdata = async (values) => {
    const data = await createAddon(POSadmin.token, values);
    if (data.status) {
      toast.success(data.message);
      toggleDrawer(["create"], false)();
    } else {
      toast.error(data.message);
    }
  };

  const fetchRawMaterials = async () => {
    const data = await getRawMaterials(POSadmin.token, "");
    setRawMaterials(data.docs);
  };

  const getUnits = async () => {
    const data = await getAllSubUnits();
    setUnits(data);
  };
  useEffect(() => {
    fetchRawMaterials();
    getUnits();
  }, []);
  return (
    <Box
      sx={{
        width: "50vw",
        p: "20px",
      }}
      role="presentation"
    >
      <Toolbar />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography>
            {/* {t("createAddons.CREATE_ADDONS")} */}Create Add-ons
          </Typography>
        </Box>
        <>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              mr: "20px",
            }}
            onClick={toggleDrawer(["create"], false)}
          >
            {/* {t("common.BACK")} */}Back
          </Button>
        </>
      </Box>

      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={addAddonSchema}
          onSubmit={submitdata}
        >
          {({ values, errors, setFieldValue }) => (
            <Form autoComplete="off">
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "75%",
                  }}
                >
                  <Typography>
                    {/* {t('createAddons.TITLE')} */}Title
                  </Typography>
                  <Field
                    size="small"
                    fullWidth
                    name="title"
                    component={TextField}
                  />

                  {isLanguageSwitched && (
                    <Box sx={{ marginTop: "15px" }}>
                      <Field
                        placeholder="اسم العنصر"
                        size="small"
                        fullWidth
                        name="titleAr"
                        component={TextField}
                        inputProps={{ style: { textAlign: "right" } }}
                      />
                    </Box>
                  )}
                </Box>
                <Box>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={values.foodLabel}
                      row
                      aria-label="position"
                      name="foodLabel"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            variant="solid"
                            value={"Veg"}
                            onChange={(e) => {
                              setFieldValue("foodLabel", e.target.value);
                            }}
                          />
                        }
                        label="Veg"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            variant="solid"
                            value={"NonVeg"}
                            onChange={(e) => {
                              setFieldValue("foodLabel", e.target.value);
                            }}
                          />
                        }
                        label="Non Veg"
                        labelPlacement="end"
                      />{" "}
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            variant="solid"
                            value={"Other"}
                            onChange={(e) => {
                              setFieldValue("foodLabel", e.target.value);
                            }}
                          />
                        }
                        label="Other"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "75%",
                  }}
                >
                  <Typography>
                    Additional Price (Enter Zero if it is complimentary)
                    {/* {t("createAddons.ADDITIONAL_PRICE")} */}
                  </Typography>
                  <Field
                    size="small"
                    fullWidth
                    name="price"
                    component={TextField}
                  />
                </Box>

                <Box>
                  <Typography variant="h6">
                    {/* {t("createAddons.ADD_RECIPE")} */}Add Recipe
                  </Typography>
                  <FieldArray name="recipe">
                    {({ push, remove }) => (
                      <>
                        {values.recipe.map((_, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              gap: "5px",
                              my: "10px",
                              // background: "red",
                              width: "100%",
                              // width: "red",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "40%",
                              }}
                            >
                              <Typography>
                                {/* {t("createAddons.RAW_MATERIAL")} */}Raw
                                Material
                              </Typography>
                              {/* <FormControl sx={{ minWidth: "100%" }}>
                                <Field
                                  size="small"
                                  component={Select}
                                  type="text"
                                  name={`recipe.${index}.rawMaterial`}
                                  // inputProps={{
                                  //   name: `addOn.${index}.item`,
                                  //   id: `addOn.${index}.item`,
                                  // }}
                                >
                                  {rawMaterials.map((item) => (
                                    <MenuItem key={item._id} value={item._id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </FormControl> */}

                              <FormControl sx={{ minWidth: 200 }}>
                                <Autocomplete
                                  options={rawMaterials}
                                  size="small"
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <Field
                                      component={TextField}
                                      {...params}
                                      value={`recipe.${index}.rawMaterial`}
                                      onChange={(e) => {}}
                                      name={`recipe.${index}.rawMaterial`}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue(
                                        `recipe.${index}.unitId`,
                                        newValue?.purchaseUnit._id
                                      );
                                      setFieldValue(
                                        `recipe.${index}.rawMaterial`,
                                        newValue?._id
                                      );
                                      setFieldValue(
                                        `recipe.${index}.units`,
                                        ""
                                      );
                                    } else {
                                      setFieldValue(
                                        `recipe.${index}.unitId`,
                                        ""
                                      );
                                      setFieldValue(
                                        `recipe.${index}.rawMaterial`,
                                        ""
                                      );
                                      setFieldValue(
                                        `recipe.${index}.units`,
                                        ""
                                      );
                                    }
                                  }}
                                />
                              </FormControl>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                // width: "35%",
                                minWidth: 100,
                              }}
                            >
                              <Typography>
                                {/* {t("createAddons.QUANTITY")} */}
                                Quantity
                              </Typography>
                              <Field
                                size="small"
                                fullWidth
                                name={`recipe.${index}.quantity`}
                                component={TextField}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                // width: "25%",
                              }}
                            >
                              <Typography>
                                {/* {t("createAddons.UNITS")} */}Units
                              </Typography>
                              <FormControl sx={{ minWidth: 150 }}>
                                <Field
                                  component={Select}
                                  name={`recipe.${index}.units`}
                                  size="small"
                                  sx={{ mb: 0 }}
                                >
                                  {units?.map((item) => {
                                    return (
                                      item.unit ==
                                        values?.recipe[index]?.unitId && (
                                        <MenuItem value={item._id}>
                                          {item.name}
                                        </MenuItem>
                                      )
                                    );
                                  })}
                                </Field>
                              </FormControl>
                            </Box>
                            <Box sx={{ mt: "25px" }}>
                              <Button onClick={() => remove(index)}>
                                <DeleteIcon />
                              </Button>
                            </Box>
                          </Box>
                        ))}
                        <Button
                          onClick={() => push(emptyRecipe)}
                          variant="contained"
                          sx={{
                            m: 3,
                            textTransform: "none",
                            background: "var(--red)",
                            "&:hover": {
                              backgroundColor: "var(--red)",
                            },
                          }}
                        >
                          + Add Row
                          {/* {t("createAddons.ADD_ROW")} */}
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box></Box>
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var( --color-secondary)",
                        "&:hover": {
                          backgroundColor: "var( --color-secondary)",
                        },
                      }}
                      onClick={() => toggleDrawer(["create"], false)()}
                    >
                      {/* {t("common.CANCEL")} */}
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var(--red)",
                        "&:hover": {
                          backgroundColor: "var(--red)",
                        },
                      }}
                    >
                      {/* {t("common.SAVE")} */}
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
