import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";

export default function SubNavbar({ handleChange, items, value, sx }) {
  return (
    <Box>
      <Box sx={sx}>
        <Tabs sx={{ marginTop: "40px" }} value={value} onChange={handleChange}>
          {items?.map((item, index) => (
            <Tab
              key={index}
              sx={{ textTransform: "none" }}
              value={item.value}
              label={<Typography>{item.label}</Typography>}
            />
          ))}
        </Tabs>
      </Box>
      <Divider orientation="horizontal" flexItem></Divider>
    </Box>
  );
}
