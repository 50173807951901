import React from "react";
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { createTable } from "../../../sevice/admin";
import { addTableSchema } from "../../../schemas";

export default function AddTable({ sentMessage, handleCloseTable }) {
  const { POSadmin } = useSelector((state) => state);
  const initialValues = {
    tableNo: "",
    noOfPeople: "",
    additionalInfo: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addTableSchema,
    onSubmit: async (values, action) => {
      const data = await createTable(values, POSadmin.token);
      sentMessage(data);
    },
  });

  return (
    <div>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Box>
              <Typography
                sx={{ my: "10px" }}
                style={{ fontSize: "15px", fontWeight: "bold" }}
              >
                Add Table
              </Typography>
            </Box>
            <Box>
              <Box sx={{ my: "20px" }}>
                <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                  Table No.
                </Typography>
                <TextField
                  id="tableNo"
                  variant="outlined"
                  name="tableNo"
                  size="small"
                  style={{ width: "100%" }}
                  value={formik.values.tableNo}
                  onChange={formik.handleChange}
                  error={!!formik.touched.tableNo && !!formik.errors.tableNo}
                  helperText={formik.touched.tableNo && formik.errors.tableNo}
                />
              </Box>
              <Box sx={{ my: "20px" }}>
                <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                  No of people
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="noOfPeople"
                    name="noOfPeople"
                    size="small"
                    value={formik.values.noOfPeople}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.noOfPeople && !!formik.errors.noOfPeople
                    }
                    helperText={
                      formik.touched.noOfPeople && formik.errors.noOfPeople
                    }
                  >
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.noOfPeople && formik.errors.noOfPeople && (
                  <p
                    style={{
                      color: "#d32f2f",
                      marginLeft: "14px",
                      fontSize: "12px",
                    }}
                    id="noOfPeople-helper-text"
                    className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
                  >
                    {formik.errors.noOfPeople}
                  </p>
                )}
              </Box>
              <Box sx={{ my: "20px" }}>
                <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                  Additional info
                </Typography>
                <TextField
                  id="additionalInfo"
                  variant="outlined"
                  name="additionalInfo"
                  size="small"
                  style={{ width: "100%" }}
                  value={formik.values.additionalInfo}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.additionalInfo &&
                    !!formik.errors.additionalInfo
                  }
                  helperText={
                    formik.touched.additionalInfo &&
                    formik.errors.additionalInfo
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{
                height: "100%",
                fontSize: "16px",
                mr: "10px",
                mt: "3px",
                textTransform: "none",
                color: "var(--font-black)",
                background: "var(--button-gray)",
              }}
              variant="text"
              onClick={() => handleCloseTable()}
            >
              Cancel
            </Button>
            <Button
              sx={{
                height: "100%",
                fontSize: "16px",
                mt: "3px",
                textTransform: "none",
                color: "var(--bg-white)",
                background: "var(--red)",
              }}
              type="submit"
              variant="text"
            >
              Save
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}
