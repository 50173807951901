import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import CircleIcon from "@mui/icons-material/Circle";
import Chart from "react-apexcharts";
import HSBar from "react-horizontal-stacked-bar-chart";
import { useSelector } from "react-redux";
import {
  getMostSellingProducts,
  getProductsBasedOnPreviousSales,
  getTotalSales,
} from "../../../sevice/reports";

export default function Dashboard() {
  const [timePeriodTotalSales, setTimePeriodTotalSales] = useState("week");
  const [timePeriodOfSales, setTimePeriodOfSales] = useState("week");
  const [timePeriodMostSelling, setTimePeriodMostSelling] = useState("week");
  const { POSadmin } = useSelector((state) => state);

  const [series, setSeries] = useState([
    {
      name: "This week",
      data: [],
    },
    {
      name: "Last week",
      data: [],
    },
  ]);

  const [label, setLabel] = useState([]);

  const findProductData = async () => {
    const data = await getProductsBasedOnPreviousSales(
      timePeriodOfSales,
      POSadmin.token
    );
    const item = data??[]?.map((obj) => obj.item);
    setLabel(item);
    const c1 = data??[]?.map((item) => item.firstCount);
    const c2 = data??[]?.map((item) => item.secondCount);
    setSeries((prevSeries) => [
      { ...prevSeries[0], data: c1 },
      { ...prevSeries[1], data: c2 },
    ]);
  };

  useEffect(() => {
    findProductData();
  }, [timePeriodOfSales]);

  var prevSalesChartOptions = {
    chart: {
      type: "bar",
      height: 430,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    // xaxis: {
    //   categories: ['Biriyani', 'Mandhi', 'Fried Rice', 'Burger', 'Pizza'],
    // },
  };

  const [saleTypes, setSaleTypes] = useState([]);
  const getDatas = async () => {
    const data = await getTotalSales(timePeriodTotalSales, POSadmin.token);
    setSaleTypes(data);
  };

  useEffect(() => {
    getDatas();
  }, [timePeriodTotalSales]);

  const [product, setProduct] = useState([]);
  const [countArray, setCountArray] = useState([]);
  const getProduct = async () => {
    const data = await getMostSellingProducts(
      timePeriodMostSelling,
      POSadmin.token
    );
    const count = data?.map((obj) => obj.count);
    const products = data?.map((obj) => obj.result.title);
    setProduct(products);
    setCountArray(count);
  };

  useEffect(() => {
    getProduct();
  }, [timePeriodMostSelling]);

  var options = {
    colors: ["#5B5EAA", "#F7941D", "#EF4136", "#F15A29"],
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Total",
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return countArray.reduce((acc, cur) => acc + cur, 0);
            },
          },
        },
      },
    },
    labels: product?.length > 0 ? product : [],
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "small",
            sm: "column",
            md: "column",
            lg: "row",
          },
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              maxWidth: "800px",
              minWidth: "550px",
              minHeight: "300px",
              p: 2,
              borderRadius: "0.7rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Total Sales</Typography>
              <FormControl
                sx={{ px: 1, minWidth: 90 }} // position: "absolute", right: 10
                size="small"
              >
                <Select
                  value={timePeriodTotalSales}
                  onChange={(e) => setTimePeriodTotalSales(e.target.value)}
                  displayEmpty
                  sx={{ fontSize: 12 }}
                >
                  <MenuItem value="day">Day</MenuItem>
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="year">Year</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                mt: 5,
              }}
            >
              {saleTypes.map((item) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  {item.paymentMethods.map((pm) => (
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Typography sx={{ width: "90px" }}>
                        {pm.paymentMethod}
                      </Typography>
                      <HSBar
                        height={20}
                        data={[
                          {
                            name: pm.paymentMethod,
                            value: pm.grandTotal,
                            color: "#0095F7",
                          },
                          {
                            name: "Total",
                            value: item.total,
                            color: "#E3E3E3",
                          },
                        ]}
                      />
                      <Typography sx={{ width: "90px", pl: 1.5 }}>
                        {pm.grandTotal.toFixed(2)}
                      </Typography>
                    </Box>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      TOTAL SALES: {item?.total ? item?.total?.toFixed(2) : 0}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              maxWidth: "800px",
              minWidth: "550px",
              minHeight: "360px",
              p: 2,
              borderRadius: "0.7rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", gap: "5px" }}>
                <InventoryIcon />
                <Typography>Products based on previous sales</Typography>
              </Box>
              <FormControl sx={{ px: 1, minWidth: 90 }} size="small">
                <Select
                  value={timePeriodOfSales}
                  onChange={(e) => setTimePeriodOfSales(e.target.value)}
                  displayEmpty
                  sx={{ fontSize: 12 }}
                >
                  <MenuItem value="day">Day</MenuItem>
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="year">Year</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Chart
                options={{
                  prevSalesChartOptions,
                  xaxis: {
                    categories: label,
                  },
                }}
                series={series}
                type="bar"
                height={350}
              />
            </Box>
          </Paper>
        </Box>
        <Paper
          elevation={0}
          sx={{
            minWidth: "550px",
            width: "51%",
            maxWidth: "800px",
            height: "350px",
            p: 2,
            borderRadius: "0.7rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography>Most Selling Product</Typography>
            <FormControl sx={{ px: 1, minWidth: 90 }} size="small">
              <Select
                value={timePeriodMostSelling}
                onChange={(e) => setTimePeriodMostSelling(e.target.value)}
                displayEmpty
                sx={{ fontSize: 12 }}
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
                <MenuItem value="year">Year</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              <Chart
                options={options}
                series={countArray}
                type="radialBar"
                height={"300px"}
                width={"300px"}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "50%",
                p: 5,
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                {product?.map((item, idx) => (
                  <>
                    {idx === 0 && (
                      <Box sx={{ display: "flex" }}>
                        <CircleIcon sx={{ color: "#5B5EAA" }} />
                        <Typography>{item}</Typography>
                      </Box>
                    )}
                    {idx === 1 && (
                      <Box sx={{ display: "flex" }}>
                        <CircleIcon sx={{ color: "#F7941D" }} />
                        <Typography>{item}</Typography>
                      </Box>
                    )}
                    {idx === 2 && (
                      <Box sx={{ display: "flex" }}>
                        <CircleIcon sx={{ color: "#EF4136" }} />
                        <Typography>{item}</Typography>
                      </Box>
                    )}
                    {idx === 3 && (
                      <Box sx={{ display: "flex" }}>
                        <CircleIcon sx={{ color: "#F15A29" }} />
                        <Typography>{item}</Typography>
                      </Box>
                    )}
                  </>
                ))}
              </Box>
              {/* <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                  <Typography>₹4,000</Typography>
                  <Typography>₹3,000</Typography>
                  <Typography>₹2,000</Typography>
                  <Typography>₹1,000</Typography>
                </Box> */}
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
