import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  Typography,
  TextField,
  Checkbox,
} from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { editBillerSchema } from "../../../schemas";
import { useSelector } from "react-redux";
import { editCaptain } from "../../../sevice/userManagement";

function EditCaptain({ editItem, handleClose }) {
  const { POSadmin } = useSelector((state) => state);

  const initialValues = {
    first_name: editItem.first_name,
    last_name: editItem.last_name,
    phone: editItem.phone,
    password: "",
    confirmPassword: "",
    userPasscode: editItem.userPasscode,
    swipeCode: editItem.swipeCode,
    rights: editItem.rights
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editBillerSchema,
    onSubmit: async (values, action) => {
      const data = await editCaptain(POSadmin.token, values, editItem._id);
      if (data.status) {
        toast.success(data.message);
        handleClose();
      } else {
        toast.error(data.message);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Typography sx={{ p: 3 }} variant="h6">
            Edit Captain
          </Typography>
          <Card
            elevation={0}
            sx={{
              width: "100%",
              height: "30%",
              marginRight: "15px",
              marginBottom: "20px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "50ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="First Name"
                id="first_name"
                variant="outlined"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.first_name && !!formik.errors.first_name
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
                required
              />
              <TextField
                label="Last Name"
                id="last_name"
                variant="outlined"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={!!formik.touched.last_name && !!formik.errors.last_name}
                helperText={formik.touched.last_name && formik.errors.last_name}
                required
              />
              <TextField
                label="Phone"
                id="phone"
                variant="outlined"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={!!formik.touched.phone && !!formik.errors.phone}
                helperText={formik.touched.phone && formik.errors.phone}
                required
              />
              <TextField
                label="Password"
                id="password"
                variant="outlined"
                name="password"
                type="password"
                value={formik.values.password||""}
                onChange={formik.handleChange}
                error={!!formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                required
              />
              <TextField
                sx={{ marginLeft: "30px" }}
                id="outlined-basic"
                label="Confirm password"
                variant="outlined"
                type="password"
                name="passwordConfirmation"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.passwordConfirmation}
                error={
                  !!formik.touched.passwordConfirmation &&
                  !!formik.errors.passwordConfirmation
                }
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
                required
              />
              <TextField
                label="User Passcode"
                id="userPasscode"
                variant="outlined"
                name="userPasscode"
                value={formik.values.userPasscode}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.userPasscode && !!formik.errors.userPasscode
                }
                helperText={
                  formik.touched.userPasscode && formik.errors.userPasscode
                }
              />
              <TextField
                label="Swipe Code"
                id="swipeCode"
                variant="outlined"
                name="swipeCode"
                value={formik.values.swipeCode}
                onChange={formik.handleChange}
                error={!!formik.touched.swipeCode && !!formik.errors.swipeCode}
                helperText={formik.touched.swipeCode && formik.errors.swipeCode}
              />
            </Box>
          </Card>
          <Typography sx={{ p: 1 }} variant="h6">
            Rights
          </Typography>
          <Card
            elevation={0}
            sx={{
              overflowY: "auto",
              width: "100%",
              height: "345px",
              marginRight: "15px",
              marginBottom: "20px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography>Edit KOT</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                labelPlacement="end"
                checked={formik.values?.rights?.editKOT}
                onChange={(e) => {
                  formik.setFieldValue(`rights.editKOT`, e.target.checked);
                }}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography>Cancel KOT</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                labelPlacement="end"
                checked={formik.values?.rights?.cancelKOT}
                onChange={(e) => {
                  formik.setFieldValue(`rights.cancelKOT`, e.target.checked);
                }}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography>Generate Bill</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                labelPlacement="end"
                checked={formik.values?.rights?.generateBill}
                onChange={(e) => {
                  formik.setFieldValue(`rights.generateBill`, e.target.checked);
                }}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography>Move Table</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                labelPlacement="end"
                checked={formik.values?.rights?.moveTable}
                onChange={(e) => {
                  formik.setFieldValue(`rights.moveTable`, e.target.checked);
                }}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography>Take Payments (Pending Bills)</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                labelPlacement="end"
                checked={formik.values?.rights?.takePayments}
                onChange={(e) => {
                  formik.setFieldValue(`rights.takePayments`, e.target.checked);
                }}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography>Add New Table</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                labelPlacement="end"
                checked={formik.values?.rights?.addNewTable}
                onChange={(e) => {
                  formik.setFieldValue(`rights.addNewTable`, e.target.checked);
                }}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography>
                Allow Discount (Applicable for Dine-In orders only)
              </Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                labelPlacement="end"
                checked={formik.values?.rights?.allowDiscount}
                onChange={(e) => {
                  formik.setFieldValue(
                    `rights.allowDiscount`,
                    e.target.checked
                  );
                }}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography>Show only my opened Table</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                labelPlacement="end"
                checked={formik.values?.rights?.showOnlyMyOpenedTable}
                onChange={(e) => {
                  formik.setFieldValue(
                    `rights.showOnlyMyOpenedTable`,
                    e.target.checked
                  );
                }}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography>Allow Special Discount</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                labelPlacement="end"
                checked={formik.values?.rights?.allowSpecialDiscount}
                onChange={(e) => {
                  formik.setFieldValue(
                    `rights.allowSpecialDiscount`,
                    e.target.checked
                  );
                }}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography>Order Management</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                labelPlacement="end"
                checked={formik.values?.rights?.orderManagement}
                onChange={(e) => {
                  formik.setFieldValue(
                    `rights.orderManagement`,
                    e.target.checked
                  );
                }}
              />
            </Box>
          </Card>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ p: 1 }} variant="h6">
              Table
            </Typography>{" "}
            <FormControlLabel
              control={<Checkbox />}
              label="Select All"
              labelPlacement="end"
            />
          </Box>
          <Card
            elevation={0}
            sx={{
              width: "100%",
              height: "110px",
              marginRight: "15px",
              marginBottom: "20px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                label="Table-1"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Table-2"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Table-3"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Table-4"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Table-5"
                labelPlacement="end"
              />
            </Box>
          </Card>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Box>
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  width: "100px",
                  textTransform: "none",
                  background: "var( --color-secondary)",
                  "&:hover": {
                    backgroundColor: "var( --color-secondary)",
                  },
                }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  width: "100px",
                  textTransform: "none",
                  background: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                }}
                type="submit"
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}

export default EditCaptain;
