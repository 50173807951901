import { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AdminNavBar from "./AdminNavBar";
import { Modal, Paper } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
//Images//
import DashboardIcon from "@mui/icons-material/Dashboard";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import SettingsIcon from "@mui/icons-material/Settings";
////

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBar() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openMouseOver, setOpenMouseOver] = useState(false);

  const details = [
    {
      href: "/",
      name: "Dashboard",
      icon: <DashboardIcon />,
    },
    {
      href: "/allorders",
      href2: "/kot",
      name: "Orders",
      icon: <WorkOutlineIcon />,
    },
    {
      href: "/menumanagemet",
      href2: "/addproduct",
      name: "Menu Managemet",
      icon: <RestaurantMenuIcon />,
    },
    {
      href: "/inventory",
      name: "Inventory",
      icon: <ListAltIcon />,
    },
    {
      href: "/reports",
      name: "Reports",
      icon: <ShowChartIcon />,
    },
    {
      href: "/settings",
      href2: "/tables",
      href3: "/configuration",
      href4: "/usermanagement",
      href5: "/profile",
      name: "Settings",
      icon: <SettingsIcon />,
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AdminNavBar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />

      <div>
        <Modal
          open={open}
          onClose={handleDrawerClose}
          BackdropProps={{
            style: { backgroundColor: "rgba(255, 255, 255, 0)" },
          }}
        >
          <Paper>
            <Toolbar />
            <Box
              sx={{
                position: "absolute",
                left: 0,
                height: "100%",
                borderRight: "solid var(--border-gray) 1px",
                backgroundColor: "background.paper",
                width: "15em",
              }}
            >
              <List
                onMouseLeave={() => {
                  if (openMouseOver) {
                    setOpen(false);
                    setOpenMouseOver(false);
                  }
                }}
              >
                {details.map((item, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "center",
                        px: 2.5,
                        color:
                          location.pathname === item.href ||
                          location.pathname === item.href2 ||
                          location.pathname === item.href3 ||
                          location.pathname === item.href4 ||
                          location.pathname === item.href5
                            ? "var(--red)"
                            : "",
                      }}
                      onClick={() => {
                        setOpenMouseOver(true);
                      }}
                      component={Link}
                      to={item.href}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: "center",
                          color:
                            location.pathname === item.href ||
                            location.pathname === item.href2 ||
                            location.pathname === item.href3 ||
                            location.pathname === item.href4 ||
                            location.pathname === item.href5
                              ? "var(--red)"
                              : "",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.name} sx={{ opacity: 1 }} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        </Modal>
      </div>
      <Drawer variant="permanent">
        <Toolbar />
        <List
          onMouseEnter={() => {
            setOpenMouseOver(true);
            setOpen(true);
          }}
        >
          {details.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: "center",
                  px: 2.5,
                }}
                component={Link}
                to={item.href}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: "auto",
                    justifyContent: "center",
                    color:
                      location.pathname === item.href ||
                      location.pathname === item.href2 ||
                      location.pathname === item.href3 ||
                      location.pathname === item.href4 ||
                      location.pathname === item.href5
                        ? "var(--red)"
                        : "",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
