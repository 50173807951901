import { useEffect, useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Toolbar } from "@mui/material";
import AdminSubNavbar from "./../components/common/SubNavbar";
import Menu from "./../components/posAdminPanel/inventory/Menu";
import RawMaterial from "./../components/posAdminPanel/inventory/RawMaterial";
import Stock from "./../components/posAdminPanel/inventory/Stock";
import Purchase from "./../components/posAdminPanel/inventory/Purchase";
import Recipe from "./../components/posAdminPanel/inventory/Recipe";
import Wastage from "./../components/posAdminPanel/inventory/Wastage";
import FlowDirection from "./../components/posAdminPanel/navBarAndSideBar/FlowDirection";
import Dashboard from "./../components/posAdminPanel/inventory/Dashboard";
import { changeflowDirectionItemInventory } from "./FlowDirection";

export default function Inventory() {
  const [value, setValue] = useState("1");
  const [state, setState] = useState(true);
  let [showBack, setShowBack] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navBarItems = [
    {
      label: "Dashboard",
      value: "1",
    },
    {
      label: "Raw material",
      value: "2",
    },
    {
      label: "Recipe",
      value: "3",
    },
    {
      label: "Purchase",
      value: "4",
    },
    {
      label: "Stock",
      value: "5",
    },
    {
      label: "Wastage",
      value: "6",
    },
    {
      label: "Menu",
      value: "7",
    },
  ];
  let [flowDirectionItems, setFlowDirectionItems] = useState([
    {
      name: "Dashboard",
      show: true,
    },
  ]);
  useEffect(() => {
    changeflowDirectionItemInventory(
      value,
      navBarItems,
      setFlowDirectionItems,
      state,
      setShowBack
    );
  }, [value, state]);

  useEffect(() => {
    setState(true);
  }, [value]);
  const backFunction = () => {
    if (!state) {
      setState(!state);
    } else {
      setValue(String(Number(value) - 1));
    }
  };
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <FlowDirection
          items={flowDirectionItems}
          backFunction={backFunction}
          showBack={showBack}
        />
        <Toolbar />
        <Box>
          <Box>
            <TabContext value={value}>
              <AdminSubNavbar
                handleChange={handleChange}
                items={navBarItems}
                value={value}
              />
              <TabPanel value="1" sx={{ bgcolor: "FFFFFF", height: "auto" }}>
                <Dashboard />
              </TabPanel>
              <TabPanel value="2">
                <RawMaterial state={state} setState={setState} />
              </TabPanel>
              <TabPanel value="3">
                <Recipe state={state} setState={setState} />
              </TabPanel>
              <TabPanel value="4">
                <Purchase state={state} setState={setState} />
              </TabPanel>{" "}
              <TabPanel value="5">
                <Stock />
              </TabPanel>{" "}
              <TabPanel value="6">
                <Wastage state={state} setState={setState} />
              </TabPanel>
              <TabPanel value="7">
                <Menu state={state} setState={setState} />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
