import Cookies from "js-cookie";

export function isLanguageSwitchedReducer(
  state = Cookies.get("isLanguageSwitched")
    ? JSON.parse(Cookies.get("isLanguageSwitched"))
    : false,
  action
) {
  switch (action.type) {
    case "isLanguageSwitched":
      return action.payload;

    default:
      return state;
  }
}
