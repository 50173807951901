const { default: styled } = require("@emotion/styled");
const { Input } = require("@mui/material");

exports.style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: "740px", sm: "370px" },
  maxHeight: { lg: "auto", md: "auto", sm: "500px" },
  overflow: { md: "hidden" },
  overflowY: { sm: "scroll" },
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 1.5,
  py: 1.5,
  borderRadius: "5px",
};
exports.styleAddVariation = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: "740px", sm: "370px" },
  maxHeight: { lg: "auto", md: "auto", sm: "560px" },
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 3,
  borderRadius: "5px",
};
exports.styleVariationOption = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 740 },
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 4,
  py: 3,
  borderRadius: "5px",
  overflow: "hidden",
  maxHeight: 590,
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar
  },
};
exports.styleAddons = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 425,
  maxHeight: 590,
  overflow: "hidden",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "5px",
};
exports.styleVariationTable = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90%",
  minWidth: "70%",
  maxHeight: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 2,
  py: 1.5,
  overflow: "hidden",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar
  },
  borderRadius: "10px",
};
exports.styleAddRecipe = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90%",
  maxHeight: "70%",
  bgcolor: "background.paper",
  overflow: "hidden",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar
  },
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};
exports.styleCreateGroup = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};

exports.InputField = styled(Input)(({ theme }) => ({
  borderRadius: "5px",
  padding: "5px",
  paddingLeft: "15px",
  width: "100%",
  color: "var(--font-black)",
  fontSize: "1.1rem",
  marginRight: "5px",
  background: "var(--bg-white)",
  border: "solid var(--border-black) 1px",
}));
