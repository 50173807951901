import { useEffect, useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Toolbar } from "@mui/material";
import Biller from "./../components/posAdminPanel/userManagement/Biller";
import AdminSubNavbar from "./../components/common/SubNavbar";
import Captain from "./../components/posAdminPanel/userManagement/Captain";
import DeliveryBoy from "./../components/posAdminPanel/userManagement/DeliveryBoy";
import OrderApp from "./../components/posAdminPanel/userManagement/OrderApp";
import FlowDirection from "./../components/posAdminPanel/navBarAndSideBar/FlowDirection";
import { useNavigate } from "react-router-dom";
import { ChangeflowDirectionItemUserManagement } from "./FlowDirection";
import Merchant from "../components/posAdminPanel/userManagement/Merchant";

function UserManagement() {
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const [state, setState] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navBarItems = [
    {
      label: "Biller",
      value: "1",
    },
    {
      label: "Captain",
      value: "2",
    },
    {
      label: "Delivery Boy",
      value: "3",
    },
    {
      label: "Order App",
      value: "4",
    },
    {
      label: "Merchant",
      value: "5",
    },
  ];
  let [flowDirectionItems, setFlowDirectionItems] = useState([
    {
      name: "Settings",
      show: true,
    },
  ]);
  useEffect(() => {
    ChangeflowDirectionItemUserManagement(
      value,
      navBarItems,
      setFlowDirectionItems,
      state
    );
  }, [value, state]);
  useEffect(() => {
    setState(true);
  }, [value]);
  const backFunction = () => {
    if (!state) {
      setState(!state);
    } else if (value === "1") {
      navigate("/settings");
    } else {
      setValue(String(Number(value) - 1));
    }
  };
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <Toolbar />
        <FlowDirection
          items={flowDirectionItems}
          showBack={true}
          backFunction={backFunction}
        />
        <Box>
          <Box>
            <TabContext value={value}>
              <AdminSubNavbar
                handleChange={handleChange}
                items={navBarItems}
                value={value}
              />
              <TabPanel value="1">
                <Biller state={state} setState={setState} />
              </TabPanel>
              <TabPanel value="2">
                <Captain state={state} setState={setState} />
              </TabPanel>
              <TabPanel value="3">
                <DeliveryBoy state={state} setState={setState} />
              </TabPanel>
              <TabPanel value="4">
                <OrderApp state={state} setState={setState} />
              </TabPanel>
              <TabPanel value="5">
                <Merchant state={state} setState={setState} />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UserManagement;
