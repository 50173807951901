import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";

export default function FIlterBox({ value, setValue }) {
  return (
    <FormControl
      sx={{
        px: 1,
        minWidth: 90,
      }}
      size="small"
    >
      <Select
        value={value}
        onChange={(e) => setValue(e.target.value)}
        displayEmpty
        sx={{ fontSize: 12 }}
      >
        <MenuItem value="day">Day</MenuItem>
        <MenuItem value="week">Week</MenuItem>
        <MenuItem value="month">Month</MenuItem>
        <MenuItem value="year">Year</MenuItem>
      </Select>
    </FormControl>
  );
}
