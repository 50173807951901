import { Box, Button, Typography, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { findUserForResetPassword } from "../../../sevice/admin";
import { findUserSchema } from "../../../schemas";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
  },
});
const style = {
  color: "red",
  fontSize: "1rem",
  margin: 0,
  fontFamily: "poppins",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  pb: "2rem",
};
function FindAccount({ setState, setData }) {
  const [isLogin, setIsLogin] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: findUserSchema,
    onSubmit: async (values) => {
      setIsLogin(true);
      const data = await findUserForResetPassword(values);
      setData(data);
      if (data.status) {
        setState("reset");
      } else {
        setIsLogin(false);
        toast.error(data.message);
      }
    },
  });
  return (
    <>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Box sx={{ pb: "2.1rem", width: "30vw" }}>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              fontFamily: "poppins",
            }}
          >
            Find Your Account
          </Typography>
        </Box>
        <Box sx={{ pb: "1rem" }}>
          <Box
            sx={{
              border: "1px solid #EEEEEE",
              width: "100%",
              height: "60px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              boxSizing: "border-box",
              px: "1.5rem",
              bgcolor: "#F5F5F5",
            }}
          >
            <PersonSearchIcon sx={{ fontSize: "1.7rem", color: "#92929B" }} />
            <TextField
              variant="outlined"
              type="email"
              placeholder="Enter Your Email"
              sx={{
                "& input::placeholder": {
                  fontFamily: "poppins",
                },
                "& fieldset": { border: "none" },
                width: "100%",
              }}
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </Box>
        </Box>
        {formik.touched.email && formik.errors.email && (
          <p style={style}>{formik.errors.email}</p>
        )}
        <Box sx={{ pb: "1.5rem", pt: "1.1rem" }}>
          <Button
            type="submit"
            sx={{
              background: "#F40000",
              width: "100%",
              height: "60px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              boxSizing: "border-box",
              px: "1.5rem",
              color: "white",
              "&:hover": {
                color: "white",
                background: "#F40000",
              },
            }}
          >
            {isLogin ? (
              <ThemeProvider theme={customTheme}>
                <CircularProgress color="primary" />
              </ThemeProvider>
            ) : (
              <Typography
                sx={{
                  color: "white",
                  fontSize: "1rem",
                  fontFamily: "poppins",
                }}
              >
                Search
              </Typography>
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default FindAccount;
