import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BannerCard from "./BannerCard";
import { getAllGroupsForBanner, getProductTitle } from "../../../sevice/admin";

function BannerManagement({ setPage }) {
  const { POSadmin } = useSelector((state) => state);
  const [group, setGroup] = useState([]);
  const [product, setProduct] = useState([]);

  const fetchDatas = async () => {
    const data = await getAllGroupsForBanner(POSadmin.token);
    const data1 = await getProductTitle(POSadmin.token);
    setGroup(data);
    setProduct(data1);
  };
  useEffect(() => {
    fetchDatas();
  }, []);
  return (
    <Box>
      <BannerCard setPage={setPage} group={group} product={product} />
    </Box>
  );
}

export default BannerManagement;
