export default function changeFlowDirectonConfiguration(
  page,
  setFlowDirectionItems
) {
  page === "restaurant"
    ? setFlowDirectionItems([
        {
          name: "Settings",
          show: true,
        },
        {
          name: "Configuration",
          show: true,
        },
      ])
    : page === "scan&Order"
    ? setFlowDirectionItems([
        {
          name: "Settings",
          show: true,
        },
        {
          name: "Configuration",
          show: true,
        },
      ])
    : page === "bannerManage"
    ? setFlowDirectionItems([
        {
          name: "Settings",
          show: true,
        },
        {
          name: "Configuration",
          show: true,
        },
        {
          name: "Banner Management",
          show: true,
        },
      ])
    : page === "printingIps"
    ? setFlowDirectionItems([
        {
          name: "Settings",
          show: true,
        },
        {
          name: "Configuration",
          show: true,
        },
        {
          name: "printingIps",
          show: true,
        },
      ])
    : page === "dine-QR-generate"
    ? setFlowDirectionItems([
        {
          name: "Settings",
          show: true,
        },
        {
          name: "Configuration",
          show: true,
        },
        {
          name: "Dine-in QR",
          show: true,
        },
      ])
    : page === "pick-up-QR"
    ? setFlowDirectionItems([
        {
          name: "Settings",
          show: true,
        },
        {
          name: "Configuration",
          show: true,
        },
      ])
    : setFlowDirectionItems([
        {
          name: "Settings",
          show: true,
        },
        {
          name: "Configuration",
          show: true,
        },
      ]);
}

export const changeflowDirectionItemInventory = (
  newValue,
  navBarItems,
  setFlowDirectionItems,
  state,
  setShowBack
) => {
  navBarItems.filter((item, index) => {
    if (newValue === item.value) {
      setFlowDirectionItems([
        {
          name: "Reports",
          show: true,
        },
        {
          name: item.label,
          show: true,
        },
        {
          name: `Add ${item.label}`,
          show: !state,
        },
      ]);
    }
  });
  if (newValue == 1) {
    setShowBack(false);
  } else {
    setShowBack(true);
  }
};

export const ChangeflowDirectionItemAddGroup = (
  newValue,
  setFlowDirectionItems,
  setShowBack,
  navBarItems
) => {
  navBarItems.filter((item, index) => {
    if (newValue === item.value) {
      setFlowDirectionItems([
        {
          name: "All Orders",
          show: true,
        },
        {
          name: item.label,
          show: true,
        },
      ]);
    }
  });
  if (newValue == 1) {
    setShowBack(false);
  } else {
    setShowBack(true);
  }
};

export const ChangeflowDirectionItemUserManagement = (
  newValue,
  navBarItems,
  setFlowDirectionItems,
  state
) => {
  navBarItems.filter((item, index) => {
    if (newValue === item.value) {
      setFlowDirectionItems([
        {
          name: "Settings",
          show: true,
        },
        {
          name: "User Management",
          show: true,
        },
        {
          name: item.label,
          show: true,
        },
        {
          name: `Add ${item.label}`,
          show: !state,
        },
      ]);
    }
  });
};

export const ChangeflowDirectionItemKot = (
  newValue,
  setFlowDirectionItems,
  navBarItems
) => {
  navBarItems.filter((item, index) => {
    if (newValue === item.value) {
      setFlowDirectionItems([
        {
          name: "Orders",
          show: true,
        },
        {
          name: "Kot",
          show: true,
        },
        {
          name: item.label + " " + "Kots",
          show: true,
        },
      ]);
    }
  });
};
