import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import CreateDiscount from "./CreateDiscount";
import {
  Toolbar,
  Typography,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AlertButton from "../../../Alert/Alert";
import { toast } from "react-hot-toast";
import { getAllDiscounts, deleteDiscountById } from "../../../sevice/discount";
import { useSelector } from "react-redux";

export default function ListAllDiscount({
  anchor,
  handleDiscount,
  items,
  groups,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [discount, setDiscount] = useState([]);
  const [editItem, setEditItem] = useState(null); 
  const POSadmin = useSelector((state) => state.POSadmin);
  const [status,setStatus]=useState(false)

  const AllDiscounts = async () => {
    try {
      const { discounts } = await getAllDiscounts(POSadmin.token);
      setDiscount(discounts);
      setLoading(false);
    } catch (error) {
      setDiscount([]);
      setLoading(false);
    }
  };

  const deleteDiscountFunction = async (id) => {
    const data = await deleteDiscountById(id, POSadmin.token);
    if (data.status) {
      AllDiscounts();
      toast.success(data.message);
    }
  };

  useEffect(() => {
    AllDiscounts();
  }, [status]);
  function handleStatusChange(){
    setStatus(!status)
  }

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        width: "50vw",
        p: "20px",
      }}
      role="presentation"
    >
      <Toolbar />
      {!isEdit ? (
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>
              All Discounts ({discount?.length} Discounts )
            </Typography>
            <>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "var(--red)",
                  mr: "20px",
                }}
                onClick={() => handleDiscount("create", true)}
              >
                Create
              </Button>
              <Drawer
                anchor="right"
                open={items["create"]}
                onClose={() => handleDiscount("create", false)}
              >
                <Box sx={{ width: "50vw" }}>
                  <CreateDiscount
                    anchor="create"
                    handleDiscount={handleDiscount}
                    groups={groups}
                    handleStatusChange={handleStatusChange}
                  />
                </Box>
              </Drawer>
            </>
          </Box>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              {discount.length === 0 ? (
                <Typography variant="body1">No discounts found.</Typography>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Add-On</TableCell>
                        <TableCell>Applicable On</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Days</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {discount?.map((item) => (
                        <TableRow key={item?._id}>
                          <TableCell>
                            <Typography fontSize={"12px"}>
                              {item?.title}
                            </Typography>
                          </TableCell>
                          <TableCell>{item?.type}</TableCell>
                          <TableCell>{item?.addOn}</TableCell>
                          <TableCell>
                            {item?.applicableOn?.type ??
                            item?.applicableOn?.goupId
                              ? "Group"
                              : "Item"}
                          </TableCell>
                          <TableCell>
                            {item?.type === "percentage"
                              ? `${item?.totalAmount + "%"}`
                              : `${item?.totalAmount}`}
                          </TableCell>
                          <TableCell>{item?.days ?? "Nill"}</TableCell>

                          <TableCell>
                            <ModeStandbyIcon
                              sx={{
                                width: "20px",
                                color:
                                  item?.status === "active" ? "green" : "red",
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Button
                              sx={{ color: "black" }}
                              onClick={() => {
                                setEditItem(item);
                                handleDiscount("edit", true);
                              }} // Pass the item to the handler function
                            >
                              <SaveAsIcon sx={{ width: "20px" }} />
                            </Button>
                            <Drawer
                              anchor="right"
                              open={items["edit"]}
                              onClose={() => handleDiscount("edit", true)}
                            >
                              <Box sx={{ width: "50vw" }}>
                                <CreateDiscount
                                  discount={editItem} // Pass the item to the CreateDiscount component
                                  anchor="edit"
                                  handleDiscount={handleDiscount}
                                  groups={groups}
                                  handleStatusChange={handleStatusChange}
                                />
                              </Box>
                            </Drawer>
                            <AlertButton
                              element={<DeleteOutlineIcon />}
                              type={"confirmation"}
                              message={
                                "Are you sure? You won't be able to revert this!"
                              }
                              style={{ color: "black" }}
                              onConfirm={() =>
                                deleteDiscountFunction(item?._id)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          )}
        </Box>
      ) : null}
    </Box>
  );
}
