import React from 'react';
import { Switch } from '@mui/material';
import { styled } from '@mui/system';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 28,
  padding: 5,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(4px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(16px)',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#F44336',
    },
    '&:not(.Mui-checked) + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#4CAF50',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 24,
    height: 24,
    color: 'gray',
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 14,
  },
}));

const MaterialUISwitch = (props) => {
  return <StyledSwitch {...props} />;
};

export default MaterialUISwitch;
