import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  Pagination,
  Stack,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { array, object, string } from "yup";
import { Box } from "@mui/system";
import SearchBar from "../../common/SearchBar";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import { DateFormatter } from "../../../utils/DateFormatter";
import { addRawMaterial } from "../../../sevice/admin";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField, Switch, Select } from "formik-mui";
import {
  deleteRawMaterial,
  getAllUnits,
  getRawMaterials,
} from "../../../sevice/inventory";
import EditRawMaterial from "./EditRawMaterial";
import AlertButton from "../../../Alert/Alert";
import LoadingImage from "../../../assets/loader.gif";

const StockAvailable = styled(Box)({
  display: "flex",
  width: 150,
  height: 40,
  color: "var(--bg-white)",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  px: 4,
  py: 2,
  borderRadius: "5px",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));

function RawMaterial({ state, setState }) {
  const { POSadmin } = useSelector((state) => state);
  const [rawmet, setRawMet] = useState([]);
  const [forCheck, setForCheck] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [editItem, setEditItem] = useState({});
  const [IsLoading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const existRamet = [];
  forCheck?.forEach((item) => {
    existRamet.push(item.name);
  });
  const addRawMaterialSchema = object({
    items: array(
      object({
        name: string()
          .required("name is requird")
          .notOneOf(existRamet, "This material alredy exist.!"),
        purchaseUnit: string().required("unit is requird"),
      })
    ).min(1, "You need to provide at least 1 item"),
  });
  const initialValues = {
    items: [
      {
        vendor: POSadmin.id,
        name: "",
        purchaseUnit: "",
        is_private: false,
      },
    ],
  };
  const emptyRaw = {
    vendor: POSadmin.id,
    name: "",
    purchaseUnit: "",
    is_private: false,
  };

  const submitdata = async (values, action) => {
    setIsSubmit(true);
    const data = await addRawMaterial(values, POSadmin.token);
    if (data.status) {
      toast.success(data.message);
      setState(true);
      action.resetForm();
    } else {
      toast.error(data.message);
    }
  };
  const fetchRawMaterials = async () => {
    const data = await getRawMaterials(POSadmin.token, searchQuery, pageNo);
    const forCheck = await getRawMaterials(POSadmin.token, "", "");
    const data1 = await getAllUnits();
    setUnits(data1);
    setRawMet(data);
    setForCheck(forCheck.docs);
    setLoading(false);
  };
  useEffect(() => {
    fetchRawMaterials();
  }, [state, open, searchQuery, pageNo]);

  const deleteRawmaterial = async (id) => {
    const data = await deleteRawMaterial(POSadmin.token, id);
    if (data.status) {
      fetchRawMaterials();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };
  const editRawmaterial = async (item) => {
    const data = await editRawmaterial(POSadmin.token, item);
    if (data.status) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };
  const handleChangePageNo = (event, value) => {
    setPageNo(value);
  };

  return (
    <>
      {state && !IsLoading && (
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            searchBarWidth={"200px"}
            placeholder={"Search"}
            onChange={(newValue) => {
              setSearchQuery(newValue.target.value);
            }}
          />
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={() => {
              setState(false);
              setIsSubmit(false);
            }}
          >
            + Add Raw Material
          </Button>
        </Box>
      )}
      {IsLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img style={{ height: "300px" }} src={LoadingImage} alt="Loading" />
        </Box>
      ) : state ? (
        <Box sx={{ marginTop: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead sx={{ background: "var(--table-head)" }}>
                <TableRow>
                  <StyledTableCell align="center">SL No</StyledTableCell>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">
                    Stock availability
                  </StyledTableCell>
                  <StyledTableCell align="center">Restocked</StyledTableCell>
                  <StyledTableCell align="center">
                    Expected Restock
                  </StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rawmet.docs?.map((item, index) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">
                      {rawmet.pagingCounter + index}
                    </TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell
                      align="center"
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      {item.stock > 0 ? (
                        <StockAvailable
                          style={{ background: "var(--green-color)" }}
                        >
                          In stock
                        </StockAvailable>
                      ) : (
                        <StockAvailable
                          style={{ background: "var(--light-red-color)" }}
                        >
                          Out of Stock
                        </StockAvailable>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <DateFormatter date={item.restokedDate} />
                    </TableCell>
                    <TableCell align="center">29 Apr 2023</TableCell>
                    <TableCell align="center">
                      {/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
                      {/* <AlertButton
                          element={<DeleteIcon />}
                          type={"confirmation"}
                          message={
                            "Are you sure? You won't be able to revert this!"
                          }
                          onConfirm={deleteRawmaterial}
                          data={item._id}
                          style={{ color: "black" }}
                        /> */}
                      <SaveAsIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setEditItem(item);
                          handleOpen();
                        }}
                      />
                      {/* </Box> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            component={Paper}
            sx={{
              marginTop: "10px",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <Stack>
              <Pagination
                count={rawmet?.totalPages}
                page={pageNo}
                onChange={handleChangePageNo}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <EditRawMaterial editItem={editItem} handleClose={handleClose} />
            </Box>
          </Modal>
        </Box>
      ) : (
        <>
          <Box>
            <Typography sx={{ p: 3 }} variant="h6">
              Add Raw material
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={addRawMaterialSchema}
              onSubmit={submitdata}
            >
              {({ values, errors }) => (
                <Form autoComplete="off">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead sx={{ background: "var(--table-head)" }}>
                        <TableRow>
                          <StyledTableCell align="center">Name</StyledTableCell>
                          <StyledTableCell align="center">
                            Purchase Unit
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            is_private
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <FieldArray name="items">
                        {({ push, remove }) => (
                          <>
                            <TableBody>
                              {values.items.map((_, index) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    <Field
                                      fullWidth
                                      name={`items.${index}.name`}
                                      render={({ field }) => (
                                        <Field
                                          sx={{ m: 1, minWidth: 300 }}
                                          size="small"
                                          component={TextField}
                                          {...field}
                                          onChange={(event) => {
                                            const uppercaseValue =
                                              event.target.value.toUpperCase();
                                            field.onChange({
                                              target: {
                                                name: field.name,
                                                value: uppercaseValue,
                                              },
                                            });
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <FormControl sx={{ m: 1, minWidth: 300 }}>
                                      <Field
                                        component={Select}
                                        size="small"
                                        type="text"
                                        name={`items.${index}.purchaseUnit`}
                                      >
                                        {units.map((item) => (
                                          <MenuItem value={item._id}>
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <FormControlLabel
                                      control={
                                        <Field
                                          name={`items.${index}.is_private`}
                                          component={Switch}
                                          type="checkbox"
                                        />
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button onClick={() => remove(index)}>
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            <Button
                              onClick={() => push(emptyRaw)}
                              variant="contained"
                              sx={{
                                m: 3,
                                width: "110px",
                                textTransform: "none",
                                background: "var(--red)",
                                "&:hover": {
                                  backgroundColor: "var(--red)",
                                },
                              }}
                            >
                              + Add Row
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          mt: 3,
                          width: "100px",
                          textTransform: "none",
                          background: "var( --color-secondary)",
                          "&:hover": {
                            backgroundColor: "var( --color-secondary)",
                          },
                        }}
                        onClick={() => setState(true)}
                      >
                        Cancel
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        disabled={isSubmit}
                        variant="contained"
                        sx={{
                          mt: 3,
                          width: "100px",
                          textTransform: "none",
                          background: "var(--red)",
                          "&:hover": {
                            backgroundColor: "var(--red)",
                          },
                        }}
                        type="submit"
                      >
                        {isSubmit ? "Loading.." : "Save"}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </>
      )}
    </>
  );
}

export default RawMaterial;
