import { useState } from "react";
import { Box, Card, CardActions, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import PhonelinkSetupSharpIcon from "@mui/icons-material/PhonelinkSetupSharp";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LockResetIcon from "@mui/icons-material/LockReset";
import GroupIcon from "@mui/icons-material/Group";
import FlowDirection from "./../components/posAdminPanel/navBarAndSideBar/FlowDirection";

function Settings() {
  let [flowDirectionItems, setFlowDirectionItems] = useState([
    {
      name: "Settings",
      show: true,
    },
  ]);
  return (
    <Box sx={{ background: "var(--bg-color)", width: "100%" }}>
      <Toolbar />
      <FlowDirection items={flowDirectionItems} showBack={false} />
      <Box style={{ display: "flex", marginTop: "40px", flexWrap: "wrap" }}>
        <Card
          component={Link}
          to={"/tables"}
          elevation={0}
          sx={{
            textDecoration: "none",
            width: "230px",
            height: "120px",
            margin: "15px",
            padding: 3,
            border: "1px solid #ccc",
          }}
        >
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <TableRestaurantIcon sx={{ width: "3rem", height: "2.1rem" }} />
          </CardActions>
          <Typography
            sx={{ display: "flex", justifyContent: "center" }}
            color="var(--font-black)"
          >
            Table
          </Typography>
        </Card>
        <Card
          component={Link}
          to={"/configuration"}
          elevation={0}
          sx={{
            textDecoration: "none",
            width: "230px",
            height: "120px",
            margin: "15px",
            padding: 3,
            border: "1px solid #ccc",
          }}
        >
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <PhonelinkSetupSharpIcon sx={{ width: "3rem", height: "2.1rem" }} />
          </CardActions>
          <Typography
            sx={{ display: "flex", justifyContent: "center" }}
            color="var(--font-black)"
          >
            Configuration
          </Typography>
        </Card>
        <Card
          component={Link}
          to={"/usermanagement"}
          elevation={0}
          sx={{
            textDecoration: "none",
            width: "230px",
            height: "120px",
            margin: "15px",
            padding: 3,
            border: "1px solid #ccc",
          }}
        >
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <GroupIcon sx={{ width: "3rem", height: "2.1rem" }} />
          </CardActions>
          <Typography
            sx={{ display: "flex", justifyContent: "center" }}
            color="var(--font-black)"
          >
            User Management
          </Typography>
        </Card>
        <Card
          component={Link}
          to={"/profile"}
          elevation={0}
          sx={{
            textDecoration: "none",
            width: "230px",
            height: "120px",
            margin: "15px",
            padding: 3,
            border: "1px solid #ccc",
          }}
        >
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <ManageAccountsIcon sx={{ width: "3rem", height: "2.1rem" }} />
          </CardActions>
          <Typography
            sx={{ display: "flex", justifyContent: "center" }}
            color="var(--font-black)"
          >
            business Profile
          </Typography>
        </Card>
        <Card
          component={Link}
          to={"/change-password"}
          elevation={0}
          sx={{
            textDecoration: "none",
            width: "230px",
            height: "120px",
            margin: "15px",
            padding: 3,
            border: "1px solid #ccc",
          }}
        >
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <LockResetIcon sx={{ width: "3rem", height: "2.1rem" }} />
          </CardActions>
          <Typography
            sx={{ display: "flex", justifyContent: "center" }}
            color="var(--font-black)"
          >
            Change Password
          </Typography>
        </Card>
      </Box>
    </Box>
  );
}

export default Settings;
