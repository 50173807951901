import { useEffect, useState } from "react";
import { Button, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { TabContext, TabPanel } from "@mui/lab";
import AdminSubNavbar from "./../components/common/SubNavbar";
import AllKotOrders from "./../components/posAdminPanel/kot/AllKotOrders";
import FlowDirection from "./../components/posAdminPanel/navBarAndSideBar/FlowDirection";
import { ChangeflowDirectionItemKot } from "./FlowDirection";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "./../components/common/SearchBar";
import { getAllKotProducts } from "../sevice/OrderAndKot";
import { useSelector } from "react-redux";

export default function Kot() {
  const { POSadmin } = useSelector((state) => state);
  const [allkotProducts, setAllkotProducts] = useState([]);
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const navBarItems = [
    {
      label: "All",
      value: "1",
    },
    {
      label: "Dine in",
      value: "2",
    },
    {
      label: "Delivery",
      value: "3",
    },
    {
      label: "Pickup",
      value: "4",
    },
  ];
  const [query, setQuery] = useState("");
  const keys = ["orderId.orderId", "tableNo", "kotId.kotName"];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let [flowDirectionItems, setFlowDirectionItems] = useState([]);
  useEffect(() => {
    ChangeflowDirectionItemKot(value, setFlowDirectionItems, navBarItems);
  }, [value]);
  const backFunction = () => {
    if (value === "1") {
      navigate("/allorders");
    } else {
      setValue(String(Number(value) - 1));
    }
  };

  useEffect(() => {
    fetchAllkots();
  }, []);
  const fetchAllkots = async () => {
    const data = await getAllKotProducts(POSadmin.token);
    setAllkotProducts(data);
  };
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Toolbar />
      <FlowDirection
        items={flowDirectionItems}
        backFunction={backFunction}
        showBack={true}
      />
      <Box>
        <TabContext value={value}>
          <AdminSubNavbar
            handleChange={handleChange}
            items={navBarItems}
            value={value}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "10px",
              px: "15px",
            }}
          >
            <SearchBar
              searchBarWidth={"200px"}
              placeholder={"Search"}
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
            />
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                background: "var(--red)",
                "&:hover": {
                  backgroundColor: "var(--red)",
                },
              }}
              component={Link}
              to={"/allorders"}
            >
              Order View
            </Button>
          </Box>
          <TabPanel value="1" sx={{ p: 0 }}>
            <AllKotOrders
              type={"All"}
              query={query}
              keys={keys}
              fetchAllkots={fetchAllkots}
              allkotProducts={allkotProducts}
            />
          </TabPanel>
          <TabPanel value="2" sx={{ p: 0 }}>
            <AllKotOrders
              type={"Dine in"}
              query={query}
              allkotProducts={allkotProducts}
              keys={keys}
              fetchAllkots={fetchAllkots}
            />
          </TabPanel>
          <TabPanel value="3" sx={{ p: 0 }}>
            <AllKotOrders
              type={"Delivery"}
              query={query}
              allkotProducts={allkotProducts}
              keys={keys}
              fetchAllkots={fetchAllkots}
            />
          </TabPanel>
          <TabPanel value="4" sx={{ p: 0 }}>
            <AllKotOrders
              type={"Pickup"}
              query={query}
              allkotProducts={allkotProducts}
              keys={keys}
              fetchAllkots={fetchAllkots}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
