import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import bread from "../../../assets/bread 1.svg";
import Vector from "../../../assets/Vector (2).svg";
import cooking from "../../../assets/cooking 1.svg";
import cups from "../../../assets/cups 1.svg";
import pizza from "../../../assets/pizza 2.svg";
import rice from "../../../assets/rice 1.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const initialValues = {
  title: "",
  titleAr: "",
};

export default function SelectVariationModal({ close, open, allProperty }) {
  const { isLanguageSwitched } = useSelector((state) => state);
  const { t } = useTranslation();
  const [variants, setVariants] = useState([]);
  useEffect(() => {
    const data = allProperty.map((item) => {
      return item[0];
    });
    setVariants(data);
  }, [allProperty]);

  const selectVariaton = object({
    title: string()
      .required("please select a option")
      .notOneOf(variants, "This variation already used"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: selectVariaton,
    onSubmit: async (values, action) => {
      open(values.title, values.titleAr);
    },
  });

  const variationList = [
    {
      image: cups,
      head: "Quantity",
      headAr: "حجم",
      discription: "Quantity variations like - Small, Medium, Large etc..",
      // head: t("selectVariationModal.QUANTITY"),
      // discription: t("selectVariationModal.QUANTITY_DESC"),
    },
    {
      image: cooking,
      head: "Preparation type",
      headAr: "نوع التحضير",
      discription: "Item preparation style eg- Halal, Non-halal, etc.",
      // head: t("selectVariationModal.PREPARATION_TYPE"),
      // discription: t("selectVariationModal.PREPARATION_TYPE_DESC"),
    },
    {
      image: pizza,
      head: "Size",
      headAr: "قياس",
      discription:
        "Different sizes of an item, eg-bread size, pizza size Etc..",
      // head: t("selectVariationModal.SIZE"),
      // discription: t("selectVariationModal.SIZE_DESC"),
    },
    {
      image: bread,
      head: "Base",
      headAr: "الأساسيات",
      discription: "Item Base types, eg-wheat bread, multi-grain bread",
      // head: t("selectVariationModal.BASE"),
      // discription: t("selectVariationModal.BASE_DESC"),
    },
    {
      image: rice,
      head: "Rice",
      headAr: "الأرز",
      discription: "Choice of item's rice selection.",
      // head: t("selectVariationModal.RICE"),
      // discription: t("selectVariationModal.RICE_DESC"),
    },
    {
      image: Vector,
      head: "Add Variation",
      headAr: "إضافة عناصر بديلة",
      discription: "Create your own variation",
      // head: t("selectVariationModal.ADD_VARIATION"),
      // discription: t("selectVariationModal.ADD_VARIATION_DESC"),
    },
  ];

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Box>
            <Typography
              sx={{ m: "10px" }}
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              {/* {t("selectVariationModal.ADD_VARIANTS")} */}
              Add Variants
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {variationList.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "10px",
                  border:
                    formik.values.title == item.head
                      ? "solid var(--red) 3px"
                      : "solid var(--border-black) 1px",
                  width: "338px",
                  p: "5px",
                  height: "85px",
                  pl: "10px",
                  borderRadius: "8px",
                  m: "10px",
                }}
                onClick={() => {
                  formik.setFieldValue("title", item.head);
                  if (isLanguageSwitched) {
                    formik.setFieldValue("titleAr", item.headAr);
                  }
                }}
              >
                <Box sx={{ display: "flex", alignContent: "center" }}>
                  <img
                    style={{ width: "30px" }}
                    src={item.image}
                    alt=""
                    srcset=""
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{ my: "10px" }}
                    style={{ fontSize: "13px", fontWeight: "bold" }}
                  >
                    {item.head}
                  </Typography>
                  <Typography style={{ fontSize: "11px" }}>
                    {item.discription}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          {formik.touched.title && formik.errors.title && (
            <p
              style={{
                color: "#D32F2F",
                marginLeft: "12px",
                fontSize: "12px",
              }}
              id="title-helper-text"
              className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
            >
              {formik.errors.title}
            </p>
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end", m: "10px" }}>
          <Button
            sx={{
              height: "100%",
              fontSize: "16px",
              mr: "10px",
              mt: "3px",
              textTransform: "none",
              color: "var(--bg-white)",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            type="submit"
            variant="text"
          >
            {/* {t("common.NEXT")} */}
            Next
          </Button>

          <Button
            sx={{
              height: "100%",
              fontSize: "16px",
              mt: "3px",
              textTransform: "none",
              color: "var(--font-black)",
              background: "var(--button-gray)",
              "&:hover": {
                backgroundColor: "var(--button-gray)",
              },
            }}
            variant="text"
            onClick={close}
          >
            {/* {t("common.CANCEL")} */}
            cancel
          </Button>
        </Box>
      </form>
    </div>
  );
}
