import { Box, Button, Card, Typography, TextField } from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { editDeliverySchema } from "../../../schemas";
import { useSelector } from "react-redux";
import { editDeliveryBoy } from "../../../sevice/userManagement";

function EditDeliveryBoy({ editItem, handleClose }) {
  const { POSadmin } = useSelector((state) => state);
  const initialValues = {
    first_name: editItem.first_name,
    last_name: editItem.last_name,
    phone: editItem.phone,
    password: "",
    confirmPassword: "",
    userPasscode: editItem.userPasscode,
    swipeCode: editItem.swipeCode,
    addressLine1: editItem.addressLine1,
    addressLine2: editItem.addressLine2,
    dateOfBirth: editItem.dateOfBirth,
    email: editItem.email,
    city: editItem.city,
    zipCode: editItem.zipCode,
    document: editItem.document,
    geoFence: editItem.geoFence,
    payout: editItem.payout,
    time: editItem.time,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editDeliverySchema,
    onSubmit: async (values, action) => {
      const data = await editDeliveryBoy(POSadmin.token, values, editItem._id);
      if (data.status) {
        toast.success(data.message);
        handleClose();
      } else {
        toast.error(data.message);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Typography sx={{ p: 3 }} variant="h6">
            Edit Delivery Boy
          </Typography>
          <Card
            elevation={0}
            sx={{
              width: "100%",
              height: "30%",
              marginRight: "15px",
              marginBottom: "20px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "50ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="Fist Name"
                id="first_name"
                variant="outlined"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.first_name && !!formik.errors.first_name
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
                required
              />
              <TextField
                label="Last Name"
                id="last_name"
                variant="outlined"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={!!formik.touched.last_name && !!formik.errors.last_name}
                helperText={formik.touched.last_name && formik.errors.last_name}
                required
              />
              <TextField
                label="Phone"
                id="phone"
                variant="outlined"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={!!formik.touched.phone && !!formik.errors.phone}
                helperText={formik.touched.phone && formik.errors.phone}
                required
              />
              <TextField
                label="Password"
                id="password"
                variant="outlined"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={!!formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                required
              />
              <TextField
                sx={{ marginLeft: "30px" }}
                id="outlined-basic"
                label="Confirm password"
                variant="outlined"
                type="password"
                name="passwordConfirmation"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.passwordConfirmation}
                error={
                  !!formik.touched.passwordConfirmation &&
                  !!formik.errors.passwordConfirmation
                }
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
                required
              />
              <TextField
                label="User Passcode"
                id="userPasscode"
                variant="outlined"
                name="userPasscode"
                value={formik.values.userPasscode}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.userPasscode && !!formik.errors.userPasscode
                }
                helperText={
                  formik.touched.userPasscode && formik.errors.userPasscode
                }
              />
              <TextField
                label="Swipe Code"
                id="swipeCode"
                variant="outlined"
                name="swipeCode"
                value={formik.values.swipeCode}
                onChange={formik.handleChange}
                error={!!formik.touched.swipeCode && !!formik.errors.swipeCode}
                helperText={formik.touched.swipeCode && formik.errors.swipeCode}
              />
            </Box>
          </Card>
          <Typography sx={{ p: 1 }} variant="h6">
            Personals Details
          </Typography>
          <Card
            elevation={0}
            sx={{
              width: "100%",
              height: "30%",
              marginRight: "15px",
              marginBottom: "20px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "50ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="Address line 1"
                id="addressLine1"
                variant="outlined"
                name="addressLine1"
                value={formik.values.addressLine1}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.addressLine1 && !!formik.errors.addressLine1
                }
                helperText={
                  formik.touched.addressLine1 && formik.errors.addressLine1
                }
                required
              />
              <TextField
                label="Address line 2"
                id="addressLine2"
                variant="outlined"
                name="addressLine2"
                value={formik.values.addressLine2}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.addressLine2 && !!formik.errors.addressLine2
                }
                helperText={
                  formik.touched.addressLine2 && formik.errors.addressLine2
                }

                required
              />
              <TextField
                label="Date of Birth"
                id="dateOfBirth"
                variant="outlined"
                name="dateOfBirth"
                type="date"
                value={formik.values.dateOfBirth}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.dateOfBirth && !!formik.errors.dateOfBirth
                }
                helperText={
                  formik.touched.dateOfBirth && formik.errors.dateOfBirth
                }

                required
              />
              <TextField
                label="Email"
                id="email"
                variant="outlined"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={!!formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                required
              />
              <TextField
                label="City"
                id="city"
                variant="outlined"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={!!formik.touched.city && !!formik.errors.city}
                helperText={formik.touched.city && formik.errors.city}
                required
              />
              <TextField
                label="Zip Code"
                id="zipCode"
                variant="outlined"
                name="zipCode"
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                error={!!formik.touched.zipCode && !!formik.errors.zipCode}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
                required
              />
              <TextField
                label="Document"
                id="document"
                variant="outlined"
                name="document"
                type="file"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Box>
          </Card>
          <Typography sx={{ p: 1 }} variant="h6">
            Delivery Details
          </Typography>
          <Card
            elevation={0}
            sx={{
              width: "100%",
              height: "30%",
              marginRight: "15px",
              marginBottom: "20px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "50ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="Geo-fence"
                id="geoFence"
                variant="outlined"
                name="geoFence"
                value={formik.values.geoFence}
                onChange={formik.handleChange}
                error={!!formik.touched.geoFence && !!formik.errors.geoFence}
                helperText={formik.touched.geoFence && formik.errors.geoFence}
                required
              />
              <TextField
                label="Payout"
                id="payout"
                variant="outlined"
                name="payout"
                value={formik.values.payout}
                onChange={formik.handleChange}
                error={!!formik.touched.payout && !!formik.errors.payout}
                helperText={formik.touched.payout && formik.errors.payout}
                required
              />
              <TextField
                label="Time"
                id="time"
                variant="outlined"
                name="time"
                value={formik.values.time}
                onChange={formik.handleChange}
                error={!!formik.touched.time && !!formik.errors.time}
                helperText={formik.touched.time && formik.errors.time}
                required
              />
            </Box>
          </Card>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Box>
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  width: "100px",
                  textTransform: "none",
                  background: "var( --color-secondary)",
                  "&:hover": {
                    backgroundColor: "var( --color-secondary)",
                  },
                }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  width: "100px",
                  textTransform: "none",
                  background: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                }}
                type="submit"
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}

export default EditDeliveryBoy;
