import React from "react";
import Moment from "react-moment";

export const DateFormatter = ({ date }) => {
  return <Moment format="MMM Do YYYY">{date}</Moment>;
};

export const DateFormatterWithTime = ({ date }) => {
  return <Moment format="MMM Do YYYY, h:mm">{date}</Moment>;
};
