import { useState } from "react";
import { Button, Drawer, Modal, Toolbar, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import AllGroups from "./../components/posAdminPanel/group/AllGroups";
import AllProducts from "./../components/posAdminPanel/group/AllProducts";
import { getAllGroups, getSingleGroup } from "./../sevice/admin"; 
import { useNavigate } from "react-router-dom";
import ListAllAddons from "./../components/posAdminPanel/addons/ListAllAddons";
import FlowDirection from "./../components/posAdminPanel/navBarAndSideBar/FlowDirection";
import SearchBar from "./../components/common/SearchBar";
import UpdateGroup from "../components/posAdminPanel/group/UpdateGroup";
import { toast } from "react-hot-toast";
import LoadingImage from "../assets/loader.gif";
import { useEffect } from "react";
import { DownLoadExcel, importProduct } from "../sevice/product";
import ListAllDiscount from "../components/posAdminPanel/discount/ListAllDiscount";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 705,
  maxHeight: 490,
  overflowY: "scroll",
  bgcolor: "white",
  boxShadow: 24,
  px: 4,
  py: 1.5,
  borderRadius: "5px",
};

export default function Group() {
  const navigate = useNavigate();
  const POSadmin = useSelector((state) => state.POSadmin);
  const [selectedGroupId, setselectedGroupId] = useState("");
  const [IsLoading, setLoading] = useState(true);
  const [selectedGroupData, setSelectedGroupData] = useState();
  const [updateState, setUpdateState] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const [items, setItems] = useState({
    addOns: false,
    create: false,
  });

  const [discountService, setDiscountService] = useState({
    discounts: false,
    create: false,
    edit: false,
  });

  const [query, setQuery] = useState("");
  const [file, setFile] = useState(null); // State to hold the selected file

  const [openAddGroup, setOpenAddGroup] = useState(false);
  const [openImportFile, setImportFile] = useState(false);

  const handleOpenGroup = () => setOpenAddGroup(true);
  const handleCloseGroup = () => setOpenAddGroup(false);
  const handleOpenFile = () => setImportFile(true);
  const handlecloseFile = () => setImportFile(false);

  const fetchSingleGroup = async (id) => {
    const data = await getSingleGroup(id, POSadmin.token);
    setSelectedGroupData(data);
    setLoading(false);
  };
  useEffect(() => {
    getallGroupsFunction();
  }, []);

  const [allGroups, setAllGroups] = useState([]);
  const getallGroupsFunction = async () => {
    const data = await getAllGroups(POSadmin?.token);
    setAllGroups(data);
    setselectedGroupId(data[0]?._id);
    fetchSingleGroup(data[0]?._id);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    setItems({ ...items, [anchor]: open });
  };
  const toggleDrawerForDiscount = (anchor, open) => (event) => {
    setDiscountService({ ...discountService, [anchor]: open });
  };

  const navBarItems = [
    {
      name: "Menu Managemet",
      show: true,
    },
    {
      name: "Groups",
      show: true,
    },
  ];
  const sentMessage = (data) => {
    if (data.status) {
      toast.success(data.message);
      handleCloseGroup();
      getallGroupsFunction();
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleBulkImport = async () => {
    setLoader(true);
    if (!file) {
      setErrorMessage("Please select a file to import.");
      setLoader(false);
      return;
    }

    if (!file.name.match(/\.(csv|xlsx)$/)) {
      setErrorMessage("Please select a CSV or Excel file.");
      setLoader(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file);
      const data = await importProduct(formData, POSadmin?.token);
      if (data?.status) {
        toast.success("Products imported successfully");
        handlecloseFile();
      } else {
        if (data.message && data.message.startsWith("Group")) {
          const groupName = data.message.split(" ")[1];
          const errorMessage = `There are no group ${groupName} found, Add particular group to continue`;
          throw new Error(errorMessage);
        
        }
        throw new Error(data?.message);

      }
    } catch (error) {
      toast.error(error.message);
      setErrorMessage(error.message);
    } finally {
      setLoader(false);
      setErrorMessage("");
    }
  };

  const handleDownloadSampleFile = async () => {
    try {
      const data = await DownLoadExcel(POSadmin?.token);

      const url = window.URL.createObjectURL(new Blob([data]));

      const a = document.createElement("a");
      a.href = url;
      a.download = "downloaded-excel-file.xlsx";

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading sample file:", error);
    }
  };


  function handleDiscount(key,value){
    setDiscountService(prevState => ({
      ...prevState,
     [key]:value   
    }));
    
  }
  return (
    <div style={{ height: "100%", width: "100%" }}>
      {IsLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{ height: "300px" }} src={LoadingImage} alt="Loading" />
        </Box>
      ) : (
        <>
          <FlowDirection
            items={navBarItems}
            style={{ width: "100%" }}
            showArabic={true}
          />
          <Toolbar />

          <div style={{ display: "flex", height: "89%", marginTop: "40px" }}>
            <AllGroups
              selectedGroupId={selectedGroupId}
              setselectedGroupId={setselectedGroupId}
              fetchSingleGroup={fetchSingleGroup}
              updateState={updateState}
              getallGroupsFunction={getallGroupsFunction}
              allGroups={allGroups}
            />
            <div style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  m: "20px",
                }}
              >
                <SearchBar
                  onChange={(e) => setQuery(e.target.value.toLowerCase())}
                  placeholder={"Search"}
                />
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{ display: "flex", justifyContent: "end", px: "20px" }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        width: "130px",
                        height: "40px",
                      }}
                      onClick={handleOpenGroup}
                    >
                      Update Group
                    </Button>
                  </Box>
                  <div>
                    <>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          background: "var(--red)",
                          mr: "20px",
                          width: "130px",
                          height: "40px",
                        }}
                        onClick={()=>handleDiscount("discounts", true)}
                      >
                        Discount
                      </Button>
                      <Drawer
                        anchor="right"
                        open={discountService?.discounts}
                        onClose={()=>handleDiscount("discounts", false)}
                      >
                        <Box sx={{ width: "50%" }}>
                          <ListAllDiscount
                            anchor="discounts"
                            handleDiscount={handleDiscount}
                            items={discountService}
                            groups={allGroups}
                          />
                        </Box>
                      </Drawer>
                    </>
                  </div>
                  <div>
                    <>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          background: "var(--red)",
                          mr: "20px",
                          width: "130px",
                          height: "40px",
                        }}
                        onClick={toggleDrawer(["addOns"], true)}
                      >
                        Add Ons
                      </Button>
                      <Drawer
                        anchor="right"
                        open={items["addOns"]}
                        onClose={toggleDrawer("addOns", false)}
                      >
                        <Box sx={{ width: "50%" }}>
                          <ListAllAddons
                            anchor="addOns"
                            toggleDrawer={toggleDrawer}
                            items={items}
                          />
                        </Box>
                      </Drawer>
                    </>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        background: "var(--red)",
                        width: "130px",
                        height: "40px",
                        mr: "20px",
                      }}
                      onClick={handleOpenFile}
                    >
                      Bulk Import
                    </Button>
                    {openImportFile && (
                      <Modal
                        open={openImportFile}
                        onClose={handlecloseFile}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Button
                            variant="text"
                            onClick={handleDownloadSampleFile}
                            sx={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              color: "black",
                              textTransform: "none",
                            }}
                          >
                            Download Sample Excel File
                          </Button>

                          <TextField
                            id="file"
                            type="file"
                            label="Select File"
                            onChange={handleFileChange}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            sx={{ marginTop: "25px" }} // Adjusted margin
                          />
                          {errorMessage && (
                            <div style={{ color: "red", marginTop: "10px" }}>
                              {errorMessage}
                            </div> // Adjusted margin
                          )}
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "none",
                              background: "var(--red)",
                              width: "130px",
                              height: "40px",
                              marginTop: "20px",
                            }}
                            onClick={handleBulkImport}
                          >
                            {loader ? "Submitting....." : "Submit"}
                          </Button>
                        </Box>
                      </Modal>
                    )}
                  </div>

                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      background: "var(--red)",
                      width: "130px",
                      height: "40px",
                    }}
                    onClick={() => navigate("/addproduct")}
                  >
                    + Add Product
                  </Button>
                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>
                <AllProducts
                  selectedGroupData={selectedGroupData}
                  fetchSingleGroup={fetchSingleGroup}
                  query={query}
                  updateState={updateState}
                  setUpdateState={setUpdateState}
                  IsLoading={IsLoading}
                />
              </Box>
            </div>
          </div>
          <Modal
            open={openAddGroup}
            onClose={handleCloseGroup}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <UpdateGroup
                handleCloseGroup={handleCloseGroup}
                sentMessage={sentMessage}
                item={selectedGroupData}
              />
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
}
