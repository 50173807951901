import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const addProductImages = async (datas, token) => {
  try {
    const { data } = await axios.post(
      `${host}/posAdminAddProductImages`,
      datas,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteProductImages = async (datas, token) => {
  try {
    const { data } = await axios.post(
      `${host}/posAdminDeleteProductImages`,
      { images: datas },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addProduct = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/posAddProduct`, datas, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateProduct = async (datas, variationData, token) => {
  try {
    const { data } = await axios.post(
      `${host}/posAdminUpdateProduct`,
      { datas: datas, variationData: variationData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};


export const deleteVariation = async (proId, qty, varId, token) => {
  try {
    const { data } = await axios.delete(
      `${host}/posAdminDeleteVariation/${proId}/${qty}/${varId}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const PosAdmindeleteProduct = async (proId, token) => {
  try {
    const { data } = await axios.delete(
      `${host}/PosAdmindeleteProduct/${proId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const fetchAllVendorProduct = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posFetchAllVendorProduct`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const importProduct = async (payLoad, token) => {
  try {
    const { data } = await axios.post(`${host}/bulkImport/`, payLoad, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 360000,
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const DownLoadExcel = async (token) => {
  try {
    const { data } = await axios.get(`${host}/download-excel/`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};


export const editProduct = async (datas, token) => {
  try {
    const { data } = await axios.post(
      `${host}/edit-product`,
      { datas},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
