import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Autocomplete,
  Modal,
  Stack,
  Pagination,
} from "@mui/material";
import { Box } from "@mui/system";
import SearchBar from "../../common/SearchBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField, Select } from "formik-mui";
import { wastageSchema, wastageItem } from "../../../schemas/index";
import {
  addWastage,
  getAllSubUnits,
  getAllWastage,
  getRawMaterials,
  getRecipes,
} from "../../../sevice/inventory";
import { toast } from "react-hot-toast";
import { DateFormatterWithTime } from "../../../utils/DateFormatter";
import EditWastage from "./EditWastage";
import LoadingImage from "../../../assets/loader.gif";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));
const style = {
  width: "90%",
  position: "absolute",
  top: "54%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
let EditInitialValues;

function Wastage({ state, setState }) {
  const { POSadmin } = useSelector((state) => state);
  const [rawmet, setRawMet] = useState([]);
  const [units, setUnits] = useState([]);
  const [wastages, setWastage] = useState([]);
  const [query, setQuery] = useState("");
  const [wastageFor, setWastageFor] = useState("Item");
  const [products, setProducts] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const handleOpenEdit = () => setEditOpen(true);
  const handleCloseEdit = () => setEditOpen(false);
  const [IsLoading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [isSubmit, setIsSubmit] = useState(false);

  const existRamet = [];
  const getAllWastageFun = async () => {
    const data2 = await getAllWastage(POSadmin.token, "");
    data2?.docs.forEach((item) => {
      existRamet.push(item.name);
    });
  };

  const initialValues = {
    wastageFor: "Item",
    items: [
      {
        name: "",
        quantity: "",
        unit: "",
      },
    ],
  };
  const initialValuesItem = {
    wastageFor: "Item",
    items: [
      {
        name: "",
        quantity: 1,
      },
    ],
  };
  const emptyRaw = {
    name: "",
    quantity: 1,
    unit: "",
  };
  const emptyRawItem = {
    name: "",
    quantity: 1,
  };

  const submitdata = async (values, action) => {
    setIsSubmit(true);
    let wastageData;
    let avgEstimatedLoss;

    if (values.wastageFor === "Item") {
      wastageData = values.items.map((item) => {
        return {
          productId: item.name.product._id,
          quantity: item.quantity,
          price: item.name.rawMaterial
            .reduce(
              (acc, cur) =>
                acc +
                Number(cur.quantity) *
                  Number(cur.unit.baseValue) *
                  Number(cur.name.purchasePrice),
              0
            )
            .toFixed(2),
        };
      });
      avgEstimatedLoss = wastageData
        .reduce((acc, cur) => acc + Number(cur.quantity) * Number(cur.price), 0)
        .toFixed(2);
    } else {
      wastageData = values.items.map((item) => {
        const selectedItem = units.find((unit) => unit._id === item.unit);
        return {
          RawMaterialId: item.name._id,
          quantity: item.quantity,
          price:
            Number(item.quantity) *
            Number(selectedItem.baseValue) *
            Number(item.name.purchasePrice).toFixed(2),
          unit: item.unit,
        };
      });
      avgEstimatedLoss = wastageData
        .reduce((acc, cur) => acc + Number(cur.price), 0)
        .toFixed(2);
    }
    const data = await addWastage(POSadmin.token, {
      wastageFor: values.wastageFor,
      items: wastageData,
      avgEstimatedLoss: avgEstimatedLoss,
    });
    if (data.status) {
      toast.success(data.message);
      setState(true);
      action.resetForm();
    } else {
      toast.error(data.message);
    }
  };
  const getDatas = async () => {
    const data = await getRawMaterials(POSadmin.token, "");
    const data1 = await getAllSubUnits();
    const productData = await getRecipes(POSadmin.token, "");
    setProducts(productData.docs);
    setRawMet(data.docs);
    setUnits(data1);
  };
  useEffect(() => {
    getDatas();
    getAllWastageFun();
  }, [state, editOpen]);

  useEffect(() => {
    Wastage();
  }, [state, editOpen, query, pageNo]);

  const Wastage = async () => {
    const data2 = await getAllWastage(POSadmin.token, query, pageNo);
    setWastage(data2);
    setLoading(false);
  };

  const findsubUnit = (editData) => {
    if (editData.wastageFor === "Item") {
      EditInitialValues = {
        wastageFor: editData.wastageFor,
        items: editData.items,
      };
    } else {
      const item = editData?.items?.map((item) => {
        let unitData = units?.find((unit) => unit._id === item.unit);
        return {
          RawMaterialId: item.RawMaterialId,
          baseUnit: unitData.unit,
          quantity: item.quantity,
          unit: item.unit,
        };
      });
      EditInitialValues = {
        wastageFor: editData.wastageFor,
        items: item,
      };
    }
  };

  const handleChangePageNo = (event, value) => {
    setPageNo(value);
  };

  return (
    <>
      {state && !IsLoading && (
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            searchBarWidth={"200px"}
            placeholder={"Search"}
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={() => {
              setState(false);
              setIsSubmit(false);
            }}
          >
            Wastage
          </Button>
        </Box>
      )}
      {IsLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img style={{ height: "300px" }} src={LoadingImage} alt="Loading" />
        </Box>
      ) : state ? (
        <Box sx={{ marginTop: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead sx={{ background: "var(--table-head)" }}>
                <TableRow>
                  <StyledTableCell align="center">SL NO </StyledTableCell>
                  <StyledTableCell align="center">Created </StyledTableCell>
                  <StyledTableCell align="center">Wastage for</StyledTableCell>
                  <StyledTableCell align="center">
                    Avg Estimated Loss (₹)
                  </StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wastages?.docs?.map((item, index) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">
                      {wastages.pagingCounter + index}
                    </TableCell>
                    <TableCell align="center">
                      <DateFormatterWithTime date={item.updatedAt} />
                    </TableCell>
                    <TableCell align="center">{item.wastageFor}</TableCell>
                    <TableCell align="center">
                      {item.avgEstimatedLoss}
                    </TableCell>
                    <TableCell align="center">
                      <VisibilityIcon />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <SaveAsIcon
                        onClick={() => {
                          setEditItem(item);
                          findsubUnit(item);
                          handleOpenEdit();
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box
              component={Paper}
              sx={{
                marginTop: "10px",
                backgroundColor: "white",
                padding: "10px",
              }}
            >
              <Stack>
                <Pagination
                  count={wastages?.totalPages}
                  page={pageNo}
                  onChange={handleChangePageNo}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            </Box>
          </TableContainer>
          <Modal
            open={editOpen}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <EditWastage
                editItem={editItem}
                units={units}
                EditInitialValues={EditInitialValues}
                handleClose={handleCloseEdit}
                products={products}
                rawmet={rawmet}
              />
            </Box>
          </Modal>
        </Box>
      ) : (
        <>
          <Box>
            <Formik
              initialValues={
                wastageFor == "Item" ? initialValuesItem : initialValues
              }
              validationSchema={
                wastageFor == "Item" ? wastageItem : wastageSchema
              }
              onSubmit={submitdata}
            >
              {({ values, setFieldValue }) => (
                <Form autoComplete="off">
                  <Typography sx={{ p: 2 }} variant="h6">
                    Add Wastage
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                      marginLeft: "30px",
                      mb: 8,
                    }}
                  >
                    <br />
                    <InputLabel>Wastage for</InputLabel>
                    <br />
                    <FormControl>
                      <RadioGroup
                        value={values.wastageFor}
                        onChange={(e) => {
                          setFieldValue(
                            "items",
                            wastageFor == "Item"
                              ? [
                                  {
                                    name: "",
                                    quantity: 1,
                                    unit: "",
                                  },
                                ]
                              : [
                                  {
                                    name: "",
                                    quantity: 1,
                                  },
                                ]
                          );
                          setFieldValue("wastageFor", e.target.value);
                          setWastageFor(e.target.value);
                        }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Item"
                          control={<Radio />}
                          label="Item"
                        />
                        <FormControlLabel
                          value="Raw Material"
                          control={<Radio />}
                          label="Raw Material"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead sx={{ background: "var(--table-head)" }}>
                        <TableRow>
                          <StyledTableCell align="center">Name</StyledTableCell>
                          <StyledTableCell align="center">Qty</StyledTableCell>
                          {wastageFor !== "Item" && (
                            <StyledTableCell align="center">
                              Unit
                            </StyledTableCell>
                          )}
                          <StyledTableCell align="center">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <FieldArray name="items">
                        {({ push, remove }) => (
                          <>
                            <TableBody>
                              {values.items.map((_, index) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    <FormControl sx={{ m: 1, minWidth: 300 }}>
                                      {wastageFor === "Item" ? (
                                        <Autocomplete
                                          options={products}
                                          size="small"
                                          getOptionLabel={(option) =>
                                            option?.product?.title
                                          }
                                          renderInput={(params) => (
                                            <Field
                                              component={TextField}
                                              {...params}
                                              value={params._id}
                                              name={`items.${index}.name`}
                                              variant="outlined"
                                              fullWidth
                                              onChange={() => {}}
                                            />
                                          )}
                                          onChange={(event, newValue) => {
                                            if (newValue) {
                                              setFieldValue(
                                                `items.${index}.name`,
                                                newValue
                                              );
                                            } else {
                                              setFieldValue(
                                                `items.${index}.name`,
                                                {}
                                              );
                                            }
                                          }}
                                        />
                                      ) : (
                                        <Autocomplete
                                          options={rawmet}
                                          size="small"
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          renderInput={(params) => (
                                            <Field
                                              component={TextField}
                                              {...params}
                                              value={params._id}
                                              name={`items.${index}.name`}
                                              variant="outlined"
                                              fullWidth
                                              onChange={() => {}}
                                            />
                                          )}
                                          onChange={(event, newValue) => {
                                            if (newValue) {
                                              setFieldValue(
                                                `items.${index}.name`,
                                                newValue
                                              );
                                            } else {
                                              setFieldValue(
                                                `items.${index}.name`,
                                                {}
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Field
                                      sx={{ m: 1 }}
                                      component={TextField}
                                      name={`items.${index}.quantity`}
                                      size="small"
                                      fullWidth
                                    />
                                  </TableCell>
                                  {wastageFor !== "Item" && (
                                    <TableCell align="center">
                                      <FormControl sx={{ m: 1, minWidth: 200 }}>
                                        <Field
                                          component={Select}
                                          name={`items.${index}.unit`}
                                          size="small"
                                        >
                                          {units?.map((item) => {
                                            return (
                                              item.unit ==
                                                values?.items[index]?.name
                                                  ?.purchaseUnit?._id && (
                                                <MenuItem value={item._id}>
                                                  {item.name}
                                                </MenuItem>
                                              )
                                            );
                                          })}
                                        </Field>
                                      </FormControl>
                                    </TableCell>
                                  )}
                                  <TableCell align="center">
                                    <Button onClick={() => remove(index)}>
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>

                            <Button
                              onClick={() =>
                                push(
                                  wastageFor == "Item" ? emptyRawItem : emptyRaw
                                )
                              }
                              variant="contained"
                              sx={{
                                m: 3,
                                width: "110px",
                                textTransform: "none",
                                background: "var(--red)",
                                "&:hover": {
                                  backgroundColor: "var(--red)",
                                },
                              }}
                            >
                              + Add Row
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var( --color-secondary)",
                        "&:hover": {
                          backgroundColor: "var( --color-secondary)",
                        },
                      }}
                      onClick={() => setState(true)}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      disabled={isSubmit}
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var(--red)",
                        "&:hover": {
                          backgroundColor: "var(--red)",
                        },
                      }}
                      type="submit"
                    >
                      {isSubmit ? "Loading.." : "Save"}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </>
      )}
    </>
  );
}

export default Wastage;
