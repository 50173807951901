import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAllSales,
  getDeliveryCount,
  getDineInCount,
  getPickupCount,
} from "./../../../sevice/dashboard";
import FIlterBox from "./FIlterBox";

export default function SaleStats() {
  const { POSadmin } = useSelector((state) => state);
  const [totalSaleFiter, setTotalSaleFiter] = useState("day");
  const [dineInFiter, setDineInFiter] = useState("day");
  const [pickUpFiter, setPickUpFiter] = useState("day");
  const [deliveryFiter, setDeliveryFiter] = useState("day");
  const [all, setAll] = useState({
    count: 0,
    preCount: 0,
  });
  const [dineIn, setDine] = useState({
    count: 0,
    preCount: 0,
  });
  const [pickup, setPickup] = useState({
    count: 0,
    preCount: 0,
  });
  const [delivery, setDelivery] = useState({
    count: 0,
    preCount: 0,
  });

  const handleFindAllSalesCount = async () => {
    const res = await getAllSales(POSadmin.token, totalSaleFiter);
    setAll(res);
  };
  useEffect(() => {
    handleFindAllSalesCount();
  }, [totalSaleFiter]);

  const handleFindDineInCount = async () => {
    const res = await getDineInCount(POSadmin.token, dineInFiter);
    setDine(res);
  };
  useEffect(() => {
    handleFindDineInCount();
  }, [dineInFiter]);

  const handleFindPickupCount = async () => {
    const res = await getPickupCount(POSadmin.token, pickUpFiter);
    setPickup(res);
  };
  useEffect(() => {
    handleFindPickupCount();
  }, [pickUpFiter]);

  const handleFindDeliveryCount = async () => {
    const res = await getDeliveryCount(POSadmin.token, deliveryFiter);
    setDelivery(res);
  };
  useEffect(() => {
    handleFindDeliveryCount();
  }, [deliveryFiter]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        // gap: "25px",
      }}
    >
      <Grid container spacing={1}>
        <Grid item md={6} lg={3}>
          <Card
            variant="outlined"
            sx={{ flex: 1, minWidth: "270px", height: "150px" }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
                // gap: "25px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Total sales</Typography>
                <FIlterBox
                  value={totalSaleFiter}
                  setValue={setTotalSaleFiter}
                />
              </Box>
              <Box
                variant="div"
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "25px" }}>{all.count}</Typography>
                <Box
                  sx={{
                    bgcolor: "#1BAD4E",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    px: 1,
                    borderRadius: "5px",
                    height: "80%",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "13px" }}>
                    {all?.count - all?.preCount >= 0 ? "+ " : "- "}
                    {all?.count - all?.preCount === 0
                      ? "0"
                      : all?.count - all?.preCount > 0
                      ? (
                          ((all?.count - all?.preCount) / all?.count) *
                          100
                        ).toFixed(2)
                      : (
                          ((all?.preCount - all?.count) / all?.preCount) *
                          100
                        ).toFixed(2)}{" "}
                    % more
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>{" "}
        <Grid item md={6} lg={3}>
          <Card
            variant="outlined"
            sx={{ flex: 1, minWidth: "270px", height: "150px" }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
                // gap: "15px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Dine-in</Typography>
                <FIlterBox value={dineInFiter} setValue={setDineInFiter} />
              </Box>
              <Box
                variant="div"
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "25px" }}>
                  {dineIn?.count}
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#1BAD4E",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    px: 1,
                    borderRadius: "5px",
                    height: "80%",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "13px" }}>
                    {dineIn?.count - dineIn?.preCount >= 0 ? "+ " : "- "}
                    {dineIn?.count - dineIn?.preCount === 0
                      ? "0"
                      : dineIn?.count - dineIn?.preCount > 0
                      ? (
                          ((dineIn?.count - dineIn?.preCount) / dineIn?.count) *
                          100
                        ).toFixed(2)
                      : (
                          ((dineIn?.preCount - dineIn?.count) /
                            dineIn?.preCount) *
                          100
                        ).toFixed(2)}{" "}
                    % more
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>{" "}
        </Grid>{" "}
        <Grid item md={6} lg={3}>
          <Card
            variant="outlined"
            sx={{ flex: 1, minWidth: "270px", height: "150px" }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
                // gap: "15px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Pick up</Typography>
                <FIlterBox value={pickUpFiter} setValue={setPickUpFiter} />
              </Box>
              <Box
                variant="div"
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "25px" }}>
                  {pickup.count}
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#1BAD4E",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    px: 1,
                    borderRadius: "5px",
                    height: "80%",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "13px" }}>
                    {pickup?.count - pickup?.preCount >= 0 ? "+ " : "- "}
                    {pickup?.count - pickup?.preCount === 0
                      ? "0"
                      : pickup?.count - pickup?.preCount > 0
                      ? (
                          ((pickup?.count - pickup?.preCount) / pickup?.count) *
                          100
                        ).toFixed(2)
                      : (
                          ((pickup?.preCount - pickup?.count) /
                            pickup?.preCount) *
                          100
                        ).toFixed(2)}{" "}
                    % more
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>{" "}
        </Grid>{" "}
        <Grid item md={6} lg={3}>
          <Card
            variant="outlined"
            sx={{ flex: 1, minWidth: "270px", height: "150px" }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Delivery</Typography>
                <FIlterBox value={deliveryFiter} setValue={setDeliveryFiter} />
              </Box>

              <Box
                variant="div"
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "25px" }}>
                  {delivery?.count}
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#1BAD4E",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    px: 1,
                    borderRadius: "5px",
                    height: "80%",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "13px" }}>
                    {delivery?.count - delivery?.preCount >= 0 ? "+ " : "- "}
                    {delivery?.count - delivery?.preCount === 0
                      ? "0"
                      : delivery?.count - delivery?.preCount > 0
                      ? (
                          ((delivery?.count - delivery?.preCount) /
                            delivery?.count) *
                          100
                        ).toFixed(2)
                      : (
                          ((delivery?.preCount - delivery?.count) /
                            delivery?.preCount) *
                          100
                        ).toFixed(2)}{" "}
                    % more
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>{" "}
        </Grid>
      </Grid>
    </Box>
  );
}
