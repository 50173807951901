import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Modal,
  Radio,
  RadioGroup,
  Toolbar,
  Typography,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-hot-toast";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { useSelector } from "react-redux";
import {
  findAllCategory,
  findAllSubCategory,
  getAllGroups,
} from "../../../sevice/admin";
import AddAdOnes from "./AddAdOnes";
import SelectVariationModal from "./SelectVariationModal";
import VariationTableModal from "./VariationTableModal";
import {
  styleVariationTable,
  styleAddons,
  style,
  styleVariationOption,
  styleAddVariation,
  styleCreateGroup,
  styleAddRecipe,
} from "./Style";
import { useNavigate } from "react-router-dom";
import AddVariationOptionModal from "./AddVariationOptionModal";
import AddVariationModal from "./AddVariationModal";
import SelectAddonModal from "./SelectAddonModal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { quickAddProductSchema } from "../../../schemas";
import { TextField } from "formik-mui";
import AddGroup from "../group/AddGroup";
import AddRecipe from "./AddRecipe";
import { addProductImages, deleteProductImages } from "../../../sevice/product";
import { useTranslation } from "react-i18next";

let initialVariationOption = {
  title: "",
  titleAr: "",
  items: [{ option: "", optionAr: "" }],
};
let initialVariationOptionupdation = {
  title: "",
  titleAr: "",
  items: [{ option: "" }],
};
let addonTitle = { title: "", titleAr: "" };
let initialValueOfAddoneEdit;
export default function QuickAddProduct({
  product,
  setproduct,
  allProperty,
  setAllProperty,
  initialState,
  AddproductMessage,
}) {
  const { POSadmin, isLanguageSwitched } = useSelector((state) => state);
  const [productData, setproductData] = useState({});
  const { t } = useTranslation();

  //////////////////////////////Select variation////////////////////////
  const [openVariationSelect, setOpenVariationSelect] = useState(false);
  const handleOpenVariationSelect = () => setOpenVariationSelect(true);
  const handleCloseVariationSelect = () => setOpenVariationSelect(false);
  //////////////////////////////add variation////////////////////////////
  const [openAddVatiation, setOpenAddVatiation] = useState(false);
  const handleCloseAddVatiation = () => setOpenAddVatiation(false);
  const handleOpenOption = (title) => {
    handleCloseAddVatiation();
    initialVariationOption = {
      ...initialVariationOption,
      title: title.title,
      titleAr: title.titleAr,
    };
    setOpenVariantOptions(true);
  };
  //////////////////////////////variation options////////////////////////
  const [openVariantOptions, setOpenVariantOptions] = useState(false);
  const [isEdit, SetIsEdit] = useState(false);
  const [editVariationIndex, SetEditVariationIndex] = useState();
  const handleOpenVariationOptions = (title, titleAr) => {
    if (title === "Add Variation") {
      handleCloseVariationSelect();
      setOpenAddVatiation(true);
    } else {
      handleCloseVariationSelect();
      initialVariationOption = {
        ...initialVariationOption,
        title: title,
        titleAr: titleAr,
      };
      setOpenVariantOptions(true);
    }
  };
  const handleCloseVariationOptions = () => setOpenVariantOptions(false);

  const editVariationOption = (item, index) => {
    initialVariationOptionupdation = {
      title: "",
      titleAr: "",
      items: [],
    };
    item.map((prpo, index) => {
      if (index === 0) {
        initialVariationOptionupdation.title = prpo.title;
        initialVariationOptionupdation.titleAr = prpo.titleAr;
      } else {
        initialVariationOptionupdation.items.push(prpo);
      }
    });
    SetIsEdit(true);
    SetEditVariationIndex(index);
    setOpenVariantOptions(true);
  };
  const addNewOption = (item, index) => {
    initialVariationOptionupdation = {
      title: "",
      titleAr: "",
      items: [],
    };
    item.map((prpo, index) => {
      if (index === 0) {
        initialVariationOptionupdation.title = prpo.title;
        initialVariationOptionupdation.titleAr = prpo.titleAr;
      } else {
        initialVariationOptionupdation.items.push(prpo);
      }
    });
    initialVariationOptionupdation.items.push({ option: "", optionAr: "" });
    SetIsEdit(true);
    SetEditVariationIndex(index);
    setOpenVariantOptions(true);
  };
  const deleteProperty = (item, propindex, itemIndex) => {
    initialVariationOptionupdation = {
      title: "",
      titleAr: "",
      items: [],
    };
    item.map((prpo, index) => {
      if (index === 0) {
        initialVariationOptionupdation.title = prpo.title;
        initialVariationOptionupdation.titleAr = prpo.titleAr;
      } else if (propindex !== index) {
        initialVariationOptionupdation.items.push(prpo);
      }
    });
    SetIsEdit(true);
    SetEditVariationIndex(itemIndex);
    setOpenVariantOptions(true);
  };

  //////////////////////////////select addons options////////////////////////
  const [openAddonSelect, setOpenAddonSelect] = useState(false);
  const handleOpenAddonsSelect = () => setOpenAddonSelect(true);
  const handleCloseAddonsSelect = () => setOpenAddonSelect(false);

  ///////////////////////////////create addons//////////////////////////////////////
  const [openAdOns, setOpenAdOns] = useState(false);
  const [editAddoneIndex, setEditAddoneIndex] = useState();

  const handleOpenAdOns = (titlevalue) => {
    if (titlevalue.title === "Add Add-ons") {
      // addonTitle = "";
      addonTitle = { title: "", titleAr: "" };

      handleCloseAddonsSelect();
      setOpenAdOns(true);
    } else {
      handleCloseAddonsSelect();
      addonTitle = { title: titlevalue.title, titleAr: titlevalue.titleAr };
      // addonTitle = titlevalue;
      setOpenAdOns(true);
    }
  };
  const handleCloseAdOns = () => setOpenAdOns(false);
  const addNewOptionOnAddon = (item, index) => {
    initialValueOfAddoneEdit = {
      ...item,
      items: [...item.items, { item: "", name: "" }],
    };
    SetIsEdit(true);
    setEditAddoneIndex(index);
    setOpenAdOns(true);
  };
  const editAddone = (item, index) => {
    initialValueOfAddoneEdit = item;
    SetIsEdit(true);
    setEditAddoneIndex(index);
    setOpenAdOns(true);
  };
  const deleteAddonOption = (item, index, itemIndex) => {
    const addoneValue = item.items.filter((addOne, addIndex) => {
      if (index !== addIndex) {
        return addOne;
      }
    });
    initialValueOfAddoneEdit = { ...item, items: addoneValue };
    SetIsEdit(true);
    setEditAddoneIndex(itemIndex);
    setOpenAdOns(true);
  };
  /////////////////////////////////////////////////////////////////////
  const [openVatiationTable, setOpenVatiationTable] = useState(false);
  ////////////////////////add new group///////////////////
  const [openCreateGroup, setOpenCreateGroup] = useState(false);
  const handleOpenCreateGroup = () => setOpenCreateGroup(true);
  const handleCloseCreateGroup = () => setOpenCreateGroup(false);
  const sentMessage = (data) => {
    if (data.status) {
      handleCloseCreateGroup();
      fetchAllgroup();
      toast.success(data.message);
    }
  };

  /////////////////////Add recipe//////////////////////
  const [openAddRecipe, setOpenAddRecipe] = useState(false);
  const handleOpenAddRecipe = () => setOpenAddRecipe(true);
  const handleCloseAddRecipe = () => setOpenAddRecipe(false);
  ///////////////////////////////////////////
  const handleOpenVatiationTable = () => setOpenVatiationTable(true);
  const handleCloseVatiationTable = () => setOpenVatiationTable(false);

  const [allCategory, setAllCategory] = useState([]);

  useEffect(() => {
    fetchCategory();
  }, []);

  const fetchCategory = async (id) => {
    const data = await findAllCategory(
      "640879ae3cee7036416aaa07",
      POSadmin.token
    );
    setAllCategory(data);
  };

  const [allGroups, setAllGroups] = useState([]);
  useEffect(() => {
    fetchAllgroup();
  }, []);

  async function fetchAllgroup() {
    const data = await getAllGroups(POSadmin.token);
    setAllGroups(data);
  }

  const submitdata = (value) => {
    let varients = value.varients.map((item, index) => {
      let property = [
        item.value1,
        item.value2,
        item.value3,
        item.value4,
        item.value5,
      ]
        .filter(Boolean)
        .join(" ");
      let propertyAr = [
        item.value1Ar,
        item.value2Ar,
        item.value3Ar,
        item.value4Ar,
        item.value5Ar,
      ]
        .filter(Boolean)
        .join(", ");
      let proName = property
        ? `${value.title.replace(/\s+/g, " ").trim()} ${property}`
        : value.title;
      let proNameAr = propertyAr
        ? `${value.titleAr.replace(/\s+/g, " ").trim()}, ${propertyAr}`
        : value.titleAr;
      let letter = proName.slice(0, 4).toUpperCase();
      let digit = Date.now().toString().slice(8, 13) + index;
      let sku = (letter + digit).replace(/\s/g, "");
      return {
        ...item,
        image: [value.image],
        productName: proName,
        productNameAr: proNameAr,
        sku: sku,
      };
    });
    setproductData({ ...value, varients });
    handleOpenVatiationTable();
  };

  const uploadVarientImage = async (e, oldImages, setFieldValue) => {
    const formData = new FormData();
    formData.append("images", e);
    const data = await addProductImages(formData);
    if (data.status) {
      setFieldValue("image", data.images[0]);
      if (oldImages) {
        await deleteProductImages([oldImages], POSadmin.token);
      }
    }
  };

  return (
    <Box>
      <Toolbar />
      <Formik
        initialValues={product}
        validationSchema={quickAddProductSchema}
        onSubmit={submitdata}
      >
        {({ values, errors, setFieldValue, resetForm }) => (
          <Form autoComplete="off">
            <div style={{ padding: "10px", marginTop: "40px" }}>
              <Typography sx={{ my: "10px" }} style={{ fontSize: "20px" }}>
                {/* {t("quickAddProduct.QUICK_ADD")} */}
                Quick Add
              </Typography>
              <Box>
                <Box>
                  <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                    {/* {t('quickAddProduct.NAME')} */}
                    Name
                  </Typography>
                  <Field
                    placeholder="Product name"
                    size="small"
                    fullWidth
                    name={"title"}
                    component={TextField}
                  />

                  {isLanguageSwitched ? (
                    <Field
                      placeholder="اسم المنتج"
                      size="small"
                      fullWidth
                      name={"titleAr"}
                      component={TextField}
                      style={{ marginTop: "15px", display: "block" }}
                      inputProps={{ style: { textAlign: "right" } }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box>
                  <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                    {/* {t("quickAddProduct.GROUP")} */}
                    Group
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Autocomplete
                      options={allGroups}
                      size="small"
                      getOptionLabel={(option) => option.groupName}
                      renderInput={(params) => (
                        <Field
                          component={TextField}
                          {...params}
                          value={params._id}
                          name={"group"}
                          onChange={() => {}}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setFieldValue("group", newValue?._id);
                        } else {
                          setFieldValue("group", "");
                        }
                      }}
                    />
                  </FormControl>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="text"
                      sx={{ mt: "5px" }}
                      style={{ fontSize: "13px" }}
                      onClick={() => handleOpenCreateGroup()}
                    >
                      {/* + {t("quickAddProduct.ADD_GROUP")} */}
                      Add Group
                    </Button>
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                    {/* {t("quickAddProduct.CATEGORY")} */}
                    Category
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Autocomplete
                      options={allCategory}
                      size="small"
                      getOptionLabel={(option) => option.category}
                      renderInput={(params) => (
                        <Field
                          component={TextField}
                          {...params}
                          value={params._id}
                          onChange={() => {}}
                          name={"category"}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setFieldValue("category", newValue?._id);
                        } else {
                          setFieldValue("category", "");
                        }
                      }}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                    {/* {t("quickAddProduct.VIDEO_LINK")} */}
                    Video Link
                  </Typography>
                  <Field
                    placeholder="Video Link"
                    size="small"
                    fullWidth
                    name={"videoLink"}
                    component={TextField}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                    {/* {t("quickAddProduct.DESCRIPTION")} */}
                    Description
                  </Typography>
                  <Field
                    placeholder="Description"
                    size="small"
                    fullWidth
                    name={"description"}
                    component={TextField}
                    multiline
                    rows={4}
                  />
                </Box>
                {isLanguageSwitched ? (
                  <Box>
                    <Field
                      placeholder="الوصف"
                      size="small"
                      fullWidth
                      name={"descriptionAr"}
                      inputProps={{ style: { textAlign: "right" } }}
                      component={TextField}
                      multiline
                      rows={4}
                    />
                  </Box>
                ) : (
                  ""
                )}
                <Box>
                  <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                    {/* {t("quickAddProduct.IMAGE")} */}
                    Image
                  </Typography>
                  <Box
                    sx={{
                      width: "90px",
                      height: "90px",
                      border: "1px dashed #000",
                    }}
                  >
                    <input
                      name="logo"
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          uploadVarientImage(
                            e.target.files[0],
                            values.image,
                            setFieldValue
                          );
                        }
                      }}
                      type="file"
                      accept="image/*"
                      id="logo"
                      style={{ display: "none" }}
                    />
                    <label htmlFor="logo">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={values.image}
                          alt=""
                          style={{
                            width: "90px",
                            height: "90px",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          bgcolor: "red",
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          position: "relative",
                          top: -60,
                          left: 30,
                        }}
                      >
                        +
                      </Box>
                    </label>
                  </Box>
                  <ErrorMessage
                    name={`image`}
                    component="div"
                    color="error"
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      color: "red",
                      fontSize: "12px",
                    }}
                  />
                </Box>
                <Box>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={values.vegOrNonVeg}
                      row
                      aria-label="position"
                      name="vegOrNonVeg"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            variant="solid"
                            value={"Veg"}
                            onChange={(e) => {
                              setFieldValue("vegOrNonVeg", e.target.value);
                            }}
                          />
                        }
                        label="Veg"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            variant="solid"
                            value={"NonVeg"}
                            onChange={(e) => {
                              setFieldValue("vegOrNonVeg", e.target.value);
                            }}
                          />
                        }
                        label="Non Veg"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            variant="solid"
                            value={"Other"}
                            onChange={(e) => {
                              setFieldValue("vegOrNonVeg", e.target.value);
                            }}
                          />
                        }
                        label="Other"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="end"
                        control={<Checkbox />}
                        label="Delivery"
                        labelPlacement="end"
                        checked={values.delivery}
                        name="delivery"
                        onChange={(e) => {
                          setFieldValue("delivery", e.target.checked);
                        }}
                      />
                      <FormControlLabel
                        value="end"
                        control={<Checkbox />}
                        label="Pickup"
                        labelPlacement="end"
                        checked={values.pickup}
                        name="pickup"
                        onChange={(e) => {
                          setFieldValue("pickup", e.target.checked);
                        }}
                      />
                      <FormControlLabel
                        value="end"
                        control={<Checkbox />}
                        label="Dine in"
                        labelPlacement="end"
                        checked={values.dineIn}
                        name="dineIn"
                        onChange={(e) => {
                          setFieldValue("dineIn", e.target.checked);
                        }}
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
                <Box sx={{ my: "20px" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      color: "var(--border-black)",
                      borderColor: "var(--border-black)",
                    }}
                    onClick={handleOpenVariationSelect}
                  >
                    {/* {t("quickAddProduct.VARIATION")} */}
                    Variants
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      color: "var(--border-black)",
                      borderColor: "var(--border-black)",
                      mx: "5px",
                    }}
                    onClick={() => {
                      handleOpenAddonsSelect();
                    }}
                  >
                    {/* {t("quickAddProduct.ADD_ON")} */}
                    Add-ons
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      color: "var(--border-black)",
                      borderColor: "var(--border-black)",
                    }}
                    onClick={() => {
                      handleOpenAddRecipe();
                    }}
                  >
                    {/* {t("quickAddProduct.RECIPE")} */}
                    Recipe
                  </Button>
                </Box>

                <Box>
                  {allProperty.length >= 1 &&
                    allProperty?.map((item, itemIndex) => (
                      <Box
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: "3px",
                          p: "10px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          my: "15px",
                        }}
                      >
                        <Box
                          sx={{
                            p: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>
                            {item[0].title} ({item.length - 1}{" "}
                            {/* {t("quickAddProduct.VARIANTS")} */}
                            Variants )
                          </Typography>
                          <SaveAsIcon
                            sx={{ height: "20px" }}
                            onClick={() => editVariationOption(item, itemIndex)}
                          />
                        </Box>
                        {item.map((prop, index) => (
                          <>
                            {index !== 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  height: "40px",
                                  p: "5px",
                                  alignItems: "center",
                                  background: "var(--light-gray)",
                                  px: "10px",
                                  borderRadius: "3px",
                                }}
                              >
                                <Typography>{prop.option}</Typography>
                                <DeleteIcon
                                  sx={{ width: "20px" }}
                                  onClick={() =>
                                    deleteProperty(item, index, itemIndex)
                                  }
                                />
                              </Box>
                            )}
                          </>
                        ))}
                        <Button
                          sx={{
                            fontSize: "16px",
                            textTransform: "none",
                            width: "100%",
                            color: "var(--red)",
                            border: "1px solid var(--red)",
                            "&:hover": {
                              border: "1px solid var(--red)",
                            },
                          }}
                          variant="outlined"
                          onClick={() => {
                            addNewOption(item, itemIndex);
                          }}
                        >
                          {/* {t("quickAddProduct.ADD_NEW_OPTION")} */}
                          Add new Option
                        </Button>
                      </Box>
                    ))}
                </Box>
                <Box>
                  {values.addOns.length >= 1 && (
                    <>
                      <Typography>
                        {/* {t("quickAddProduct.ADDONS")} */}
                        Add-ons
                      </Typography>
                      {values.addOns?.map((item, itemIndex) => (
                        <Box
                          sx={{
                            border: "1px solid #ccc",
                            borderRadius: "3px",
                            p: "10px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            my: "15px",
                          }}
                        >
                          <Box
                            sx={{
                              p: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>
                              {item.title} ({item.items.length}{" "}
                              {/* {t("quickAddProduct.VARIANTS")} */}Variants )
                            </Typography>
                            <SaveAsIcon
                              sx={{ height: "20px" }}
                              onClick={() => editAddone(item, itemIndex)}
                            />
                          </Box>
                          {item.items.map((prop, index) => (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  height: "40px",
                                  p: "5px",
                                  alignItems: "center",
                                  background: "var(--light-gray)",
                                  px: "10px",
                                  borderRadius: "3px",
                                }}
                              >
                                <Typography>{prop.name}</Typography>
                                <DeleteIcon
                                  sx={{ width: "20px" }}
                                  onClick={() =>
                                    deleteAddonOption(item, index, itemIndex)
                                  }
                                />
                              </Box>
                            </>
                          ))}
                          <Button
                            sx={{
                              fontSize: "16px",
                              textTransform: "none",
                              width: "100%",
                              color: "var(--red)",
                              border: "1px solid var(--red)",
                              "&:hover": {
                                border: "1px solid var(--red)",
                              },
                            }}
                            variant="outlined"
                            onClick={() => {
                              addNewOptionOnAddon(item, itemIndex);
                            }}
                          >
                            {/* {t("quickAddProduct.ADD_NEW_OPTION")} */}
                            Add new Option
                          </Button>
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  my: "20px",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    background: "var(--red)",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: "var(--red)",
                    },
                  }}
                  type="submit"
                >
                  {/* {t("quickAddProduct.SAVE_ITEM")} */}
                  Save Item
                </Button>
              </Box>
            </div>
            {/* //////////////////////////////Select variation modal//////////////////////// */}
            <Modal
              open={openVariationSelect}
              onClose={handleCloseVariationSelect}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <SelectVariationModal
                  product={product}
                  setproduct={setproduct}
                  allProperty={allProperty}
                  setAllProperty={setAllProperty}
                  close={handleCloseVariationSelect}
                  open={handleOpenVariationOptions}
                />
              </Box>
            </Modal>
            {/* //////////////////////////////add variation modal//////////////////////// */}
            <Modal
              open={openAddVatiation}
              onClose={handleCloseAddVatiation}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleAddVariation}>
                <AddVariationModal
                  product={product}
                  setproduct={setproduct}
                  allProperty={allProperty}
                  setAllProperty={setAllProperty}
                  close={handleCloseAddVatiation}
                  open={handleOpenOption}
                />
              </Box>
            </Modal>
            {/* //////////////////////////////variation option modal//////////////////////// */}
            <Modal
              open={openVariantOptions}
              onClose={handleCloseVariationOptions}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleVariationOption}>
                <AddVariationOptionModal
                  isEdit={isEdit}
                  SetIsEdit={SetIsEdit}
                  editVariationIndex={editVariationIndex}
                  product={product}
                  setproduct={setproduct}
                  allProperty={allProperty}
                  setAllProperty={setAllProperty}
                  close={handleCloseVariationOptions}
                  initialVariationOption={initialVariationOption}
                  initialVariationOptionupdation={
                    initialVariationOptionupdation
                  }
                  setFieldValue={setFieldValue}
                />
              </Box>
            </Modal>
            {/* //////////////////////////////select addon modal//////////////////////// */}
            <Modal
              open={openAddonSelect}
              onClose={handleCloseAddonsSelect}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <SelectAddonModal
                  close={handleCloseAddonsSelect}
                  open={handleOpenAdOns}
                />
              </Box>
            </Modal>
            {/* //////////////////////////////create addon modal//////////////////////// */}
            <Modal
              open={openAdOns}
              onClose={handleCloseAdOns}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleAddons}>
                <AddAdOnes
                  product={product}
                  setproduct={setproduct}
                  close={handleCloseAdOns}
                  // initialAddonValue={initialAddonValue}
                  values={values}
                  title={addonTitle}
                  productSetFieldValue={setFieldValue}
                  isEdit={isEdit}
                  setEdit={SetIsEdit}
                  handleOpenAdOns={handleOpenAdOns}
                  initialValueOfAddoneEdit={initialValueOfAddoneEdit}
                  editAddoneIndex={editAddoneIndex}
                />
              </Box>
            </Modal>
            {/* //////////////////////////////Add recipe//////////////////////// */}
            <Modal
              open={openAddRecipe}
              onClose={handleCloseAddRecipe}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleAddRecipe}>
                <AddRecipe
                  close={handleCloseAddRecipe}
                  setFieldValue={setFieldValue}
                  product={product}
                  setproduct={setproduct}
                  allProperty={allProperty}
                  setAllProperty={setAllProperty}
                  initialState={initialState}
                  AddproductMessage={AddproductMessage}
                  productData={productData}
                />
              </Box>
            </Modal>
            {/* //////////////////////////////variatio modal modal//////////////////////// */}
            <Modal
              open={openVatiationTable}
              onClose={handleCloseVatiationTable}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleVariationTable}>
                <VariationTableModal
                  product={product}
                  setproduct={setproduct}
                  allProperty={allProperty}
                  setAllProperty={setAllProperty}
                  close={handleCloseVatiationTable}
                  initialState={initialState}
                  AddproductMessage={AddproductMessage}
                  productData={productData}
                  resetForm={resetForm}
                />
              </Box>
            </Modal>
          </Form>
        )}
      </Formik>
      <Modal
        open={openCreateGroup}
        onClose={handleCloseCreateGroup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleCreateGroup}>
          <AddGroup
            page={openCreateGroup}
            handleCloseGroup={handleCloseCreateGroup}
            sentMessage={sentMessage}
          />
        </Box>
      </Modal>
    </Box>
  );
}
