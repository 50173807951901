import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { BiFoodTag } from "react-icons/bi";
import { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  styled,
} from "@mui/material";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
} from "@material-ui/core";
import { toast } from "react-hot-toast";
import { deleteProductFromGroup } from "../../../sevice/group";
import AlertButton from "../../../Alert/Alert";
import LoadingImage from "../../../assets/loader.gif";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));
const useStyles = makeStyles({
  customTableContainer: {
    overflowX: "initial",
  },
});

function Row(props) {
  const { POSadmin } = useSelector((state) => state);
  const { row, index, deleteProduct } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          style={{ overflowX: "scroll" }}
        >
          <TableCell align="center">{index + 1}</TableCell>
          <TableCell align="center">
            <img
              src={row?.varients[0]?.varientId?.image[0]}
              alt=""
              srcset=""
              style={{ height: "60px", width: "60px" }}
            />
          </TableCell>
          <TableCell align="center">{row?.title}</TableCell>
          <TableCell align="center" style={{ width: "20px" }}>
            <BiFoodTag
              style={{
                width: "20px",
                height: "20px",
                color:
                  row.vegOrNonVeg == "Veg"
                    ? "var(--green-color)"
                    : row.vegOrNonVeg == "NonVeg"
                    ? "var(--red)"
                    : "var(--bg-blue)",
              }}
            />
          </TableCell>
          <TableCell align="center">
            <span style={{ fontSize: "11px" }}>{POSadmin.symbol} </span>
            <span style={{ fontSize: "15px" }}>
              {Math.max.apply(
                null,
                row?.varients?.map((item) => item?.varientId?.salePrice)
              )}
            </span>
          </TableCell>
          <TableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              {row.varients.length}
            </IconButton>
          </TableCell>
          <TableCell align="center" style={{ maxWidth: "200px" }}>
            <FormControl
              component="fieldset"
              sx={{
                minWidth: {
                  md: "130px",
                },
              }}
            >
              <FormGroup
                aria-label="position"
                row
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  mx: "auto",
                }}
              >
                <FormControlLabel
                  sx={{ "& .MuiFormControlLabel-label": { fontSize: "13px" } }}
                  checked={row.delivery}
                  control={<Checkbox size="small" />}
                  label="Delivery"
                  labelPlacement="end"
                />
                <FormControlLabel
                  sx={{ "& .MuiFormControlLabel-label": { fontSize: "13px" } }}
                  checked={row.pickup}
                  control={<Checkbox size="small" />}
                  label="Pickup"
                  labelPlacement="end"
                />
                <FormControlLabel
                  sx={{ "& .MuiFormControlLabel-label": { fontSize: "13px" } }}
                  checked={row.dineIn}
                  control={<Checkbox size="small" />}
                  label="Dine in"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </TableCell>
          <TableCell align="center">
            <AlertButton
              element={"Delete"}
              type={"confirmation"}
              message={"Are you sure? You won't be able to revert this!"}
              onConfirm={deleteProduct}
              data={row._id}
              style={{ color: "red" }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              background: "var(--bg-primary)",
            }}
            colSpan={12}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Variations
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center" sx={{ width: "30px" }}>
                        Sl.No
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Product Name
                      </StyledTableCell>
                      <StyledTableCell align="center">SKU</StyledTableCell>
                      <StyledTableCell align="center">
                        Sale Price
                      </StyledTableCell>
                      {row.property1 && (
                        <StyledTableCell align="center">
                          {row.property1}
                        </StyledTableCell>
                      )}
                      {row.property2 && (
                        <StyledTableCell align="center">
                          {row.property2}
                        </StyledTableCell>
                      )}
                      {row.property3 && (
                        <StyledTableCell align="center">
                          {row.property3}
                        </StyledTableCell>
                      )}
                      {row.property4 && (
                        <StyledTableCell align="center">
                          {row.property4}
                        </StyledTableCell>
                      )}
                      {row.property5 && (
                        <StyledTableCell align="center">
                          {row.property5}
                        </StyledTableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.varients?.map((varientsRow, index) => (
                      <TableRow key={varientsRow?.varientId?._id}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {varientsRow?.varientId?.productName}
                        </TableCell>
                        <TableCell align="center">
                          {varientsRow?.varientId?.sku}
                        </TableCell>

                        <TableCell align="center">
                          <span style={{ fontSize: "11px" }}>
                            {POSadmin.symbol}
                          </span>{" "}
                          {varientsRow?.varientId?.salePrice}
                        </TableCell>

                        {row.property1 && (
                          <TableCell align="center">
                            {varientsRow?.varientId?.value1}
                          </TableCell>
                        )}
                        {row.property2 && (
                          <TableCell align="center">
                            {varientsRow?.varientId?.value2}
                          </TableCell>
                        )}
                        {row.property3 && (
                          <TableCell align="center">
                            {varientsRow?.varientId?.value3}
                          </TableCell>
                        )}
                        {row.property4 && (
                          <TableCell align="center">
                            {varientsRow?.varientId?.value4}
                          </TableCell>
                        )}
                        {row?.property5 && (
                          <TableCell align="center">
                            {varientsRow?.varientId?.value5}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    </>
  );
}

export default function AllProducts({
  selectedGroupData,
  fetchSingleGroup,
  query,
  IsLoading,
}) {
  const keys = ["title"];

  const { POSadmin } = useSelector((state) => state);

  const deleteProduct = async (id) => {
    let data = await deleteProductFromGroup(
      id,
      selectedGroupData._id,
      POSadmin.token
    );
    if (data.status) {
      toast.success(data.message);
      fetchSingleGroup(selectedGroupData._id);
    }
  };
  const classes = useStyles();
  return (
    <Box
      sx={{
        width: "100%",
        borderLeft: "solid var(--border-gray) 1px",
        height: { lg: "81vh", md: "79vh", sm: "85vh" },
        overflow: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {IsLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{ height: "300px" }} src={LoadingImage} alt="Loading" />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          classes={{ root: classes.customTableContainer }}
        >
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" sx={{ width: "30px" }}>
                  Sl.No
                </StyledTableCell>
                <StyledTableCell align="center">Image</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">Price</StyledTableCell>
                <StyledTableCell align="center">Variation</StyledTableCell>
                <StyledTableCell align="center">Type</StyledTableCell>
                <StyledTableCell sx={{ width: "60px" }} align="center">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedGroupData?.products
                ?.filter((item) =>
                  keys.some((key) => item[key]?.toLowerCase()?.includes(query))
                )
                ?.map((row, index) => (
                  <>
                    <Row
                      key={row._id}
                      row={row}
                      index={index}
                      deleteProduct={deleteProduct}
                    />
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
