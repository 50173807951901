import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const addPrintingIp = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/addPrintingIp`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getPrintingIps = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getPrintingIps`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editPrintingIp = async (values, token, id) => {
  try {
    const { data } = await axios.put(`${host}/editPrintingIp/${id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
