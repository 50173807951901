import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const getAllSales = async (token, type) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetAllSales?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getDineInCount = async (token, type) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetDineInCount?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getPickupCount = async (token, type) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetPickupCount?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getDeliveryCount = async (token, type) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetDeliveryCount?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getRevenueLeakageData = async (token, type) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetRevenueLeakage?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getChartData = async (token, type) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetChartData?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getExpenseData = async (type, token) => {

  try {
    const { data } = await axios.get(
      `${host}/posGetExpenseData?currentData=${new Date().toISOString()}&period=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const successOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/successOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const cancelOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/cancelOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const onlineAndPicukuOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/onlineAndPicukuOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const orderStatisticsData = async (token, type) => {
  try {
    const { data } = await axios.get(
      `${host}/orderStatisticsData?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
