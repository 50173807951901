import { Box, Button, Typography, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import LockIcon from "@mui/icons-material/Lock";
import { useState } from "react";
import { useFormik } from "formik";
import { changePassword } from "../../../sevice/admin";
import { changePasswordSchema } from "../../../schemas";
import { toast } from "react-hot-toast";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
  },
});
const style = {
  color: "red",
  fontSize: "1rem",
  margin: 0,
  fontFamily: "poppins",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  pb: "2rem",
};

function ChangePassword({ setState, data }) {
  const [isLogin, setIsLogin] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values) => {
      setIsLogin(true);
      const formData = { ...values, email: data?.email };
      const resp = await changePassword(formData);
      if (resp.status) {
        toast.success(resp.message);
        setState("login");
      } else {
        setIsLogin(false);
        toast.error(resp.message);
      }
    },
  });

  return (
    <Box sx={{ width: "30vw" }} component="form" onSubmit={formik.handleSubmit}>
      <Box sx={{ pb: "2.1rem" }}>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontFamily: "poppins",
          }}
        >
          Change Password
        </Typography>
      </Box>
      <Box sx={{ pb: "1rem" }}>
        <Box
          sx={{
            border: "1px solid #EEEEEE",
            width: "100%",
            height: "60px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            px: "1.5rem",
            bgcolor: "#F5F5F5",
          }}
        >
          <LockIcon sx={{ fontSize: "1.7rem", color: "#92929B" }} />
          <TextField
            variant="outlined"
            type="password"
            placeholder="Enter Password"
            sx={{
              "& input::placeholder": {
                fontFamily: "poppins",
              },
              "& fieldset": { border: "none" },
              width: "100%",
            }}
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </Box>
      </Box>
      <Box sx={{ pb: "1rem" }}>
        <Box
          sx={{
            border: "1px solid #EEEEEE",
            width: "100%",
            height: "60px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            px: "1.5rem",
            bgcolor: "#F5F5F5",
          }}
        >
          <LockIcon sx={{ fontSize: "1.7rem", color: "#92929B" }} />
          <TextField
            variant="outlined"
            type="text"
            placeholder="Confirm Password"
            sx={{
              "& input::placeholder": {
                fontFamily: "poppins",
              },
              "& fieldset": { border: "none" },
              width: "100%",
            }}
            name="confirmPassword"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
          />
        </Box>
      </Box>
      {formik.touched.password && formik.errors.password && (
        <p style={style}>{formik.errors.password}</p>
      )}
      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
        <p style={style}>{formik.errors.confirmPassword}</p>
      )}
      <Box sx={{ pb: "1.5rem", pt: "1.1rem" }}>
        <Button
          type="submit"
          disabled={isLogin}
          sx={{
            background: "#F40000",
            width: "100%",
            height: "60px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            px: "1.5rem",
            color: "white",
            "&:hover": {
              color: "white",
              background: "#F40000",
            },
          }}
        >
          {isLogin ? (
            <ThemeProvider theme={customTheme}>
              <CircularProgress color="primary" />
            </ThemeProvider>
          ) : (
            <Typography
              sx={{
                color: "white",
                fontSize: "1rem",
                fontFamily: "poppins",
              }}
            >
              Submit
            </Typography>
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default ChangePassword;
