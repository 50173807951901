import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Autocomplete,
  Modal,
  Stack,
  Pagination,
} from "@mui/material";
import { array, number, object, string } from "yup";
import { Box } from "@mui/system";
import SearchBar from "../../common/SearchBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { TextField, Select } from "formik-mui";
import {
  addRecipe,
  getAllSubUnits,
  getProductInRecipe,
  getProductName,
  getRawMaterials,
  getRecipes,
} from "../../../sevice/inventory";
import { toast } from "react-hot-toast";
import { Field, FieldArray, Form, Formik } from "formik";
import { DateFormatterWithTime } from "../../../utils/DateFormatter";
import EditRecipe from "./EditRecipe";
import LoadingImage from "../../../assets/loader.gif";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));
let initialEditValue;
function Recipe({ state, setState }) {
  const { POSadmin } = useSelector((state) => state);
  const [products, setProducts] = useState([]);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [units, setUnits] = useState([]);
  const [existProduct, setExistPro] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [IsLoading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [query, setQuery] = useState("");

  const addRecipeSchema = object({
    product: string()
      .required("a product must be selected.")
      .notOneOf(existProduct, "There is already a recipe for this item."),
    rawMaterial: array(
      object({
        name: string().required("name is requird"),
        quantity: number()
          .typeError("field must be a number")
          .required(" quantity is requird"),
        unit: string().required("unit is requird"),
      })
    ).min(1, "You need to provide at least 1 raw  material"),
  });

  const initialValues = {
    vendor: POSadmin.id,
    product: "",
    rawMaterial: [{ name: "", quantity: "", unitId: "", unit: "" }],
  };

  const emptyRawMaterial = { name: "", quantity: "", unitId: "", unit: "" };

  const submitdata = async (values, action) => {
    setIsSubmit(true);
    const data = await addRecipe(POSadmin.token, values);
    if (data.status) {
      action.resetForm();
      toast.success(data.message);
      setState(true);
    } else {
      toast.error(data.message);
    }
  };

  const getDatas = async () => {
    const data = await getAllSubUnits();
    const data1 = await getProductName(POSadmin.token);
    const data2 = await getRawMaterials(POSadmin.token, "");
    const data4 = await getProductInRecipe(POSadmin.token);
    setUnits(data);
    setProducts(data1);
    setRawMaterials(data2.docs);
    setExistPro(data4);
  };
  useEffect(() => {
    getDatas();
  }, [state, open]);

  useEffect(() => {
    recipe();
  }, [state, open, query, pageNo]);

  const recipe = async () => {
    const data = await getRecipes(POSadmin.token, query, pageNo);
    setRecipes(data);
    setLoading(false);
  };
  const setEditRecipeInitialValue = (value) => {
    let editValue = value.rawMaterial.map((item) => {
      return {
        name: item.name._id,
        unit: item.unit._id,
        baseUnit: item.unit.unit,
        quantity: item.quantity,
      };
    });
    initialEditValue = {
      product: value.product._id,
      rawMaterial: editValue,
    };
  };

  const handleChangePageNo = (event, value) => {
    setPageNo(value);
  };

  return (
    <>
      {state && !IsLoading && (
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            searchBarWidth={"200px"}
            placeholder={"Search"}
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={() => {
              setState(false);
              setIsSubmit(false);
            }}
          >
            Add Recipe
          </Button>
        </Box>
      )}
      {IsLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img style={{ height: "300px" }} src={LoadingImage} alt="Loading" />
        </Box>
      ) : state ? (
        <Box sx={{ marginTop: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead sx={{ background: "var(--table-head)" }}>
                <TableRow>
                  <StyledTableCell align="center">SL No </StyledTableCell>
                  <StyledTableCell align="center">Created </StyledTableCell>
                  <StyledTableCell align="center">Product</StyledTableCell>
                  <StyledTableCell align="center">
                    Cost per Item (₹)
                  </StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recipes &&
                  recipes?.docs?.map((item, index) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        <DateFormatterWithTime date={item.date} />
                      </TableCell>
                      <TableCell align="center">{item?.product?.title}</TableCell>
                      <TableCell align="center">
                        {item.rawMaterial
                          .reduce(
                            (acc, cur) =>
                              acc +
                              cur.quantity *
                                cur.unit.baseValue *
                                cur.name.purchasePrice,
                            0
                          )
                          .toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        {/* <VisibilityIcon sx={{ cursor: "pointer" }} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                        <SaveAsIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditRecipeInitialValue(item);
                            setEditItem(item);
                            handleOpen();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            component={Paper}
            sx={{
              marginTop: "10px",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <Stack>
              <Pagination
                count={recipes?.totalPages}
                page={pageNo}
                onChange={handleChangePageNo}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <EditRecipe
                handleClose={handleClose}
                editItem={editItem}
                initialEditValue={initialEditValue}
                subUnit={units}
                rawMaterials={rawMaterials}
              />
            </Box>
          </Modal>
        </Box>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={addRecipeSchema}
            onSubmit={submitdata}
          >
            {({ values, setFieldValue }) => (
              <Form autoComplete="off">
                <Box>
                  <Typography sx={{ p: 1, mt: 2 }} variant="h6">
                    Add Recipe
                  </Typography>
                  <Box
                    sx={{
                      mt: 2,
                      mb: 6,
                      ml: 1,
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <InputLabel>Product</InputLabel> <br />
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <Autocomplete
                        options={products}
                        getOptionLabel={(option) => option.item}
                        renderInput={(params) => (
                          <Field
                            component={TextField}
                            {...params}
                            value={params.id}
                            name={"product"}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setFieldValue("product", newValue?.id);
                          } else {
                            setFieldValue("product", "");
                          }
                        }}
                      />
                    </FormControl>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead sx={{ background: "var(--table-head)" }}>
                        <TableRow>
                          <StyledTableCell align="center">Name</StyledTableCell>
                          <StyledTableCell align="center">Qty</StyledTableCell>
                          <StyledTableCell align="center">Unit</StyledTableCell>
                          <StyledTableCell align="center">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <FieldArray name="rawMaterial">
                        {({ push, remove }) => (
                          <>
                            <TableBody>
                              {values.rawMaterial.map((_, index) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    <Autocomplete
                                      size="small"
                                      options={rawMaterials}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => (
                                        <Field
                                          component={TextField}
                                          {...params}
                                          value={params._id}
                                          name={`rawMaterial.${index}.name`}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      )}
                                      onChange={(event, newValue) => {
                                        if (newValue) {
                                          setFieldValue(
                                            `rawMaterial.${index}.unitId`,
                                            newValue?.purchaseUnit._id
                                          );
                                          setFieldValue(
                                            `rawMaterial.${index}.name`,
                                            newValue?._id
                                          );
                                          setFieldValue(
                                            `rawMaterial.${index}.unit`,
                                            ""
                                          );
                                        } else {
                                          setFieldValue(
                                            `rawMaterial.${index}.unitId`,
                                            ""
                                          );
                                          setFieldValue(
                                            `rawMaterial.${index}.name`,
                                            ""
                                          );
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Field
                                      size="small"
                                      fullWidth
                                      name={`rawMaterial.${index}.quantity`}
                                      component={TextField}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <FormControl sx={{ m: 1, minWidth: 250 }}>
                                      <Field
                                        component={Select}
                                        name={`rawMaterial.${index}.unit`}
                                        size="small"
                                        sx={{ mb: 0 }}
                                      >
                                        {units.map((item) => {
                                          return (
                                            item.unit ==
                                              values?.rawMaterial[index]
                                                ?.unitId && (
                                              <MenuItem value={item._id}>
                                                {item.name}
                                              </MenuItem>
                                            )
                                          );
                                        })}
                                      </Field>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button onClick={() => remove(index)}>
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>

                            <Button
                              onClick={() => push(emptyRawMaterial)}
                              variant="contained"
                              sx={{
                                m: 3,
                                textTransform: "none",
                                background: "var(--red)",
                                "&:hover": {
                                  backgroundColor: "var(--red)",
                                },
                              }}
                            >
                              + Add Row
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          mt: 3,
                          width: "100px",
                          textTransform: "none",
                          background: "var( --color-secondary)",
                          "&:hover": {
                            backgroundColor: "var( --color-secondary)",
                          },
                        }}
                        onClick={() => setState(true)}
                      >
                        Cancel
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        disabled={isSubmit}
                        type="submit"
                        variant="contained"
                        sx={{
                          mt: 3,
                          width: "100px",
                          textTransform: "none",
                          background: "var(--red)",
                          "&:hover": {
                            backgroundColor: "var(--red)",
                          },
                        }}
                      >
                        {isSubmit ? "Loading.." : "Save"}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
}

export default Recipe;
