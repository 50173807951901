import axios from "axios";

const host = process.env.REACT_APP_BACKEND_URL;

export const createDiscount = async (discountData, token) => {
  try {
    const { data } = await axios.post(`${host}/api/v1/pos/discounts`, discountData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllDiscounts = async (token) => {
  try {
    const { data } = await axios.get(`${host}/api/v1/pos/discounts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getDiscountById = async (discountId, token) => {
  try {
    const { data } = await axios.get(`${host}/discounts/${discountId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateDiscountById = async (discountId, updatedData, token) => {
  try {
    const { data } = await axios.put(`${host}/api/v1/pos/discounts/${discountId}`, updatedData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteDiscountById = async (discountId, token) => {
  try {
    const { data } = await axios.delete(`${host}/api/v1/pos/discounts/${discountId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};


export const getAllProducts = async (token) => {
  try {
    const { data } = await axios.get(`${host}/api/v1/pos/products`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
    console.log(data);
  } catch (error) {
    return error.response.data.message;
  }
};
