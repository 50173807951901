import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateGroup, fetchAllVarientProduct } from "../../../sevice/admin";
import { addGroupSchema } from "../../../schemas";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";

export default function UpdateGroup({
  handleCloseGroup,
  sentMessage,
  page,
  item,
}) {
  const { POSadmin, isLanguageSwitched } = useSelector((state) => state);
  const [allProduct, setAllProduct] = useState([]);
  const [sumitData, setSumitData] = useState(false);
  let products;
  useEffect(() => {
    fetchAllProduct();
  }, []);

  products = item?.products?.map((item) => {
    return item;
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupName: item.groupName,
      groupNameAr: item.groupNameAr,
      image: item.image,
      products: products,
    },
    validationSchema: addGroupSchema,
    onSubmit: async (values, action) => {
      setSumitData(true);
      let formData = new FormData();
      formData.append("groupName", values.groupName);
      formData.append("groupNameAr", values.groupNameAr);
      formData.append("image", values.image);
      formData.append("oldImage", item.image);

      if (values.products.length > 0) {
        let proData = values?.products.map((item) => {
          return { item: item._id };
        });

        formData.append("products", JSON.stringify(proData));
      }
      const data = await updateGroup(formData, item._id, POSadmin.token);
      sentMessage(data);
    },
  });

  const fetchAllProduct = async () => {
    const data = await fetchAllVarientProduct(POSadmin.token);
    setAllProduct(data);
  };
  return (
    <div>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Box>
              <Typography
                sx={{ my: "10px" }}
                style={{ fontSize: "15px", fontWeight: "bold" }}
              >
                Add Group
              </Typography>
            </Box>

            <Box>
              <Box sx={{ my: "10px" }}>
                <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                  Title *
                </Typography>
                <TextField
                  id="groupName"
                  placeholder="Enter Group Title"
                  variant="outlined"
                  name="groupName"
                  size="small"
                  style={{ width: "100%" }}
                  value={formik.values.groupName}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.groupName && !!formik.errors.groupName
                  }
                  helperText={
                    formik.touched.groupName && formik.errors.groupName
                  }
                />
                {isLanguageSwitched && (
                  <TextField
                    id="groupNameAr"
                    placeholder="أدخل اسم المجموعة"
                    variant="outlined"
                    name="groupNameAr"
                    size="small"
                    style={{ width: "100%", marginTop: "15px" }}
                    value={formik.values.groupNameAr}
                    onChange={formik.handleChange}
                    // error={
                    //   !!formik.touched.groupName && !!formik.errors.groupName
                    // }
                    // helperText={
                    //   formik.touched.groupName && formik.errors.groupName
                    // }
                    inputProps={{ style: { textAlign: "right" } }}
                  />
                )}
              </Box>
              <Box sx={{ my: "10px" }}>
                <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                  Image
                </Typography>

                <TextField
                  id="image"
                  name="image"
                  size="small"
                  type="file"
                  style={{ width: "100%" }}
                  variant="outlined"
                  accept="image/*"
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      image: e.target.files[0],
                    });
                  }}
                  error={!!formik.touched.image && !!formik.errors.image}
                  helperText={formik.touched.image && formik.errors.image}
                />
              </Box>
              {!page && (
                <>
                  <Box>
                    <Typography
                      sx={{ my: "10px" }}
                      style={{ fontSize: "13px" }}
                    >
                      Items *
                    </Typography>
                    {formik?.values?.products?.map((item) => (
                      <>
                        <TextField
                          id="title"
                          disabled
                          placeholder="Enter Group Title"
                          variant="outlined"
                          name="title"
                          size="small"
                          style={{ width: "100%", marginBottom: "10px" }}
                          value={item?.title}
                        />
                      </>
                    ))}
                  </Box>

                  <Box sx={{ mb: "10px" }}>
                    <FormControl fullWidth>
                      <Autocomplete
                        options={allProduct}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={params}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        )}
                        onChange={(y, e) => {
                          if (e && !formik.values.products.includes(e)) {
                            formik.setValues({
                              ...formik.values,
                              products: [...formik.values.products, e],
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {sumitData ? (
              <Button
                disabled
                sx={{
                  height: "100%",
                  fontSize: "16px",
                  mr: "10px",
                  mt: "3px",
                  textTransform: "none",
                  color: "var(--bg-white)",
                  background: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                }}
              >
                Loading..
              </Button>
            ) : (
              <Button
                sx={{
                  height: "100%",
                  fontSize: "16px",
                  mr: "10px",
                  mt: "3px",
                  textTransform: "none",
                  color: "var(--bg-white)",
                  background: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                }}
                type="submit"
              >
                {item ? "Update" : "Save"}
              </Button>
            )}
            <Button
              sx={{
                height: "100%",
                fontSize: "16px",
                mt: "3px",
                textTransform: "none",
                color: "var(--font-black)",
                background: "var(--button-gray)",
              }}
              variant="text"
              onClick={() => handleCloseGroup()}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}
