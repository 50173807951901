import { Box, Typography } from "@mui/material";
import mobileError from "../assets/mobileError.png";

export default function MobileViewErrorMessage() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <img src={mobileError} alt="Image" style={{ width: "50vw" }} />
      <Typography sx={{ fontFamily: "poppins", fontSize: ".9em" }}>
        Please login using{" "}
        <span style={{ color: "#F40000" }}>Laptop or Desktop</span>
      </Typography>
    </Box>
  );
}
