import { combineReducers } from "redux";
import { PosAdminReducer } from "./reducers/PosAdminReducer";
import { isLanguageSwitchedReducer } from "./reducers/isLanguageSwitchedReducer";

const rootReducer = combineReducers({
  POSadmin: PosAdminReducer,
  isLanguageSwitched:isLanguageSwitchedReducer
});

export default rootReducer;
