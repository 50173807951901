import "./App.css";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import NavabrSetting from "./components/navBar/NavabrSetting";
import LoggedInRoutes from "././routes/LoggedInRoutes";
import NotLoggedInRoutes from "././routes/NotLoggedInRoutes";
import Dashboard from "./pages/Dashboard";
import AddProduct from "./pages/AddProduct";
import LoginPage from "./pages/LoginPage";
import AllOrders from "./pages/AllOrders";
import Group from "./pages/Group";
import Inventory from "./pages/Inventory";
import Configuration from "./pages/Configuration";
import Table from "./pages/Table";
import Kot from "./pages/Kot";
import UserManagement from "./pages/UserManagement";
import Settings from "./pages/Settings";
import Reports from "./pages/Reports";
import Profile from "./pages/Profile";
import { useMediaQuery } from "@mui/material";
import MobileViewErrorMessage from "./pages/MobileViewErrorMessage";
import ChangePassword from "./pages/ChangePassword";
function App() {
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  return (
    <div>
      {isLargeScreen ? (
        <div className="main" style={{ display: "flex", height: "100vh" }}>
          <NavabrSetting />
          <Toaster />
          <Routes>
            <Route element={<LoggedInRoutes />}>
              <Route path="/" exact element={<Dashboard />} />
              <Route path="/menumanagemet" exact element={<Group />} />
              <Route path="/kot" exact element={<Kot />} />
              <Route path="/allorders" exact element={<AllOrders />} />
              <Route path="/addproduct" exact element={<AddProduct />} />
              <Route path="/inventory" exact element={<Inventory />} />
              <Route path="/tables" exact element={<Table />} />
              <Route path="/inventory" exact element={<Inventory />} />
              <Route path="/configuration" exact element={<Configuration />} />
              <Route
                path="/usermanagement"
                exact
                element={<UserManagement />}
              />
              <Route path="/reports" exact element={<Reports />} />
              <Route path="/settings" exact element={<Settings />} />
              <Route path="/profile" exact element={<Profile />} />
              <Route
                path="/change-password"
                exact
                element={<ChangePassword />}
              />
            </Route>
            <Route element={<NotLoggedInRoutes />}>
              <Route path="/login" element={<LoginPage />} />
            </Route>
          </Routes>
        </div>
      ) : (
        <MobileViewErrorMessage />
      )}
    </div>
  );
}

export default App;
