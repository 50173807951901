import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Autocomplete,
} from "@mui/material";
import { array, number, object, string } from "yup";
import { Box } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { TextField, Select } from "formik-mui";
import {
  getAllSubUnits,
  getProductInRecipe,
  getRawMaterials,
  getRecipes,
} from "../../../sevice/inventory";
import { Field, FieldArray, Form, Formik } from "formik";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));

function AddRecipe({ setFieldValue, close }) {
  const { POSadmin } = useSelector((state) => state);
  const [rawMaterials, setRawMaterials] = useState([]);
  // const [recipes, setRecipes] = useState([]);
  const [units, setUnits] = useState([]);
  // const [existProduct, setExistPro] = useState([]);

  const addRecipeSchema = object({
    rawMaterial: array(
      object({
        name: string().required("name is requird"),
        quantity: number()
          .typeError("field must be a number")
          .required(" quantity is requird"),
        unit: string().required("unit is requird"),
      })
    ).min(1, "You need to provide at least 1 raw  material"),
  });

  const initialValues = {
    rawMaterial: [{ name: "", quantity: "", unitId: "", unit: "" }],
  };

  const emptyRawMaterial = { name: "", quantity: "", unitId: "", unit: "" };

  const submitdata = async (values, action) => {
    setFieldValue("recipe", values.rawMaterial);
    close();
  };

  const getDatas = async () => {
    const data = await getAllSubUnits();
    const data2 = await getRawMaterials(POSadmin.token);
    // const data3 = await getRecipes(POSadmin.token);
    // const data4 = await getProductInRecipe(POSadmin.token);
    setUnits(data);
    setRawMaterials(data2);
    // setRecipes(data3);
    // setExistPro(data4);
  };
  useEffect(() => {
    getDatas();
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={addRecipeSchema}
        onSubmit={submitdata}
      >
        {({ values, setFieldValue }) => (
          <Form autoComplete="off">
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>
                  Add Recipe
                </Typography>
              </Box>

              <TableContainer>
                <Table sx={{ minWidth: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Name</StyledTableCell>
                      <StyledTableCell align="center">Qty</StyledTableCell>
                      <StyledTableCell align="center">Unit</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <FieldArray name="rawMaterial">
                    {({ push, remove }) => (
                      <>
                        <TableBody>
                          {values.rawMaterial.map((_, index) => (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">
                                <FormControl sx={{ minWidth: 250 }}>
                                  <Autocomplete
                                    options={rawMaterials}
                                    size="small"
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextField}
                                        {...params}
                                        value={`rawMaterial.${index}.name`}
                                        onChange={(e) => {}}
                                        name={`rawMaterial.${index}.name`}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        setFieldValue(
                                          `rawMaterial.${index}.unitId`,
                                          newValue?.purchaseUnit._id
                                        );
                                        setFieldValue(
                                          `rawMaterial.${index}.name`,
                                          newValue?._id
                                        );
                                        setFieldValue(
                                          `rawMaterial.${index}.unit`,
                                          ""
                                        );
                                      } else {
                                        setFieldValue(
                                          `rawMaterial.${index}.unitId`,
                                          ""
                                        );
                                        setFieldValue(
                                          `rawMaterial.${index}.name`,
                                          ""
                                        );
                                      }
                                    }}
                                  />
                                </FormControl>
                              </TableCell>
                              <TableCell align="center">
                                <Field
                                  sx={{ minWidth: 200 }}
                                  size="small"
                                  fullWidth
                                  name={`rawMaterial.${index}.quantity`}
                                  component={TextField}
                                />
                              </TableCell>

                              <TableCell align="center">
                                <FormControl sx={{ minWidth: 250 }}>
                                  <Field
                                    component={Select}
                                    name={`rawMaterial.${index}.unit`}
                                    size="small"
                                    sx={{ mb: 0 }}
                                  >
                                    {units.map((item) => {
                                      return (
                                        item.unit ==
                                          values?.rawMaterial[index]
                                            ?.unitId && (
                                          <MenuItem value={item._id}>
                                            {item.name}
                                          </MenuItem>
                                        )
                                      );
                                    })}
                                  </Field>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center">
                                <DeleteIcon onClick={() => remove(index)} />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>

                        <Button
                          onClick={() => push(emptyRawMaterial)}
                          variant="text"
                          sx={{
                            textTransform: "none",
                            color: "var(--red)",
                            ml: 2,
                          }}
                        >
                          + Add Row
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </Table>
              </TableContainer>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100px",
                      textTransform: "none",
                      background: "var( --color-secondary)",
                      "&:hover": {
                        backgroundColor: "var( --color-secondary)",
                      },
                    }}
                    onClick={() => close()}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      width: "100px",
                      textTransform: "none",
                      background: "var(--red)",
                      "&:hover": {
                        backgroundColor: "var(--red)",
                      },
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AddRecipe;
