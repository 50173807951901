import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  Typography,
  TextField,
  Checkbox,
} from "@mui/material";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { editBillerSchema } from "../../../schemas";
import { editBiller } from "../../../sevice/userManagement";

function EditBiller({ editItem, handleClose }) {
  const { POSadmin } = useSelector((state) => state);

  const initialValues = {
    first_name: editItem.first_name,
    last_name: editItem.last_name,
    phone: editItem.phone,
    password: "",
    confirmPassword: "",
    userPasscode: editItem.userPasscode,
    swipeCode: editItem.swipeCode,
    itemCreation: {
      read: editItem?.itemCreation?.read,
      write: editItem?.itemCreation?.write,
    },
    kotManagement: {
      read: editItem?.kotManagement?.read,
      write: editItem?.kotManagement?.write,
    },
    areaManagement: {
      read: editItem?.areaManagement?.read,
      write: editItem?.areaManagement?.write,
    },
    tableManagement: {
      read: editItem?.tableManagement?.read,
      write: editItem?.tableManagement?.write,
    },
    allowBillToCancel: {
      read: editItem?.allowBillToCancel?.read,
      write: editItem?.allowBillToCancel?.write,
    },
    afterSaveKotModification: {
      read: editItem?.afterSaveKotModification?.read,
      write: editItem?.afterSaveKotModification?.write,
    },
    expenseAndWithdrawalManagement: {
      read: editItem?.expenseAndWithdrawalManagement?.read,
      write: editItem?.expenseAndWithdrawalManagement?.write,
    },
    pendingOrderManagement: {
      read: editItem?.pendingOrderManagement?.read,
      write: editItem?.pendingOrderManagement?.write,
    },
    settleAndSave: {
      read: editItem?.settleAndSave?.read,
      write: editItem?.settleAndSave?.write,
    },
    allowBillRepayment: {
      read: editItem?.allowBillRepayment?.read,
      write: editItem?.allowBillRepayment?.write,
    },
    dayEnd: {
      read: editItem?.dayEnd?.read,
      write: editItem?.dayEnd?.write,
    },
  };
  

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editBillerSchema,
    onSubmit: async (values, action) => {
      const data = await editBiller(POSadmin.token, values, editItem._id);
      if (data.status) {
        toast.success(data.message);
        handleClose();
      } else {
        toast.error(data.message);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Typography sx={{ p: 3 }} variant="h6">
            Edit Biller
          </Typography>
          <Card
            elevation={0}
            sx={{
              width: "100%",
              height: "30%",
              marginRight: "15px",
              marginBottom: "20px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "50ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="Fist Name *"
                id="first_name"
                variant="outlined"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.first_name && !!formik.errors.first_name
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
              <TextField
                label="Last Name *"
                id="last_name"
                variant="outlined"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={!!formik.touched.last_name && !!formik.errors.last_name}
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
              <TextField
                label="Phone *"
                id="phone"
                variant="outlined"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={!!formik.touched.phone && !!formik.errors.phone}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <TextField
                label="Password *"
                type="password"
                id="password"
                variant="outlined"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={!!formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
              />
              <TextField
                sx={{ marginLeft: "30px" }}
                id="outlined-basic"
                type="password"
                label="Confirm password *"
                variant="outlined"
                name="passwordConfirmation"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.passwordConfirmation}
                error={
                  !!formik.touched.passwordConfirmation &&
                  !!formik.errors.passwordConfirmation
                }
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
              />

              <TextField
                label="User Passcode"
                id="userPasscode"
                variant="outlined"
                name="userPasscode"
                value={formik.values.userPasscode}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.userPasscode && !!formik.errors.userPasscode
                }
                helperText={
                  formik.touched.userPasscode && formik.errors.userPasscode
                }
              />
              <TextField
                label="Swipe Code"
                id="swipeCode"
                variant="outlined"
                name="swipeCode"
                value={formik.values.swipeCode}
                onChange={formik.handleChange}
                error={!!formik.touched.swipeCode && !!formik.errors.swipeCode}
                helperText={formik.touched.swipeCode && formik.errors.swipeCode}
              />
            </Box>
          </Card>
          <Typography sx={{ p: 1 }} variant="h6">
            Rights
          </Typography>
          <Card
            elevation={0}
            sx={{
              overflowY:"auto",
              width: "100%",
              height: "345px",
              marginRight: "15px",
              marginBottom: "20px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Divider />
            <Box
              sx={{
                p: 2,
              }}
            >
              <Typography sx={{ pr: 3 }}>Item Creation</Typography>
              <FormControlLabel
                control={<Checkbox />}
                
                label="Read"
                labelPlacement="end"
                checked={formik.values?.itemCreation?.read}
                name={`itemCreation.read`}
                onChange={(e) => {
                  formik.setFieldValue(`itemCreation.read`, e.target.checked);
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.itemCreation?.write}
                name={`itemCreation.write`}
                onChange={(e) => {
                  formik.setFieldValue(`itemCreation.write`, e.target.checked);
                }}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
               
              }}
            >
              <Typography>Kot Management</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
                checked={formik.values?.kotManagement?.read}
                name={`kotManagement.read`}
                onChange={(e) => {
                  formik.setFieldValue(`kotManagement.read`, e.target.checked);
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.kotManagement?.write}
                name={`kotManagement.write`}
                onChange={(e) => {
                  formik.setFieldValue(`kotManagement.write`, e.target.checked);
                }}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
               
              }}
            >
              <Typography>Area Management</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
                checked={formik.values?.areaManagement?.read}
                name={`areaManagement.read`}
                onChange={(e) => {
                  formik.setFieldValue(`areaManagement.read`, e.target.checked);
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.areaManagement?.write}
                name={`areaManagement.write`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `areaManagement.write`,
                    e.target.checked
                  );
                }}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
              }}
            >
              <Typography>Table Management</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
                checked={formik.values?.tableManagement?.read}
                name={`tableManagement.read`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `tableManagement.read`,
                    e.target.checked
                  );
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.tableManagement?.write}
                name={`tableManagement.write`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `tableManagement.write`,
                    e.target.checked
                  );
                }}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
        
              }}
            > <Typography>Allow Bill To Cancel</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
                checked={formik.values?.allowBillToCancel?.read}
                name={`allowBillToCancel.read`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `allowBillToCancel.read`,
                    e.target.checked
                  );
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.allowBillToCancel?.write}
                name={`allowBillToCancel.write`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `allowBillToCancel.write`,
                    e.target.checked
                  );
                }}
              />
            </Box>
            <Divider />
            <Divider />
            <Box
              sx={{
                p: 2,
                
              }}
            >
              <Typography>After Save Kot Modification</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
                checked={formik.values?.afterSaveKotModification?.read}
                name={`afterSaveKotModification.read`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `afterSaveKotModification.read`,
                    e.target.checked
                  );
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.afterSaveKotModification?.write}
                name={`afterSaveKotModification.write`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `afterSaveKotModification.write`,
                    e.target.checked
                  );
                }}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
               
              }}
            >
              <Typography>Expense And Withdrawal Management</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
                checked={formik.values?.expenseAndWithdrawalManagement?.read}
                name={`expenseAndWithdrawalManagement.read`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `expenseAndWithdrawalManagement.read`,
                    e.target.checked
                  );
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.expenseAndWithdrawalManagement?.write}
                name={`expenseAndWithdrawalManagement.write`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `expenseAndWithdrawalManagement.write`,
                    e.target.checked
                  );
                }}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
              
              }}
            >
              <Typography>Order Management</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
                checked={formik.values?.orderManagement?.read}
                name={`orderManagement.read`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `orderManagement.read`,
                    e.target.checked
                  );
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.pendingOrderManagement?.write}
                name={`pendingOrderManagement.write`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `pendingOrderManagement.write`,
                    e.target.checked
                  );
                }}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
              
              }}
            >
              <Typography>Settle and Save</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
                checked={formik.values?.settleAndSave?.read}
                name={`settleAndSave.read`}
                onChange={(e) => {
                  formik.setFieldValue(`settleAndSave.read`, e.target.checked);
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.settleAndSave?.write}
                name={`settleAndSave.write`}
                onChange={(e) => {
                  formik.setFieldValue(`settleAndSave.write`, e.target.checked);
                }}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
                
              }}
            >
              <Typography>Allow Bill Reprint</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
                checked={formik.values?.allowBillReprint?.read}
                name={`allowBillReprint.read`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `allowBillReprint.read`,
                    e.target.checked
                  );
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.allowBillReprint?.write}
                name={`allowBillReprint.write`}
                onChange={(e) => {
                  formik.setFieldValue(
                    `allowBillReprint.write`,
                    e.target.checked
                  );
                }}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
              
              }}
            >
              <Typography>Day End</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
                checked={formik.values?.dayEnd?.read}
                name={`dayEnd.read`}
                onChange={(e) => {
                  formik.setFieldValue(`dayEnd.read`, e.target.checked);
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.dayEnd?.write}
                name={`dayEnd.write`}
                onChange={(e) => {
                  formik.setFieldValue(`dayEnd.write`, e.target.checked);
                }}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
              
              }}
            >
              <Typography>Allow Billing Rights</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
                checked={formik.values?.allowBillingRights?.read}
                name={`dayEnd.read`}
                onChange={(e) => {
                  formik.setFieldValue(`allowBillingRights.read`, e.target.checked);
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
                checked={formik.values?.allowBillingRights?.write}
                name={`dayEnd.write`}
                onChange={(e) => {
                  formik.setFieldValue(`allowBillingRights.write`, e.target.checked);
                }}
              />
            </Box>
            <Divider />
            {/*   <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Kot Management</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
              />
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Kot Management</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Read"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Write"
                labelPlacement="end"
              />
            </Box>
            <Divider /> */}
          </Card>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ p: 1 }} variant="h6">
              Table
            </Typography>{" "}
            <FormControlLabel
              control={<Checkbox />}
              label="Select All"
              labelPlacement="end"
            />
          </Box>
          <Card
            elevation={0}
            sx={{
              width: "100%",
              height: "110px",
              marginRight: "15px",
              marginBottom: "20px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                label="Table-1"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Table-2"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Table-3"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Table-4"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Table-5"
                labelPlacement="end"
              />
            </Box>
          </Card>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Box>
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  width: "100px",
                  textTransform: "none",
                  background: "var( --color-secondary)",
                  "&:hover": {
                    backgroundColor: "var( --color-secondary)",
                  },
                }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  width: "100px",
                  textTransform: "none",
                  background: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                }}
                type="submit"
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}

export default EditBiller;
