import { Box, Button, Typography, TextField, Radio } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { sendResetPasswordCode } from "../../../sevice/admin";
import { toast } from "react-hot-toast";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
  },
});

function ResetPassword({ setState, data }) {
  const [isLogin, setIsLogin] = useState(false);
  const handleSendResetCode = async (val) => {
    setIsLogin(true);
    const data = await sendResetPasswordCode(val);
    if (data.status) {
      setState("code-vrfy");
    } else {
      setIsLogin(false);
      toast.error(data.message);
    }
  };
  return (
    <Box
      sx={{ width: "30vw", display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontFamily: "poppins",
          }}
        >
          Reset Your Password !
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            width: "80%",
            fontSize: "1rem",
            fontWeight: "bold",
            // fontFamily: "poppins",
          }}
        >
          How do you want to recive the code to reset your password...?
        </Typography>
      </Box>
      <Box sx={{ display: "flex", columnGap: 6 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Radio checked />
          <Typography
            sx={{
              fontSize: ".8rem",
              fontWeight: "bold",
              fontFamily: "poppins",
            }}
          >
            Send code via email
            <br />
            <span style={{ fontSize: "1rem" }}>{data?.email}</span>
          </Typography>
        </Box>
        <img
          style={{ borderRadius: "1%", width: "5rem" }}
          src={data?.logo}
        ></img>
      </Box>
      <Box></Box>
      <Box sx={{ pb: "1.5rem" }}>
        <Button
          onClick={() => handleSendResetCode(data?.email)}
          sx={{
            background: "#F40000",
            width: "100%",
            height: "60px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            px: "1.5rem",
            color: "white",
            "&:hover": {
              color: "white",
              background: "#F40000",
            },
          }}
        >
          {isLogin ? (
            <ThemeProvider theme={customTheme}>
              <CircularProgress color="primary" />
            </ThemeProvider>
          ) : (
            <Typography
              sx={{
                color: "white",
                fontSize: "1rem",
                fontFamily: "poppins",
              }}
            >
              Continue
            </Typography>
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default ResetPassword;
