import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import { Box } from "@mui/material";

function DatePicker({ setStartDate, setEndDate }) {
  const handleDatesChange = (value) => {
    if (value === null) {
      setStartDate(Date.now());
      setEndDate(Date.now());
    } else {
      function startDate(date) {
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate()
        );
      }
      function endDate(date) {
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          (date.getDate() + 1)
        );
      }
      setStartDate(startDate(value[0]));
      setEndDate(endDate(value[1]));
    }
  };
  return (
    <Box>
      <DateRangePicker
        onChange={(value) => {
          console.log(value);
          if (value) {
            handleDatesChange(value);
          } else {
            setStartDate("");
            setEndDate("");
          }
        }}
        views={["year", "month", "day"]}
        format="dd-MM-yyyy"
        size="lg"
      />
    </Box>
  );
}

export default DatePicker;
