import { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  styled,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Autocomplete,
} from "@mui/material";
import { Box } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField, Select } from "formik-mui";
import {
  EditWastageSchemaRowMaterial,
  editWastageItem,
} from "../../../schemas/index";
import { editWastage } from "../../../sevice/inventory";
import { toast } from "react-hot-toast";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));
const emptyRaw = {
  RawMaterialId: "",
  quantity: 1,
  unit: "",
  baseUnit: "",
};
const emptyRawItem = {
  productId: "",
  quantity: 1,
};
export default function EditWastage({
  editItem,
  EditInitialValues,
  handleClose,
  units,
  products,
  rawmet,
}) {
  const { POSadmin } = useSelector((state) => state);
  const [wastageFor, setWastageFor] = useState("Item");

  const submitdata = async (values, action) => {
    let wastageData;
    let avgEstimatedLoss;
    if (values.wastageFor === "Item") {
      wastageData = values.items.map((item) => {
        let productItme = products.find(
          (pro) => pro.product._id === item.productId
        );
        return {
          productId: item.productId,
          quantity: item.quantity,
          price: productItme.rawMaterial
            .reduce(
              (acc, cur) =>
                acc +
                Number(cur.quantity) *
                  Number(cur.unit.baseValue) *
                  Number(cur.name.purchasePrice),
              0
            )
            .toFixed(2),
        };
      });
      avgEstimatedLoss = wastageData
        .reduce((acc, cur) => acc + Number(cur.quantity) * Number(cur.price), 0)
        .toFixed(2);
    } else {
      wastageData = values.items.map((item) => {
        const selectedItem = units.find((unit) => unit._id === item.unit);
        let rowMateralItem = rawmet.find(
          (row) => row._id === item.RawMaterialId
        );
        return {
          RawMaterialId: item.RawMaterialId,
          quantity: item.quantity,
          price: (
            Number(item.quantity) *
            Number(selectedItem.baseValue) *
            Number(rowMateralItem.purchasePrice)
          ).toFixed(2),
          unit: item.unit,
        };
      });
      avgEstimatedLoss = wastageData
        .reduce((acc, cur) => acc + Number(cur.price), 0)
        .toFixed(2);
    }
    const data = await editWastage(
      POSadmin.token,
      {
        wastageFor: values.wastageFor,
        items: wastageData,
        avgEstimatedLoss: avgEstimatedLoss,
      },
      editItem._id
    );
    if (data.status) {
      handleClose();
      toast.success(data.message);
    }
  };
  return (
    <>
      {products.length && rawmet.length && (
        <Box
          sx={{
            maxHeight: "75vh",
            overflow: "scroll",
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar
            },
            bgcolor: "white",
            boxShadow: 24,
            px: 4,
            py: 2,
            borderRadius: "6px",
          }}
        >
          <Formik
            initialValues={EditInitialValues}
            validationSchema={
              editItem.wastageFor == "Item"
                ? editWastageItem
                : EditWastageSchemaRowMaterial
            }
            onSubmit={submitdata}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form autoComplete="off">
                <Typography sx={{ p: 2 }} variant="h6">
                  Add Wastage
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    marginLeft: "30px",
                    mb: 8,
                  }}
                >
                  <br />
                  <InputLabel>Wastage for</InputLabel>
                  <br />
                  <FormControl>
                    <RadioGroup
                      value={values?.wastageFor}
                      onChange={(e) => {
                        setFieldValue(
                          "items",
                          wastageFor == "Item"
                            ? [
                                {
                                  name: "",
                                  quantity: 1,
                                  unit: "",
                                },
                              ]
                            : [
                                {
                                  name: "",
                                  quantity: 1,
                                },
                              ]
                        );
                        setFieldValue("wastageFor", e.target.value);
                        setWastageFor(e.target.value);
                      }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Item"
                        disabled={values?.wastageFor === "Raw Material"}
                        control={<Radio />}
                        label="Item"
                      />
                      <FormControlLabel
                        value="Raw Material"
                        disabled={values?.wastageFor === "Item"}
                        control={<Radio />}
                        label="Raw Material"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                    <TableHead sx={{ background: "var(--table-head)" }}>
                      <TableRow>
                        <StyledTableCell align="center">Name</StyledTableCell>
                        <StyledTableCell align="center">Qty</StyledTableCell>
                        {values?.wastageFor !== "Item" && (
                          <StyledTableCell align="center">Unit</StyledTableCell>
                        )}
                        <StyledTableCell align="center">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {values?.wastageFor === "Item" ? (
                      <FieldArray name="items">
                        {({ push, remove }) => (
                          <>
                            <TableBody>
                              {values.items.map((_, index) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    <FormControl sx={{ m: 1, minWidth: 300 }}>
                                      <Autocomplete
                                        options={products}
                                        size="small"
                                        value={products.find(
                                          (item) =>
                                            values.items[index].productId ===
                                            item.product._id
                                        )}
                                        getOptionLabel={(option) =>
                                          option?.product?.title
                                        }
                                        renderInput={(params) => (
                                          <Field
                                            component={TextField}
                                            {...params}
                                            value={params._id}
                                            name={`items.${index}.productId`}
                                            variant="outlined"
                                            fullWidth
                                            onChange={() => {}}
                                          />
                                        )}
                                        onChange={(event, newValue) => {
                                          if (newValue) {
                                            setFieldValue(
                                              `items.${index}.productId`,
                                              newValue.product._id
                                            );
                                          } else {
                                            setFieldValue(
                                              `items.${index}.productId`,
                                              ""
                                            );
                                          }
                                        }}
                                      />
                                    </FormControl>
                                  </TableCell>

                                  <TableCell align="center">
                                    <Field
                                      sx={{ m: 1 }}
                                      component={TextField}
                                      name={`items.${index}.quantity`}
                                      size="small"
                                      fullWidth
                                    />
                                  </TableCell>

                                  <TableCell align="center">
                                    <Button onClick={() => remove(index)}>
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>

                            <Button
                              onClick={() => push(emptyRawItem)}
                              variant="contained"
                              sx={{
                                m: 3,
                                width: "110px",
                                textTransform: "none",
                                background: "var(--red)",
                                "&:hover": {
                                  backgroundColor: "var(--red)",
                                },
                              }}
                            >
                              + Add Row
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    ) : (
                      <FieldArray name="items">
                        {({ push, remove }) => (
                          <>
                            <TableBody>
                              {values?.items?.map((_, index) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  {" "}
                                  <TableCell align="center">
                                    <FormControl sx={{ m: 1, minWidth: 300 }}>
                                      <Autocomplete
                                        options={rawmet}
                                        size="small"
                                        value={rawmet.find(
                                          (item) =>
                                            values.items[index]
                                              .RawMaterialId === item._id
                                        )}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                          <Field
                                            component={TextField}
                                            {...params}
                                            value={params._id}
                                            name={`items.${index}.RawMaterialId`}
                                            variant="outlined"
                                            fullWidth
                                            onChange={() => {}}
                                          />
                                        )}
                                        onChange={(event, newValue) => {
                                          if (newValue) {
                                            setFieldValue(
                                              `items.${index}.RawMaterialId`,
                                              newValue._id
                                            );
                                            setFieldValue(
                                              `items.${index}.baseUnit`,
                                              newValue.purchaseUnit._id
                                            );
                                            setFieldValue(
                                              `items.${index}.unit`,
                                              ""
                                            );
                                          } else {
                                            setFieldValue(
                                              `items.${index}.RawMaterialId`,
                                              ""
                                            );
                                            setFieldValue(
                                              `items.${index}.baseUnit`,
                                              ""
                                            );
                                            setFieldValue(
                                              `items.${index}.unit`,
                                              ""
                                            );
                                          }
                                        }}
                                      />
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Field
                                      sx={{ m: 1 }}
                                      component={TextField}
                                      name={`items.${index}.quantity`}
                                      size="small"
                                      fullWidth
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                                      <Field
                                        component={Select}
                                        name={`items.${index}.unit`}
                                        size="small"
                                      >
                                        {units?.map((item) => {
                                          return (
                                            item?.unit ==
                                              values?.items[index]
                                                ?.baseUnit && (
                                              <MenuItem value={item._id}>
                                                {item.name}
                                              </MenuItem>
                                            )
                                          );
                                        })}
                                      </Field>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button onClick={() => remove(index)}>
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>

                            <Button
                              onClick={() => push(emptyRaw)}
                              variant="contained"
                              sx={{
                                m: 3,
                                width: "110px",
                                textTransform: "none",
                                background: "var(--red)",
                                "&:hover": {
                                  backgroundColor: "var(--red)",
                                },
                              }}
                            >
                              + Add Row
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    )}
                  </Table>
                </TableContainer>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3,
                      width: "100px",
                      textTransform: "none",
                      background: "var( --color-secondary)",
                      "&:hover": {
                        backgroundColor: "var( --color-secondary)",
                      },
                    }}
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3,
                      width: "100px",
                      textTransform: "none",
                      background: "var(--red)",
                      "&:hover": {
                        backgroundColor: "var(--red)",
                      },
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </>
  );
}
