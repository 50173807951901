import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableContainer,
  styled,
  Pagination,
  Stack,
  Typography,
  Modal,
} from "@mui/material";
import React from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DatePicker from "./DatePicker";
import { useState } from "react";
import { useEffect } from "react";
import { posGetSalesReport } from "../../../sevice/reports";
import { useSelector } from "react-redux";
import SearchBar from "../../common/SearchBar";
import { DateFormatterWithTime } from "../../../utils/DateFormatter";
import SalesView from "./SalesView";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
}));

const StatusView = styled(Box)({
  display: "flex",
  width: 150,
  height: 40,
  color: "#00876",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  maxHeight: "70vh",
  overflow: "hidden",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar
  },
  py: 2,
  width: "600px",
  borderRadius: "5px",
};

export default function Sales() {
  const { POSadmin } = useSelector((state) => state);
  const [status, setStatus] = useState("All");
  const [sortby, setSortby] = useState("All");
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [salesReport, setSalesReport] = useState("");
  const fetchDatas = async () => {
    const sales = await posGetSalesReport(
      POSadmin.token,
      status,
      sortby,
      search,
      startDate,
      endDate,
      pageNo
    );
    setSalesReport(sales);
  };
  useEffect(() => {
    fetchDatas();
  }, [pageNo, search]);
  const changeSearchQuery = (e) => {
    setSearch(e.target.value.toLowerCase());
    setPageNo(1);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [saleData, setSaleData] = useState();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <FormLabel sx={{ color: "#000" }} id="sortby-label">
              Status
            </FormLabel>
            <Select
              labelId="sortby-label"
              id="sortby"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              size="small"
              sx={{ width: "130px" }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Preparing">Preparing</MenuItem>
              <MenuItem value="Ready">Ready</MenuItem>
              <MenuItem value="Delivered">Delivered</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <FormLabel sx={{ color: "#000" }} id="sortby-label">
              Sort by
            </FormLabel>
            <Select
              labelId="sortby-label"
              id="sortby"
              value={sortby}
              onChange={(e) => setSortby(e.target.value)}
              size="small"
              sx={{ width: "130px" }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Dine in">Dine in</MenuItem>
              <MenuItem value="Pickup">Pickup</MenuItem>
              <MenuItem value="Delivery">Delivery</MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <FormLabel sx={{ color: "#000" }}>Search</FormLabel>
            <SearchBar
              placeholder={"Enter invoice no"}
              onChange={changeSearchQuery}
              searchBarWidth={"160px"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <FormLabel sx={{ color: "#000" }} id="sortby-label">
              Date Range
            </FormLabel>
            <DatePicker setStartDate={setStartDate} setEndDate={setEndDate} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <br />
            <Button
              variant="contained"
              onClick={() => {
                fetchDatas();
                setPageNo(1);
              }}
              sx={{
                bgcolor: "#F40000",
                "&:hover": {
                  backgroundColor: "#F40000",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </FormControl>
        <Box
          sx={{ height: "100%", flex: 1, overflowY: "auto", overflowX: "auto" }}
        >
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, minHeight: "50%" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: "#D9D9D9" }}>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    SL No
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Order Date
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Invoice No
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Order Items
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Order Type
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Price
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Status
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesReport?.docs?.map((row, index) => (
                  <StyledTableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">
                      {salesReport.pagingCounter + index}
                    </TableCell>
                    <TableCell align="center">
                      <DateFormatterWithTime date={row.createAt} />
                    </TableCell>
                    <TableCell align="center">{row.invoiceNo}</TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          maxHeight: "150px",
                          overflow: "auto",
                          "&::-webkit-scrollbar": {
                            display: "none", // Hide scrollbar
                          },
                        }}
                      >
                        {row.products.map((item, index) => (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography>{`${item?.quantity} x`}</Typography>
                              &nbsp;
                              <Typography>
                                {item?.varientId?.productName}
                              </Typography>
                            </Box>
                          </>
                        ))}
                      </Box>
                    </TableCell>
                    <TableCell align="center">{row.typeofOrder}</TableCell>
                    <TableCell align="center">
                      <span style={{ fontSize: "11px" }}>
                        {POSadmin.symbol}
                      </span>{" "}
                      {row.grandTotal.toFixed(2)}
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: "15rem" }}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <StatusView
                          sx={{
                            background:
                              row.orderStatus === "Pending"
                                ? "#EEDD82"
                                : row.orderStatus === "Preparing"
                                ? "#FFCC99"
                                : row.orderStatus === "Ready"
                                ? "#CCE5FF"
                                : row.orderStatus === "Delivered"
                                ? "#cfead9"
                                : row.orderStatus === "Cancelled"
                                ? "#FF9999"
                                : "#E0E0E0",
                          }}
                        >
                          {row.orderStatus}
                        </StatusView>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <RemoveRedEyeIcon
                        onClick={() => {
                          setSaleData(row);
                          handleOpen();
                        }}
                      />
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            component={Paper}
            sx={{
              marginTop: "10px",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <Stack>
              <Pagination
                count={salesReport?.totalPages}
                page={pageNo}
                onChange={(event, value) => setPageNo(value)}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SalesView saleData={saleData} close={handleClose} />
          {/* <EditRawMaterial editItem={editItem} handleClose={handleClose} /> */}
        </Box>
      </Modal>
    </>
  );
}
