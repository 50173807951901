import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import {
  fetchDeliveredOrders,
  fetchPendingOrders,
  fetchPreparingOrders,
  fetchReadyOrders,
} from "./../sevice/admin";
import AdminSubNavbar from "./../components/common/SubNavbar";
import SearchBar from "./../components/common/SearchBar";
import PendingOrderCard from ".././components/posAdminPanel/order/PendingOrderCard";
import PreparingOrderCard from ".././components/posAdminPanel/order/PreparingOrderCard";
import ReadyOrderCard from ".././components/posAdminPanel/order/ReadyOrderCard";
import DeliveredOrderCard from ".././components/posAdminPanel/order/DeliveredOrderCard";
import FlowDirection from ".././components/posAdminPanel/navBarAndSideBar/FlowDirection";
import { ChangeflowDirectionItemAddGroup } from "./FlowDirection";
function AllOrders() {
  const { POSadmin } = useSelector((state) => state);
  const [value, setValue] = useState("1");
  const [orderType, setOrderType] = useState("All");
  const [pendingOrders, setPendingOrders] = useState([]);
  const [preparingOrders, setPreparingOrders] = useState([]);
  const [readyOrders, setReadyOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  let [showBack, setShowBack] = useState(false);

  const navBarItems = [
    {
      label: "All",
      value: "1",
    },
    {
      label: "Dine in",
      value: "2",
    },
    {
      label: "Delivery",
      value: "3",
    },
    {
      label: "Pickup",
      value: "4",
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setOrderType(navBarItems[Number(newValue - 1)].label);
  };
  useEffect(() => {
    getAllPendingOrder();
    getAllPreparingOrder();
    getAllReadyOrder();
    getAllDeliveredOrder();
  }, [value]);

  const getAllPendingOrder = async () => {
    const data = await fetchPendingOrders(POSadmin.token);
    setPendingOrders(data);
  };
  const getAllPreparingOrder = async () => {
    const data = await fetchPreparingOrders(POSadmin.token);
    setPreparingOrders(data);
  };
  const getAllReadyOrder = async () => {
    const data = await fetchReadyOrders(POSadmin.token);
    setReadyOrders(data);
  };
  const getAllDeliveredOrder = async () => {
    const data = await fetchDeliveredOrders(POSadmin.token);
    setDeliveredOrders(data);
  };

  let [flowDirectionItems, setFlowDirectionItems] = useState([
    {
      name: "Settings",
      show: true,
    },
  ]);
  useEffect(() => {
    ChangeflowDirectionItemAddGroup(
      value,
      setFlowDirectionItems,
      setShowBack,
      navBarItems
    );
  }, [value]);
  const backFunction = () => {
    setValue(String(Number(value) - 1));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Toolbar />
        <FlowDirection
          items={flowDirectionItems}
          showBack={showBack}
          backFunction={backFunction}
        />

        <AdminSubNavbar
          handleChange={handleChange}
          items={navBarItems}
          value={value}
        />
        <TabPanel value="1" sx={{ p: 1.5 }}>
          <TabPanelBody
            pendingOrders={pendingOrders}
            preparingOrders={preparingOrders}
            readyOrders={readyOrders}
            deliveredOrders={deliveredOrders}
            orderType={orderType}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 1.5 }}>
          <TabPanelBody
            pendingOrders={pendingOrders}
            preparingOrders={preparingOrders}
            readyOrders={readyOrders}
            deliveredOrders={deliveredOrders}
            orderType={orderType}
          />
        </TabPanel>
        <TabPanel value="3" sx={{ p: 1.5 }}>
          <TabPanelBody
            pendingOrders={pendingOrders}
            preparingOrders={preparingOrders}
            readyOrders={readyOrders}
            deliveredOrders={deliveredOrders}
            orderType={orderType}
          />
        </TabPanel>
        <TabPanel value="4" sx={{ p: 1.5 }}>
          <TabPanelBody
            pendingOrders={pendingOrders}
            preparingOrders={preparingOrders}
            readyOrders={readyOrders}
            deliveredOrders={deliveredOrders}
            orderType={orderType}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default AllOrders;

function TabPanelBody({
  pendingOrders,
  preparingOrders,
  readyOrders,
  deliveredOrders,
  orderType,
}) {
  const [query, setQuery] = useState("");
  const keys = ["orderId", "tableNo", "invoiceNo"];
  return (
    <Box>
      <Box
        sx={{ mx: 0.5, mb: 1 }}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <SearchBar
          searchBarWidth={"200px"}
          placeholder={"Search"}
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            background: "var(--red)",
            "&:hover": {
              backgroundColor: "var(--red)",
            },
          }}
          component={Link}
          to={"/kot"}
        >
          Kot View
        </Button>
      </Box>
      <Grid container>
        <Grid item xs={3}>
          <PendingOrderCard
            orders={pendingOrders}
            type={orderType}
            query={query}
            keys={keys}
          />
        </Grid>
        <Grid item xs={3}>
          <PreparingOrderCard
            orders={preparingOrders}
            type={orderType}
            query={query}
            keys={keys}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadyOrderCard
            orders={readyOrders}
            type={orderType}
            query={query}
            keys={keys}
          />
        </Grid>
        <Grid item xs={3}>
          <DeliveredOrderCard
            orders={deliveredOrders}
            type={orderType}
            query={query}
            keys={keys}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
