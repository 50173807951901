import { useState } from "react";
import { Box, Card, Typography, Divider } from "@mui/material";
import Moment from "react-moment";
import styled from "@emotion/styled";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
const BuyIcon = styled(Box)({
  backgroundColor: "var(--red)",
  color: "var(--font-white)",
  width: "100px",
  height: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
});

function PendingOrderCard({ orders, type, query, keys }) {
  return (
    <Card
      elevation={0}
      sx={{
        margin: 0.5,
        p: 1,
        pb: 5,
        background: "var(--bg-forth)",
        height: { lg: "76vh", md: "72vh" },
      }}
    >
      <span style={{ marginTop: 10 }}>
        <b>Preparing</b>
      </span>
      <Box
        sx={{
          mt: 1,

          height: "100%",
          overflow: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar
          },
        }}
      >
        {orders
          ?.filter((item) =>
            keys.some((key) => item[key]?.toLowerCase()?.includes(query))
          )
          ?.map((item, index) => (
            <Box key={item._id}>
              {type === "All" ? (
                <CardBody item={item} />
              ) : (
                item.typeofOrder == type && <CardBody item={item} />
              )}
            </Box>
          ))}
      </Box>
    </Card>
  );
}

export default PendingOrderCard;

function CardBody({ item }) {
  return (
    <Card
      elevation={0}
      sx={{
        mb: 2,
        p: 1.5,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BuyIcon sx={{ width: { sm: "70px", md: "70px", lg: "100px" } }}>
            <Typography
              sx={{ fontSize: { sm: "13px", md: "13px", lg: "15px" } }}
            >
              {item?.typeofOrder}
            </Typography>
          </BuyIcon>
          <Box
            sx={{
              display: "flex",
              alignContent: "end",
              textAlign: "end",
            }}
          >
            <Typography
              sx={{ fontSize: { sm: "13px", md: "12px", lg: "15px" } }}
            >
              <Moment format="MM/D/YY,h:mm" withTitle>
                {item?.createdAt}
              </Moment>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "8px",
          }}
        >
          <Typography sx={{ fontSize: { sm: "13px", md: "13px", lg: "14px" } }}>
            <span style={{ fontWeight: "bolder" }}>Invoice No : </span>
            {item?.invoiceNo}
          </Typography>
          {item?.typeofOrder == "Delivery" || item?.typeofOrder == "Pickup" ? (
            ""
          ) : (
            <Box
              sx={{
                display: "flex",
                alignContent: "end",
                textAlign: "end",
              }}
            >
              <Typography
                sx={{
                  fontSize: { sm: "13px", md: "13px", lg: "14px" },
                }}
              >
                <span style={{ fontWeight: "bolder" }}>Table No</span>:
                {item?.tableNo}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ mt: "15px" }}>
        <Products item={item} />
      </Box>
    </Card>
  );
}
function Products({ item }) {
  const { POSadmin } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  return (
    <>
      {open ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <ExpandLessIcon onClick={() => setOpen(false)} />
          </Box>
          {item?.products?.map((product) => (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "14px" }}>
                  {product?.quantity} x {product?.varientId?.productName}
                  <br />
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {product?.subTotal}
                  <br />
                </Typography>
              </Box>
              <Box sx={{ ml: "10px" }}>
                {product?.addOns.length > 0 &&
                  product?.addOns?.map((addOn) => (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography sx={{ fontSize: "14px" }} value={addOn?._id}>
                        {addOn?.quantity} x {addOn?.name}
                        <br />
                      </Typography>
                      <Typography sx={{ fontSize: "14px" }} value={addOn?._id}>
                        {addOn?.price}
                        <br />
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </>
          ))}
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Divider style={{ width: "100px", color: "black" }} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Box direction="column">
                <Typography sx={{ fontSize: "13px" }}>Sub Total</Typography>
                <Typography sx={{ fontSize: "13px" }}>
                  {POSadmin.gstOrVat} Amount
                </Typography>
                <Typography sx={{ fontSize: "13px" }}>Package</Typography>
                <Typography sx={{ fontSize: "13px" }}>Tips</Typography>
                <Typography sx={{ fontSize: "13px" }}>Delivery</Typography>
                <Typography sx={{ fontSize: "13px" }}>Grand Total</Typography>
              </Box>
              <Box direction="column">
                <Typography sx={{ fontSize: "13px" }}>:</Typography>
                <Typography sx={{ fontSize: "13px" }}>:</Typography>
                <Typography sx={{ fontSize: "13px" }}>:</Typography>
                <Typography sx={{ fontSize: "13px" }}>:</Typography>
                <Typography sx={{ fontSize: "13px" }}>:</Typography>
                <Typography sx={{ fontSize: "13px" }}>:</Typography>
              </Box>
              <Box direction="column">
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  <span style={{ fontSize: "11px" }}>{POSadmin.symbol} </span>
                  {item.productsSubTotal}
                </Typography>
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  <span style={{ fontSize: "11px" }}>{POSadmin.symbol} </span>
                  {item.gstAmount}
                </Typography>
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  <span style={{ fontSize: "11px" }}>{POSadmin.symbol} </span>
                  {item.package ? item.package : 0}
                </Typography>
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  <span style={{ fontSize: "11px" }}>{POSadmin.symbol} </span>
                  {item.tips ? item.tips : 0}
                </Typography>
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  <span style={{ fontSize: "11px" }}>{POSadmin.symbol} </span>
                  {item.deliveryCharge}
                </Typography>
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  <span style={{ fontSize: "11px" }}>{POSadmin.symbol} </span>
                  {item.grandTotal}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <ExpandMore onClick={() => setOpen(true)} />
        </Box>
      )}
    </>
  );
}
