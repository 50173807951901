import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import ProductTable from "./../components/posAdminPanel/createProduct/ProductTable";
import QuickAddProduct from "./../components/posAdminPanel/createProduct/QuickAddProduct";
import { vendorAllProducts } from "../sevice/admin";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import FlowDirection from "./../components/posAdminPanel/navBarAndSideBar/FlowDirection";
import { useNavigate } from "react-router-dom";
import LoadingImage from "../assets/loader.gif";

function ResponsiveDrawer(props) {
  const navigate = useNavigate();
  const { POSadmin } = useSelector((state) => state);
  const initialState = {
    title: "",
    titleAr: "",
    group: "",
    category: "",
    description: "",
    descriptionAr: "",
    vegOrNonVeg: "Veg",
    image: "",
    delivery: true,
    pickup: true,
    dineIn: true,
    varients: [
      {
        image: "",
        productName: "",
        productNameAr: "",
        stock: 0,
        sku: "",
        unitPrice: 0,
        salePrice: 0,
        sellerStatus: true,
        isDeleted: false,
      },
    ],
    addOns: [],
    recipe: [],
    saleStartDate: new Date(),
    saleEndDate: new Date().getFullYear() + 1,
    videoLink: "",
    property1: "",
    property1Ar: "",
    property2: "",
    property2Ar: "",
    property3: "",
    property3Ar: "",
    property4: "",
    property4Ar: "",
    property5: "",
    property5Ar: "",
    department: "640879ae3cee7036416aaa07",
  };
  const [product, setproduct] = useState(initialState);
  const [allProperty, setAllProperty] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [allProduct, setAllProduct] = useState([]);
  const [IsLoading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const { window } = props;

  useEffect(() => {
    fetchAllProduct();
  }, [pageNo, searchQuery]);

  const fetchAllProduct = async () => {
    const data = await vendorAllProducts(POSadmin.token, pageNo, searchQuery);
    setAllProduct(data);
    setLoading(false);
  };

  const AddproductMessage = (data) => {
    fetchAllProduct();
    if (data.status) {
      toast.success(data.message);
    }
  };
  const sentMessage = (data) => {
    if (data.status) {
      toast.success(data.message);
      fetchAllProduct();
    }
  };
  const drawer = (
    <QuickAddProduct
      product={product}
      setproduct={setproduct}
      allProperty={allProperty}
      setAllProperty={setAllProperty}
      initialState={initialState}
      AddproductMessage={AddproductMessage}
    />
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const navBarItems = [
    {
      name: "Menu Managemet",
      show: true,
    },
    {
      name: "Product",
      show: true,
    },
  ];
  const backFunction = () => {
    navigate("/menumanagemet");
  };
  return (
    <Box sx={{ display: "flex", width: "100%", height: "98%" }}>
      {IsLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{ height: "300px" }} src={LoadingImage} alt="Loading" />
        </Box>
      ) : (
        <>
          <Box sx={{ width: "100%", height: "100%" }}>
            <FlowDirection
              items={navBarItems}
              backFunction={backFunction}
              showBack={true}
              showArabic={true}
            />
            <Toolbar />
            <Box sx={{ display: "flex", justifyContent: "end", mt: "40px" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                maxHeight: "91%",
                overflow: "auto",
                overflowX: "hidden",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <ProductTable
                allProduct={allProduct}
                sentMessage={sentMessage}
                setPageNo={setPageNo}
                pageNo={pageNo}
                setSearchQuery={setSearchQuery}
              />
            </Box>
          </Box>
          <Box
            component="nav"
            sx={{
              flexShrink: { md: 0 },
              maxHeight: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            aria-label="mailbox folders"
          >
            <Drawer
              anchor="right"
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { lg: "block", md: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                },
              }}
            >
              {drawer}
            </Drawer>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                },
              }}
              open
            >
              {drawer}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default ResponsiveDrawer;
