import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { toast } from "react-hot-toast";
import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import SearchBar from "../../common/SearchBar";
import AddTable from "./AddTable";
import EditTable from "./EditTable";
import AlertButton from "../../../Alert/Alert";
import { getAllTables, deleteTable } from "../../../sevice/admin";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--font-white)",
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 705,
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 4,
  py: 3,
  borderRadius: "5px",
};

export default function AllTable() {
  const { POSadmin } = useSelector((state) => state);
  const [tables, setTables] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [openAddTable, setOpenAddTable] = useState(false);
  const handleOpenTable = () => setOpenAddTable(true);
  const handleCloseTable = () => setOpenAddTable(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetchAllTable();
  }, [openAddTable, open]);

  const fetchAllTable = async () => {
    const data = await getAllTables(POSadmin.id);
    setTables(data);
  };

  const handleDeleteTable = async (id) => {
    const data = await deleteTable(POSadmin.token, id);
    sentMessage(data);
    fetchAllTable();
  };

  const sentMessage = (data) => {
    if (data.status) {
      toast.success(data.message);
      handleCloseTable();
    }
  };
  return (
    <div>
      <Box sx={{ px: "20px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", py: "20px" }}
        >
          <SearchBar searchBarWidth={"200px"} placeholder={"Search"} />
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={handleOpenTable}
          >
            Add Table
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: "100%" }} aria-label="simple table">
            <TableHead sx={{ background: "var(--dark-gray)" }}>
              <TableRow>
                <StyledTableCell align="center">Table No</StyledTableCell>
                <StyledTableCell align="center">
                  Number of person
                </StyledTableCell>
                <StyledTableCell align="center">Info</StyledTableCell>
                <StyledTableCell align="center">Area</StyledTableCell>
                <StyledTableCell align="center">Active</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tables.map((row) => (
                <TableRow key={row._id}>
                  <TableCell align="center">{row.tableNo}</TableCell>
                  <TableCell align="center">{row.noOfPeople}</TableCell>
                  <TableCell align="center">{row.additionalInfo}</TableCell>
                  <TableCell align="center">{row?.area?.areaName}</TableCell>
                  <TableCell align="center">
                    {row.isActive ? "Active" : "Not Active"}
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Button
                        onClick={() => {
                          handleOpen();
                          setEditItem(row);
                        }}
                        sx={{
                          width: "20px",
                        }}
                      >
                        <DescriptionIcon />
                      </Button>
                      <AlertButton
                        onClick={() => setEditItem(row)}
                        element={<DeleteIcon />}
                        type={"confirmation"}
                        message={
                          "Are you sure? You won't be able to revert this!"
                        }
                        onConfirm={handleDeleteTable}
                        data={row._id}
                        style={{ width: "20px", color: "var(--red)" }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
          open={openAddTable}
          onClose={handleCloseTable}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <AddTable
              sentMessage={sentMessage}
              handleCloseTable={handleCloseTable}
            />
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <EditTable
              sentMessage={sentMessage}
              editItem={editItem}
              handleClose={handleClose}
            />
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
