import { useEffect, useState } from "react";
import {
  Button,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import SearchBar from "../../common/SearchBar";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import {
  deleteStock,
  editCurrentStock,
  getRawMaterials,
} from "../../../sevice/inventory";
import { toast } from "react-hot-toast";
import AlertButton from "../../../Alert/Alert";
import LoadingImage from "../../../assets/loader.gif";

const StockAvailable = styled(Box)({
  display: "flex",
  width: 150,
  height: 40,
  color: "var(--bg-white)",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));

function Stock() {
  const { POSadmin } = useSelector((state) => state);
  const [RawMaterial, setRawMaterial] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [IsLoading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);

  const getDatas = async () => {
    const data = await getRawMaterials(POSadmin.token, searchQuery, pageNo);
    setRawMaterial(data);
    setLoading(false);
  };
  useEffect(() => {
    getDatas();
  }, [searchQuery, pageNo]);

  const clearStock = async (item) => {
    const data = await deleteStock(POSadmin.token, item._id);
    if (data.status) {
      toast.success(data.message);
      getDatas();
    } else {
      toast.error(data.message);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const { currentStock } = event.target.elements;
    const data = await editCurrentStock(
      POSadmin.token,
      event.target.id,
      currentStock.value
    );
    if (data.status) {
      toast.success(data.message);
      getDatas();
      document.getElementById(event.target.id).reset();
    } else {
      toast.error(data.message);
    }
  }
  const handleChangePageNo = (event, value) => {
    setPageNo(value);
  };
  return (
    <>
      {IsLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img style={{ height: "300px" }} src={LoadingImage} alt="Loading" />
        </Box>
      ) : (
        <>
          <Box style={{ display: "flex", justifyContent: "start" }}>
            <SearchBar
              searchBarWidth={"200px"}
              placeholder={"Search"}
              onChange={(newValue) => {
                setSearchQuery(newValue.target.value);
                setPageNo(1);
              }}
            />
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead sx={{ background: "var(--table-head)" }}>
                  <TableRow>
                    <StyledTableCell align="center">SL No</StyledTableCell>
                    <StyledTableCell align="center">Name</StyledTableCell>
                    <StyledTableCell align="center">
                      Stock availability
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Existing Stock
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Stock Managing
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {RawMaterial?.docs?.map((item, index) => (
                    <>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {RawMaterial.pagingCounter + index}
                        </TableCell>
                        <TableCell align="center">{item.name}</TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            {item.stock > 0 ? (
                              <StockAvailable
                                style={{ background: "var(--green-color)" }}
                              >
                                In stock
                              </StockAvailable>
                            ) : (
                              <StockAvailable
                                style={{ background: "var(--light-red-color)" }}
                              >
                                Out of Stock
                              </StockAvailable>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {Number(item.stock).toFixed(2)}&nbsp;&nbsp;
                          {item.purchaseUnit.name}
                        </TableCell>
                        <TableCell align="center">
                          <form
                            id={item._id}
                            onSubmit={handleSubmit}
                            // style={{ display: "flex", alignItems: "center" }}
                          >
                            <TextField
                              type="number"
                              required
                              variant="outlined"
                              name="currentStock"
                              id="currentStock"
                              size="small"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button type="submit">
                              <SaveAsIcon sx={{ color: "black" }} />
                            </Button>
                            {/* <AlertButton
                              element={<DeleteIcon />}
                              type={"confirmation"}
                              message={"Are you sure?"}
                              onConfirm={clearStock}
                              data={item}
                              style={{ color: "black" }}
                            /> */}
                          </form>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              component={Paper}
              sx={{
                marginTop: "10px",
                backgroundColor: "white",
                padding: "10px",
              }}
            >
              <Stack>
                <Pagination
                  count={RawMaterial?.totalPages}
                  page={pageNo}
                  onChange={handleChangePageNo}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default Stock;
