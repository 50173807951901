import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Modal,
  Paper,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import SearchBar from "../../common/SearchBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSelector } from "react-redux";
import {
  deliveryBoyBlockAndUnblock,
  getAllDeliveryBoys,
  registerDeliverBoy,
} from "../../../sevice/userManagement";
import { deliverySchema } from "../../../schemas";
import EditDeliveryBoy from "./EditDeliveryBoy";
import MaterialUISwitch from "./MaterialUISwitch";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));

const style = {
  width: "90%",
  height: "80%",
  position: "absolute",
  top: "54%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  px: 4,
  py: 2,
  borderRadius: "6px",
  overflow: "auto",
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar
  },
};

function DeliveryBoy({ state, setState }) {
  const { POSadmin } = useSelector((state) => state);
  const [editItem, setEditItem] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deliverBoys, setDeliverBoys] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    password: "",
    userPasscode: "",
    swipeCode: "",
    addressLine1: "",
    addressLine2: "",
    dateOfBirth: "",
    email: "",
    city: "",
    zipCode: "",
    document: "",
    geoFence: "",
    payout: "",
    time: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: deliverySchema,
    onSubmit: async (values, action) => {
      const data = await registerDeliverBoy(values, POSadmin.token);
      if (data.status) {
        setState(true);
        toast.success(data.message);
        action.resetForm();
      } else {
        toast.error(data.message);
      }
    },
  });

  const handleSwitchChange = async (status, id) => {
    const res = await deliveryBoyBlockAndUnblock(POSadmin.token, id, !status);
    if (res) {
      toast.success(res.message);
      getDatas();
    }
  };

  const getDatas = async () => {
    const data = await getAllDeliveryBoys(POSadmin.token);
    setDeliverBoys(data);
  };
  useEffect(() => {
    getDatas();
  }, [state, open]);

  const filteredDeliverBoys = deliverBoys.filter((item) =>
    item.userName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {state && (
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            searchBarWidth={"200px"}
            placeholder={"Search"}
            onChange={({ target }) => setSearchQuery(target.value)}
          />
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={() => setState(false)}
          >
            + Add DeliveryBoy
          </Button>
        </Box>
      )}
      {state ? (
        <Box sx={{ marginTop: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead sx={{ background: "var(--table-head)" }}>
                <TableRow>
                  <StyledTableCell align="center">
                    Delivery Name
                  </StyledTableCell>
                  <StyledTableCell align="center">User Name</StyledTableCell>
                  <StyledTableCell align="center">User Code</StyledTableCell>
                  <StyledTableCell align="center">Active</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDeliverBoys.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredDeliverBoys.map((item, index) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={index}
                    >
                      <TableCell align="center">{item.userName}</TableCell>
                      <TableCell align="center">{item.first_name}</TableCell>
                      <TableCell align="center">{item.userCode}</TableCell>
                      <TableCell align="center">
                      <MaterialUISwitch
                          checked={item?.isActive}
                          onChange={() =>
                            handleSwitchChange(item.isActive, item._id)
                          }
                          sx={{ marginTop: "0px" }}
                          inputProps={{ "aria-label": "controlled" }}
                          color="error"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <VisibilityIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditItem(item);
                            handleOpen();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <EditDeliveryBoy editItem={editItem} handleClose={handleClose} />
            </Box>
          </Modal>
        </Box>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Typography sx={{ p: 3 }} variant="h6">
                Add Delivery Boy
              </Typography>
              <Card
                elevation={0}
                sx={{
                  width: "100%",
                  height: "30%",
                  marginRight: "15px",
                  marginBottom: "20px",
                  padding: 3,
                  border: "1px solid #ccc",
                }}
              >
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "53ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    label="Fist Name"
                    id="first_name"
                    variant="outlined"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.first_name && !!formik.errors.first_name
                    }
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                    required
                  />
                  <TextField
                    label="Last Name"
                    id="last_name"
                    variant="outlined"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.last_name && !!formik.errors.last_name
                    }
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                    required
                  />
                  <TextField
                    label="Phone"
                    id="phone"
                    variant="outlined"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={!!formik.touched.phone && !!formik.errors.phone}
                    helperText={formik.touched.phone && formik.errors.phone}
                    required
                  />
                  <TextField
                    label="Password"
                    id="password"
                    variant="outlined"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.password && !!formik.errors.password
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    required
                  />
                  <TextField
                    label="User Passcode"
                    id="userPasscode"
                    variant="outlined"
                    name="userPasscode"
                    value={formik.values.userPasscode}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.userPasscode &&
                      !!formik.errors.userPasscode
                    }
                    helperText={
                      formik.touched.userPasscode && formik.errors.userPasscode
                    }
                  />
                  <TextField
                    label="Swipe Code"
                    id="swipeCode"
                    variant="outlined"
                    name="swipeCode"
                    value={formik.values.swipeCode}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.swipeCode && !!formik.errors.swipeCode
                    }
                    helperText={
                      formik.touched.swipeCode && formik.errors.swipeCode
                    }
                  />
                </Box>
              </Card>
              <Typography sx={{ p: 1 }} variant="h6">
                Personals Details
              </Typography>
              <Card
                elevation={0}
                sx={{
                  width: "100%",
                  height: "30%",
                  marginRight: "15px",
                  marginBottom: "20px",
                  padding: 3,
                  border: "1px solid #ccc",
                }}
              >
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "53ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    label="Address line 1"
                    id="addressLine1"
                    variant="outlined"
                    name="addressLine1"
                    value={formik.values.addressLine1}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.addressLine1 &&
                      !!formik.errors.addressLine1
                    }
                    helperText={
                      formik.touched.addressLine1 && formik.errors.addressLine1
                    }
                    required
                  />
                  <TextField
                    label="Address line 2"
                    id="addressLine2"
                    variant="outlined"
                    name="addressLine2"
                    value={formik.values.addressLine2}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.addressLine2 &&
                      !!formik.errors.addressLine2
                    }
                    helperText={
                      formik.touched.addressLine2 && formik.errors.addressLine2
                    }
                    required
                  />
                  <TextField
                    label="Date of Birth"
                    id="dateOfBirth"
                    variant="outlined"
                    name="dateOfBirth"
                    type="date"
                    value={formik.values.dateOfBirth}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.dateOfBirth &&
                      !!formik.errors.dateOfBirth
                    }
                    helperText={
                      formik.touched.dateOfBirth && formik.errors.dateOfBirth
                    }
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: { minWidth: "160px" }, // Adjust the width as needed
                    }}
                  />

                  <TextField
                    label="Email"
                    id="email"
                    variant="outlined"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={!!formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                    required
                  />

                  <TextField
                    label="City"
                    id="city"
                    variant="outlined"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={!!formik.touched.city && !!formik.errors.city}
                    helperText={formik.touched.city && formik.errors.city}
                    required
                  />
                  <TextField
                    label="Zip Code"
                    id="zipCode"
                    variant="outlined"
                    name="zipCode"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    error={!!formik.touched.zipCode && !!formik.errors.zipCode}
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                    required
                  />
                  <TextField
                    label="Document"
                    id="document"
                    variant="outlined"
                    name="document"
                    type="file"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formik.values.document}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.document && !!formik.errors.document
                    }
                    helperText={
                      formik.touched.document && formik.errors.document
                    }
                    required
                  />
                </Box>
              </Card>
              <Typography sx={{ p: 1 }} variant="h6">
                Delivery Details
              </Typography>
              <Card
                elevation={0}
                sx={{
                  width: "100%",
                  height: "30%",
                  marginRight: "15px",
                  marginBottom: "20px",
                  padding: 3,
                  border: "1px solid #ccc",
                }}
              >
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "53ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    label="Geo-fence"
                    id="geoFence"
                    variant="outlined"
                    name="geoFence"
                    value={formik.values.geoFence}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.geoFence && !!formik.errors.geoFence
                    }
                    helperText={
                      formik.touched.geoFence && formik.errors.geoFence
                    }
                    required
                  />
                  <TextField
                    label="Payout"
                    id="payout"
                    variant="outlined"
                    name="payout"
                    value={formik.values.payout}
                    onChange={formik.handleChange}
                    error={!!formik.touched.payout && !!formik.errors.payout}
                    helperText={formik.touched.payout && formik.errors.payout}
                    required
                  />
                  <TextField
                    label="Time"
                    id="time"
                    variant="outlined"
                    name="time"
                    value={formik.values.time}
                    onChange={formik.handleChange}
                    error={!!formik.touched.time && !!formik.errors.time}
                    helperText={formik.touched.time && formik.errors.time}
                    required
                  />
                </Box>
              </Card>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3,
                      width: "100px",
                      textTransform: "none",
                      background: "var( --color-secondary)",
                      "&:hover": {
                        backgroundColor: "var( --color-secondary)",
                      },
                    }}
                    onClick={() => setState(true)}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3,
                      width: "100px",
                      textTransform: "none",
                      background: "var(--red)",
                      "&:hover": {
                        backgroundColor: "var(--red)",
                      },
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </>
      )}
    </>
  );
}

export default DeliveryBoy;
