import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  styled,
  MenuItem,
  Select,
} from "@mui/material";
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";
import { addExpense, getAllEmployees } from "../../../sevice/expense";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

// Define styled components
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
}));

// Define validation schema
const validationSchema = Yup.object().shape({
  expenses: Yup.array().of(
    Yup.object().shape({
      reason: Yup.string().required("Reason is required"),
      amount: Yup.number().required("Amount is required"),
      explanation: Yup.string(),
      employee: Yup.string().required("Employee is required"),
      paidfrom: Yup.string(),
    })
  ),
});

export default function Expense({ setShowExpense }) {
  const POSadmin = useSelector((state) => state.POSadmin);
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    // Fetch employees when the component mounts
    const fetchEmployees = async () => {
      try {
        const { employees } = await getAllEmployees(POSadmin?.token);
        setEmployees(employees);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Expense Management
      </Typography>
      <Typography variant="h5" gutterBottom>
        {new Date().toLocaleDateString()}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please fill out the expenses below.
      </Typography>
      <Formik
        initialValues={{
          expenses: [
            {
              reason: "",
              amount: "",
              explanation: "",
              employee: "",
              paidfrom: "",
            },
          ],
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const { status, message } = await addExpense(
              values,
              POSadmin?.token
            );
            if (status) {
              toast.success(message);
              resetForm();
            } else {
              throw new Error(message);
            }
          } catch (error) {
            console.log(error);
            toast.error(error?.message);
          }
        }}
      >
        {({ values, errors, touched }) => (
          <Form autoComplete="off">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Reason</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Explanation</TableCell>
                    <TableCell align="center">Employee</TableCell>
                    <TableCell align="center">Paid From</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="expenses">
                    {({ push, remove }) => (
                      <>
                        {values.expenses.map((expense, index) => (
                          <StyledTableRow key={index}>
                            <TableCell>
                              <Field
                                name={`expenses.${index}.reason`}
                                type="text"
                                as={Select}
                                sx={{ width: "100%" }}
                              >
                                {[
                                  "Salary",
                                  "Advance Salary",
                                  "Petrol",
                                  "Rent",
                                ].map((reason) => (
                                  <MenuItem key={reason} value={reason}>
                                    {reason}
                                  </MenuItem>
                                ))}
                              </Field>

                              {errors.expenses?.[index]?.reason &&
                                touched.expenses?.[index]?.reason && (
                                  <div style={{ color: "red" ,paddingTop:"5px"}}>
                                    {errors.expenses[index].reason}
                                  </div>
                                )}
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`expenses.${index}.amount`}
                                type="number"
                                as={TextField}
                                sx={{ width: "100%" }}
                              />
                              {errors.expenses?.[index]?.amount &&
                                touched.expenses?.[index]?.amount && (
                                  <div style={{ color: "red" ,paddingTop:"5px"}}>
                                    {errors.expenses[index].amount}
                                  </div>
                                )}
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`expenses.${index}.explanation`}
                                type="text"
                                as={TextField}
                                sx={{ width: "100%" }}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`expenses.${index}.employee`}
                                type="text"
                                as={Select}
                                sx={{ width: "100%" }}
                              >
                                {employees.map((employee) => (
                                  <MenuItem
                                    key={employee._id}
                                    value={`${employee?.userName} (${employee?.role})`}
                                  >
                                    {`${employee?.userName} (${employee?.role})`}
                                  </MenuItem>
                                ))}
                              </Field>
                              {errors.expenses?.[index]?.employee &&
                                touched.expenses?.[index]?.employee && (
                                  <div style={{ color: "red",paddingTop:"5px" }}>
                                    {errors.expenses[index].employee}
                                  </div>
                                )}
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`expenses.${index}.paidfrom`}
                                type="text"
                                as={Select}
                                sx={{ width: "100%" }}
                              >
                                <MenuItem value="From Cash">From Cash</MenuItem>
                              </Field>
                            </TableCell>
                            <TableCell>
                              <Button
                                sx={{ width: "100%" }}
                                onClick={() => remove(index)}
                              >
                                Clear
                              </Button>
                            </TableCell>
                          </StyledTableRow>
                        ))}

                        <Button
                          onClick={() =>
                            push({
                              reason: "",
                              amount: "",
                              explanation: "",
                              employee: "",
                              paidfrom: "",
                            })
                          }
                          variant="contained"
                          sx={{
                            m: 3,
                            textTransform: "none",
                            background: "var(--red)",
                            "&:hover": {
                              backgroundColor: "var(--red)",
                            },
                          }}
                        >
                          + Add Row
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
              <Button onClick={() => setShowExpense(false)}>Back</Button>

              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
