import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Modal,
  Paper,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import SearchBar from "../../common/SearchBar";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSelector } from "react-redux";
import { blockUnblock, getMerchant, registerMerchant } from "../../../sevice/userManagement";
import {  MerchantSchema } from "../../../schemas";
import EditMerchant from "./EditMerchant";
import MaterialUISwitch from "./MaterialUISwitch";

const style = {
  width: "90%",
  height: "80%",
  position: "absolute",
  top: "54%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  px: 4,
  py: 2,
  borderRadius: "6px",
  overflow: "auto",
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar
  },
};



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));

function Merchant({ state, setState }) {
  const { POSadmin } = useSelector((state) => state);
  const [editItem, setEditItem] = useState({});
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    userPasscode: "",
    swipeCode: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: MerchantSchema,
    onSubmit: async (values, action) => {
      const data = await registerMerchant(values, POSadmin.token);
      console.log(data);
      if (data.status) {
        toast.success(data.message);
        action.resetForm();
      } else {
        toast.error(data.message);
      }
    },
  });

  const handleSwitchChange = async (status, id) => {
    const res = await blockUnblock(POSadmin.token, id, !status);
    if (res) {
      toast.success(res.message);
      getDatas();
    }
  };

  const getDatas = async () => {
    const data = await getMerchant(POSadmin.token);
    setUsers(data);
  };
  useEffect(() => {
    getDatas();
  }, [state, open]);

  const filteredUsers = users.filter((user) =>
    user.userName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {state && (
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            searchBarWidth={"200px"}
            placeholder={"Search"}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Box>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                background: "var(--red)",
                "&:hover": {
                  backgroundColor: "var(--red)",
                },
                width: "130px",
              }}
              onClick={() => setState(false)}
            >
              + Add
            </Button>
          </Box>
        </Box>
      )}
      {state ? (
        <Box sx={{ marginTop: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead sx={{ background: "var(--table-head)" }}>
                <TableRow>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">User Name</StyledTableCell>
                  <StyledTableCell align="center">User Code</StyledTableCell>
                  <StyledTableCell align="center">Active</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredUsers.map((item, index) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={index}
                    >
                      <TableCell align="center">{item?.first_name}</TableCell>
                      <TableCell align="center">{item.userName}</TableCell>
                      <TableCell align="center">{item.userCode}</TableCell>
                      <TableCell align="center">
                      <MaterialUISwitch
                          checked={item?.isActive}
                          onChange={() =>
                            handleSwitchChange(item.isActive, item._id)
                          }
                          sx={{ marginTop: "0px" }}
                          inputProps={{ "aria-label": "controlled" }}
                          color="error"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <VisibilityIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditItem(item);
                            handleOpen();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <EditMerchant editItem={editItem} handleClose={handleClose} />
            </Box>
          </Modal>
        </Box>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Typography sx={{ p: 3 }} variant="h6">
                Add User
              </Typography>
              <Card
                elevation={0}
                sx={{
                  width: "100%",
                  height: "30%",
                  marginRight: "15px",
                  marginBottom: "20px",
                  padding: 3,
                  border: "1px solid #ccc",
                }}
              >
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "53ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    label="First Name *"
                    id="first_name"
                    variant="outlined"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.first_name && !!formik.errors.first_name
                    }
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                  />
                  <TextField
                    label="Last Name *"
                    id="last_name"
                    variant="outlined"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.last_name && !!formik.errors.last_name
                    }
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                  />
                  <TextField
                    label="Phone *"
                    id="phone"
                    variant="outlined"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={!!formik.touched.phone && !!formik.errors.phone}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                  <TextField
                    label="Email *"
                    id="email"
                    variant="outlined"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={!!formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <TextField
                    label="Password *"
                    id="password"
                    variant="outlined"
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.password && !!formik.errors.password
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                  <TextField
                    label="User Passcode"
                    id="userPasscode"
                    variant="outlined"
                    name="userPasscode"
                    value={formik.values.userPasscode}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.userPasscode &&
                      !!formik.errors.userPasscode
                    }
                    helperText={
                      formik.touched.userPasscode && formik.errors.userPasscode
                    }
                  />
                  <TextField
                    label="Swipe Code"
                    id="swipeCode"
                    variant="outlined"
                    name="swipeCode"
                    value={formik.values.swipeCode}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.swipeCode && !!formik.errors.swipeCode
                    }
                    helperText={
                      formik.touched.swipeCode && formik.errors.swipeCode
                    }
                  />
                </Box>
              </Card>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3,
                      width: "100px",
                      textTransform: "none",
                      background: "var( --color-secondary)",
                      "&:hover": {
                        backgroundColor: "var( --color-secondary)",
                      },
                    }}
                    onClick={() => setState(true)}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3,
                      width: "100px",
                      textTransform: "none",
                      background: "var(--red)",
                      "&:hover": {
                        backgroundColor: "var(--red)",
                      },
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </>
      )}
    </>
  );
}

export default Merchant;
