import axios from "axios";

const host = process.env.REACT_APP_BACKEND_URL;

export const addExpense = async (payload, token) => {
  try {
    const { data } = await axios.post(`${host}/api/v1/merchant/add-expense`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllEmployees = async (token) => {
  try {
    const { data } = await axios.get(`${host}/api/v1/merchant/get-all-employees`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};