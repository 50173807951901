import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField as TextFieldCustom,
  Autocomplete,
  Box,
} from "@mui/material";
import { toast } from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField, Select } from "formik-mui";
import { purchaseSchema } from "../../../schemas";
import { editPurchase } from "../../../sevice/inventory";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));

function EditPurchase({
  editInitialValue,
  editItem,
  handleClose,
  rawmet,
  units,
}) {
  const { POSadmin } = useSelector((state) => state);
  const emptyRaw = {
    name: "",
    quantity: "",
    unit: "",
    price: "",
    amount: "",
    tax: "",
  };
  const submitdata = async (values, action) => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(values));
    formData.append("image", values.invoicefile);

    const data = await editPurchase(POSadmin.token, formData, editItem._id);
    if (data.status) {
      toast.success(data.message);
      handleClose();
      action.resetForm();
    } else {
      toast.error(data.message);
    }
  };
  return (
    <>
      {rawmet.length && (
        <Box
          sx={{
            maxHeight: "80vh",
            overflow: "scroll",
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar
            },
            bgcolor: "white",
            px: 4,
            py: 2,
            boxShadow: 24,
            borderRadius: "5px",
          }}
        >
          <Box>
            <Formik
              initialValues={editInitialValue}
              validationSchema={purchaseSchema}
              onSubmit={submitdata}
            >
              {({ values, setFieldValue, errors }) => (
                <Form autoComplete="off">
                  <Box>
                    <Typography sx={{ p: 3 }} variant="h6">
                      Edit Purchase
                    </Typography>
                    <Card
                      elevation={0}
                      sx={{
                        width: "100%",
                        height: "250px",
                        marginRight: "15px",
                        marginBottom: "20px",
                        padding: 2,
                        border: "1px solid #ccc",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "50%",
                          }}
                        >
                          <InputLabel>Supplier</InputLabel> <br />
                          <Field
                            size="small"
                            fullWidth
                            name={`supplier`}
                            component={TextField}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "49%",
                          }}
                        >
                          <InputLabel>Invoice Date</InputLabel> <br />
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={values.invoiceDate}
                              onChange={(e) => {
                                setFieldValue("invoiceDate", e.$d);
                              }}
                              renderInput={(params) => (
                                <TextFieldCustom
                                  size="small"
                                  defaultShow={new Date()}
                                  {...params}
                                  sx={{
                                    borderRadius: "5px",
                                    width: "100%",
                                    color: "var(--font-black)",
                                    fontSize: "1.1rem",
                                    marginRight: "5px",
                                  }}
                                />
                              )}
                              disableUnderline
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "50%",
                            marginTop: "20px",
                          }}
                        >
                          <InputLabel>Invoice file</InputLabel>
                          <br />
                          <TextFieldCustom
                            type="file"
                            size="small"
                            id="outlined-basic"
                            variant="outlined"
                            onChange={(e) =>
                              setFieldValue("invoicefile", e.target.files[0])
                            }
                            InputProps={{
                              inputProps: {
                                accept: "image/*",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "49%",
                            marginTop: "20px",
                          }}
                        >
                          <InputLabel>Payment Type</InputLabel>
                          <br />
                          <FormControl>
                            <RadioGroup
                              value={values.paymentType}
                              onChange={(e) => {
                                setFieldValue("paymentType", e.target.value);
                              }}
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="Paid"
                                control={<Radio />}
                                label="Paid"
                              />
                              <FormControlLabel
                                value="Unpaid"
                                control={<Radio />}
                                label="Unpaid"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Box>
                    </Card>
                    <TableContainer component={Paper} elevation={0}>
                      <Table
                        sx={{ minWidth: "100%" }}
                        aria-label="simple table"
                      >
                        <TableHead sx={{ background: "var(--table-head)" }}>
                          <TableRow>
                            <StyledTableCell align="center">
                              Name
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Qty
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Unit
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Price
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Amount
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Tax %
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Action
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>

                        <FieldArray name="products">
                          {({ push, remove }) => (
                            <>
                              <TableBody>
                                {values.products.map((_, index) => (
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell align="center">
                                      <FormControl sx={{ m: 1, minWidth: 200 }}>
                                        <Autocomplete
                                          size="small"
                                          options={rawmet}
                                          value={
                                            rawmet.length &&
                                            rawmet.find(
                                              (item) =>
                                                values.products[index].name ===
                                                item._id
                                            )
                                          }
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          renderInput={(params) => (
                                            <Field
                                              component={TextField}
                                              {...params}
                                              value={params._id}
                                              name={`products.${index}.name`}
                                              variant="outlined"
                                              fullWidth
                                            />
                                          )}
                                          onChange={(event, newValue) => {
                                            if (newValue) {
                                              setFieldValue(
                                                `products.${index}.name`,
                                                newValue?._id
                                              );
                                            } else {
                                              setFieldValue(
                                                `products.${index}.name`,
                                                ""
                                              );
                                            }
                                          }}
                                        />
                                      </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Field
                                        size="small"
                                        fullWidth
                                        name={`products.${index}.quantity`}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `products.${index}.quantity`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `products.${index}.amount`,
                                            e.target.value *
                                              values.products[index].price
                                          );
                                        }}
                                        component={TextField}
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <Field
                                          component={Select}
                                          size="small"
                                          name={`products.${index}.unit`}
                                        >
                                          {units.map((item) => (
                                            <MenuItem value={item._id}>
                                              {item.name}
                                            </MenuItem>
                                          ))}
                                        </Field>
                                      </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Field
                                        size="small"
                                        fullWidth
                                        name={`products.${index}.price`}
                                        component={TextField}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `products.${index}.price`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `products.${index}.amount`,
                                            e.target.value *
                                              values.products[index].quantity
                                          );
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <Field
                                        size="small"
                                        fullWidth
                                        onChange={() => {}}
                                        name={`products.${index}.amount`}
                                        component={TextField}
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <Field
                                        size="small"
                                        fullWidth
                                        name={`products.${index}.tax`}
                                        component={TextField}
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button onClick={() => remove(index)}>
                                        <DeleteIcon />
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                              <Button
                                onClick={() => push(emptyRaw)}
                                variant="contained"
                                sx={{
                                  m: 3,
                                  textTransform: "none",
                                  background: "var(--red)",
                                  "&:hover": {
                                    backgroundColor: "var(--red)",
                                  },
                                }}
                              >
                                + Add Row
                              </Button>
                            </>
                          )}
                        </FieldArray>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box></Box>
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          mt: 3,
                          width: "100px",
                          textTransform: "none",
                          background: "var( --color-secondary)",
                          "&:hover": {
                            backgroundColor: "var( --color-secondary)",
                          },
                        }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        variant="contained"
                        sx={{
                          mt: 3,
                          width: "100px",
                          textTransform: "none",
                          background: "var(--red)",
                          "&:hover": {
                            backgroundColor: "var(--red)",
                          },
                        }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      )}
    </>
  );
}

export default EditPurchase;
