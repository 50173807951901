import React, { useState } from "react";
import "./Alert.css";
import { Button } from "@mui/material";

export default function AlertButton({
  element,
  message,
  type,
  onConfirm,
  data,
  style,
}) {
  const [open, setOpen] = useState(false);
  const handleOnClose = () => setOpen(false);
  return (
    <div>
      <Button
        sx={{ ...style }}
        style={{ textTransform: "none" }}
        onClick={() => setOpen(true)}
      >
        {element}
      </Button>
      {open && (
        <Alert
          type={type}
          message={message}
          onClose={handleOnClose}
          onConfirm={onConfirm}
          data={data}
        />
      )}
    </div>
  );
}

const Alert = ({ type, message, onClose, onConfirm, data }) => {
  return (
    <div className="shopewell-alert">
      <div className="shopewell-alert-box" style={{ width: "500px" }}>
        <div className="shopewell-alert-content" style={{ display: "flex" }}>
          {message}
        </div>
        <div className="shopewell-alert-btn-container">
          {type !== "alert" && (
            <button
              onClick={onClose}
              type="button"
              className="shopewell-alert-cancel"
            >
              Cancel
            </button>
          )}
          <button
            onClick={() => {
              type !== "alert" && onConfirm(data);
              onClose();
            }}
            type="button"
            className="shopewell-alert-ok"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};
// export default Alert;
