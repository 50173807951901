import {
  Box,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function SalesView({ saleData, close }) {
  console.log(saleData);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "40px",
          px: 2.5,
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"}>
          Invoice No #{saleData.invoiceNo}
        </Typography>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Box
            sx={{
              px: 1,
              py: 0.5,
              border: "solid var(--border-gray) 1px",
              borderRadius: "5px",

              background:
                saleData.orderStatus === "Pending"
                  ? "#EEDD82"
                  : saleData.orderStatus === "Preparing"
                  ? "#FFCC99"
                  : saleData.orderStatus === "Ready"
                  ? "#CCE5FF"
                  : saleData.orderStatus === "Delivered"
                  ? "#cfead9"
                  : saleData.orderStatus === "Cancelled"
                  ? "#FF9999"
                  : "#E0E0E0",
            }}
          >
            {saleData.orderStatus}
          </Box>
          <CloseIcon onClick={close} />
        </Box>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", px: 2.5 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            width: "50%",
          }}
        >
          <Box>
            <Typography fontWeight={"bold"}>Name</Typography>
            <Typography>
              {saleData?.deliveryDetails?.name
                ? saleData?.deliveryDetails?.name
                : "Null"}
            </Typography>
          </Box>{" "}
          <Box>
            <Typography fontWeight={"bold"}>Payment Method</Typography>
            <Typography>{saleData?.paymentMethod}</Typography>

            {saleData?.paymentMethod === "UPI" &&
              saleData?.paymentGateway?.map((item) => (
                <Typography>({item?.paymentId})</Typography>
              ))}
          </Box>
          <Box>
            <Typography fontWeight={"bold"}>Gst Amount</Typography>
            <Typography>{saleData?.gstAmount}</Typography>
          </Box>{" "}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            width: "50%",
          }}
        >
          <Box>
            <Typography fontWeight={"bold"}>Phone</Typography>
            <Typography>
              {saleData?.deliveryDetails?.mobile
                ? saleData?.deliveryDetails?.mobile
                : "Null"}
            </Typography>
          </Box>{" "}
          <Box>
            <Typography fontWeight={"bold"}>Type of Order</Typography>
            <Typography>{saleData?.typeofOrder}</Typography>
          </Box>
          <Box>
            <Typography fontWeight={"bold"}>Grand Total</Typography>
            <Typography>{saleData?.grandTotal}</Typography>
          </Box>
        </Box>
      </Box>
      {saleData.typeofOrder === "Delivery" && (
        <Box
          sx={{
            px: 2.5,
          }}
        >
          <Typography sx={{ mb: 1, fontWeight: "bold" }}>
            Shipping details
          </Typography>
          <Typography>
            {saleData?.deliveryDetails?.addressId?.address}
          </Typography>
        </Box>
      )}

      <Divider />
      <Box>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  marginLeft: 5,
                }}
              >
                <TableCell sx={{ fontWeight: "bold", ml: 1 }}>
                  Product
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="right">
                  Quantity
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="right">
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {saleData?.products?.map((row) => (
                <>
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.varientId.productName}
                    </TableCell>
                    <TableCell align="right">{row.quantity}</TableCell>
                    <TableCell align="right">
                      {row.quantity * row.salePrice}
                    </TableCell>
                  </TableRow>
                  {row.addOns?.length
                    ? row?.addOns?.map((item) => (
                        <TableRow
                          key={item._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {item.addOnName} ({item.name})
                          </TableCell>
                          <TableCell align="right">{item.quantity}</TableCell>

                          <TableCell align="right">
                            {item.quantity * Number(item.price)}
                          </TableCell>
                        </TableRow>
                      ))
                    : ""}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
