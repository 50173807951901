import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import KotCard from "./KotCard";
import { getAllKotProducts } from "../../../sevice/OrderAndKot";

export default function AllKotOrders({
  type,
  query,
  keys,
  allkotProducts,
  fetchAllkots,
}) {
  const { POSadmin } = useSelector((state) => state);
  // const [allkotProducts, setAllkotProducts] = useState([]);

  return (
    <div>
      <Box sx={{ px: "20px" }}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "start",
              alignContent: "start",
            }}
          >
            {allkotProducts
              ?.filter((item) =>
                keys.some((key) => item[key]?.toLowerCase()?.includes(query))
              )
              ?.map((item, index) => (
                <Box>
                  {type === "All" ? (
                    <KotCard item={item} fetchAllkots={fetchAllkots} />
                  ) : (
                    item.typeofOrder == type && (
                      <KotCard item={item} fetchAllkots={fetchAllkots} />
                    )
                  )}
                </Box>
              ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
