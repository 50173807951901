import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const posAdminLogin = async (datas) => {
  try {
    const { data } = await axios.post(`${host}/posAdminLogin`, datas);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

// export const addProductImages = async (datas, token) => {
//   try {
//     const { data } = await axios.post(`${host}/addProductImages`, datas, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return data;
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

// export const deleteProductImages = async (datas, token) => {
//   try {
//     const { data } = await axios.post(
//       `${host}/deleteProductImages`,
//       { images: datas },
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );
//     return data;
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

// export const addProduct = async (datas, token) => {
//   try {
//     const { data } = await axios.post(`${host}/addProduct`, datas, {
//       headers: { Authorization: `Bearer ${token}` },
//     });
//     return data;
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

export const posAdminData = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posFindAdminData`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const posUpdateProfile = async (value, id, token) => {
  try {
    const { data } = await axios.patch(
      `${host}/posUpdateProfile/${id}`,
      value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateShopStatus = async (value, token) => {
  try {
    const { data } = await axios.patch(`${host}/updateShopStatus`, value, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const posGetShopStatus = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetShopStatus`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllDepartment = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posFindAllDepartment`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const findAllCategory = async (id, token) => {
  try {
    const { data } = await axios.get(`${host}/posFindAllCategory/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const findAllSubCategory = async (id, token) => {
  try {
    const { data } = await axios.get(`${host}/posFindAllSubCategory/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllGroupsForBanner = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetAllGroupsForBanner`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getProductTitle = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetProductTitle`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const addSubBanner = async (token, formData) => {
  try {
    const { data } = await axios.post(`${host}/posAddSubBanner`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const addMainBanner = async (token, formData) => {
  try {
    const { data } = await axios.post(`${host}/posAddMainBanner`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getSubBanner = async (id, token) => {
  try {
    const { data } = await axios.get(`${host}/posGetSubBanner/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllBanner = async (id, token) => {
  try {
    const { data } = await axios.get(`${host}/posGetMainBanner/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const addRawMaterial = async (formData, token) => {
  try {
    const { data } = await axios.post(`${host}/posAddRawMaterial`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const fetchAllVarientProduct = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posFetchAllVarientProduct`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const vendorAllProducts = async (token, pageNo, search) => {
  try {
    const { data } = await axios.get(
      `${host}/posVendorAllProducts?search=${search}&pageNo=${pageNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

////////////////////Group////////////////////
export const createGroup = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/posCreateGroup`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateGroup = async (datas, id, token) => {
  try {
    const { data } = await axios.put(`${host}/posUpdateGroup/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllGroups = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetAllGroups`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getSingleGroup = async (id, token) => {
  try {
    const { data } = await axios.get(`${host}/posGetSingleGroup/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

////////////////////order////////////////////

export const fetchPendingOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posFetchPendingOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const fetchPreparingOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posFetchPreparingOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const fetchReadyOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posFetchReadyOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const fetchDeliveredOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posFetchDeliveredOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const fetchDeliveryBoys = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posFetchDeliveryBoys`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const changeOrderStatus = async (value, id, token) => {
  try {
    const { data } = await axios.put(
      `${host}/posChangeOrderStatus/${id}`,
      value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const createTable = async (values, token) => {
  try {
    const { data } = await axios.post(`${host}/posCreateTable`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllTables = async (id, token) => {
  try {
    const { data } = await axios.get(`${host}/posGetAllTables/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const createTableArea = async (values, token) => {
  try {
    const { data } = await axios.post(`${host}/posCreateTableArea`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllTableAreas = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetAllTableAreas`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editTable = async (token, value, id) => {
  try {
    const { data } = await axios.put(
      `${host}/posEditTable/${id}`,
      { ...value },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editTableArea = async (token, value, id) => {
  try {
    const { data } = await axios.put(
      `${host}/posEditTableArea/${id}`,
      { ...value },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteTable = async (token, id) => {
  try {
    const { data } = await axios.delete(`${host}/posDeleteTable/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteTableArea = async (token, id) => {
  try {
    const { data } = await axios.delete(`${host}/posDeleteTableArea/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

/////////////////////////////////// forgot password /////////////////////

export const findUserForResetPassword = async (email) => {
  try {
    const { data } = await axios.post(
      `${host}/findUserForResetPassword`,
      email
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const sendResetPasswordCode = async (email) => {
  try {
    const { data } = await axios.post(`${host}/sendResetPasswordCode`, {
      email,
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const verifyResetPasswordCode = async (email, code) => {
  try {
    const { data } = await axios.post(`${host}/verifyResetPasswordCode`, {
      email,
      code,
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const changePassword = async (formData) => {
  try {
    const { data } = await axios.put(`${host}/changePassword`, formData);
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const changePasswordByOldPassword = async (token, formData) => {
  try {
    const { data } = await axios.put(
      `${host}/changePasswordByOldPassword`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};
