import React, { createContext, useContext, useState } from "react";

const EditDataContext = createContext();

export const useEditData = () => useContext(EditDataContext);

export const EditDataProvider = ({ children }) => {
  const [editdata, setEditdata] = useState();
  const [editVariationData, setEditVariationData] = useState();

  return (
    <EditDataContext.Provider value={{ editdata, setEditdata, editVariationData, setEditVariationData }}>
      {children}
    </EditDataContext.Provider>
  );
};
