import { Box, Button, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { editPrintingIp } from "../../../sevice/settings";
import { printingIpSchema } from "../../../schemas";

function EditPrintingIp({ onClose, editItem }) {
  const { POSadmin } = useSelector((state) => state);
  const initialValues = {
    name: editItem.name,
    ip: editItem.ip,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: printingIpSchema,
    onSubmit: async (values, action) => {
      const data = await editPrintingIp(values, POSadmin.token, editItem?._id);
      if (data.status) {
        toast.success(data.message);
        onClose();
      } else {
        toast.error(data.message);
      }
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Typography
        sx={{ pb: 2, textAlign: "center", fontFamily: "poppins" }}
        variant="h6"
      >
        Edit Printing Ip
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="Printer Name"
          id="name"
          variant="outlined"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          label="Printer Ip"
          id="ip"
          variant="outlined"
          name="ip"
          value={formik.values.ip}
          onChange={formik.handleChange}
          error={!!formik.touched.ip && !!formik.errors.ip}
          helperText={formik.touched.ip && formik.errors.ip}
        />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            style={{
              width: "100px",
              textTransform: "none",
              background: "var(--red)",
            }}
            type="submit"
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default EditPrintingIp;
