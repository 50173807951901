import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { BiFastForwardCircle, BiFoodTag } from "react-icons/bi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import { useSelector } from "react-redux";
import AlertButton from "../../../Alert/Alert";
import SearchBar from "../../common/SearchBar";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  styled,
  Button,
  Switch,
  TextField,
  Radio,
  RadioGroup,
  Pagination,
  Stack,
  Grid,
  Modal,
} from "@mui/material";
import {
  PosAdmindeleteProduct,
  addProductImages,
  deleteProductImages,
  deleteVariation,
  updateProduct,
} from "../../../sevice/product";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
} from "@material-ui/core";
import EditVariation from "./EditVariation";
import SelectVariationModal from "./SelectVariationModal";
import { style, styleVariationTable } from "./Style";
import { useEditData } from "../../../context/EditDataContext";
import EditVariationtableModal from "./EditVariationtableModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: "initial",
  },
});

let initialVariationOption = {
  title: "",
  titleAr: "",
  items: [{ option: "", optionAr: "" }],
};

function extractPropertiesAndOptions(data) {
  const extractedData = [];
  for (let i = 1; i <= 5; i++) {
    const propertyTitle = data[`property${i}`];
    const propertyTitleAr = data[`property${i}Ar`];
    if (propertyTitle) {
      const propertyArray = [
        { title: propertyTitle, titleAr: propertyTitleAr },
      ];
      const uniqueOptions = new Set();
      for (const variant of data.varients) {
        const value = variant?.varientId?.[`value${i}`];
        if (value !== undefined && !uniqueOptions.has(value)) {
          uniqueOptions.add(value);
          propertyArray.push({ option: value });
        }
      }
      extractedData.push(propertyArray);
    }
  }
  return extractedData;
}

function Row(props) {
  const {
    row,
    index,
    editFieldOpen,
    setEditFieldOpen,
    inventoryUpdate,
    setOldImage,
    sentMessage,
    pagingCounter,
    setEditdata,
    setEditVariationData,
    editdata,
    editVariationData,
  } = props;
  const { POSadmin, isLanguageSwitched } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [variationOption, setVariationOption] = useState(
    initialVariationOption
  );
  const [allProperty, setAllProperty] = useState([]);
  const [product, setproduct] = useState([]);

  useEffect(() => {
    editdata && setAllProperty(extractPropertiesAndOptions(editdata));
  }, [editdata]);


  //////////////////////////////variation table////////////////////////////
  const [openVatiationTable, setOpenVatiationTable] = useState(false);
  function handleOpenVatiationTable() {
    setOpenVatiationTable(true);
  }
  function handleCloseVatiationTable() {
    setOpenVatiationTable(false);
  }
  //////////////////////////////Select variation////////////////////////
  const [openVariationSelect, setOpenVariationSelect] = useState(false);
  const handleOpenVariationSelect = () => setOpenVariationSelect(true);
  const handleCloseVariationSelect = () => setOpenVariationSelect(false);

  //////////////////////////////add variation////////////////////////////
  const [openAddVatiation, setOpenAddVatiation] = useState(false);
  const handleCloseAddVatiation = () => setOpenAddVatiation(false);
  const handleOpenOption = (title) => {
    handleCloseAddVatiation();
    initialVariationOption = {
      ...initialVariationOption,
      title: title.title,
      titleAr: title.titleAr,
    };
    handleOpenModal(true);
    setEditData(null);
  };

  const handleOpenVariationOptions = (title, titleAr) => {
    if (title === "Add Variation") {
      handleCloseVariationSelect();
      setOpenAddVatiation(true);
    } else {
      handleCloseVariationSelect();
      setVariationOption((prevState) => ({
        ...prevState,
        title: title,
        titleAr: titleAr,
      }));
      console.log("form here", variationOption);
      handleOpenModal(true);
      setEditData(null);
    }
  };

  const handleOpenModal = () => {
    setIsEdit(true);
  };

  const handleCloseModal = () => {
    setIsEdit(false);
  };

  const showEditField = (id) => {
    setEditFieldOpen(id);
  };
  const cancelEditForm = async () => {
    if (uploadedImage.length >= 1) {
      await deleteProductImages(uploadedImage, POSadmin.token);
    }
    setEditFieldOpen("");
    setOldImage([]);
  };

  const handleEditData = (title, titleAr, items) => {
    setEditData({
      title: title,
      titleAr: titleAr,
      items: items,
    });
    handleOpenModal();
  };

  const updateVariationValue = (value, field, variantIndex) => {
    const newTable = editVariationData?.map((item, index) => {
      if (variantIndex === index) {
        if (field == "productName") {
          let letter = value.slice(0, 4).toUpperCase();
          let digit = Date.now().toString().slice(8, 13);
          return { ...item, [field]: value, sku: letter + digit };
        } else {
          return { ...item, [field]: value };
        }
      }
      return item;
    });
    setEditVariationData(newTable);
  };

  const uploadVarientImage = async (e, image, indexNo) => {
    setOldImage(image);
    const formData = new FormData();
    formData.append("images", e);
    const data = await addProductImages(formData, POSadmin.token);
    if (data.status) {
      const newTable = editVariationData.map((item, index) => {
        return { ...item, image: data.images };
      });
      setUploadedImage(data.images);
      setEditVariationData(newTable);
    }
  };
  const setEditVariationFunction = (variation) => {
    const data = variation.map((item) => {
      return item.varientId;
    });
    setEditVariationData(data);
  };
  const deleteVariationAction = async (id) => {
    const { proId, qty, varId } = id;
    const data = await deleteVariation(proId, qty, varId, POSadmin.token);
    sentMessage(data);
  };
  const deleteProductAction = async (proId) => {
    const data = await PosAdmindeleteProduct(proId, POSadmin.token);
    sentMessage(data);
  };

  const changeVarirantName = (title) => {
    const newTable = editVariationData?.map((item, index) => {
      let property = [
        item.value1,
        item.value2,
        item.value3,
        item.value4,
        item.value5,
      ]
        .filter(Boolean)
        .join(" ");
      let proName = property
        ? `${title.replace(/\s+/g, " ").trim()} ${property}`
        : title;
      let letter = proName.slice(0, 4).toUpperCase();
      let digit = Date.now().toString().slice(8, 13) + index;
      let sku = (letter + digit).replace(/\s/g, "");
      return { ...item, productName: proName, sku: sku };
    });

    setEditVariationData(newTable);
  };
  const changeVarirantNameAr = (titleAr) => {
    const newTable = editVariationData.map((item, index) => {
      let property = [
        item.value1Ar,
        item.value2Ar,
        item.value3Ar,
        item.value4Ar,
        item.value5Ar,
      ]
        .filter(Boolean)
        .join(", ");
      let proName = property
        ? `${titleAr.replace(/\s+/g, " ").trim()}, ${property}`
        : titleAr;
      return { ...item, productNameAr: proName };
    });

    setEditVariationData(newTable);
  };
  return (
    <>
      {editFieldOpen === row._id ? (
        <>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell align="left">{pagingCounter + index}</TableCell>

            <TableCell align="center">
              <Box
                sx={{
                  width: "60px",
                  height: "60px",
                }}
              >
                <input
                  name="logo"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      uploadVarientImage(
                        e.target.files[0],
                        editVariationData[0]?.image
                      );
                    }
                  }}
                  type="file"
                  accept="image/*"
                  id="logo"
                  style={{ display: "none" }}
                />
                <label htmlFor="logo">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={editVariationData[0]?.image[0]}
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      bgcolor: "red",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      position: "relative",
                      top: -40,
                      left: 20,
                    }}
                  >
                    +
                  </Box>
                </label>
              </Box>
            </TableCell>
            <TableCell align="center">
              <TextField
                value={editdata?.title}
                size="small"
                id="outlined-basic"
                variant="outlined"
                sx={{ minWidth: "180px" }}
                onChange={(e) => {
                  setEditdata({
                    ...editdata,
                    title: e.target.value,
                  });
                  changeVarirantName(e.target.value);
                }}
              />
              {isLanguageSwitched && (
                <TextField
                  value={editdata?.titleAr}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ minWidth: "180px", mt: 1 }}
                  inputProps={{ style: { textAlign: "right" } }}
                  onChange={(e) => {
                    setEditdata({
                      ...editdata,
                      titleAr: e.target.value,
                    });
                    changeVarirantNameAr(e.target.value);
                  }}
                />
              )}
            </TableCell>
            <TableCell align="center">
              <FormControl component="fieldset">
                <RadioGroup
                  value={editdata.vegOrNonVeg}
                  row
                  aria-label="position"
                  name="vegOrNonVeg"
                  defaultValue="top"
                >
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        variant="solid"
                        value={"Veg"}
                        onChange={(e) => {
                          setEditdata({
                            ...editdata,
                            vegOrNonVeg: e.target.value,
                          });
                        }}
                      />
                    }
                    label="Veg"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        variant="solid"
                        value={"NonVeg"}
                        onChange={(e) => {
                          setEditdata({
                            ...editdata,
                            vegOrNonVeg: e.target.value,
                          });
                        }}
                      />
                    }
                    label="Non Veg"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        variant="solid"
                        value={"Other"}
                        onChange={(e) => {
                          setEditdata({
                            ...editdata,
                            vegOrNonVeg: e.target.value,
                          });
                        }}
                      />
                    }
                    label="Other"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </TableCell>
            <TableCell align="center">price</TableCell>
            <TableCell align="center">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                {editdata?.varients.length}
              </IconButton>
            </TableCell>

            <TableCell align="right" style={{ maxWidth: "200px" }}>
              <FormControl component="fieldset">
                <FormGroup
                  aria-label="position"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mx: "auto",
                  }}
                  row
                >
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "12.5px" },
                    }}
                    checked={editdata.delivery}
                    control={<Checkbox size="small" />}
                    label="Delivery"
                    labelPlacement="end"
                    onChange={(e) => {
                      setEditdata({
                        ...editdata,
                        delivery: e.target.checked,
                      });
                    }}
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "13.5px" },
                    }}
                    checked={editdata.pickup}
                    control={<Checkbox size="small" />}
                    label="Pickup"
                    labelPlacement="end"
                    onChange={(e) => {
                      setEditdata({
                        ...editdata,
                        pickup: e.target.checked,
                      });
                    }}
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "13px" },
                    }}
                    checked={editdata.dineIn}
                    control={<Checkbox size="small" />}
                    label="Dine in"
                    labelPlacement="end"
                    onChange={(e) => {
                      setEditdata({
                        ...editdata,
                        dineIn: e.target.checked,
                      });
                    }}
                  />
                </FormGroup>
              </FormControl>
            </TableCell>
            <TableCell align="center">
              <Switch
                sx={{ marginTop: "0px" }}
                checked={editdata.isVisible}
                onChange={() =>
                  setEditdata({
                    ...editdata,
                    isVisible: !editdata.isVisible,
                  })
                }
                inputProps={{ "aria-label": "controlled" }}
                color="warning"
              />
            </TableCell>
            <TableCell align="center">
              <Switch
                sx={{ marginTop: "0px" }}
                checked={editdata.bestSeller}
                onChange={() =>
                  setEditdata({
                    ...editdata,
                    bestSeller: !editdata.bestSeller,
                  })
                }
                inputProps={{ "aria-label": "controlled" }}
                color="warning"
              />
            </TableCell>
            <TableCell align="center">
              <Box>
                <Button
                  sx={{
                    width: "20px",
                    color: "var(--button-gray)",
                  }}
                  onClick={() => cancelEditForm()}
                >
                  <HighlightOffIcon />
                </Button>
                <Button
                  sx={{
                    width: "20px",
                    color: "var(--button-green)",
                  }}
                  onClick={() => inventoryUpdate()}
                >
                  <CheckCircleIcon />
                </Button>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                background: "var(--bg-primary)",
              }}
              colSpan={12}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6" gutterBottom component="div">
                        Variations
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        style={{ backgroundColor: "red" }}
                        variant="contained"
                        onClick={() => handleOpenVariationSelect()}
                        endIcon={<AddIcon />}
                      >
                        Add Variation
                      </Button>
                    </Grid>
                  </Grid>
                  {/* //////////////////////////////Select variation modal//////////////////////// */}
                  <Modal
                    open={openVariationSelect}
                    onClose={handleCloseVariationSelect}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <SelectVariationModal
                        product={product}
                        setproduct={setproduct}
                        allProperty={allProperty}
                        setAllProperty={setVariationOption}
                        close={handleCloseVariationSelect}
                        open={handleOpenVariationOptions}
                      />
                    </Box>
                  </Modal>
                  {/* ////////////////////////////// add/edit variation modal//////////////////////// */}

                  <Modal
                    open={isEdit} // Control modal visibility with the isEdit state
                    onClose={handleCloseModal} // Close modal when clicked outside or on the close button
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                      }}
                    >
                      <EditVariation
                        isEdit={isEdit}
                        SetIsEdit={setIsEdit}
                        editVariationIndex={1}
                        setproduct={setproduct}
                        allProperty={allProperty}
                        setAllProperty={setAllProperty}
                        close={handleCloseModal}
                        initialVariationOptionupdation={editData}
                        initialVariationOption={variationOption}
                        editdata={editdata}
                        handleOpenVatiationTable={handleOpenVatiationTable}
                      />{" "}
                    </Box>
                  </Modal>
                  {/* //////////////////////////////variation modal tabel //////////////////////// */}
                  <Modal
                    open={openVatiationTable}
                    onClose={handleCloseVatiationTable}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleVariationTable}>
                      <EditVariationtableModal
                        close={handleCloseVatiationTable}
                        productData={product}
                      />
                    </Box>
                  </Modal>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">No</StyledTableCell>
                        <StyledTableCell align="center">
                          Product Name
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Sale Price
                        </StyledTableCell>
                        {editdata.property1 && (
                          <StyledTableCell align="center">
                            {editdata.property1}
                            {/* <IconButton
                              onClick={() =>
                                handleEditData(
                                  editdata.property1,
                                  "",
                                  editdata?.varients.map((variant) => ({
                                    option: variant.varientId.value1,
                                    optionAr: "",
                                  }))
                                )
                              }
                            >
                             <EditIcon />
                            </IconButton> */}
                          </StyledTableCell>
                        )}

                        {editdata.property2 && (
                          <StyledTableCell align="center">
                            {editdata.property2}
                            {/* <IconButton
                              onClick={() =>
                                handleEditData(
                                  editdata.property2,
                                  "",
                                  editdata?.varients.map((variant) => ({
                                    option: variant.varientId.value2,
                                    optionAr: "",
                                  }))
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton> */}
                          </StyledTableCell>
                        )}
                        {editdata.property3 && (
                          <StyledTableCell align="center">
                            {editdata.property3}
                            {/* <IconButton
                              onClick={() =>
                                handleEditData(
                                  editdata.property3,
                                  "",
                                  editdata?.varients.map((variant) => ({
                                    option: variant.varientId.value3,
                                    optionAr: "",
                                  }))
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton> */}
                          </StyledTableCell>
                        )}
                        {editdata.property4 && (
                          <StyledTableCell align="center">
                            {editdata.property4}
                            {/* <IconButton>
                              <EditIcon />
                            </IconButton> */}
                          </StyledTableCell>
                        )}
                        {editdata.property5 && (
                          <StyledTableCell align="center">
                            {editdata.property5}
                            {/* <IconButton>
                              <EditIcon />
                            </IconButton> */}
                          </StyledTableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {editVariationData?.map((varientsRow, index) => (
                        <TableRow key={varientsRow._id}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="center"
                            // style={{ display: "flex", flexDirection: "column" }}
                          >
                            <TextField
                              value={varientsRow.productName}
                              sx={{ minWidth: "180px" }}
                              size="small"
                              id="outlined-basic"
                              variant="outlined"
                              onChange={(e) =>
                                updateVariationValue(
                                  e.target.value,
                                  "productName",
                                  index
                                )
                              }
                            />
                            {isLanguageSwitched && (
                              <div>
                                <TextField
                                  value={varientsRow.productNameAr}
                                  sx={{ minWidth: "180px", mt: 1 }}
                                  size="small"
                                  id="outlined-basic"
                                  inputProps={{ style: { textAlign: "right" } }}
                                  variant="outlined"
                                  onChange={(e) =>
                                    updateVariationValue(
                                      e.target.value,
                                      "productNameAr",
                                      index
                                    )
                                  }
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              value={varientsRow.salePrice}
                              type={"number"}
                              sx={{ width: "130px" }}
                              size="small"
                              id="outlined-basic"
                              variant="outlined"
                              onChange={(e) =>
                                updateVariationValue(
                                  e.target.value,
                                  "salePrice",
                                  index
                                )
                              }
                            />
                          </TableCell>
                          {editdata.property1 && (
                            <TableCell align="center">
                              {varientsRow.value1}
                            </TableCell>
                          )}
                          {editdata.property2 && (
                            <TableCell align="center">
                              {varientsRow.value2}
                            </TableCell>
                          )}
                          {editdata.property3 && (
                            <TableCell align="center">
                              {varientsRow.value3}
                            </TableCell>
                          )}
                          {editdata.property4 && (
                            <TableCell align="center">
                              {varientsRow.value4}
                            </TableCell>
                          )}
                          {editdata.property5 && (
                            <TableCell align="center">
                              {varientsRow.value5}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ) : (
        <>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell align="left">{pagingCounter + index}</TableCell>
            <TableCell align="center">
              <img
                src={row.varients[0]?.varientId?.image[0]}
                alt=""
                srcset=""
                style={{ height: "60px", width: "60px" }}
              />
            </TableCell>
            <TableCell align="center">{row?.title}</TableCell>
            <TableCell align="center">
              {" "}
              <BiFoodTag
                style={{
                  width: "20px",
                  height: "20px",
                  color:
                    row.vegOrNonVeg == "Veg"
                      ? "var(--green-color)"
                      : row.vegOrNonVeg == "NonVeg"
                      ? "var(--red)"
                      : "var(--bg-blue)",
                }}
              />
            </TableCell>
            <TableCell align="center">
              <span style={{ fontSize: "11px" }}>{POSadmin.symbol} </span>
              <span style={{ fontSize: "15px" }}>
                {Math.max.apply(
                  null,
                  row?.varients?.map((item) => item?.varientId?.salePrice)
                )}
              </span>
            </TableCell>
            <TableCell align="center">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                {row?.varients?.length}
              </IconButton>
            </TableCell>
            <TableCell align="right" style={{ maxWidth: "200px" }}>
              <FormControl component="fieldset">
                <FormGroup
                  aria-label="position"
                  row
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mx: "auto",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "12.5px" },
                    }}
                    checked={row.delivery}
                    control={<Checkbox size="small" />}
                    label="Delivery"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "13.7px" },
                    }}
                    checked={row.pickup}
                    control={<Checkbox size="small" />}
                    label="Pickup"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "13px" },
                    }}
                    checked={row.dineIn}
                    control={<Checkbox size="small" />}
                    label=" Dine in"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </TableCell>
            <TableCell align="center">
              <Switch
                sx={{ marginTop: "0px" }}
                checked={row.isVisible}
                inputProps={{ "aria-label": "controlled" }}
                color="warning"
              />
            </TableCell>
            <TableCell align="center">
              <Switch
                sx={{ marginTop: "0px" }}
                checked={row.bestSeller}
                inputProps={{ "aria-label": "controlled" }}
                color="warning"
              />
            </TableCell>
            <TableCell align="right">
              <Box sx={{ display: "flex" }}>
                <Button
                  sx={{
                    width: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    showEditField(row._id);
                    setEditdata(row);
                    setEditVariationFunction(row.varients);
                  }}
                >
                  <DescriptionIcon />
                </Button>
                <AlertButton
                  element={<DeleteIcon />}
                  type={"confirmation"}
                  message={"Are you sure? You won't be able to revert this!"}
                  onConfirm={deleteProductAction}
                  data={row._id}
                  style={{ width: "20px", color: "var(--red)" }}
                />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                background: "var(--bg-primary)",
              }}
              colSpan={12}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Variations
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">No</StyledTableCell>
                        <StyledTableCell align="center">
                          Product Name
                        </StyledTableCell>
                        <StyledTableCell align="center">SKU</StyledTableCell>
                        <StyledTableCell align="center">
                          Sale Price
                        </StyledTableCell>
                        {row.property1 && (
                          <StyledTableCell align="center">
                            {row.property1}
                          </StyledTableCell>
                        )}
                        {row.property2 && (
                          <StyledTableCell align="center">
                            {row.property2}
                          </StyledTableCell>
                        )}
                        {row.property3 && (
                          <StyledTableCell align="center">
                            {row.property3}
                          </StyledTableCell>
                        )}
                        {row.property4 && (
                          <StyledTableCell align="center">
                            {row.property4}
                          </StyledTableCell>
                        )}
                        {row.property5 && (
                          <StyledTableCell align="center">
                            {row.property5}
                          </StyledTableCell>
                        )}
                        <StyledTableCell align="right">Delete</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.varients.map((varientsRow, index) => (
                        <TableRow key={varientsRow?.varientId?._id}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "10px" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {varientsRow?.varientId?.productName}
                          </TableCell>
                          <TableCell align="center">
                            {varientsRow?.varientId?.sku}
                          </TableCell>
                          <TableCell align="center">
                            <span style={{ fontSize: "11px" }}>
                              {POSadmin.symbol}{" "}
                            </span>
                            {varientsRow?.varientId?.salePrice}
                          </TableCell>
                          {row.property1 && (
                            <TableCell align="center">
                              {varientsRow?.varientId?.value1}
                            </TableCell>
                          )}
                          {row.property2 && (
                            <TableCell align="center">
                              {varientsRow?.varientId?.value2}
                            </TableCell>
                          )}
                          {row.property3 && (
                            <TableCell align="center">
                              {varientsRow?.varientId?.value3}
                            </TableCell>
                          )}
                          {row.property4 && (
                            <TableCell align="center">
                              {varientsRow?.varientId?.value4}
                            </TableCell>
                          )}
                          {row.property5 && (
                            <TableCell align="center">
                              {varientsRow.varientId?.value5}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            <AlertButton
                              element={<DeleteIcon />}
                              type={"confirmation"}
                              message={
                                "Are you sure? You won't be able to revert this!"
                              }
                              onConfirm={deleteVariationAction}
                              data={{
                                proId: row._id,
                                qty: row.varients.length,
                                varId: varientsRow?.varientId?._id,
                              }}
                              style={{ width: "20px", color: "var(--red)" }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

export default function InventoryTable({
  allProduct,
  sentMessage,
  setPageNo,
  pageNo,
  setSearchQuery,
}) {
  const classes = useStyles();
  const { POSadmin } = useSelector((state) => state);
  const { editdata, setEditdata, editVariationData, setEditVariationData } =
    useEditData();

  const [editFieldOpen, setEditFieldOpen] = useState("");
  // const [editdata, setEditdata] = useState();
  // const [editVariationData, setEditVariationData] = useState();
  const [oldImage, setOldImage] = useState([]);

  const inventoryUpdate = async () => {
    const data = await updateProduct(
      editdata,
      editVariationData,
      POSadmin.token
    );

    if (data.status) {
      if (oldImage && oldImage.length >= 1) {
        await deleteProductImages(oldImage, POSadmin.token);
      }
      setEditFieldOpen("");
      setOldImage([]);
      sentMessage(data);
    }
  };
  const handleChangePageNo = (event, value) => {
    setPageNo(value);
  };
  return (
    <>
      <Box sx={{ width: "300px", padding: 1 }}>
        <SearchBar
          searchBarWidth={"200px"}
          placeholder={"Search"}
          onChange={(newValue) => {
            setSearchQuery(newValue.target.value);
            setPageNo(1);
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          borderRight: "solid var(--border-gray) 1px",
        }}
      >
        <TableContainer
          component={Paper}
          classes={{ root: classes.customTableContainer }}
        >
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: "30px" }} align="center">
                  Sl.No
                </StyledTableCell>
                <StyledTableCell sx={{ width: "40px" }} align="center">
                  Image
                </StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell
                  sx={{ width: "10px" }}
                  align="center"
                ></StyledTableCell>

                <StyledTableCell align="center" sx={{ width: "180px" }}>
                  Price
                </StyledTableCell>
                <StyledTableCell sx={{ width: "20px" }} align="center">
                  Variation
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ width: { lg: "300px", md: "180px", sm: "200px" } }}
                >
                  Type
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "80px" }}>
                  Active
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "80px" }}>
                  Best Seller
                </StyledTableCell>
                <StyledTableCell sx={{ width: "100px" }} align="center">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {allProduct.docs &&
                allProduct.docs?.map((row, index) => (
                  <Row
                    key={row.name}
                    row={row}
                    index={index}
                    pagingCounter={allProduct.pagingCounter}
                    editFieldOpen={editFieldOpen}
                    setEditFieldOpen={setEditFieldOpen}
                    inventoryUpdate={inventoryUpdate}
                    setOldImage={setOldImage}
                    sentMessage={sentMessage}
                    setEditdata={setEditdata}
                    setEditVariationData={setEditVariationData}
                    editdata={editdata}
                    editVariationData={editVariationData}
                  />
                ))}
            </TableBody>
          </Table>
          <Box
            component={Paper}
            sx={{
              marginTop: "10px",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <Stack>
              <Pagination
                count={allProduct?.totalPages}
                page={pageNo}
                onChange={handleChangePageNo}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </Box>
        </TableContainer>
      </Box>
    </>
  );
}
