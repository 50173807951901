import { Box, Button, Card, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { editMerchant, editOrderAppUser } from "../../../sevice/userManagement";
import { MerchantSchema } from "../../../schemas";




  
function EditMerchant({ editItem, handleClose }) {
  const { POSadmin } = useSelector((state) => state);

  const initialValues = {
    first_name: editItem.first_name,
    last_name: editItem.last_name,
    phone: editItem.phone,
    password:"",
    confirmPassword: "",
    email:editItem?.email,
    userPasscode: editItem.userPasscode,
    swipeCode: editItem.swipeCode,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: MerchantSchema,
    onSubmit: async (values, action) => {
      const data = await editMerchant(POSadmin.token, values, editItem._id);

      if (data.status) {
        toast.success(data.message);
        handleClose();
      } else {
        toast.error(data.message);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Typography sx={{ p: 3 }} variant="h6">
            Edit User
          </Typography>
          <Card
            elevation={0}
            sx={{
              width: "100%",
              height: "30%",
              marginRight: "15px",
              marginBottom: "20px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "50ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="First Name *"
                id="first_name"
                variant="outlined"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.first_name && !!formik.errors.first_name
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
              <TextField
                label="Last Name *"
                id="last_name"
                variant="outlined"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={!!formik.touched.last_name && !!formik.errors.last_name}
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
              <TextField
                label="Phone *"
                id="phone"
                variant="outlined"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={!!formik.touched.phone && !!formik.errors.phone}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <TextField
                label="Password *"
                id="password"
                variant="outlined"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={!!formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
              />
              <TextField
                sx={{ marginLeft: "30px" }}
                id="outlined-basic"
                label="Confirm password *"
                variant="outlined"
                type="password"
                name="confirmPassword"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                error={
                  !!formik.touched.confirmPassword &&
                  !!formik.errors.confirmPassword
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
              <TextField
                label="User Passcode"
                id="userPasscode"
                variant="outlined"
                name="userPasscode"
                value={formik.values.userPasscode}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.userPasscode && !!formik.errors.userPasscode
                }
                helperText={
                  formik.touched.userPasscode && formik.errors.userPasscode
                }
              />
              <TextField
                label="Swipe Code"
                id="swipeCode"
                variant="outlined"
                name="swipeCode"
                value={formik.values.swipeCode}
                onChange={formik.handleChange}
                error={!!formik.touched.swipeCode && !!formik.errors.swipeCode}
                helperText={formik.touched.swipeCode && formik.errors.swipeCode}
              />
            </Box>
          </Card>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Box>
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  width: "100px",
                  textTransform: "none",
                  background: "var( --color-secondary)",
                  "&:hover": {
                    backgroundColor: "var( --color-secondary)",
                  },
                }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  width: "100px",
                  textTransform: "none",
                  background: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                }}
                type="submit"
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}

export default EditMerchant;
