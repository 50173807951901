import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "@emotion/styled";
import { toast } from "react-hot-toast";
import DescriptionIcon from "@mui/icons-material/Description";
import { deleteTableArea, getAllTableAreas } from "../../../sevice/admin";
import SearchBar from "../../common/SearchBar";
import AddArea from "./AddArea";
import EditArea from "./EditArea";
import AlertButton from "../../../Alert/Alert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--font-white)",
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 705,
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 4,
  py: 3,
  borderRadius: "5px",
};

export default function AllArea() {
  const { POSadmin } = useSelector((state) => state);
  const [allTableAreas, setAllTableAreas] = useState([]);
  const [openAddArea, setOpenAddArea] = useState(false);
  const handleOpenArea = () => setOpenAddArea(true);
  const handleCloseArea = () => setOpenAddArea(false);
  const [editItem, setEditItem] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDeleteArea = async (id) => {
    const data = await deleteTableArea(POSadmin.token, id);
    sentMessage(data);
    fetchAllTableAreas();
  };

  useEffect(() => {
    fetchAllTableAreas();
  }, [open, openAddArea]);

  const fetchAllTableAreas = async () => {
    const data = await getAllTableAreas(POSadmin.token);
    setAllTableAreas(data);
  };

  const sentMessage = (data) => {
    if (data.status) {
      toast.success(data.message);
      handleCloseArea();
    }
  };
  return (
    <div>
      <Box sx={{ px: "20px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", py: "20px" }}
        >
          <SearchBar searchBarWidth={"200px"} placeholder={"Search"} />
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={handleOpenArea}
          >
            Add Area
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: "100%" }} aria-label="simple table">
            <TableHead sx={{ background: "var(--dark-gray)" }}>
              <TableRow>
                <StyledTableCell align="center">Area Name</StyledTableCell>
                <StyledTableCell align="center">Area Type</StyledTableCell>
                <StyledTableCell align="center">Tables</StyledTableCell>
                <StyledTableCell align="center">Active</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTableAreas?.map((row) => (
                <TableRow key={row._id}>
                  <TableCell align="center">{row.areaType}</TableCell>
                  <TableCell align="center">{row.areaName}</TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {row.tables.map((item) => (
                        <Typography
                          sx={{ my: "10px" }}
                          style={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          {item.tableNo},&nbsp;&nbsp;
                        </Typography>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {row.isActive ? "Active" : "Not Active"}
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleOpen();
                          setEditItem(row);
                        }}
                        sx={{
                          width: "20px",
                        }}
                      >
                        <DescriptionIcon />
                      </Button>
                      <AlertButton
                        onClick={() => setEditItem(row)}
                        element={<DeleteIcon />}
                        type={"confirmation"}
                        message={
                          "Are you sure? You won't be able to revert this!"
                        }
                        onConfirm={handleDeleteArea}
                        data={row._id}
                        style={{ width: "20px", color: "var(--red)" }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
          open={openAddArea}
          onClose={handleCloseArea}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <AddArea
              sentMessage={sentMessage}
              handleCloseArea={handleCloseArea}
            />
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <EditArea
              sentMessage={sentMessage}
              editItem={editItem}
              handleClose={handleClose}
            />
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
