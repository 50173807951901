import {
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Typography,
  Select,
  Box,
} from "@mui/material";
import React from "react";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

export default function StatsCard(props) {
  const { title, content, description, wastageFilter, setWastageFilter } =
    props;

  return (
    <Card
      variant="outlined"
      sx={{ position: "relative", flex: 1, minWidth: "270px" }}
    >
      <CardContent>
        <Box sx={{ display: "flex" }}>
          <Inventory2OutlinedIcon sx={{ marginRight: 1 }} />
          <Typography sx={{ fontSize: 18 }} component="span">
            {title}
            {title === "Wastage" && (
              <FormControl
                sx={{ px: 1, minWidth: 90, position: "absolute", right: 0 }}
                size="small"
              >
                <Select
                  value={wastageFilter}
                  onChange={(e) => setWastageFilter(e.target.value)}
                  displayEmpty
                  sx={{ fontSize: 12 }}
                >
                  <MenuItem value="day">Day</MenuItem>
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="year">Year</MenuItem>
                </Select>
              </FormControl>
            )}
          </Typography>
        </Box>
        <Typography sx={{ py: 2 }} variant="h4">
          {content}
        </Typography>
        <Typography sx={{ fontSize: 12 }} variant="h4">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}
