import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import Vector from "../../../assets/Vector (2).svg";
import sparkling from "../../../assets/sparkling-water 1.svg";
import cheese from "../../../assets/cheese 1.svg";
import pizza from "../../../assets/pizza (1) 1.svg";
import { selectVariaton } from "../../../schemas";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const initialValues = {
  title: "",
};

export default function SelectAddonModal({ close, open }) {
  const { isLanguageSwitched } = useSelector((state) => state);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: selectVariaton,
    onSubmit: async (values, action) => {
      open(values);
    },
  });

  const addonList = [
    {
      image: sparkling,
      head: "Add-ons",
      headAr: "الإضافات",
      discription: "Add ons can be Soft drinks, Fries etc..",
      // head: t("selectAddonModal.ADDONS"),
      // discription: t("selectAddonModal.ADDONS_DESC"),
    },
    {
      image: cheese,
      head: "Extras",
      headAr: "عناصر إضافية",
      discription: "Extra ingredients like cheese, sauce etc..",
      // head: t("selectAddonModal.EXTRAS"),
      // discription: t("selectAddonModal.EXTRAS_DESC"),
    },
    {
      image: pizza,
      head: "Toppings",
      headAr: "توبنغس",
      discription: "Toppings can be veggies, sprinkles, nuts etc..",
      // head: t("selectAddonModal.TOPPINGS"),
      // discription: t("selectAddonModal.TOPPINGS_DESC"),
    },
    {
      image: Vector,
      head: "Add Add-ons",
      headAr: "إضافة إضافات",
      discription: "Create your own add-ons",
    },
  ];

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Box>
            <Typography
              sx={{ m: "10px" }}
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              {/* {t("selectAddonModal.ADD_ADDONS")} */}
              Add Add-ons
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {addonList.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "10px",
                  border:
                    formik.values.title == item.head
                      ? "solid var(--red) 3px"
                      : "solid var(--border-black) 1px",
                  width: "338px",
                  p: "5px",
                  height: "85px",
                  pl: "10px",
                  borderRadius: "8px",
                  m: "10px",
                }}
                onClick={() => {
                  formik.setFieldValue("title", item.head);
                  if (isLanguageSwitched) {
                    formik.setFieldValue("titleAr", item.headAr);
                  }
                }}
              >
                <Box sx={{ display: "flex", alignContent: "center" }}>
                  <img
                    style={{ width: "30px" }}
                    src={item.image}
                    alt=""
                    srcset=""
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{ my: "10px" }}
                    style={{ fontSize: "13px", fontWeight: "bold" }}
                  >
                    {item.head}
                  </Typography>
                  <Typography style={{ fontSize: "11px" }}>
                    {item.discription}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          {formik.touched.title && formik.errors.title && (
            <p
              style={{
                color: "#D32F2F",
                marginLeft: "12px",
                fontSize: "12px",
              }}
              id="title-helper-text"
              className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
            >
              {formik.errors.title}
            </p>
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end", m: "10px" }}>
          <Button
            sx={{
              height: "100%",
              fontSize: "16px",
              mr: "10px",
              mt: "3px",
              textTransform: "none",
              color: "var(--bg-white)",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            type="submit"
            variant="text"
          >
            {/* {t("common.NEXT")} */}
            Next
          </Button>

          <Button
            sx={{
              height: "100%",
              fontSize: "16px",
              mt: "3px",
              textTransform: "none",
              color: "var(--font-black)",
              background: "var(--button-gray)",
              "&:hover": {
                backgroundColor: "var(--button-gray)",
              },
            }}
            variant="text"
            onClick={close}
          >
            {/* {t("common.CANCEL")} */}
            Cancel
          </Button>
        </Box>
      </form>
    </div>
  );
}
