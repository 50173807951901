import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { editRawmaterial, getAllUnits } from "../../../sevice/inventory";
import { Field, Form, Formik } from "formik";
import { TextField, Select, Switch } from "formik-mui";
import { editRawMaterialSchema } from "../../../schemas";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

function EditRawMaterial({ editItem, handleClose }) {
  const { POSadmin } = useSelector((state) => state);

  const initialValues = {
    name: editItem.name,
    purchaseUnit: editItem.purchaseUnit._id,
    is_private: editItem.is_private,
  };
  const submitdata = async (values, action) => {
    const data = await editRawmaterial(POSadmin.token, values, editItem._id);
    if (data.status) {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
    }
  };
  const [units, setUnits] = useState([]);
  const getData = async () => {
    const data = await getAllUnits();
    setUnits(data);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {" "}
      <Formik
        initialValues={initialValues}
        validationSchema={editRawMaterialSchema}
        onSubmit={submitdata}
        enableReinitialize
      >
        {({ values }) => (
          <Form autoComplete="off">
            <Box>
              <Typography sx={{ p: 3 }} variant="h6">
                Edit Raw material
              </Typography>
              <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead sx={{ background: "var(--table-head)" }}>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Purchase Unit</TableCell>
                      <TableCell align="center">is_private</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell align="center">
                        <Field
                          fullWidth
                          name={`name`}
                          render={({ field }) => (
                            <Field
                              sx={{ m: 1, minWidth: 300 }}
                              size="small"
                              component={TextField}
                              {...field}
                              onChange={(event) => {
                                const uppercaseValue =
                                  event.target.value.toUpperCase();
                                field.onChange({
                                  target: {
                                    name: field.name,
                                    value: uppercaseValue,
                                  },
                                });
                              }}
                            />
                          )}
                        />
                      </TableCell>

                      <TableCell align="center">
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                          <Field
                            component={Select}
                            size="small"
                            type="text"
                            name={`purchaseUnit`}
                          >
                            {units.map((item) => (
                              <MenuItem value={item._id}>{item.name}</MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControlLabel
                          control={
                            <Field
                              name={`is_private`}
                              component={Switch}
                              type="checkbox"
                            />
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 1,
                      width: "100px",
                      textTransform: "none",
                      background: "var( --color-secondary)",
                      "&:hover": {
                        backgroundColor: "var( --color-secondary)",
                      },
                    }}
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    sx={{
                      mt: 1,
                      width: "100px",
                      textTransform: "none",
                      background: "var(--red)",
                      "&:hover": {
                        backgroundColor: "var(--red)",
                      },
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>{" "}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditRawMaterial;
