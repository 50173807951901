import { useEffect, useState } from "react";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Pagination,
  Stack,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField as TextFieldCustom,
  Autocomplete,
  Modal,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { Box } from "@mui/system";
import SearchBar from "../../common/SearchBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Field, FieldArray, Form, Formik } from "formik";
import { DateFormatterWithTime } from "../../../utils/DateFormatter";
import { TextField, Select } from "formik-mui";
import { purchaseSchema } from "../../../schemas";
import {
  getAllPurchase,
  getAllUnits,
  getRawMaterials,
  newPurchase,
} from "../../../sevice/inventory";
import EditPurchase from "./EditPurchase";
import LoadingImage from "../../../assets/loader.gif";

const Completed = styled(Box)({
  display: "flex",
  width: 150,
  height: 40,
  color: "var(--bg-white)",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "19px",
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));

const style = {
  width: "90%",
  position: "absolute",
  top: "54%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

let editInitialValue;
function Purchase({ state, setState }) {
  const { POSadmin } = useSelector((state) => state);
  const [searchQuery, setSearchQuery] = useState("");
  const [IsLoading, setLoading] = useState(true);
  const [rawmet, setRawMet] = useState([]);
  const [purch, setPurchase] = useState([]);
  const [units, setUnits] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const [editItem, setEditItem] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialValues = {
    vendor: POSadmin.id,
    supplier: "",
    invoiceDate: new Date(),
    paymentType: "Paid",
    invoicefile: "",
    products: [
      {
        name: "",
        quantity: "",
        unit: "",
        price: "",
        amount: "",
        tax: "",
      },
    ],
  };

  const emptyRaw = {
    name: "",
    quantity: "",
    unit: "",
    price: "",
    amount: "",
    tax: "",
  };

  const submitdata = async (values, action) => {
    setIsSubmit(true);
    // const formData = new FormData();
    // formData.append("data", values);
    // formData.append("image", image);
    const data = await newPurchase(POSadmin.token, values);
    if (data.status) {
      toast.success(data.message);
      setState(true);
      action.resetForm();
    } else {
      toast.error(data.message);
    }
  };
  const getPurchase = async () => {
    const data1 = await getAllUnits();
    setUnits(data1);
    const data = await getAllPurchase(POSadmin.token, searchQuery);
    setPurchase(data);
    setLoading(false);
  };
  const fetchRawMaterials = async () => {
    const data = await getRawMaterials(POSadmin.token, "", "");
    setRawMet(data.docs);
  };
  useEffect(() => {
    fetchRawMaterials();
    getPurchase();
  }, [state, searchQuery, open]);

  const setInitalValueFun = (value) => {
    let productData = value.products.map((item) => {
      return {
        name: item.name,
        quantity: item.quantity,
        preQuantity: item.quantity,
        unit: item.unit,
        price: item.price,
        amount: item.amount,
        tax: item.tax,
      };
    });
    editInitialValue = {
      supplier: value.supplier,
      invoiceDate: value.invoiceDate,
      paymentType: value.paymentType,
      invoicefile: value.invoicefile,
      products: productData,
    };
  };
  const handleChangePageNo = (event, value) => {
    setPageNo(value);
  };
  return (
    <>
      {state && !IsLoading && (
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            searchBarWidth={"200px"}
            placeholder={"Search"}
            onChange={(newValue) => {
              setSearchQuery(newValue.target.value);
            }}
          />
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={() => {
              setState(false);
              setIsSubmit(false);
            }}
          >
            Purchase
          </Button>
        </Box>
      )}
      {IsLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img style={{ height: "300px" }} src={LoadingImage} alt="Loading" />
        </Box>
      ) : state ? (
        <Box sx={{ marginTop: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead sx={{ background: "var(--table-head)" }}>
                <TableRow>
                  <StyledTableCell align="center">SL No</StyledTableCell>
                  <StyledTableCell align="center">Date</StyledTableCell>
                  <StyledTableCell align="center">From</StyledTableCell>
                  <StyledTableCell align="center">PO No</StyledTableCell>
                  <StyledTableCell align="center">Total ( ₹ )</StyledTableCell>
                  <StyledTableCell align="center">
                    Payment ( ₹ )
                  </StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purch?.docs?.map((item, index) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      <DateFormatterWithTime date={item.invoiceDate} />
                    </TableCell>
                    <TableCell align="center">{item.supplier}</TableCell>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{item.totalPrice}</TableCell>
                    <TableCell align="center">{item.paymentType}</TableCell>
                    <TableCell align="center">
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Completed style={{ background: "var(--green-color)" }}>
                          Completed
                        </Completed>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <VisibilityIcon sx={{ cursor: "pointer" }} />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <SaveAsIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setInitalValueFun(item);
                          setEditItem(item);
                          handleOpen();
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            component={Paper}
            sx={{
              marginTop: "10px",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <Stack>
              <Pagination
                count={purch?.totalPages}
                page={pageNo}
                onChange={handleChangePageNo}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <EditPurchase
                editInitialValue={editInitialValue}
                editItem={editItem}
                handleClose={handleClose}
                rawmet={rawmet}
                units={units}
              />
            </Box>
          </Modal>
        </Box>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={purchaseSchema}
            onSubmit={submitdata}
          >
            {({ values, setFieldValue }) => (
              <Form autoComplete="off">
                <Box>
                  <Typography sx={{ p: 3 }} variant="h6">
                    New Purchase
                  </Typography>
                  <Card
                    elevation={0}
                    sx={{
                      width: "100%",
                      height: "400px",
                      marginRight: "15px",
                      marginBottom: "20px",
                      padding: 3,
                      border: "1px solid #ccc",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <InputLabel>Supplier</InputLabel> <br />
                        <Field
                          fullWidth
                          name={`supplier`}
                          component={TextField}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "49%",
                        }}
                      >
                        <InputLabel>Invoice Date</InputLabel> <br />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={values.invoiceDate}
                            onChange={(e) => {
                              setFieldValue("invoiceDate", e.$d);
                            }}
                            renderInput={(params) => (
                              <TextFieldCustom
                                defaultShow={new Date()}
                                {...params}
                                sx={{
                                  borderRadius: "5px",
                                  width: "100%",
                                  color: "var(--font-black)",
                                  fontSize: "1.1rem",
                                  marginRight: "5px",
                                }}
                              />
                            )}
                            disableUnderline
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                        marginTop: "3opx",
                      }}
                    >
                      <br />
                      <InputLabel>Payment Type</InputLabel>
                      <FormControl>
                        <RadioGroup
                          value={values.paymentType}
                          onChange={(e) => {
                            setFieldValue("paymentType", e.target.value);
                          }}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="Paid"
                            control={<Radio />}
                            label="Paid"
                          />
                          <FormControlLabel
                            value="Unpaid"
                            control={<Radio />}
                            label="Unpaid"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                        marginTop: "3opx",
                      }}
                    >
                      <br />
                      <InputLabel>Invoice file</InputLabel>
                      <br />
                      <Field
                        component={TextField}
                        name="invoicefile"
                        type="file"
                        fullWidth
                      />
                    </Box>
                  </Card>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead sx={{ background: "var(--table-head)" }}>
                        <TableRow>
                          <StyledTableCell align="center">Name</StyledTableCell>
                          <StyledTableCell align="center">Qty</StyledTableCell>
                          <StyledTableCell align="center">Unit</StyledTableCell>
                          <StyledTableCell align="center">
                            Price
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Amount
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Tax %
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>

                      <FieldArray name="products">
                        {({ push, remove }) => (
                          <>
                            <TableBody>
                              {values.products.map((_, index) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                                      <Autocomplete
                                        size="small"
                                        options={rawmet}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                          <Field
                                            component={TextField}
                                            {...params}
                                            value={params._id}
                                            name={`products.${index}.name`}
                                            variant="outlined"
                                            fullWidth
                                          />
                                        )}
                                        onChange={(event, newValue) => {
                                          if (newValue) {
                                            setFieldValue(
                                              `products.${index}.name`,
                                              newValue?._id
                                            );
                                          } else {
                                            setFieldValue(
                                              `products.${index}.name`,
                                              ""
                                            );
                                          }
                                        }}
                                      />
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Field
                                      size="small"
                                      fullWidth
                                      name={`products.${index}.quantity`}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `products.${index}.quantity`,
                                          e.target.value
                                        );
                                        setFieldValue(
                                          `products.${index}.amount`,
                                          e.target.value *
                                            values.products[index].price
                                        );
                                      }}
                                      component={TextField}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                                      <Field
                                        component={Select}
                                        size="small"
                                        name={`products.${index}.unit`}
                                      >
                                        {units.map((item) => (
                                          <MenuItem value={item._id}>
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Field
                                      size="small"
                                      fullWidth
                                      name={`products.${index}.price`}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `products.${index}.price`,
                                          e.target.value
                                        );
                                        setFieldValue(
                                          `products.${index}.amount`,
                                          e.target.value *
                                            values.products[index].quantity
                                        );
                                      }}
                                      component={TextField}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Field
                                      size="small"
                                      fullWidth
                                      name={`products.${index}.amount`}
                                      onChange={() => {}}
                                      component={TextField}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Field
                                      size="small"
                                      fullWidth
                                      name={`products.${index}.tax`}
                                      component={TextField}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button onClick={() => remove(index)}>
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>

                            <Button
                              onClick={() => push(emptyRaw)}
                              variant="contained"
                              sx={{
                                m: 3,
                                textTransform: "none",
                                background: "var(--red)",
                                "&:hover": {
                                  backgroundColor: "var(--red)",
                                },
                              }}
                            >
                              + Add Row
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Table>
                  </TableContainer>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box></Box>
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          mt: 3,
                          width: "100px",
                          textTransform: "none",
                          background: "var( --color-secondary)",
                          "&:hover": {
                            backgroundColor: "var( --color-secondary)",
                          },
                        }}
                        onClick={() => setState(true)}
                      >
                        Cancel
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        disabled={isSubmit}
                        variant="contained"
                        sx={{
                          mt: 3,
                          width: "100px",
                          textTransform: "none",
                          background: "var(--red)",
                          "&:hover": {
                            backgroundColor: "var(--red)",
                          },
                        }}
                        type="submit"
                      >
                        {isSubmit ? "Loading.." : "Save"}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
}

export default Purchase;
