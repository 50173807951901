import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  InputLabel,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
  Grid,
} from "@mui/material";
import { getAllTables } from "../../../sevice/admin";
import html2pdf from "html2pdf.js";
import { QRCodeCanvas } from "qrcode.react";
import QR from "../../../assets/qr.png";
import { toast } from "react-hot-toast";

function DineInQR({ setPage }) {
  const url = "dinein.ouronlineservice.com";
  const { POSadmin } = useSelector((state) => state);
  const [tables, setTables] = useState([]);
  const [table, setTable] = useState({});
  const [image, setImage] = useState("");

  const QrCodeDownload = async () => {
    const element = document.getElementById("qr-page");
    const opt = {
      margin: 1,
      filename: "scan-qr.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "b4", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
  };
  const fetchAllTable = async () => {
    const data = await getAllTables(POSadmin.id);
    setTable(data[0].tableNo);
    setTables(data);
  };
  useEffect(() => {
    fetchAllTable();
  }, []);
  return (
    <>
      <div style={{ margin: "50px" }}>
        <Card
          square={true}
          elevation={0}
          sx={{
            width: "100%",
            height: "310px",
            padding: 3,
          }}
        >
          <Box sx={{ margin: "20px" }}>
            <Typography
              fontWeight={"bold"}
              sx={{ fontSize: 16 }}
              color="var(--font-black)"
              gutterBottom
            >
              Dine-in QR Generator
            </Typography>
            <Box
              sx={{
                display: "flex",
                margin: "34px",
                marginRight: "34px",
              }}
            >
              <InputLabel
                component={"h4"}
                sx={{ fontWeight: "bold", width: "20%" }}
              >
                Title
              </InputLabel>
              <TextField fullWidth id="outlined-basic" variant="outlined" />
            </Box>
            <Box
              sx={{
                display: "flex",
                margin: "34px",
              }}
            >
              <InputLabel
                component={"h4"}
                sx={{ fontWeight: "bold", width: "20%" }}
              >
                Logo
              </InputLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                type="file"
                onChange={(e) =>
                  setImage(URL.createObjectURL(e.target.files[0]))
                }
              />
            </Box>
          </Box>
        </Card>
        <br />
        <Card
          square={true}
          elevation={0}
          sx={{
            width: "100%",
            height: "300px",
          }}
        >
          <Box
            sx={{
              margin: "20px",
              maxHeight: "315px",
              padding: 3,
              overflow: "hidden",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontWeight={"bold"}
                sx={{ fontSize: 16 }}
                color="var(--font-black)"
                gutterBottom
              >
                Download Dine-in QR
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                margin: "34px",
                marginRight: "34px",
              }}
            >
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <Box sx={{ display: "flex" }}>
                    <Grid container spacing={{ xs: 2, md: 2 }}>
                      {tables?.map((item, index) => (
                        <Grid xs={2} sm={5} md={2} lg={2} key={index}>
                          <FormControlLabel
                            checked={table._id === item._id}
                            onChange={() => setTable(item)}
                            control={<Checkbox />}
                            label={
                              <Typography style={{ fontFamily: "poppins" }}>
                                Table-{item.tableNo}
                              </Typography>
                            }
                            labelPlacement="end"
                            sx={{ marginRight: "40px" }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </FormGroup>
              </FormControl>
            </Box>
            <Button
              variant="contained"
              sx={{
                mb: 11,
                width: "100px",
                textTransform: "none",
                background: "var( --red)",
                "&:hover": {
                  backgroundColor: "var( --red)",
                },
              }}
              onClick={() => {
                if (table?._id) {
                  QrCodeDownload();
                } else {
                  toast.error("Please select a table.");
                }
              }}
            >
              Download
            </Button>
          </Box>
          <Box hidden>
            <div id="qr-page">
              <Paper
                elevation={0}
                variant="outlined"
                sx={{ width: "210mm", height: "297mm" }}
              >
                <Box>
                  <img
                    style={{ width: "200mm", height: "290mm" }}
                    src={QR}
                    alt={QR}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      bottom: "45.9rem",
                      left: "14.2rem",
                      height: "10rem",
                      width: "10rem",
                    }}
                  >
                    <QRCodeCanvas
                      value={`https://www.dinein.ouronlineservice.com/?id=${POSadmin.id}&table=${table?.tableNo}&tableId=${table?._id}`}
                      size={300}
                      bgColor={"#ffffff"}
                      fgColor={"#000000"}
                      level="L"
                      includeMargin={false}
                      imageSettings={{
                        src: image,
                        x: undefined,
                        y: undefined,
                        height: 100,
                        width: 100,
                        excavate: true,
                      }}
                    />
                  </Box>
                </Box>
              </Paper>
            </div>
          </Box>
        </Card>
        <br />
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            sx={{
              mb: 1,

              textTransform: "none",
              background: "var( --color-secondary)",
              "&:hover": {
                backgroundColor: "var( --color-secondary)",
              },
            }}
            onClick={() => setPage("config")}
          >
            Back
          </Button>
        </Box>
      </div>
    </>
  );
}

export default DineInQR;
