import { useEffect, useState } from "react";
import { Box, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import PrintIcon from "@mui/icons-material/Print";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ConfigCard from "./../components/posAdminPanel/configuration/ConfigCard";
import BannerManagement from "./../components/posAdminPanel/configuration/BannerManagement";
import DineInQR from "./../components/posAdminPanel/configuration/DineInQR";
import FlowDirection from "./../components/posAdminPanel/navBarAndSideBar/FlowDirection";
import changeFlowDirectonConfiguration from "./FlowDirection";
import PrintingIps from "../components/posAdminPanel/printingIps/PrintingIps";

function Configuration() {
  const navigate = useNavigate();
  const [page, setPage] = useState("config");
  const config = [
    // {
    //   icon: <AppShortcutIcon />,
    //   text: "In Restaurant Purchase",
    //   action: () => setPage("restaurant"),
    // },
    {
      icon: <QrCodeIcon />,
      text: "Dine-in QR",
      action: () => setPage("dine-QR-generate"),
    },
    // {
    //   icon: <QrCodeIcon />,
    //   text: "Pick up QR",
    //   action: () => setPage("pick-up-QR"),
    // },
    {
      icon: <ViewCarouselIcon />,
      text: "Banner Management",
      action: () => setPage("bannerManage"),
    },
    {
      icon: <PrintIcon />,
      text: "Printing Ips",
      action: () => setPage("printingIps"),
    },
  ];
  const restaurant = [
    {
      icon: <SettingsApplicationsIcon />,
      text: "Captain Application",
      action: () => setPage(""),
    },
    {
      icon: <DocumentScannerIcon />,
      text: "Scan & Order App",
      action: () => setPage("scan&Order"),
    },
  ];
  const scanAndOrder = [
    {
      icon: <QrCodeIcon />,
      text: "Dine-in QR",
      action: () => setPage("dine-QR-generate"),
    },
    {
      icon: <QrCodeIcon />,
      text: "Pick up QR",
      action: () => setPage("pick-up-QR"),
    },
    {
      icon: <ViewCarouselIcon />,
      text: "Banner Management",
      action: () => setPage("bannerManage"),
    },
    {
      icon: <PrintIcon />,
      text: "Printing Ips",
      action: () => setPage("printingIps"),
    },
  ];
  let [flowDirectionItems, setFlowDirectionItems] = useState([
    {
      name: "Settings",
      show: true,
    },
  ]);
  useEffect(() => {
    changeFlowDirectonConfiguration(page, setFlowDirectionItems);
  }, [page]);

  function backFunctionConfiguration() {
    // page === "config"
    //   ? navigate("/settings")
    // :
    // :
    // : page === "restaurant"
    // ? setPage("config")
    // page === "scan&Order"
    // ? setPage("restaurant")
    page === "bannerManage"
      ? setPage("config")
      : page === "dine-QR-generate"
      ? setPage("config")
      : page === "printingIps"
      ? setPage("config")
      : navigate("/settings");
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        background: "var(--bg-color)",
        height: "100%",
      }}
    >
      <FlowDirection
        items={flowDirectionItems}
        showBack={true}
        backFunction={backFunctionConfiguration}
      />
      <Box sx={{ width: "100%", mt: "40px" }}>
        <Toolbar />
        {page === "config" ? (
          <ConfigCard data={config} />
        ) : // ) : page === "restaurant" ? (
        //   <ConfigCard data={restaurant} />
        // page === "scan&Order" ? (
        //   <ConfigCard data={scanAndOrder} />
        // ) :
        // ) : page === "pick-up-QR" ? (
        //   <ConfigCard data={config} />
        page === "bannerManage" ? (
          <BannerManagement setPage={setPage} />
        ) : page === "printingIps" ? (
          <PrintingIps setPage={setPage} />
        ) : page === "dine-QR-generate" ? (
          <DineInQR setPage={setPage} />
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}

export default Configuration;
