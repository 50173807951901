import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { editRecipe } from "../../../sevice/inventory";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField, Select } from "formik-mui";
import { useSelector } from "react-redux";
import { editRecipeSchema } from "../../../schemas";
import { toast } from "react-hot-toast";

const emptyRawMaterial = { name: "", quantity: "", baseUnit: "", unit: "" };
function EditRecipe({
  editItem,
  handleClose,
  initialEditValue,
  subUnit,
  rawMaterials,
}) {
  const { POSadmin } = useSelector((state) => state);

  const submitdata = async (values, action) => {
    const data = await editRecipe(POSadmin.token, values, editItem._id);
    if (data.status) {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
    }
  };

  return (
    <>
      {rawMaterials.length ? (
        <Box
          sx={{
            maxHeight: "75vh",
            bgcolor: "white",
            px: 4,
            py: 2,
            boxShadow: 24,
            borderRadius: "5px",
            overflow: "scroll",
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar
            },
          }}
        >
          <Box>
            <Formik
              initialValues={initialEditValue}
              validationSchema={editRecipeSchema}
              onSubmit={submitdata}
              enableReinitialize={true}
            >
              {({ values, setFieldValue, errors }) => (
                <Form autoComplete="off">
                  <Typography sx={{ p: 3 }} variant="h6">
                    Edit Recipe
                  </Typography>
                  <TableContainer component={Paper} elevation={0}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead sx={{ background: "var(--table-head)" }}>
                        <TableRow>
                          <TableCell align="center">Raw Material</TableCell>
                          <TableCell align="center">Quantity</TableCell>
                          <TableCell align="center">Unit</TableCell>
                          <TableCell align="center">Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <FieldArray name="rawMaterial">
                        {({ push, remove }) => (
                          <>
                            <TableBody>
                              {values.rawMaterial.map((_, index) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    <FormControl sx={{ m: 1, minWidth: 250 }}>
                                      {/* {rawMaterials.length && ( */}
                                      <Autocomplete
                                        options={rawMaterials}
                                        size="small"
                                        value={rawMaterials.find(
                                          (item) =>
                                            values.rawMaterial[index].name ===
                                            item._id
                                        )}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                          <Field
                                            component={TextField}
                                            {...params}
                                            value={params._id}
                                            name={`rawMaterial.${index}.name`}
                                            variant="outlined"
                                            fullWidth
                                            onChange={() => {}}
                                          />
                                        )}
                                        onChange={(event, newValue) => {
                                          if (newValue) {
                                            setFieldValue(
                                              `rawMaterial.${index}.baseUnit`,
                                              newValue?.purchaseUnit._id
                                            );
                                            setFieldValue(
                                              `rawMaterial.${index}.name`,
                                              newValue?._id
                                            );
                                            setFieldValue(
                                              `rawMaterial.${index}.unit`,
                                              ""
                                            );
                                          } else {
                                            setFieldValue(
                                              `rawMaterial.${index}.baseUnit`,
                                              ""
                                            );
                                            setFieldValue(
                                              `rawMaterial.${index}.unit`,
                                              ""
                                            );
                                            setFieldValue(
                                              `rawMaterial.${index}.name`,
                                              ""
                                            );
                                          }
                                        }}
                                      />
                                      {/* )} */}
                                    </FormControl>

                                    {/* {rawMaterials.length && (
                                  <Autocomplete
                                    id="size-small-standard"
                                    size="small"
                                    options={rawMaterials}
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={rawMaterials.find(
                                      (item) =>
                                        values.rawMaterial[index].name ===
                                        item._id
                                    )}
                                    renderInput={(params) => (
                                      <Aamplea
                                        {...params}
                                        variant="standard"
                                        label="Size small"
                                        placeholder="Favorites"
                                      />
                                    )}
                                  />
                                )} */}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Field
                                      size="small"
                                      fullWidth
                                      name={`rawMaterial.${index}.quantity`}
                                      component={TextField}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                                      <Field
                                        component={Select}
                                        name={`rawMaterial.${index}.unit`}
                                        size="small"
                                        sx={{ mb: 0 }}
                                      >
                                        {subUnit.map((item) => {
                                          return (
                                            item.unit ==
                                              values?.rawMaterial[index]
                                                ?.baseUnit && (
                                              <MenuItem value={item._id}>
                                                {item.name}
                                              </MenuItem>
                                            )
                                          );
                                        })}
                                      </Field>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Button onClick={() => remove(index)}>
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            <Button
                              onClick={() => push(emptyRawMaterial)}
                              variant="contained"
                              sx={{
                                m: 3,
                                textTransform: "none",
                                background: "var(--red)",
                                "&:hover": {
                                  backgroundColor: "var(--red)",
                                },
                              }}
                            >
                              + Add Row
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          mt: 1,
                          width: "100px",
                          textTransform: "none",
                          background: "var( --color-secondary)",
                          "&:hover": {
                            backgroundColor: "var( --color-secondary)",
                          },
                        }}
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        variant="contained"
                        sx={{
                          mt: 1,
                          width: "100px",
                          textTransform: "none",
                          background: "var(--red)",
                          "&:hover": {
                            backgroundColor: "var(--red)",
                          },
                        }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </>
  );
}

export default EditRecipe;
