import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import {
  addMainBanner,
  addSubBanner,
  getAllBanner,
  getSubBanner,
} from "../../../sevice/admin";
import bannerviewImage from "../../../assets/bannerviewImage.png";
function BannerCard({ setPage, group, product }) {
  const { POSadmin } = useSelector((state) => state);
  const [banners, setBanners] = useState([]);
  const [subBannerData, setsubBannerData] = useState();
  const [sub, setSub] = useState({
    image: "",
    product: false,
    group: true,
    item: "",
  });
  const handleAddSubBanner = async () => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(sub));
    formData.append("image", sub.image[0]);
    const data = await addSubBanner(POSadmin.token, formData);
    if (data.status) {
      fethDataSubBanner();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };
  const [main, setMain] = useState([
    {
      image1: "",
      product: false,
      group: false,
      item: "",
      isMain: true,
      id: "",
    },
    {
      image2: "",
      product: false,
      group: false,
      item: "",
      isMain: true,
      id: "",
    },
    {
      image3: "",
      product: false,
      group: false,
      item: "",
      isMain: true,
      id: "",
    },
  ]);
  const selectItem = async (value, index) => {
    const ad = main.map((data, ind) => {
      if (index === ind) {
        return { ...data, item: value };
      }
      return data;
    });
    setMain(ad);
  };
  const selectImage = async (value, index, field) => {
    const ad = main.map((data, ind) => {
      if (index === ind) {
        return { ...data, [field]: value };
      }
      return data;
    });
    setMain(ad);
  };
  const selectItemTrue = async (truevale, falsevalue, index) => {
    const ad = main.map((data, ind) => {
      if (index === ind) {
        return { ...data, [truevale]: true, [falsevalue]: false };
      }
      return data;
    });
    setMain(ad);
  };
  const fethDataSubBanner = async () => {
    const data = await getSubBanner(POSadmin.id, POSadmin.token);
    if (data.length) {
      setSub({
        image: data[0]?.image,
        product: data[0]?.product,
        group: data[0]?.group,
        item: data[0]?.item,
      });
    }
    setsubBannerData(data[0]);
  };
  const fethData = async () => {
    const data = await getAllBanner(POSadmin.id, POSadmin.token);
    setBanners(data);
    setMain([
      {
        image1: data[0]?.image,
        oldImage: data[0]?.image,
        product: data[0]?.product,
        group: data[0]?.group,
        item: data[0]?.item,
        isMain: true,
        id: data[0]?._id,
      },
      {
        image2: data[1]?.image,
        oldImage: data[1]?.image,
        product: data[1]?.product,
        group: data[1]?.group,
        item: data[1]?.item,
        isMain: true,
        id: data[1]?._id,
      },
      {
        image3: data[2]?.image,
        oldImage: data[2]?.image,
        product: data[2]?.product,
        group: data[2]?.group,
        item: data[2]?.item,
        isMain: true,
        id: data[2]?._id,
      },
    ]);
  };
  const handleAddManiBanner = async () => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(main));
    formData.append("image1", main[0].image1);
    formData.append("image2", main[1].image2);
    formData.append("image3", main[2].image3);
    const data = await addMainBanner(POSadmin.token, formData);
    fethData();
    if (data.status) {
      fethData();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    fethData();
    fethDataSubBanner();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        background: "white",
        padding: 9,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <Card
            square={true}
            elevation={0}
            sx={{
              width: 600,
              height: "280px",
              margin: "15px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Typography
              fontWeight={"bold"}
              sx={{ fontSize: 16, fontFamily: "Poppins" }}
              color="var(--font-black)"
              gutterBottom
            >
              Main Banner 1
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: 4,
                marginTop: 4,
              }}
            >
              <InputLabel sx={{ marginRight: 9, fontFamily: "Poppins" }}>
                Banner
              </InputLabel>
              <TextField
                sx={{ width: 300 }}
                size="small"
                id="outlined-basic"
                variant="outlined"
                type="file"
                onChange={(e) =>
                  selectImage(e.currentTarget.files[0], 0, "image1")
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: 3,
                marginLeft: 21,
              }}
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    onClick={() => {
                      selectItemTrue("group", "product", 0);
                    }}
                    value="Group"
                    control={<Radio />}
                    label="Group"
                  />
                  <FormControlLabel
                    onClick={() => {
                      selectItemTrue("product", "group", 0);
                    }}
                    value="Item"
                    control={<Radio />}
                    label="Item"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: 4,
                marginTop: 2,
              }}
            >
              <InputLabel sx={{ marginRight: 5, fontFamily: "Poppins" }}>
                Item
              </InputLabel>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                onChange={(event, newValue) => selectItem(newValue.id, 0)}
                options={main[0].product ? product : main[0].group ? group : []}
                getOptionLabel={(option) => option.item}
                sx={{ width: 300, marginLeft: 7 }}
                renderInput={(params) => (
                  <TextField {...params} onChange={() => ""} label="Item" />
                )}
              />
            </Box>
          </Card>
          <Card
            square={true}
            elevation={0}
            sx={{
              width: 600,
              height: "280px",
              margin: "15px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Typography
              fontWeight={"bold"}
              sx={{ fontSize: 16, fontFamily: "Poppins" }}
              color="var(--font-black)"
              gutterBottom
            >
              Main Banner 2
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: 4,
                marginTop: 4,
              }}
            >
              <InputLabel sx={{ marginRight: 9, fontFamily: "Poppins" }}>
                Banner
              </InputLabel>
              <TextField
                required
                size="small"
                sx={{ width: 300 }}
                id="outlined-basic"
                variant="outlined"
                type="file"
                onChange={(e) =>
                  selectImage(e.currentTarget.files[0], 1, "image2")
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: 3,
                marginLeft: 21,
              }}
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    onClick={() => {
                      selectItemTrue("group", "product", 1);
                    }}
                    value="Group"
                    control={<Radio />}
                    label="Group"
                  />
                  <FormControlLabel
                    onClick={() => {
                      selectItemTrue("product", "group", 1);
                    }}
                    value="Item"
                    control={<Radio />}
                    label="Item"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: 4,
                marginTop: 2,
              }}
            >
              <InputLabel sx={{ marginRight: 5, fontFamily: "Poppins" }}>
                Item
              </InputLabel>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                onChange={(event, newValue) => selectItem(newValue.id, 1)}
                options={main[1].product ? product : main[1].group ? group : []}
                getOptionLabel={(option) => option.item}
                sx={{ width: 300, marginLeft: 7 }}
                renderInput={(params) => (
                  <TextField {...params} onChange={() => ""} label="Item" />
                )}
              />
            </Box>
          </Card>
          <Card
            square={true}
            elevation={0}
            sx={{
              width: 600,
              height: "280px",
              margin: "15px",
              padding: 3,
              border: "1px solid #ccc",
            }}
          >
            <Typography
              fontWeight={"bold"}
              sx={{ fontSize: 16, fontFamily: "Poppins" }}
              color="var(--font-black)"
              gutterBottom
            >
              Main Banner 3
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: 4,
                marginTop: 4,
              }}
            >
              <InputLabel sx={{ marginRight: 9, fontFamily: "Poppins" }}>
                Banner
              </InputLabel>
              <TextField
                sx={{ width: 300 }}
                size="small"
                id="outlined-basic"
                variant="outlined"
                type="file"
                onChange={(e) =>
                  selectImage(e.currentTarget.files[0], 2, "image3")
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: 3,
                marginLeft: 21,
              }}
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    onClick={() => {
                      selectItemTrue("group", "product", 2);
                    }}
                    value="Group"
                    control={<Radio />}
                    label="Group"
                  />
                  <FormControlLabel
                    onClick={() => {
                      selectItemTrue("product", "group", 2);
                    }}
                    value="Item"
                    control={<Radio />}
                    label="Item"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: 4,
                marginTop: 2,
              }}
            >
              <InputLabel sx={{ marginRight: 5, fontFamily: "Poppins" }}>
                Item
              </InputLabel>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  if (newValue) {
                    selectItem(newValue.id, 2);
                  } else {
                    selectItem("", 2);
                  }
                }}
                options={main[2].product ? product : main[2].group ? group : []}
                getOptionLabel={(option) => option.item}
                sx={{ width: 300, marginLeft: 7 }}
                renderInput={(params) => (
                  <TextField {...params} onChange={(e) => ""} label="Item" />
                )}
              />
            </Box>
          </Card>
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{
              mt: 3,
              mb: 3,
              width: "100px",
              textTransform: "none",
              background: "var( --color-secondary)",
              "&:hover": {
                backgroundColor: "var( --color-secondary)",
              },
            }}
            onClick={() => setPage("config")}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            sx={{
              mt: 3,
              mb: 3,
              width: "100px",
              textTransform: "none",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={handleAddManiBanner}
          >
            Save
          </Button>
        </Box>
        <Card
          square={true}
          elevation={0}
          sx={{
            width: 600,
            height: "280px",
            margin: "15px",
            padding: 3,
            border: "1px solid #ccc",
          }}
        >
          <Typography
            fontWeight={"bold"}
            sx={{ fontSize: 16, fontFamily: "Poppins" }}
            color="var(--font-black)"
            gutterBottom
          >
            Sub Banner
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: 4,
              marginTop: 4,
            }}
          >
            <InputLabel sx={{ marginRight: 9, fontFamily: "Poppins" }}>
              Sub Banner
            </InputLabel>
            <TextField
              required
              sx={{ width: 300 }}
              size="small"
              onChange={(e) => setSub({ ...sub, image: e.target.files })}
              id="outlined-basic"
              variant="outlined"
              type="file"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: 3,
              marginLeft: 25,
            }}
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  onClick={() => {
                    setSub({ ...sub, group: true, product: false });
                  }}
                  value="Group"
                  control={<Radio />}
                  label="Group"
                />
                <FormControlLabel
                  onClick={() => {
                    setSub({ ...sub, group: false, product: true });
                  }}
                  value="Item"
                  control={<Radio />}
                  label="Item"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: 4,
              marginTop: 2,
            }}
          >
            <InputLabel sx={{ marginRight: 9, fontFamily: "Poppins" }}>
              Item
            </InputLabel>
            <Autocomplete
              size="small"
              disablePortal
              id="combo-box-demo"
              onChange={(event, newValue) =>
                setSub({ ...sub, item: newValue.id })
              }
              options={sub.product ? product : sub.group ? group : []}
              getOptionLabel={(option) => option.item}
              sx={{ width: 300, marginLeft: 7 }}
              renderInput={(params) => <TextField {...params} label="Item" />}
            />
          </Box>
        </Card>
        <Box>
          <Button
            variant="contained"
            sx={{
              mt: 3,
              mb: 3,
              width: "100px",
              textTransform: "none",
              background: "var( --color-secondary)",
              "&:hover": {
                backgroundColor: "var( --color-secondary)",
              },
            }}
            onClick={() => setPage("config")}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            sx={{
              mt: 3,
              mb: 3,
              width: "100px",
              textTransform: "none",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={handleAddSubBanner}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginLeft: 3, marginTop: 3 }}>
        <img style={{ height: "40rem" }} src={bannerviewImage} alt="" />
        <Box
          sx={{
            height: 100,
            width: 255,
            position: "relative",
            top: -568,
            left: 30,
          }}
        >
          <Carousel
            autoPlay
            showThumbs={false}
            infiniteLoop={true}
            showStatus={true}
          >
            {banners?.map((item, index) => {
              return (
                <div key={{ index }}>
                  <img
                    style={{ height: 100, width: 260 }}
                    alt="Main Banner Image"
                    src={item.image}
                  />
                </div>
              );
            })}
          </Carousel>
        </Box>
        <Box
          sx={{
            height: 100,
            width: 260,
            position: "relative",
            top: -250,
            left: 30,
          }}
        >
          <div>
            <img
              style={{ height: 100, width: 260 }}
              alt="sub Banner Image"
              src={subBannerData?.image}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default BannerCard;
