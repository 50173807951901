import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field, FieldArray, Form, Formik } from "formik";
import { selectAddonSchema } from "../../../schemas";
import { TextField } from "formik-mui";
import { fetchAddOns } from "../../../sevice/group";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const emptyRaw = {
  item: "",
};
let initialValue = {
  title: "",
  titleAr: "",
  minQty: "",
  maxQty: "",
  items: [{ item: "", name: "" }],
};
export default function AddAdOnes({
  close,
  editAddoneIndex,
  title,
  productSetFieldValue,
  values,
  isEdit,
  initialValueOfAddoneEdit,
  setEdit,
}) {
  const { POSadmin, isLanguageSwitched } = useSelector((state) => state);
  const { t } = useTranslation();

  const [allAddons, setAllAddons] = useState([]);
  const [saveAndAdd, setsaveAndAdd] = useState(false);
  useEffect(() => {
    getallAddons();
  }, []);
  const getallAddons = async () => {
    const data = await fetchAddOns(POSadmin.token);
    setAllAddons(data);
  };

  useEffect(() => {
    initialValue = {
      title: title.title,
      titleAr: title.titleAr,
      minQty: "",
      maxQty: "",
      items: [{ item: "", name: "" }],
    };
  }, [title]);
  const submitData = (value) => {
    productSetFieldValue("addOns", [...values.addOns, value]);
    close();
  };
  const updateData = (value) => {
    const updatedData = values.addOns.map((item, index) => {
      if (index === editAddoneIndex) {
        return value;
      } else {
        return item;
      }
    });
    productSetFieldValue("addOns", updatedData);
    setEdit(false);
    close();
  };
  return (
    <div>
      <Formik
        initialValues={isEdit ? initialValueOfAddoneEdit : initialValue}
        validationSchema={selectAddonSchema}
        onSubmit={isEdit ? updateData : submitData}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{ my: "10px" }}
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  {/* {t('addAdons.ADD_ADDONS')} */}
                  Add Add-ons
                </Typography>

                <Button
                  type="submit"
                  sx={{
                    height: "40px",
                    background: "var(--red)",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "var(--red)",
                    },
                  }}
                  onClick={() => {
                    setsaveAndAdd(true);
                  }}
                  variant="contained"
                >
                  {/* {t("addAdons.SAVE&ADD_ADDONS")} */}
                  Save & Add Add-on
                </Button>
              </Box>

              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box>
                  <Box
                    sx={{
                      my: "20px",
                      mx: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box>
                      <Typography style={{ fontSize: "13px" }}>
                        {/* {t("addAdons.ADDON_TITLE")} */}
                        Add on title
                      </Typography>
                      <Field
                        placeholder="Add on Title"
                        sx={{ my: "10px" }}
                        size="small"
                        fullWidth
                        name={"title"}
                        component={TextField}
                      />
                      {isLanguageSwitched && (
                        <Field
                          placeholder="عنوان البند الإضافي"
                          sx={{ my: "10px" }}
                          size="small"
                          fullWidth
                          name={"titleAr"}
                          component={TextField}
                          inputProps={{ style: { textAlign: "right" } }}
                        />
                      )}
                    </Box>

                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Field
                        placeholder={"min"}
                        sx={{ my: "10px" }}
                        size="small"
                        fullWidth
                        name={"minQty"}
                        component={TextField}
                      />

                      <Field
                        placeholder={"mix"}
                        sx={{ my: "10px" }}
                        size="small"
                        fullWidth
                        name={"maxQty"}
                        component={TextField}
                      />
                    </Box>
                    <FieldArray name="items">
                      {({ push, remove }) => (
                        <>
                          {values.items.map((_, index) => (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                my: "5px",
                              }}
                            >
                              {isEdit ? (
                                <FormControl sx={{ width: "100%" }}>
                                  <Autocomplete
                                    options={allAddons}
                                    size="small"
                                    defaultValue={{
                                      title: values.items[index].name
                                        ? values.items[index].name
                                        : "",
                                    }}
                                    getOptionLabel={(option) => option.title}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextField}
                                        {...params}
                                        onChange={(e) => {}}
                                        name={`items.${index}.item`}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        setFieldValue(
                                          `items.${index}.item`,
                                          newValue?._id
                                        );
                                        setFieldValue(
                                          `items.${index}.name`,
                                          newValue?.title
                                        );
                                      } else {
                                        setFieldValue(
                                          `items.${index}.item`,
                                          ""
                                        );
                                        setFieldValue(
                                          `items.${index}.name`,
                                          newValue?.title
                                        );
                                      }
                                    }}
                                  />
                                </FormControl>
                              ) : (
                                <FormControl sx={{ width: "100%" }}>
                                  <Autocomplete
                                    options={allAddons}
                                    size="small"
                                    getOptionLabel={(option) => option.title}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextField}
                                        {...params}
                                        onChange={(e) => {}}
                                        name={`items.${index}.item`}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        setFieldValue(
                                          `items.${index}.item`,
                                          newValue?._id
                                        );
                                        setFieldValue(
                                          `items.${index}.name`,
                                          newValue?.title
                                        );
                                      } else {
                                        setFieldValue(
                                          `items.${index}.item`,
                                          ""
                                        );
                                        setFieldValue(
                                          `items.${index}.name`,
                                          newValue?.title
                                        );
                                      }
                                    }}
                                  />
                                </FormControl>
                              )}

                              <Box
                                sx={{
                                  mt: "9px",
                                  ml: "10px",
                                }}
                              >
                                <DeleteIcon
                                  sx={{ width: "20px" }}
                                  onClick={() => remove(index)}
                                />
                              </Box>
                            </Box>
                          ))}
                          <Button
                            onClick={() => push(emptyRaw)}
                            variant="text"
                            sx={{
                              textTransform: "none",
                              color: "var(--red)",
                            }}
                          >
                            + Add More Option
                            {/* {t("common.ADD_MORE_OPTION")} */}
                          </Button>
                        </>
                      )}
                    </FieldArray>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              {isEdit ? (
                <Button
                  sx={{
                    height: "100%",
                    fontSize: "16px",
                    mr: "10px",
                    mt: "3px",
                    textTransform: "none",
                    color: "var(--bg-white)",
                    background: "var(--red)",
                    "&:hover": {
                      backgroundColor: "var(--red)",
                    },
                  }}
                  type="submit"
                  variant="text"
                >
                  {/* {t("common.UPDATE")} */}
                  Update
                </Button>
              ) : (
                <Button
                  sx={{
                    height: "100%",
                    fontSize: "16px",
                    mr: "10px",
                    mt: "3px",
                    textTransform: "none",
                    color: "var(--bg-white)",
                    background: "var(--red)",
                    "&:hover": {
                      backgroundColor: "var(--red)",
                    },
                  }}
                  type="submit"
                  variant="text"
                  onClick={() => {
                    setsaveAndAdd(false);
                  }}
                >
                  {/* {t("common.NEXT")} */}
                  Next
                </Button>
              )}
              <Button
                sx={{
                  height: "100%",
                  fontSize: "16px",
                  mt: "3px",
                  textTransform: "none",
                  color: "var(--font-black)",
                  background: "var(--button-gray)",
                  "&:hover": {
                    backgroundColor: "var(--button-gray)",
                  },
                }}
                variant="text"
                onClick={close}
              >
                {/* {t("common.CANCEL")} */}
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}
