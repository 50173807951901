import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field, FieldArray, Form, Formik } from "formik";
import { addVariationArSchema, addVariationSchema } from "../../../schemas";
import { TextField } from "formik-mui";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const emptyRaw = {
  option: "",
  optionAr: "",
};
export default function AddVariationOptionModal({
  allProperty,
  isEdit,
  SetIsEdit,
  editVariationIndex,
  initialVariationOptionupdation,
  setAllProperty,
  close,
  initialVariationOption,
  setFieldValue,
}) {
  const { isLanguageSwitched } = useSelector((state) => state);
  const { t } = useTranslation();

  const [render, setRender] = useState(false);
  const submitdata = async (values, action) => {
    let data = values.items.map((item) => {
      return { option: item.option, optionAr: item.optionAr };
    });
    data.unshift({ title: values.title, titleAr: values.titleAr });
    setAllProperty([...allProperty, data]);
    setRender(true);
  };
  const updateData = async (values, action) => {
    let data = values.items.map((item) => {
      return { option: item.option, optionAr: item.optionAr };
    });
    data.unshift({ title: values.title, titleAr: values.titleAr });
    const updatedData = allProperty.map((prop, propIndex) => {
      if (editVariationIndex === propIndex) {
        return data;
      } else {
        return prop;
      }
    });
    SetIsEdit(false);
    setAllProperty(updatedData);
    setRender(true);
  };

  const deleteVariation = () => {
    const updatedData = allProperty.filter((item, propIndex) => {
      if (propIndex !== editVariationIndex) {
        return item;
      }
    });

    SetIsEdit(false);
    setAllProperty(updatedData);
    setRender(true);
  };

  useEffect(() => {
    if (render) {
      generateProduct();
    }
  }, [allProperty]);

  const generateProduct = () => {
    let property1Val = "";
    let property2Val = "";
    let property3Val = "";
    let property4Val = "";
    let property5Val = "";
    let property1ValAr = "";
    let property2ValAr = "";
    let property3ValAr = "";
    let property4ValAr = "";
    let property5ValAr = "";
    let variation1 = [];
    let variation2 = [];
    let variation3 = [];
    let variation4 = [];
    let variation5 = [];
    function findCombinations(arrays, currentCombination = []) {
      if (!arrays.length) {
        return [currentCombination];
      }
      const [currentArray, ...remainingArrays] = arrays;
      const combinations = [];
      for (const element of currentArray) {
        const newCombination = [...currentCombination, element];
        const subCombinations = findCombinations(
          remainingArrays,
          newCombination
        );
        combinations.push(...subCombinations);
      }
      return combinations;
    }
    const newArray = allProperty.map((nestedArray) => nestedArray.slice(1));
    const newArray2 = newArray.filter((nestedArray) => {
      if (nestedArray.length > 0) {
        return nestedArray.slice(1);
      }
    });
    const combinations = findCombinations(newArray2);
    let arrr = [];
    combinations.forEach((item, index) => {
      let objValue = {};
      allProperty.forEach((item2, index2) => {
        if (item2.length > 1) {
          let data;
          if (index2 === 0) {
            data = {
              value1: combinations[index][index2].option,
              value1Ar: combinations[index][index2].optionAr,
            };
          } else if (index2 === 1) {
            data = {
              value2: combinations[index][index2].option,
              value2Ar: combinations[index][index2].optionAr,
            };
          } else if (index2 === 2) {
            data = {
              value3: combinations[index][index2].option,
              value3Ar: combinations[index][index2].optionAr,
            };
          } else if (index2 === 3) {
            data = {
              value4: combinations[index][index2].option,
              value4Ar: combinations[index][index2].optionAr,
            };
          } else {
            data = {
              value5: combinations[index][index2].option,
              value5Ar: combinations[index][index2].optionAr,
            };
          }
          let data2 = objValue;
          objValue = Object.assign({}, data2, data);
        }
      });
      arrr.push({
        image: "",
        productName: "",
        sku: "",
        stock: 0,
        unitPrice: 0,
        salePrice: 0,
        ...objValue,
        sellerStatus: true,
        isDeleted: false,
      });
    });

    allProperty.map((item, index) => {
      if (index === 0 && item.length > 1) {
        property1Val = item[0].title.trim();
        property1ValAr = item[0].titleAr.trim();
        // variation1 = item.slice(1);
      } else if (index === 1 && item.length > 1) {
        property2Val = item[0].title.trim();
        property2ValAr = item[0].titleAr.trim();
        // variation2 = item.slice(1);
      } else if (index === 2 && item.length > 1) {
        property3Val = item[0].title.trim();
        property3ValAr = item[0].titleAr.trim();
        // variation3 = item.slice(1);
      } else if (index === 3 && item.length > 1) {
        property4Val = item[0].title.trim();
        property4ValAr = item[0].titleAr.trim();
        // variation4 = item.slice(1);
      } else if (index === 4 && item.length > 1) {
        property5Val = item[0].title.trim();
        property5ValAr = item[0].titleAr.trim();
        // variation5 = item.slice(1);
      }
    });

    setFieldValue("varients", arrr);
    setFieldValue("property1", property1Val);
    setFieldValue("property2", property2Val);
    setFieldValue("property3", property3Val);
    setFieldValue("property4", property4Val);
    setFieldValue("property5", property5Val);
    setFieldValue("property1Ar", property1ValAr);
    setFieldValue("property2Ar", property2ValAr);
    setFieldValue("property3Ar", property3ValAr);
    setFieldValue("property4Ar", property4ValAr);
    setFieldValue("property5Ar", property5ValAr);
    // setFieldValue("variation1Values", variation1);
    // setFieldValue("variation2Values", variation2);
    // setFieldValue("variation3Values", variation3);
    // setFieldValue("variation4Values", variation4);
    // setFieldValue("variation5Values", variation5);
    close();
  };

  return (
    <div>
      <Formik
        initialValues={
          isEdit ? initialVariationOptionupdation : initialVariationOption
        }
        validationSchema={
          isLanguageSwitched ? addVariationArSchema : addVariationSchema
        }
        onSubmit={isEdit ? updateData : submitdata}
      >
        {({ values }) => (
          <Form autoComplete="off">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Add Variants
                  {/* {t('addVariationOptionModal.ADD_VARIANT')} */}
                </Typography>
                {isEdit && (
                  <DeleteIcon
                    sx={{ width: "20px", color: "var(red)" }}
                    onClick={() => deleteVariation()}
                  />
                )}
              </Box>
              <Typography style={{ fontSize: "13px" }}>
                {/* {t("addVariationOptionModal.TITLE_OF_VARIANT")} */}
                Title of the variant
              </Typography>{" "}
              {isEdit ? (
                <>
                  <Field
                    sx={{ mb: "10px", background: "var(--light-gray)" }}
                    size="small"
                    fullWidth
                    name={`title`}
                    component={TextField}
                  />
                  {isLanguageSwitched ? (
                    <Field
                      sx={{ mb: "10px", background: "var(--light-gray)" }}
                      size="small"
                      fullWidth
                      name={`titleAr`}
                      component={TextField}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {values.title}
                </Typography>
              )}
            </Box>
            <FieldArray name="items">
              {({ push, remove }) => (
                <>
                  {values.items.map((_, index) => (
                    <>
                      <Box key={index} sx={{ display: "flex" }}>
                        <Box sx={{ width: "100%" }}>
                          <Field
                            sx={{ my: "10px" }}
                            size="small"
                            fullWidth
                            name={`items.${index}.option`}
                            component={TextField}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            ml: "10px",
                          }}
                        >
                          <DeleteIcon
                            sx={{ width: "20px" }}
                            onClick={() => {
                              remove(index);
                            }}
                          />
                        </Box>
                      </Box>

                      {isLanguageSwitched ? (
                        <Box key={index} sx={{ display: "flex" }}>
                          <Box sx={{ width: "100%" }}>
                            <Field
                              sx={{ my: "10px" }}
                              size="small"
                              fullWidth
                              name={`items.${index}.optionAr`}
                              component={TextField}
                              inputProps={{ style: { textAlign: "right" } }}
                              placeholder="في العربية"
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ml: "10px",
                            }}
                          >
                            <DeleteIcon
                              sx={{ width: "20px" }}
                              onClick={() => {
                                remove(index);
                              }}
                            />
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                  <Button
                    onClick={() => {
                      push(emptyRaw);
                    }}
                    variant="text"
                    sx={{
                      textTransform: "none",
                      color: "var(--red)",
                    }}
                  >
                    + Add More Option
                    {/* {t("common.ADD_MORE_OPTION")} */}
                  </Button>
                </>
              )}
            </FieldArray>

            <Box sx={{ display: "flex", justifyContent: "end" }}>
              {isEdit ? (
                <Button
                  sx={{
                    height: "100%",
                    fontSize: "16px",
                    mr: "10px",
                    mt: "3px",
                    textTransform: "none",
                    color: "var(--bg-white)",
                    background: "var(--red)",
                    "&:hover": {
                      backgroundColor: "var(--red)",
                    },
                  }}
                  type="submit"
                  variant="text"
                >
                  {/* {t("common.UPDATE")} */}
                  Update
                </Button>
              ) : (
                <Button
                  sx={{
                    height: "100%",
                    fontSize: "16px",
                    mr: "10px",
                    mt: "3px",
                    textTransform: "none",
                    color: "var(--bg-white)",
                    background: "var(--red)",
                    "&:hover": {
                      backgroundColor: "var(--red)",
                    },
                  }}
                  type="submit"
                  variant="text"
                >
                  {/* {t("common.NEXT")} */}
                  Next
                </Button>
              )}

              <Button
                sx={{
                  height: "100%",
                  fontSize: "16px",
                  mt: "3px",
                  textTransform: "none",
                  color: "var(--font-black)",
                  background: "var(--button-gray)",
                  "&:hover": {
                    backgroundColor: "var(--button-gray)",
                  },
                }}
                variant="text"
                onClick={close}
              >
                {/* {t("common.CANCEL")} */}
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}
