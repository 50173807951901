import { Box, Button, Typography, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import PasswordIcon from "@mui/icons-material/Password";
import { useState } from "react";
import { verifyResetPasswordCode } from "../../../sevice/admin";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { codeVerifySchema } from "../../../schemas";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
  },
});

const style = {
  color: "red",
  fontSize: "1rem",
  margin: 0,
  fontFamily: "poppins",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  pb: "2rem",
};

function CodeVerification({ setState, data }) {
  const [isLogin, setIsLogin] = useState(false);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: codeVerifySchema,
    onSubmit: async (values) => {
      setIsLogin(true);
      const resp = await verifyResetPasswordCode(data?.email, values?.code);
      if (resp.status) {
        setState("change");
      } else {
        setIsLogin(false);
        toast.error(resp.message);
      }
    },
  });
  return (
    <Box sx={{ width: "30vw" }} component="form" onSubmit={formik.handleSubmit}>
      <Box sx={{ pb: "2.1rem" }}>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontFamily: "poppins",
          }}
        >
          Code Verification
        </Typography>
      </Box>
      <Box sx={{ pb: "1rem" }}>
        <Box
          sx={{
            border: "1px solid #EEEEEE",
            width: "100%",
            height: "60px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            px: "1.5rem",
            bgcolor: "#F5F5F5",
          }}
        >
          <PasswordIcon sx={{ fontSize: "1.7rem", color: "#92929B" }} />
          <TextField
            variant="outlined"
            type="number"
            placeholder="Enter Your Code"
            sx={{
              "& input::placeholder": {
                fontFamily: "poppins",
              },
              "& fieldset": { border: "none" },
              width: "100%",
            }}
            name="code"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.code}
          />
        </Box>
      </Box>
      {formik.touched.code && formik.errors.code && (
        <p style={style}>{formik.errors.code}</p>
      )}
      <Box sx={{ pb: "1.5rem", pt: "1.1rem" }}>
        <Button
          type="submit"
          disabled={isLogin}
          sx={{
            background: "#F40000",
            width: "100%",
            height: "60px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            px: "1.5rem",
            color: "white",
            "&:hover": {
              color: "white",
              background: "#F40000",
            },
          }}
        >
          {isLogin ? (
            <ThemeProvider theme={customTheme}>
              <CircularProgress color="primary" />
            </ThemeProvider>
          ) : (
            <Typography
              sx={{
                color: "white",
                fontSize: "1rem",
                fontFamily: "poppins",
              }}
            >
              Submit
            </Typography>
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default CodeVerification;
