import { useEffect, useState } from "react";
import AdminSubNavbar from "./../components/common/SubNavbar";
import { Toolbar } from "@mui/material";
import AllTable from "./../components/posAdminPanel/table/AllTable";
import { TabContext, TabPanel } from "@mui/lab";
import AllArea from "./../components/posAdminPanel/table/AllArea";
import FlowDirection from "./../components/posAdminPanel/navBarAndSideBar/FlowDirection";
import { useNavigate } from "react-router-dom";

export default function Table() {
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const navBarItems = [
    {
      label: "Table",
      value: "1",
    },
    {
      label: "Area",
      value: "2",
    },
  ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let [flowDirectionItems, setFlowDirectionItems] = useState([
    {
      name: "Settings",
      show: true,
    },
  ]);

  useEffect(() => {
    ChangeflowDirectionItem(value);
  }, [value]);

  const ChangeflowDirectionItem = (newValue) => {
    navBarItems.filter((item) => {
      if (newValue === item.value) {
        setFlowDirectionItems([
          {
            name: "Settings",
            show: true,
          },
          {
            name: item.label,
            show: true,
          },
        ]);
      }
    });
  };
  const backFunction = () => {
    if (value === "1") {
      navigate("/settings");
    } else {
      setValue("1");
    }
  };
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Toolbar />
      <FlowDirection
        items={flowDirectionItems}
        showBack={true}
        backFunction={backFunction}
      />
      <TabContext value={value}>
        <AdminSubNavbar
          handleChange={handleChange}
          items={navBarItems}
          value={value}
        />
        <TabPanel value="1">
          <AllTable />
        </TabPanel>
        <TabPanel value="2">
          <AllArea />
        </TabPanel>
      </TabContext>
    </div>
  );
}
