import { useEffect, useState } from "react";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Chart from "react-apexcharts";
import { getMostUsingRawMaterials } from "../../../sevice/inventory";
import { useSelector } from "react-redux";
import moment from "moment/moment";

export default function UsageChart() {
  const [timePeriod, setTimePeriod] = useState("week");

  const handleChange = (event) => {
    setTimePeriod(event.target.value);
  };

  const { POSadmin } = useSelector((state) => state);

  const [series, setSeries] = useState([]);
  const [day, setDay] = useState([]);
  const getDatas = async () => {
    const data = await getMostUsingRawMaterials(timePeriod, POSadmin.token);
    setSeries(data.data);
    setDay(data.days);
  };
  useEffect(() => {
    getDatas();
  }, [timePeriod]);

  var options = {
    stroke: { width: 2 },
    chart: {
      type: "line",
      height: 350,
      zoom: false,
      animations: { easing: "easeinout" },
    },

    xaxis: {
      categories:
        timePeriod == "week"
          ? [
              moment(new Date(day[0])).format("ddd"),
              moment(new Date(day[1])).format("ddd"),
              moment(new Date(day[2])).format("ddd"),
              moment(new Date(day[3])).format("ddd"),
              moment(new Date(day[4])).format("ddd"),
              moment(new Date(day[5])).format("ddd"),
              moment(new Date(day[6])).format("ddd"),
            ]
          : day.map((item, index) => {
              return moment(new Date(day[index])).format("MMM");
            }),
    },
    yaxis: {
      min: 0,
      max: 600,
      tickAmount: 6,
      title: {
        text: "UNIT - KG/L/PCS",
      },
    },
  };
  return (
    <Card variant="outlined" sx={{ position: "relative", minWidth: "55%" }}>
      <CardContent>
        <Box sx={{ display: "flex", mb: 1 }}>
          <Inventory2OutlinedIcon sx={{ marginRight: 1 }} />
          <Typography sx={{ fontSize: 18 }}>
            Raw material usage (Top 4)
            <FormControl
              sx={{ px: 1, minWidth: 90, position: "absolute", right: 0 }}
              size="small"
            >
              <Select
                value={timePeriod}
                onChange={handleChange}
                displayEmpty
                sx={{ fontSize: 12 }}
              >
                {/* <MenuItem value="day">Day</MenuItem> */}
                <MenuItem value="week">Week</MenuItem>
                {/* <MenuItem value="month">Month</MenuItem> */}
                <MenuItem value="year">Year</MenuItem>
              </Select>
            </FormControl>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            overflowX: "auto",
          }}
        >
          {series?.map((item, index) => (
            <Card
              variant="outlined"
              sx={{
                minWidth: "150px",
                flex: 1,
                my: 1,
                borderTop:
                  index === 0
                    ? "6px solid #0095F7"
                    : index === 1
                    ? "6px solid #20C83B"
                    : index === 2
                    ? "6px solid #FE9E00"
                    : "6px solid #F40000",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 14 }}>{item.name}</Typography>
                <Typography sx={{ my: 1 }} variant="h5">
                  <span style={{ fontSize: "18px" }}>{POSadmin.symbol} </span>{" "}
                  {item.price?.toFixed(2)} &nbsp;
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>

        <Box sx={{ height: "220px", flex: 1, mt: 2 }}>
          <Chart options={options} series={series} type="line" height={220} />
        </Box>
      </CardContent>
    </Card>
  );
}
