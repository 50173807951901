import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const createAddon = async (token, values) => {
  try {
    const { data } = await axios.post(`${host}/posCreateAddon`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const fetchAddOns = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posFetchAddOns`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteAddon = async (id, token) => {
  try {
    const { data } = await axios.delete(`${host}/posDeleteAddon/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateAddon = async (token, id, value) => {
  try {
    const { data } = await axios.put(`${host}/posUpdateAddon/${id}`, value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteProductFromGroup = async (proId, groupId, token) => {
  try {
    const { data } = await axios.delete(
      `${host}/posDeleteProductFromGroup/${proId}/${groupId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteGroup = async (id, token) => {
  try {
    const { data } = await axios.delete(`${host}/posDeleteGroup/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};



