import styled from "@emotion/styled";
import {
  Typography,
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  RadioGroup,
} from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import { TextField } from "formik-mui";
import { useSelector } from "react-redux";
import { addMenuSchema } from "../../../schemas";
import { editMenu } from "../../../sevice/inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-hot-toast";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));

const options = [
  {
    label: "Sun",
    value: "Sun",
  },
  {
    label: "Mon",
    value: "Mon",
  },
  {
    label: "Tue",
    value: "Tue",
  },
  {
    label: "Wed",
    value: "Wed",
  },
  {
    label: "Thu",
    value: "Thu",
  },
  {
    label: "Fri",
    value: "Fri",
  },
  {
    label: "Sat",
    value: "Sat",
  },
];

function EditMenu({ editItem, handleClose, products }) {
  const { POSadmin } = useSelector((state) => state);

  const initialValues = {
    title: editItem.title,
    autoPurchase: editItem.autoPurchase,
    days: editItem.days,
    products: editItem.products,
  };
  const emptyMenu = { item: "", days: [], quantity: "" };

  const submitdata = async (values, action) => {
    const data = await editMenu(POSadmin.token, values, editItem._id);
    if (data.status) {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
    }
  };

  return (
    <>
      {products.length && (
        <Box
          sx={{
            maxHeight: "75vh",
            overflow: "scroll",
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar
            },
            bgcolor: "white",
            boxShadow: 24,
            px: 4,
            py: 2,
            borderRadius: "6px",
            minHight: 50,
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={addMenuSchema}
            onSubmit={submitdata}
          >
            {({ values, setFieldValue }) => (
              <Form autoComplete="off">
                <Box>
                  <Typography sx={{ p: 3 }} variant="h6">
                    Add Menu
                  </Typography>
                  <Card
                    elevation={0}
                    sx={{
                      width: "100%",
                      height: "280px",
                      marginRight: "15px",
                      marginBottom: "15px",
                      padding: 3,
                      border: "1px solid #ccc",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "40%",
                        }}
                      >
                        <InputLabel>Title</InputLabel>
                        <Field name={"title"} component={TextField} />
                        <Box sx={{ p: 5 }}>
                          <FormGroup>
                            <Grid container spacing={3}>
                              {options.map((opt) => (
                                <Grid xs={2} sm={4} md={3} lg={3}>
                                  <Field
                                    type="checkbox"
                                    component={CheckboxWithLabel}
                                    name="days"
                                    key={opt.value}
                                    value={opt.value}
                                    onChange={(e) => {
                                      const arr = values.days;
                                      if (e.target.checked) {
                                        arr.push(opt.value);
                                      } else {
                                        const index = arr.indexOf(opt.value);
                                        if (index !== -1) {
                                          arr.splice(index, 1);
                                        }
                                      }
                                      setFieldValue(`days`, arr);
                                    }}
                                    checked={values.days.includes(opt.value)}
                                    Label={{ label: opt.label }}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </FormGroup>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <InputLabel>Auto Purchase</InputLabel>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="autoPurchase"
                            value={values.autoPurchase}
                            onChange={(e) => {
                              setFieldValue("autoPurchase", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                            <FormControlLabel
                              value="Daily"
                              control={<Radio />}
                              label="Daily"
                            />
                            <FormControlLabel
                              value="Weekly"
                              control={<Radio />}
                              label="Weekly"
                            />
                            <FormControlLabel
                              value="Monthly"
                              control={<Radio />}
                              label="Monthly"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Card>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead sx={{ background: "var(--table-head)" }}>
                        <TableRow>
                          <StyledTableCell align="center">Item</StyledTableCell>
                          <StyledTableCell align="center">Days</StyledTableCell>
                          <StyledTableCell align="center">Qty</StyledTableCell>
                          <StyledTableCell align="center">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <FieldArray name="products">
                        {({ push, remove }) => (
                          <>
                            <TableBody>
                              {values.products.map((_, index) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    <Autocomplete
                                      size="small"
                                      options={products}
                                      value={products.find(
                                        (item) =>
                                          values.products[index].item == item.id
                                      )}
                                      getOptionLabel={(option) => option.item}
                                      renderInput={(params) => (
                                        <Field
                                          component={TextField}
                                          {...params}
                                          value={params.id}
                                          name={`products.${index}.item`}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      )}
                                      onChange={(event, newValue) => {
                                        if (newValue) {
                                          setFieldValue(
                                            `products.${index}.item`,
                                            newValue?.id
                                          );
                                        } else {
                                          setFieldValue(
                                            `products.${index}.item`,
                                            ""
                                          );
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <FormControl component="fieldset">
                                      <FormGroup>
                                        <Grid
                                          container
                                          spacing={3}
                                          sx={{ padding: 4 }}
                                        >
                                          {options.map((opt) => (
                                            <Grid xs={2} sm={4} md={3} lg={3}>
                                              <Field
                                                disabled={
                                                  !values.days.includes(
                                                    opt.value
                                                  )
                                                }
                                                type="checkbox"
                                                component={CheckboxWithLabel}
                                                name={`products.${index}.days`}
                                                key={opt.value}
                                                value={opt.value}
                                                onChange={(e) => {
                                                  const arr =
                                                    values.products[index].days;
                                                  if (e.target.checked) {
                                                    arr.push(opt.value);
                                                  } else {
                                                    const index = arr.indexOf(
                                                      opt.value
                                                    );
                                                    if (index !== -1) {
                                                      arr.splice(index, 1);
                                                    }
                                                  }
                                                  setFieldValue(
                                                    `products.${index}.days`,
                                                    arr
                                                  );
                                                }}
                                                checked={values.products[
                                                  index
                                                ].days.includes(opt.value)}
                                                Label={{ label: opt.label }}
                                              />
                                            </Grid>
                                          ))}
                                        </Grid>
                                      </FormGroup>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Field
                                      size="small"
                                      name={`products.${index}.quantity`}
                                      component={TextField}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <DeleteIcon onClick={() => remove()} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            <Button
                              onClick={() => push(emptyMenu)}
                              variant="contained"
                              sx={{
                                m: 3,
                                width: 105,
                                textTransform: "none",
                                background: "var(--red)",
                                "&:hover": {
                                  backgroundColor: "var(--red)",
                                },
                              }}
                            >
                              + Add Row
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var( --color-secondary)",
                        "&:hover": {
                          backgroundColor: "var( --color-secondary)",
                        },
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var(--red)",
                        "&:hover": {
                          backgroundColor: "var(--red)",
                        },
                      }}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </>
  );
}

export default EditMenu;
