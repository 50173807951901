import { useEffect, useState } from "react";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Chart from "react-apexcharts";
import { posGetNetProfitPerSales } from "../../../sevice/inventory";
import { useSelector } from "react-redux";

export default function ProfitChart() {
  const { POSadmin } = useSelector((state) => state);
  const [timePeriod, setTimePeriod] = useState("week");
  const handleChange = (event) => {
    setTimePeriod(event.target.value);
  };

  useEffect(() => {
    findProfitData();
  }, []);

  const [series, setSeries] = useState([
    {
      name: "Revenue Growth",
      data: [],
    },
  ]);

  const [label, setLabel] = useState([]);

  const findProfitData = async () => {
    const data = await posGetNetProfitPerSales(POSadmin.token);
    const title = data??[]?.map((item) => item.title);
    setLabel(title);
    const newData = data??[]?.map((item) => parseFloat(item.profit));
    setSeries((prevSeries) => [{ ...prevSeries[0], data: newData }]);
  };

  useEffect(() => {
    findProfitData();
  }, []);

  const [options] = useState({
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    yaxis: {
      min: 0,
      max: 1000,
      tickAmount: 5,
    },
  });

  return (
    <Card variant="outlined" sx={{ position: "relative", minWidth: "44%" }}>
      <CardContent>
        <Box sx={{ display: "flex", ml: 1 }}>
          <Inventory2OutlinedIcon sx={{ marginRight: 1 }} />
          <Typography sx={{ fontSize: 18 }}>
            Net Profit per 10 sales (Product)
            {/* <FormControl
            sx={{ px: 1, minWidth: 90, position: "absolute", right: 0 }}
            size="small"
            >
            <Select
              value={timePeriod}
              onChange={handleChange}
              displayEmpty
              sx={{ fontSize: 12 }}
              >
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
              </Select>
            </FormControl> */}
          </Typography>
        </Box>
        <Box sx={{ my: 1, flex: 1 }}>
          <Chart
            options={{
              options,
              xaxis: {
                categories: label,
              },
            }}
            series={series}
            type="bar"
            height={350}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
