import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  styled,
  TableFooter,
  Stack,
  Pagination,
  Modal,
  RadioGroup,
  Typography,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import DatePicker from "./DatePicker";
import { addEntrySchema } from "../../../schemas";
import {
  getClosingBalance,
  posAddAccountEntry,
  posGetAllEntries,
} from "../../../sevice/reports";
import { DateFormatterWithTime } from "../../../utils/DateFormatter";

const DebitCredit = styled(Box)({
  display: "flex",
  width: 100,
  height: 40,
  color: "#00876",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
}));

export default function Accounts({setShowExpense}) {
  const { POSadmin } = useSelector((state) => state);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [clsBalance, setClsBalance] = useState({});
  const [entries, setEntries] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const initialValues = {
    particular: "",
    cashAmount: 0,
    bankAmount: 0,
    paymentType: "Credit",
    // cashBalance: 0,
    // bankBalance: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addEntrySchema,
    onSubmit: async (values, action) => {
      const data = await posAddAccountEntry(POSadmin.token, values);
      if (data.status) {
        toast.success(data.message);
        action.resetForm();
        handleClose();
        getData();
      } else {
        toast.error(data.error);
      }
    },
  });

  const getData = async () => {
    const entries = await posGetAllEntries(
      POSadmin.token,
      startDate,
      endDate,
      pageNo
    );
    setEntries(entries);
    const closing = await getClosingBalance(POSadmin.token);
    setClsBalance(closing);
  };

  useEffect(() => {
    getData();
  }, [pageNo]);


  const handleopenExpense =()=>{
    setShowExpense(true)
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel sx={{ color: "#000" }} id="sortby-label">
                Date Range
              </FormLabel>
              <DatePicker setStartDate={setStartDate} setEndDate={setEndDate} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <br />
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#F40000",
                  "&:hover": {
                    backgroundColor: "#F40000",
                  },
                }}
                onClick={() => {
                  getData();
                  setPageNo(1);
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              height:"40px",
              gap: "5px",
            }}
          >
            <Button
              onClick={handleopenExpense}
              variant="contained"
              sx={{
                bgcolor: "#F40000",
                "&:hover": {
                  backgroundColor: "#F40000",
                },
              }}
            >
              Add Expense
            </Button>
            <Button
              onClick={handleOpen}
              variant="contained"
              sx={{
                bgcolor: "#F40000",
                "&:hover": {
                  backgroundColor: "#F40000",
                },
              }}
            >
              Add Entry
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <form onSubmit={formik.handleSubmit}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    bgcolor: "background.paper",
                    borderRadius: "5px",
                    boxShadow: 24,
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <>
                    <Typography variant="h6" sx={{ fontWeight: "550" }}>
                      Add Entry
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        value={formik.values.paymentType}
                        onChange={(e) => {
                          formik.setFieldValue("paymentType", e.target.value);
                        }}
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Credit"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Credit"
                          control={<Radio />}
                          label="Credit"
                        />
                        <FormControlLabel
                          value="Debit"
                          control={<Radio />}
                          label="Debit"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormLabel sx={{ color: "#000" }} id="sortby-label">
                      Cash
                    </FormLabel>
                    <TextField
                      id="cashAmount"
                      variant="outlined"
                      name="cashAmount"
                      size="small"
                      value={formik.values.cashAmount}
                      onChange={formik.handleChange}
                      error={
                        !!formik.touched.cashAmount &&
                        !!formik.errors.cashAmount
                      }
                      helperText={
                        formik.touched.cashAmount && formik.errors.cashAmount
                      }
                    />
                    <FormLabel sx={{ color: "#000" }} id="sortby-label">
                      Bank
                    </FormLabel>
                    <TextField
                      id="bankAmount"
                      variant="outlined"
                      name="bankAmount"
                      size="small"
                      value={formik.values.bankAmount}
                      onChange={formik.handleChange}
                      error={
                        !!formik.touched.bankAmount &&
                        !!formik.errors.bankAmount
                      }
                      helperText={
                        formik.touched.bankAmount && formik.errors.bankAmount
                      }
                    />
                    <FormLabel sx={{ color: "#000" }} id="sortby-label">
                      Particulars
                    </FormLabel>
                    <TextField
                      id="particular"
                      variant="outlined"
                      name="particular"
                      size="small"
                      value={formik.values.particular}
                      onChange={formik.handleChange}
                      error={
                        !!formik.touched.particular &&
                        !!formik.errors.particular
                      }
                      helperText={
                        formik.touched.particular && formik.errors.particular
                      }
                      multiline
                      rows={4}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          bgcolor: "#F40000",
                          "&:hover": {
                            backgroundColor: "#F40000",
                          },
                          width: "100px",
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{
                          bgcolor: "#8B8B8B",
                          "&:hover": {
                            backgroundColor: "#8B8B8B",
                          },
                          width: "100px",
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                </Box>
              </form>
            </Modal>
          </Box>
        </FormControl>
        <Box
          sx={{ height: "100%", flex: 1, overflowY: "auto", overflowX: "auto" }}
        >
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ bgcolor: "#D9D9D9" }}>
                  <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", fontFamily: "poppins" }}
                  >
                    Particular
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", fontFamily: "poppins" }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", fontFamily: "poppins" }}
                  >
                    Cash
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", fontFamily: "poppins" }}
                  >
                    Bank
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", fontFamily: "poppins" }}
                  >
                    Cash Bal
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", fontFamily: "poppins" }}
                  >
                    Bank Bal
                  </TableCell>
                  {/* <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", fontFamily: "poppins" }}
                  >
                    Action
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {entries?.docs?.map((row) => (
                  <StyledTableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <DateFormatterWithTime date={row.updatedAt} />
                    </TableCell>

                    <TableCell align="center" sx={{ fontFamily: "poppins" }}>
                      {row.particular}
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: "15rem" }}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {row.paymentType === "Debit" ? (
                          <DebitCredit
                            sx={{
                              background: "#FF9999",
                              fontFamily: "poppins",
                            }}
                          >
                            {row.paymentType}
                          </DebitCredit>
                        ) : row.paymentType === "Credit" ? (
                          <DebitCredit
                            sx={{
                              background: "#cfead9",
                              fontFamily: "poppins",
                            }}
                          >
                            {row.paymentType}
                          </DebitCredit>
                        ) : (
                          ""
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      {Number(row.cashAmount).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      {Number(row.bankAmount).toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", fontFamily: "poppins" }}
                    >
                      {Number(row.cashBalance).toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", fontFamily: "poppins" }}
                    >
                      {Number(row.bankBalance).toFixed(2)}
                    </TableCell>
                    {/* <TableCell align="center"> */}
                    {/* <RemoveRedEyeIcon />  */}
                    {/* <DownloadIcon />
                    </TableCell> */}
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ bgcolor: "#F2F2F2", width: "100%" }}>
                  <TableCell
                    colSpan={4}
                    sx={{ fontWeight: "bold", fontFamily: "poppins" }}
                  ></TableCell>

                  <TableCell
                    colSpan={2}
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      fontSize: 14,
                    }}
                  >
                    Cash in Hand :&nbsp; ₹
                    {clsBalance?.cashBalance
                      ? Number(clsBalance?.cashBalance).toFixed(2)
                      : 0}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      fontSize: 14,
                    }}
                  >
                    Cash in Bank :&nbsp; ₹
                    {clsBalance?.bankBalance
                      ? Number(clsBalance?.bankBalance).toFixed(2)
                      : 0}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <Box
            component={Paper}
            sx={{
              marginTop: "10px",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <Stack>
              <Pagination
                count={entries?.totalPages}
                page={pageNo}
                onChange={(event, value) => setPageNo(value)}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
}
