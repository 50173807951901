import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Icon from "../../../assets/Logo.png";
import Cookies from "js-cookie";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { CssBaseline, FormControlLabel, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IOSSwitch from "../../../utils/IOSSwitch";
import { posGetShopStatus, updateShopStatus } from "../../../sevice/admin";
import { toast } from "react-hot-toast";

export default function ClippedDrawer({
  open,
  handleDrawerOpen,
  handleDrawerClose,
}) {
  const { POSadmin } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState({});

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    Cookies.set("POSadmin", "");
    dispatch({
      type: "LOGOUT",
    });
    navigate("/login");
  };

  const checkExpiration = setInterval(() => {
    const expirationTime = localStorage.getItem("expirationTime");
    if (expirationTime && Date.now() > expirationTime) {
      // If the expiration time has passed, log the user out
      clearInterval(checkExpiration);
      localStorage.removeItem("expirationTime");
      handleLogout();
    }
  }, 60 * 60 * 1000); // Every hour, check

  const handleUpdate = async (checked, key) => {
    let option = {};
    if (key === "Delivery") {
      option.isDelivering = checked;
    } else if (key === "Shop") {
      option.isShopOpen = checked;
    }
    const res = await updateShopStatus(option, POSadmin?.token);
    if (res.status) toast.success(res.message);
  };

  const getdatas = async () => {
    const res = await posGetShopStatus(POSadmin?.token);
    setStatus(res);
  };

  useEffect(() => {
    getdatas();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 200,
          background: "var(--bg-white)",
          borderBottom: "solid var(--border-gray) 1px",
        }}
        elevation={0}
      >
        <Toolbar>
          <Box
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "var(--font-black)" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <IconButton
                  color="inherit"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 2,
                    ...(open && { display: "none" }),
                    mb: "5px",
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={handleDrawerClose}
                  edge="start"
                  sx={{
                    marginRight: 2,
                    ...(!open && { display: "none" }),
                  }}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Box>
              <Box>
                <img style={{ width: "7rem" }} src={Icon} alt="vatmac-icon" />
              </Box>
            </Box>
          </Box>

          <FormControlLabel
            labelPlacement="top"
            label={
              <Typography
                sx={{
                  color: "black",
                  fontSize: ".9rem",
                  fontFamily: "poppins",
                  paddingBottom:"3px"
                }}
              >
                Shop Open
              </Typography>
            }
            control={
              <IOSSwitch
                size={"small"}
                checked={status?.isShopOpen}
                onChange={(e) => handleUpdate(e.target.checked, "Shop")}
              />
            }
          />
          <FormControlLabel
            labelPlacement="top"
            label={
              <Typography
                sx={{
                  color: "black",
                  fontSize: ".9rem",
                  fontFamily: "poppins",
                  paddingBottom:"3px"

                }}
              >
                Dellivery
              </Typography>
            }
            control={
              <IOSSwitch
                size={"small"}
                checked={status?.isDelivering}
                onChange={(e) => handleUpdate(e.target.checked, "Delivery")}
              />
            }
          />

          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="white"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ zIndex: "1400" }}
            >
              <MenuItem onClick={handleClose}>{POSadmin?.username}</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
