import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfitChart from "./ProfitChart";
import StatsCard from "./StatsCard";
import UsageChart from "./UsageChart";
import { getRecipeCount, getWastageAmount } from "../../../sevice/inventory";
import { useSelector } from "react-redux";

export default function Dashboard() {
  const { POSadmin } = useSelector((state) => state);
  const [wastageFilter, setWastageFilter] = useState("day");
  const [wastage, setWastage] = useState({ count: 0, preCount: 0 });
  const [recipe, setRecipe] = useState(0);

  const getDatas = async () => {
    const data = await getWastageAmount(wastageFilter, POSadmin.token);
    const data1 = await getRecipeCount(POSadmin.token);
    setWastage(data);
    setRecipe(data1.count);
  };
  useEffect(() => {
    getDatas();
  }, [wastageFilter]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          overflowX: "auto",
          width: "100%",
        }}
      >
        <StatsCard
          title="Stock Available"
          content="80%"
          // description={
          //   <>
          //     <Typography sx={{ fontSize: 12 }} variant="h4">
          //       Restock on &nbsp;
          //       <Typography
          //         sx={{ fontSize: 12, color: "#268703" }}
          //         component="span"
          //       >
          //         24 April 2023
          //       </Typography>
          //     </Typography>
          //   </>
          // }
        />

        <StatsCard
          title="Wastage"
          content={
            wastage?.count > 0 ? (
              <span>
                <span style={{ fontSize: "20px" }}>{POSadmin.symbol}</span>{" "}
                {wastage?.count}
              </span>
            ) : (
              <span>
                <span style={{ fontSize: "20px" }}>{POSadmin.symbol}</span> 0
              </span>
            )
          }
          description={
            <>
              <Typography sx={{ fontSize: 12, color: "#268703" }} variant="h4">
                {wastage?.count - wastage?.preCount >= 0 ? "+ " : "- "}
                {wastage?.count - wastage?.preCount === 0
                  ? "0"
                  : wastage?.count - wastage?.preCount > 0
                  ? (
                      ((wastage?.count - wastage?.preCount) / wastage?.count) *
                      100
                    ).toFixed(2)
                  : (
                      ((wastage?.preCount - wastage?.count) /
                        wastage?.preCount) *
                      100
                    ).toFixed(2)}{" "}
                % &nbsp;
                <Typography
                  sx={{ fontSize: 12, color: "#000" }}
                  component="span"
                >
                  based on last {wastageFilter}
                </Typography>
              </Typography>
            </>
          }
          wastageFilter={wastageFilter}
          setWastageFilter={setWastageFilter} 
        />
        <StatsCard
          title="Recipe"
          content={
            <>
              {recipe ? recipe : 0}&nbsp;
              <Typography sx={{ fontSize: 18 }} component="span">
                Items
              </Typography>
            </>
          }
          //   description={
          //     <Typography
          //       sx={{ fontSize: 12, color: "#FF0000" }}
          //       component="span"
          //     >
          //       Items needs Recipe
          //     </Typography>
          //   }
        />
        <StatsCard
          title="Cost"
          content={
            <>
              4&nbsp;
              <Typography sx={{ fontSize: 18 }} component="span">
                Items
              </Typography>
            </>
          }
          //   description={
          //     <Typography
          //       sx={{ fontSize: 12, color: "#FF0000" }}
          //       component="span"
          //     >
          //       Items valued less
          //     </Typography>
          //   }
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, my: 2 }}>
        <UsageChart />
        <ProfitChart />
      </Box>
    </>
  );
}
