import { useEffect, useState } from "react";
import { Box, Toolbar } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import FlowDirection from "./../components/posAdminPanel/navBarAndSideBar/FlowDirection";
import { changeflowDirectionItemInventory } from "./FlowDirection";
import AdminSubNavbar from "./../components/common/SubNavbar";
import Sales from "./../components/posAdminPanel/reports/Sales";
import Dashboard from "../components/posAdminPanel/reports/Dashboard";
import Accounts from "../components/posAdminPanel/reports/Accounts";
import Expense from "../components/posAdminPanel/reports/Expense";

export default function Reports() {
  const [value, setValue] = useState("1");
  const [state, setState] = useState(true);
  let [showBack, setShowBack] = useState(false);
  const [showExpense, setShowExpense] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navBarItems = [
    {
      label: "Dashboard",
      value: "1",
    },
    {
      label: "Sales",
      value: "2",
    },
    {
      label: "Accounts",
      value: "3",
    },
    
  ];
  let [flowDirectionItems, setFlowDirectionItems] = useState([
    {
      name: "Reports",
      show: true,
    },
  ]);
  useEffect(() => {
    changeflowDirectionItemInventory(
      value,
      navBarItems,
      setFlowDirectionItems,
      state,
      setShowBack
    );
  }, [value, state]);

  useEffect(() => {
    setState(true);
  }, [value]);
  const backFunction = () => {
    if (!state) {
      setState(!state);
    } else {
      setValue(String(Number(value) - 1));
    }
  };
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <FlowDirection
          items={flowDirectionItems}
          backFunction={backFunction}
          showBack={showBack}
        />
        <Toolbar />
        <Box>
          <Box>
            <TabContext value={value}>
              <AdminSubNavbar
                handleChange={handleChange}
                items={navBarItems}
                value={value}
              />
              <TabPanel value="1" sx={{ height: "auto", bgcolor: "#FFFFFF" }}>
                <Dashboard />
              </TabPanel>
              <TabPanel value="2" sx={{ height: "auto" }}>
                <Sales />
              </TabPanel>
              <TabPanel value="3">
                {!showExpense && <Accounts setShowExpense={setShowExpense} />}
                {showExpense && <Expense setShowExpense={setShowExpense} />}
              </TabPanel>
            </TabContext>
          </Box>
          <Box sx={{ display: "flex" }}></Box>
        </Box>
      </Box>
    </Box>
  );
}
