import { useEffect, useState } from "react";
import {
  Button,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  Paper,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import SearchBar from "../../common/SearchBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import {
  billerBlockAndUnblock,
  getBillers,
  registerBiller,
} from "../../../sevice/userManagement";
import { billerSchema } from "../../../schemas";
import EditBiller from "./EditBiller";
import MaterialUISwitch from "./MaterialUISwitch";

const style = {
  width: "90%",
  height: "80%",
  position: "absolute",
  top: "54%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  px: 4,
  py: 2,
  borderRadius: "6px",
  overflow: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));

function Biller({ state, setState }) {
  const { POSadmin } = useSelector((state) => state);
  const [editItem, setEditItem] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [billers, setBillers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    password: "",
    userPasscode: "",
    swipeCode: "",
    itemCreation: {
      read: false,
      write: false,
    },
    kotManagement: {
      read: false,
      write: false,
    },
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: billerSchema,
    onSubmit: async (values, action) => {
      const data = await registerBiller(values, POSadmin.token);
      if (data.status) {
        setState(true);
        toast.success(data.message);
        action.resetForm();
      } else {
        toast.error(data.message);
      }
    },
  });

  const handleSwitchChange = async (status, id) => {
    const res = await billerBlockAndUnblock(POSadmin.token, id, !status);
    if (res) {
      toast.success(res.message);
      data();
    }
  };

  const data = async () => {
    const data = await getBillers(POSadmin.token);
    setBillers(data);
  };
  useEffect(() => {
    data();
  }, [state, open]);

  const filteredBillers = billers.filter((biller) =>
    biller.first_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      {state && (
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchBar
            searchBarWidth={"200px"}
            placeholder={"Search"}
            onChange={({ target }) => setSearchQuery(target.value)} // Step 3: Update search query state
          />{" "}
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
              "&:hover": {
                backgroundColor: "var(--red)",
              },
            }}
            onClick={() => setState(false)}
          >
            + Add Biller
          </Button>
        </Box>
      )}
      {state ? (
        <Box sx={{ marginTop: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead sx={{ background: "var(--table-head)" }}>
                <TableRow>
                  <StyledTableCell align="center">Biller Name</StyledTableCell>
                  <StyledTableCell align="center">Unique Id</StyledTableCell>
                  <StyledTableCell align="center">User Code</StyledTableCell>
                  <StyledTableCell align="center">Active</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredBillers.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredBillers.map((item, index) => (
                    <TableRow
                      key={item._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">
                        {item.first_name + item.last_name}
                      </TableCell>
                      <TableCell align="center">{item.uniqueId}</TableCell>
                      <TableCell align="center">{item.userCode}</TableCell>
                      <TableCell align="center">
                      <MaterialUISwitch
                          checked={item?.isActive}
                          onChange={() =>
                            handleSwitchChange(item.isActive, item._id)
                          }
                          sx={{ marginTop: "0px" }}
                          inputProps={{ "aria-label": "controlled" }}
                          color="error"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <VisibilityIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditItem(item);
                            handleOpen();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <EditBiller editItem={editItem} handleClose={handleClose} />
            </Box>
          </Modal>
        </Box>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Typography sx={{ p: 3 }} variant="h6">
                Add Biller
              </Typography>
              <Card
                elevation={0}
                sx={{
                  width: "100%",
                  height: "30%",
                  marginRight: "15px",
                  marginBottom: "20px",
                  padding: 3,
                  border: "1px solid #ccc",
                }}
              >
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "53ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    label="Fist Name *"
                    id="first_name"
                    variant="outlined"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.first_name && !!formik.errors.first_name
                    }
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                  />
                  <TextField
                    label="Last Name *"
                    id="last_name"
                    variant="outlined"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.last_name && !!formik.errors.last_name
                    }
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                  />
                  <TextField
                    label="Phone *"
                    id="phone"
                    variant="outlined"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={!!formik.touched.phone && !!formik.errors.phone}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                  <TextField
                    label="Password *"
                    id="password"
                    variant="outlined"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.password && !!formik.errors.password
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                  <TextField
                    label="User Passcode "
                    id="userPasscode"
                    variant="outlined"
                    name="userPasscode"
                    value={formik.values.userPasscode}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.userPasscode &&
                      !!formik.errors.userPasscode
                    }
                    helperText={
                      formik.touched.userPasscode && formik.errors.userPasscode
                    }
                  />
                  <TextField
                    label="Swipe Code"
                    id="swipeCode"
                    variant="outlined"
                    name="swipeCode"
                    value={formik.values.swipeCode}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.swipeCode && !!formik.errors.swipeCode
                    }
                    helperText={
                      formik.touched.swipeCode && formik.errors.swipeCode
                    }
                  
                  />
                </Box>
              </Card>
              <Typography sx={{ p: 1 }} variant="h6">
                Rights
              </Typography>
              <Card
                elevation={0}
                sx={{
                  width: "100%",
                  height: "345px",
                  marginRight: "15px",
                  marginBottom: "20px",
                  padding: 3,
                  border: "1px solid #ccc",
                }}
              >
                <Divider />
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ pr: 3 }}>Item Creation</Typography>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Read"
                    labelPlacement="end"
                    checked={formik.values?.itemCreation?.read}
                    name={`itemCreation.read`}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `itemCreation.read`,
                        e.target.checked
                      );
                    }}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Write"
                    labelPlacement="end"
                    checked={formik.values?.itemCreation?.write}
                    name={`itemCreation.write`}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `itemCreation.write`,
                        e.target.checked
                      );
                    }}
                  />
                </Box>
                <Divider />
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Kot Management</Typography>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Read"
                    labelPlacement="end"
                    checked={formik.values?.kotManagement?.read}
                    name={`kotManagement.read`}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `kotManagement.read`,
                        e.target.checked
                      );
                    }}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Write"
                    labelPlacement="end"
                    checked={formik.values?.kotManagement?.write}
                    name={`kotManagement.write`}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `kotManagement.write`,
                        e.target.checked
                      );
                    }}
                  />
                </Box>
                <Divider />
                {/* <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Kot Management</Typography>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Read"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Write"
                    labelPlacement="end"
                  />
                </Box>
                <Divider />
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Kot Management</Typography>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Read"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Write"
                    labelPlacement="end"
                  />
                </Box>
                <Divider /> */}
              </Card>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ p: 1 }} variant="h6">
                  Table
                </Typography>{" "}
                <FormControlLabel
                  control={<Checkbox />}
                  label="Select All"
                  labelPlacement="end"
                />
              </Box>
              <Card
                elevation={0}
                sx={{
                  width: "100%",
                  height: "110px",
                  marginRight: "15px",
                  marginBottom: "20px",
                  padding: 3,
                  border: "1px solid #ccc",
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Table-1"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Table-2"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Table-3"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Table-4"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Table-5"
                    labelPlacement="end"
                  />
                </Box>
              </Card>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3,
                      width: "100px",
                      textTransform: "none",
                      background: "var( --color-secondary)",
                      "&:hover": {
                        backgroundColor: "var( --color-secondary)",
                      },
                    }}
                    onClick={() => setState(true)}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3,
                      width: "100px",
                      textTransform: "none",
                      background: "var(--red)",
                      "&:hover": {
                        backgroundColor: "var(--red)",
                      },
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </>
      )}
    </>
  );
}

export default Biller;
