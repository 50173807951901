import {
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import HSBar from "react-horizontal-stacked-bar-chart";
import {
  cancelOrders,
  getChartData,
  getRevenueLeakageData,
  onlineAndPicukuOrders,
  orderStatisticsData,
  successOrders,
  getExpenseData,
} from "../../../sevice/dashboard";
import { useSelector } from "react-redux";
import { getTotalSales } from "../../../sevice/reports";

export default function SaleChart() {
  const { POSadmin } = useSelector((state) => state);
  const [series, setSeries] = useState([]);
  const [success, setSuccess] = useState(0);
  const [cancell, setCancell] = useState(0);
  const [online_pickup, setOnlinePickup] = useState(0);

  const [orderTypeTimePeriod, setOrderTypeTimePeriod] = useState("day");
  const [orderStatusTimePeriod, setOrderStatusTimePeriod] = useState("day");
  // const [orderTypeTimePeriod, setOrderTypeTimePeriod] = useState("day");
  const [expenseTime, setExpenseTime] = useState("day");

  const [timePeriodTotalSales, setTimePeriodTotalSales] = useState("week");
  const [saleTypes, setSaleTypes] = useState([]);
  const [expenseData, setExpenseData] = useState({
    utilities: 0,
    staff: 0,
    rent: 0,
    foodCost: 0
  });

  const getData = async () => {
    try {
      const data = await getTotalSales(timePeriodTotalSales, POSadmin.token);
      setSaleTypes(data);
    } catch (error) {
      console.error("Error fetching total sales data:", error);
    }
  };

  const getExpenseDatas = async () => {
    try {
      const { percentages } = await getExpenseData(expenseTime, POSadmin.token);
      setExpenseData(prevData => ({
        ...prevData,
        ...percentages
      }));
    } catch (error) {
      console.error("Error fetching Expense data:", error);
    }
  };
  useEffect(() => {
    getData();
  }, [timePeriodTotalSales, POSadmin.token]);

  const getDatas = async () => {
    const data = await orderStatisticsData(
      POSadmin.token,
      orderStatusTimePeriod
    );
    setSuccess(data.delivered);
    setCancell(data.cancelled);
    setOnlinePickup(data.pickupAndDelivery);
    // const success = await successOrders(POSadmin.token);
    // const cancell = await cancelOrders(POSadmin.token);
    // const onlPick = await onlineAndPicukuOrders(POSadmin.token);
  };

  const findChartData = async () => {
    const data = await getChartData(POSadmin.token, orderTypeTimePeriod);
    setSeries(data);
  };
  const handleFindWastage = async () => {
    const data = await getRevenueLeakageData(POSadmin.token, "day");
    // setSeries(data);
  };

  useEffect(() => {
    handleFindWastage();
  }, []);

  useEffect(() => {
    findChartData();
  }, [orderTypeTimePeriod]);

  useEffect(() => {
    getDatas();
  }, [orderStatusTimePeriod]);

  useEffect(() => {
    getExpenseDatas();
  }, [expenseTime]);

  var options = {
    chart: {
      type: "donut",
    },
    colors: ["#008FFB", "#00E396", "#FF4560"],
    legend: {
      position: "right", // set the position to the right of the chart
      offsetY: 30,
      offsetX: -30,
      fontSize: "15px",
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 1,
        vertical: 5,
      },
    },
    labels: ["Dine-in", "Pickup", "Delivery"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const handleChange = (event) => {
    setOrderTypeTimePeriod(event.target.value);
  };




  var expenseOption = {
    chart: {
      type: "donut",
    },
    labels:Object.keys(expenseData),
    legend: {
      position: "right",
      offsetY: 30,
      offsetX: -30,
      fontSize: "15px",
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      annotations: {
        points: [
          {
            x: "50%",
            y: "45%",
            text: `Total: 100`,
            textAnchor: "middle",
            fontSize: "20px",
            fontWeight: "bold",
            borderColor: "#000",
            borderWidth: 1,
            offsetX: 0,
            offsetY: 0,
            borderRadius: 4,
          },
        ],
      },
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 1,
        vertical: 5,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: { md: "row", sm: "column", xs: "column" },
        gap: "25px",
        py: 5,
      }}
    >
      <Card
        variant="outlined"
        sx={{
          position: "relative",
          flex: 1,
          minWidth: "270px",
          height: "250px",
          py: 1,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
        <Typography sx={{ fontSize: "20px" }}>Orders</Typography>

          <FormControl
            sx={{
              px: 1,
              minWidth: 90,
              position: "absolute",
              right: 10,
              zIndex: 1,
            }}
            size="small"
          >
            <Select
              value={orderTypeTimePeriod}
              onChange={handleChange}
              displayEmpty
              sx={{ fontSize: 12 }}
            >
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
            </Select>
          </FormControl>

          <Chart options={options} series={series} type="donut" height={185} />
        </CardContent>
      </Card>
      <Card
        variant="outlined"
        sx={{
          position: "relative",
          flex: 1,
          minWidth: "270px",
          height: "250px",
          py: 1,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <Typography sx={{ fontSize: "20px" }}>Order Statistics</Typography>
          <FormControl
            sx={{ px: 1, minWidth: 90, position: "absolute", right: 10 }}
            size="small"
          >
            <Select
              value={orderStatusTimePeriod}
              onChange={(e) => setOrderStatusTimePeriod(e.target.value)}
              displayEmpty
              sx={{ fontSize: 12 }}
            >
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box
              sx={{
                bgcolor: "#F4F5F7",
                p: 1,
                flex: 1,
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>{success}</Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Successful Order
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#F4F5F7",
                p: 1,
                flex: 1,
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>{cancell}</Typography>
              <Typography sx={{ fontSize: "12px" }}>Cancelled Order</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box
              sx={{
                bgcolor: "#F4F5F7",
                p: 1,
                flex: 1,
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>{online_pickup}</Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Online/Pickup Order
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#F4F5F7",
                p: 1,
                flex: 1,
              }}
            ></Box>
          </Box>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{
          position: "relative",
          flex: 1,
          minWidth: "270px",
          height: "250px",
          py: 1,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <Typography sx={{ fontSize: "20px" }}>Expense</Typography>

          <FormControl
            sx={{
              px: 1,
              minWidth: 90,
              position: "absolute",
              right: 10,
              zIndex: 1,
            }}
            size="small"
          >
            <Select
              value={expenseTime}
              onChange={(event) => setExpenseTime(event.target.value)}
              displayEmpty
              sx={{ fontSize: 12 }}
            >
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
            </Select>
          </FormControl>
          <Chart
            options={expenseOption}
            series={Object.values(expenseData)}
            type="donut"
            height={185}
          />
        </CardContent>
      </Card>
      {/* <Card
        variant="outlined"
        sx={{
          position: "relative",
          flex: 1,
          minWidth: "270px",
          height: "250px",
          py: 1,
        }}
      > */}
      {/* <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        > */}
      {/* <Typography sx={{ fontSize: "20px" }}>Revenue Leakage</Typography>
          <FormControl
            sx={{ px: 1, minWidth: 90, position: "absolute", right: 10 }}
            size="small"
          >
            <Select
              value={orderTypeTimePeriod}
              onChange={handleChange}
              displayEmpty
              sx={{ fontSize: 12 }}
            >
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box
              sx={{
                bgcolor: "#F4F5F7",
                p: 1,
                flex: 1,
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>10</Typography>
              <Typography sx={{ fontSize: "12px" }}>Bill Modified</Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#F4F5F7",
                p: 1,
                flex: 1,
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>10</Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Bills Re-printed
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#F4F5F7",
                p: 1,
                flex: 1,
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>10</Typography>
              <Typography sx={{ fontSize: "12px" }}>Waived off</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box
              sx={{
                bgcolor: "#F4F5F7",
                p: 1,
                flex: 1,
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>10 KOTS</Typography>
              <Typography sx={{ fontSize: "12px" }}>Cancelled</Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#F4F5F7",
                p: 1,
                flex: 1,
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>10</Typography>
              <Typography sx={{ fontSize: "12px" }}>Modified KOTS</Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#F4F5F7",
                p: 1,
                flex: 1,
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>10 KOTS</Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Not Used in Bill
              </Typography>
            </Box>
          </Box> */}
      {/* </CardContent> */}
      {/* </Card> */}
      <Card
        variant="outlined"
        sx={{
          position: "relative",
          flex: 1,
          minWidth: "270px",
          height: "250px",
          py: 1,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            maxWidth: "800px",
            p: 2,
            borderRadius: "0.7rem",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "20px" }}>Total Sales</Typography>
            <FormControl sx={{ px: 1, minWidth: 90 }} size="small">
              <Select
                value={timePeriodTotalSales}
                onChange={(e) => setTimePeriodTotalSales(e.target.value)}
                displayEmpty
                sx={{ fontSize: 12 }}
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
                <MenuItem value="year">Year</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              mt: 5,
            }}
          >
            {saleTypes.map((item) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
              >
                {item.paymentMethods.map((pm) => (
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Typography sx={{ width: "90px" }}>
                      {pm.paymentMethod}
                    </Typography>
                    <HSBar
                      height={20}
                      data={[
                        {
                          name: pm.paymentMethod,
                          value: pm.grandTotal,
                          color: "#EF4136",
                        },
                        {
                          name: "Total",
                          value: item.total,
                          color: "#E3E3E3",
                        },
                      ]}
                    />
                    <Typography sx={{ width: "90px", pl: 1.5 }}>
                      {pm.grandTotal.toFixed(2)}
                    </Typography>
                  </Box>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Typography>
                    TOTAL: {item?.total ? item?.total?.toFixed(2) : 0}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Paper>
      </Card>
    </Box>
  );
}
