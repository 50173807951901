import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  Grid,
  MenuItem,
  Switch,
} from "@mui/material";

import { Field, Form, Formik } from "formik";
import { TextField, Select } from "formik-mui";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  createDiscount,
  getAllProducts,
  updateDiscountById,
} from "../../../sevice/discount";
import toast from "react-hot-toast";

const DiscountSchema = Yup.object().shape({
  title: Yup.string()
    .required("Required")
    .max(50, "Title must be at most 50 characters"),
  type: Yup.string().required("Required"),
  addOn: Yup.string().required("Required"),
  totalAmount: Yup.number()
    .min(0, "Total amount must be a non-negative number")
    .max(10000, "Total amount must be less than or equal to 10000"),
  termsAndConditions: Yup.string()
    .optional()
    .max(500, "Terms and conditions must be at most 500 characters"),
  orderType: Yup.string().required("Required"),
  applicableOn: Yup.object().shape({
    type: Yup.string().required("Required").oneOf(["All", "Group", "Item"]),
  }),
  applicableAmount: Yup.number()
    .required("Required")
    .min(0, "Applicable amount must be a non-negative number")
    .max(10000, "Applicable amount must be less than or equal to 10000"),
  fromDate: Yup.date().required("Required"),
  fromTime: Yup.string().required("Required"),
  toDate: Yup.date().required("Required"),
  toTime: Yup.string().required("Required"),
  status: Yup.string().optional(),
  days: Yup.string().required("Required"),
});

const CreateDiscounts = ({
  discount,
  anchor,
  handleDiscount,
  groups,
  handleStatusChange,
}) => {
  const POSadmin = useSelector((state) => state.POSadmin);
  const [items, setItems] = useState([]);

  const initialValues = discount
    ? discount
    : {
        title: "",
        type: "",
        addOn: "",
        totalAmount: "",
        termsAndConditions: "",
        orderType: "pos",
        applicableOn: {
          type: "",
          groupId: "",
          itemId: "",
        },
        applicableAmount: "",
        fromDate: "",
        fromTime: "",
        toDate: "",
        toTime: "",
        status: "",
        days: "",
      };

  useEffect(() => {
    getAllProducts(POSadmin.token)
      .then((data) => {
        if (data.status) {
          setItems(data.data);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
      });
  }, [POSadmin.token]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const data = discount
        ? await updateDiscountById(discount._id, values, POSadmin.token)
        : await createDiscount(values, POSadmin.token);
      if (data.status) {
        toast.success(data.message);
        resetForm();
        handleStatusChange();
        handleDiscount(anchor + "", false);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleRadioChange = (setFieldValue, setFieldTouched, field, value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box sx={{ width: "100%", maxWidth: "600px", mt: "80px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>
            {discount ? "Edit Discount" : "Discount Configuration"}
          </Typography>
          <Button
            variant="contained"
            sx={{ textTransform: "none", background: "var(--red)", mr: "20px" }}
            onClick={() => handleDiscount(anchor + "", false)}
          >
            Back
          </Button>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={DiscountSchema}
        >
          {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={24}>
                  <Field
                    component={TextField}
                    name="title"
                    label="Discount Title"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                {/* Type */}
                <Grid item xs={12} sm={6}>
                  <FormControl
                    component="fieldset"
                    margin="normal"
                    error={Boolean(errors.type && touched.type)}
                  >
                    <Typography variant="subtitle1">Type</Typography>
                    <RadioGroup
                      row
                      name="type"
                      value={values.type}
                      onChange={(e) =>
                        handleRadioChange(
                          setFieldValue,
                          setFieldTouched,
                          "type",
                          e.target.value
                        )
                      }
                    >
                      <FormControlLabel
                        value="percentage"
                        control={<Radio />}
                        label="Percentage"
                      />
                      <FormControlLabel
                        value="fixed"
                        control={<Radio />}
                        label="Fixed"
                      />
                    </RadioGroup>
                    {errors.type && touched.type && (
                      <Typography variant="body2" color="error">
                        {errors.type}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {/* Add-On Type & Amount */}
                <Grid item xs={12} sm={6}>
                  <FormControl
                    component="fieldset"
                    margin="normal"
                    error={Boolean(errors.addOn && touched.addOn)}
                  >
                    <Typography variant="subtitle1">Add-On Type</Typography>
                    <RadioGroup
                      row
                      name="addOn"
                      value={values.addOn}
                      onChange={(e) =>
                        handleRadioChange(
                          setFieldValue,
                          setFieldTouched,
                          "addOn",
                          e.target.value
                        )
                      }
                    >
                      <FormControlLabel
                        value="core"
                        control={<Radio />}
                        label="Add-On Core"
                      />
                      <FormControlLabel
                        value="total"
                        control={<Radio />}
                        label="Add-On Total"
                      />
                    </RadioGroup>
                    {errors.addOn && touched.addOn && (
                      <Typography variant="body2" color="error">
                        {errors.addOn}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {/* Total Amount */}
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    name="totalAmount"
                    label="Total Value"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                {/* Terms and Conditions */}
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="termsAndConditions"
                    label="Terms and Conditions"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                  />
                </Grid>
                {/* Order Type */}
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset" margin="normal">
                    <Typography variant="subtitle1">Order Type</Typography>
                    <RadioGroup
                      row
                      name="orderType"
                      value={values.orderType}
                      onChange={(e) =>
                        handleRadioChange(
                          setFieldValue,
                          setFieldTouched,
                          "orderType",
                          e.target.value
                        )
                      }
                    >
                      <FormControlLabel
                        value="pos"
                        control={<Radio />}
                        label="POS"
                      />
                    </RadioGroup>
                    {errors.orderType && touched.orderType && (
                      <Typography variant="body2" color="error">
                        {errors.orderType}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {/* Applicable Option & Amount */}
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset" margin="normal">
                    <Typography variant="subtitle1">
                      Applicable Option
                    </Typography>
                    <RadioGroup
                      row
                      name="applicableOn.type"
                      value={values.applicableOn.type}
                      onChange={(e) =>
                        handleRadioChange(
                          setFieldValue,
                          setFieldTouched,
                          "applicableOn.type",
                          e.target.value
                        )
                      }
                    >
                      <FormControlLabel
                        value="All"
                        control={<Radio />}
                        label="All"
                      />
                      <FormControlLabel
                        value="Group"
                        control={<Radio />}
                        label="Group"
                      />
                      <FormControlLabel
                        value="Item"
                        control={<Radio />}
                        label="Item"
                      />
                    </RadioGroup>
                    {values.applicableOn.type === "Group" ||
                    values.applicableOn.type === "Item" ? (
                      <Grid container spacing={2} alignItems="center">
                        {values.applicableOn.type === "Group" && (
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <Field
                                component={Select}
                                name="applicableOn.groupId"
                                label="Group"
                                margin="normal"
                              >
                                {groups?.map((group) => (
                                  <MenuItem key={group?._id} value={group?._id}>
                                    {group?.groupName}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                          </Grid>
                        )}
                        {values.applicableOn.type === "Item" && (
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <Field
                                component={Select}
                                name="applicableOn.itemId"
                                label="Item"
                                margin="normal"
                              >
                                {items?.map((item) => (
                                  <MenuItem key={item?._id} value={item?._id}>
                                    {item?.title}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    ) : null}
                    {errors.applicableOn && touched.applicableOn && (
                      <Typography variant="body2" color="error">
                        {errors.applicableOn.type}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {/* Applicable Amount */}
                <Grid item xs={12} sm={8}>
                  <Field
                    component={TextField}
                    name="applicableAmount"
                    label="Applicable Amount"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                {/* Dates and Times */}
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    name="fromDate"
                    label="From Date"
                    type="date"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      discount
                        ? new Date(values.fromDate).toISOString().split("T")[0]
                        : values?.fromDate
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    name="fromTime"
                    label="From Time"
                    type="time"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    name="toDate"
                    label="To Date"
                    type="date"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      discount
                        ? new Date(values.toDate).toISOString().split("T")[0]
                        : values?.toDate
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    name="toTime"
                    label="To Time"
                    type="time"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* Days and Status */}
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset" margin="normal">
                    <Typography variant="subtitle1">Days</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.days === "all"}
                          onChange={(e) =>
                            setFieldValue("days", e.target.checked ? "all" : "")
                          }
                        />
                      }
                      label="All"
                    />
                    {errors.days && touched.days && (
                      <Typography variant="body2" color="error">
                        {errors.days}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* Status */}
                  <FormControl component="fieldset" margin="normal">
                    <Typography variant="subtitle1">Status</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.status === "active"}
                          onChange={(e) =>
                            setFieldValue(
                              "status",
                              e.target.checked ? "active" : ""
                            )
                          }
                        />
                      }
                      label="Active"
                    />
                  </FormControl>
                </Grid>
                {/* Save Button */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: "5px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var(--red)",
                        "&:hover": { backgroundColor: "var(--red)" },
                      }}
                      onClick={() => handleDiscount(anchor + "", false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        mt: 3,
                        width: "100px",
                        textTransform: "none",
                        background: "var(--red)",
                        "&:hover": { backgroundColor: "var(--red)" },
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default CreateDiscounts;
