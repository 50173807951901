import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import {
  editTableArea,
  getAllTableAreas,
  getAllTables,
} from "../../../sevice/admin";
import { addAreaSchema } from "../../../schemas";

export default function EditArea({ sentMessage, editItem, handleClose }) {
  const { POSadmin } = useSelector((state) => state);
  const [allTable, setTables] = useState([]);
  const [selectedTables, setSelectedTables] = useState([]);

  let arr = [];
  editItem.tables.map((item) => {
    arr.push(item._id);
  });

  const initialValues = {
    areaType: editItem.areaType,
    areaName: editItem.areaName,
    tables: arr,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addAreaSchema,
    onSubmit: async (values, action) => {
      let areaDatas = { areaType: values.areaType, areaName: values.areaName };
      if (values.tables.length > 0) {
        let tableData = values?.tables.map((item) => {
          return { table: item };
        });
        areaDatas = {
          tables: tableData,
          ...areaDatas,
        };
      }
      const data = await editTableArea(POSadmin.token, areaDatas, editItem._id);
      sentMessage(data);
      handleClose();
    },
  });

  useEffect(() => {
    fetchAllTable();
    fetchAllTableAreas();
  }, []);

  const fetchAllTable = async () => {
    const data = await getAllTables(POSadmin.id);
    setTables(data);
  };

  const fetchAllTableAreas = async () => {
    const data = await getAllTableAreas(POSadmin.token);
    let table = [];
    data?.map((tableItem) => {
      tableItem.tables.map((item) => {
        if (!initialValues.tables.includes(item._id)) {
          table.push(item._id);
        }
      });
    });

    setSelectedTables(table);
  };

  const selectTable = (e) => {
    if (formik.values.tables.includes(e)) {
      formik.setValues({
        ...formik.values,
        tables: formik.values.tables.filter((item) => {
          return item !== e;
        }),
      });
    } else {
      formik.setValues({
        ...formik.values,
        tables: [...formik.values.tables, e],
      });
    }
  };
  const selectAllTable = (cheked) => {
    if (cheked) {
      let tableId = [];
      allTable.map((item) => {
        if (!selectedTables.includes(item._id)) tableId.push(item._id);
      });
      formik.setValues({
        ...formik.values,
        tables: tableId,
      });
    } else {
      formik.setValues({
        ...formik.values,
        tables: [],
      });
    }
  };
  return (
    <div>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Box>
              <Typography
                sx={{ my: "10px" }}
                style={{ fontSize: "15px", fontWeight: "bold" }}
              >
                Add Area
              </Typography>
            </Box>

            <Box>
              <Box sx={{ my: "20px" }}>
                <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                  Area Type
                </Typography>
                <TextField
                  id="areaType"
                  variant="outlined"
                  name="areaType"
                  size="small"
                  style={{ width: "100%" }}
                  value={formik.values.areaType}
                  onChange={formik.handleChange}
                  error={!!formik.touched.areaType && !!formik.errors.areaType}
                  helperText={formik.touched.areaType && formik.errors.areaType}
                />
              </Box>
              <Box sx={{ my: "20px" }}>
                <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                  Area Name
                </Typography>
                <TextField
                  id="areaName"
                  variant="outlined"
                  name="areaName"
                  size="small"
                  style={{ width: "100%" }}
                  value={formik.values.areaName}
                  onChange={formik.handleChange}
                  error={!!formik.touched.areaName && !!formik.errors.areaName}
                  helperText={formik.touched.areaName && formik.errors.areaName}
                />
              </Box>
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                    Table
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={"Select All"}
                    onChange={(e) => selectAllTable(e.target.checked)}
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  {allTable?.map((item) => {
                    return (
                      <FormControlLabel
                        disabled={selectedTables.includes(item._id)}
                        value={item._id}
                        control={<Checkbox />}
                        label={item.tableNo}
                        checked={formik.values.tables.includes(item._id)}
                        onChange={(e) => selectTable(e.target.value)}
                      />
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{
                height: "100%",
                fontSize: "16px",
                mr: "10px",
                mt: "3px",
                textTransform: "none",
                color: "var(--font-black)",
                background: "var(--button-gray)",
              }}
              variant="text"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                height: "100%",
                fontSize: "16px",
                mt: "3px",
                textTransform: "none",
                color: "var(--bg-white)",
                background: "var(--red)",
              }}
              type="submit"
              variant="text"
            >
              Save
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}
