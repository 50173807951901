import { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useNavigate } from "react-router-dom";
import { variationTableSchema } from "../../../schemas";
import { addProduct } from "../../../sevice/product";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));

export default function VariationTableModal({
  setproduct,
  close,
  initialState,
  AddproductMessage,
  productData,
  resetForm,
}) {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const { POSadmin } = useSelector((state) => state);
  const { t } = useTranslation();

  const submitdata = async (value) => {
    setIsSubmit(true);
    const data = await addProduct(value, POSadmin.token);
    if (data.status) {
      setproduct({ ...initialState });
      resetForm();
      AddproductMessage(data);
      navigate("/menumanagemet");
    }
  };
  return (
    <Box>
      <Formik
        initialValues={productData}
        validationSchema={variationTableSchema}
        onSubmit={submitdata}
      >
        {({ values, setFieldValue }) => (
          <Form autoComplete="off">
            <TableContainer component={Paper} sx={{ my: "10px" }}>
              <Table
                sx={{ minWidth: 650, backgroundColor: "#FFFFFF" }}
                aria-label="simple table"
              >
                <TableHead style={{ backgroundColor: "#D9D9D9" }}>
                  <TableRow sx={{ fontWeight: "bold" }}>
                    <StyledTableCell align="left" sx={{ width: "20px" }}>
                      No
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Product Name
                    </StyledTableCell>
                    {values.property1 && (
                      <StyledTableCell align="center">
                        {values.property1}
                      </StyledTableCell>
                    )}
                    {values.property2 && (
                      <StyledTableCell align="center">
                        {values.property2}
                      </StyledTableCell>
                    )}
                    {values.property3 && (
                      <StyledTableCell align="center">
                        {values.property3}
                      </StyledTableCell>
                    )}
                    {values.property4 && (
                      <StyledTableCell align="center">
                        {values.property4}
                      </StyledTableCell>
                    )}
                    {values.property5 && (
                      <StyledTableCell align="center">
                        {values.property5}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center">Sale Price</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="varients">
                    {({ push, remove }) => (
                      <>
                        {values.varients.map((row, index) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              height: "10px",
                            }}
                          >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                              {values.varients[index].productName}
                            </TableCell>
                            {values?.property1 && (
                              <TableCell align="center">
                                {row?.value1}
                              </TableCell>
                            )}
                            {values?.property2 && (
                              <TableCell align="center">
                                {row?.value2}
                              </TableCell>
                            )}
                            {values?.property3 && (
                              <TableCell align="center">
                                {row?.value3}
                              </TableCell>
                            )}
                            {values?.property4 && (
                              <TableCell align="center">
                                {row?.value4}
                              </TableCell>
                            )}
                            {values?.property5 && (
                              <TableCell align="center">
                                {row?.value5}
                              </TableCell>
                            )}
                            <TableCell align="center">
                              <Field
                                sx={{ width: "100px" }}
                                size="small"
                                fullWidth
                                name={`varients.${index}.salePrice`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `varients.${index}.salePrice`,
                                    e.target.value
                                  );
                                  setFieldValue(
                                    `varients.${index}.unitPrice`,
                                    e.target.value
                                  );
                                }}
                                component={TextField}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                variant="text"
                                sx={{
                                  height: "100%",
                                  fontSize: "16px",
                                  textTransform: "none",
                                  color: "var(--red)",
                                }}
                                onClick={() => {
                                  if (values.varients.length <= 1) {
                                    remove(index);
                                    setFieldValue("varients", [
                                      {
                                        image: "",
                                        productName: "",
                                        stock: 1,
                                        unitPrice: 0,
                                        salePrice: 0,
                                        sku: "",
                                        sellerStatus: true,
                                        isDeleted: false,
                                      },
                                    ]);
                                  } else {
                                    remove(index);
                                  }
                                }}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ my: "20px", display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                sx={{
                  background: "var(--dark-gray)",
                  width: "130px",
                  mr: "10px",
                  textTransform: "none",
                }}
                onClick={() => close()}
              >
                Back
              </Button>
              <Button
                disabled={isSubmit}
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "var(--red)",
                  width: "130px",
                }}
                type="submit"
              >
                {isSubmit ? "Loading.." : "Save Item"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
