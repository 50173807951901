import { Card, CardActions, Typography } from "@mui/material";
function ConfigCard({ data }) {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {data?.map((item, index) => (
        <Card
          key={index}
          onClick={() => item.action()}
          elevation={0}
          sx={{
            width: "250px",
            height: "120px",
            margin: "15px",
            padding: 3,
            border: "1px solid #ccc",
          }}
        >
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            {item.icon}
          </CardActions>
          <Typography
            sx={{ display: "flex", justifyContent: "center" }}
            color="var(--font-black)"
          >
            {item.text}
          </Typography>
        </Card>
      ))}
    </div>
  );
}

export default ConfigCard;
