import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";

import { TextField } from "formik-mui";
import bread from "../../../assets/bread 1.svg";
import cups from "../../../assets/cups 1.svg";
import pizza from "../../../assets/pizza 2.svg";
import rice from "../../../assets/rice 1.svg";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const initialValue = {
  title: "",
  titleAr: "",
};
export default function AddVariationModal({ close, open, allProperty }) {
  const { isLanguageSwitched } = useSelector((state) => state);
  const { t } = useTranslation();
  const [variants, setVariants] = useState([]);
  useEffect(() => {
    const data = allProperty.map((item) => {
      return item[0];
    });
    setVariants(data);
  }, [allProperty]);
  const addVariationTitle = object({
    title: string()
      .required("Title is required")
      .notOneOf(variants, "This variation already used"),
  });
  const addVariationTitleWithArabic = object({
    title: string()
      .required("Title is required")
      .notOneOf(variants, "This variation already used"),
    titleAr: string()
      .required("Title is required")
      .notOneOf(variants, "This variation already used"),
  });
  //********************************************************************* */
  const submitdata = (value) => {
    open(value);
  };
  //********************************************************************* */
  const variationList = [
    {
      image: bread,
      head: "Base",
      // head: t("addVariationModal.variationList.BASE"),
    },
    {
      image: pizza,
      head: "Size",
      // head: t("addVariationModal.variationList.SIZE"),
    },
    {
      image: cups,
      head: "Quantity",
      // head: t("addVariationModal.variationList.QUANTITY"),
    },
    {
      image: rice,
      // head: t("addVariationModal.variationList.RICE"),
      head: "Rice",
    },
  ];

  return (
    <div>
      <Formik
        initialValues={initialValue}
        validationSchema={
          isLanguageSwitched ? addVariationTitleWithArabic : addVariationTitle
        }
        onSubmit={submitdata}
      >
        {({ values, errors }) => (
          <Form autoComplete="off">
            <Box>
              <Box>
                <Typography
                  sx={{ my: "20px" }}
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  {/* {t("addVariationModal.ADD_VARIANTS")} */}
                  Add Variants
                </Typography>
              </Box>
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                Title of the variant
                {/* {t('addVariationModal.TITLE_OF_VARIANT')} */}
              </Typography>
              <Box>
                <Field
                  placeholder="Enter your custom variant name"
                  size="small"
                  fullWidth
                  name="title"
                  component={TextField}
                />
                {isLanguageSwitched ? (
                  <Box sx={{ marginTop: "15px" }}>
                    <Field
                      placeholder="أدخل اسم البديل المخصص الخاص بك"
                      size="small"
                      fullWidth
                      name="titleAr"
                      component={TextField}
                      inputProps={{ style: { textAlign: "right" } }}
                    />
                  </Box>
                ) : (
                  ""
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  my: "20px",
                  border: "dashed var(--border-gray) 2px",
                  p: "10px",
                }}
              >
                <Box>
                  <Typography style={{ fontSize: "12px" }}>
                    {/* {t("addVariationModal.DESCRIPTION")} */}A variation in
                    product refers to a difference or modification made to a
                    product to create a new version or variant that offers
                    unique features, benefits, or characteristics.
                  </Typography>
                  <Typography style={{ fontSize: "12px", marginTop: "10px" }}>
                    {/* {t("addVariationModal.EXAMPLE")} */}
                    Eg:
                  </Typography>
                </Box>

                {variationList.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      gap: { md: "10px", sm: "5px" },
                      border: "solid var(--border-black) 1px",
                      width: { md: "150px", sm: "130px" },
                      height: "80px",
                      pl: "10px",
                      borderRadius: "8px",
                      my: "10px",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ width: "30px" }}
                        src={item.image}
                        alt=""
                        srcset=""
                      />

                      <Typography
                        sx={{ my: "auto", mx: "20px" }}
                        style={{ fontSize: "13px", fontWeight: "bold" }}
                      >
                        {item.head}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end", m: "10px" }}>
              <Button
                id="sadasd"
                type="submit"
                sx={{
                  height: "100%",
                  fontSize: "16px",
                  mr: "10px",
                  mt: "3px",
                  textTransform: "none",
                  color: "var(--bg-white)",
                  background: "var(--red)",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                }}
                variant="text"
              >
                {/* {t("common.NEXT")} */}
                Next
              </Button>

              <Button
                sx={{
                  height: "100%",
                  fontSize: "16px",
                  mt: "3px",
                  textTransform: "none",
                  color: "var(--font-black)",
                  background: "var(--button-gray)",
                  "&:hover": {
                    backgroundColor: "var(--button-gray)",
                  },
                }}
                variant="text"
                onClick={close}
              >
                {/* {t("common.CANCEL")} */}
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}
