import { useEffect, useState } from "react";
import { Box, Typography, Divider, Button, Modal } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSelector } from "react-redux";
import AddGroup from "./AddGroup";
import { toast } from "react-hot-toast";
import SearchBar from "../../common/SearchBar";
import { deleteGroup } from "../../../sevice/group";
import styled from "@emotion/styled";
import AlertButton from "../../../Alert/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 705,
  maxHeight: 490,
  overflowY: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 4,
  py: 1.5,
  borderRadius: "5px",
};

const StyledBox = styled(Box)({
  width: "100%",
  minHeight: "50px",
  border: 1,
  display: "flex",
  padding: "10px",
});

const AdminBrandName = styled(Typography)({
  // height: "19px",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "19.36px",
  color: "#2A98E1",
  color: "var(--font-black)",
});

const AllGroups = ({
  selectedGroupId,
  setselectedGroupId,
  fetchSingleGroup,
  updateState,
  getallGroupsFunction,
  allGroups,
}) => {
  const [openAddGroup, setOpenAddGroup] = useState(false);
  const handleOpenGroup = () => setOpenAddGroup(true);
  const handleCloseGroup = () => setOpenAddGroup(false);
  const [query, setQuery] = useState("");
  const keys = ["groupName"];

  const { POSadmin } = useSelector((state) => state);

  useEffect(() => {
    getallGroupsFunction();
  }, [updateState]);

  const deleteGroupFunction = async (groupId) => {
    const data = await deleteGroup(groupId, POSadmin.token);
    if (data.status) {
      toast.success(data.message);
      getallGroupsFunction();
    }
  };
  const sentMessage = (data) => {
    if (data.status) {
      toast.success(data.message);
      handleCloseGroup();
      getallGroupsFunction();
    } else {
      toast.error(data.message);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "327px",
        borderRight: "solid var(--border-gray) 1px",
        overflow: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box sx={{ marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "15px",
            marginTop: "10px",
            alignItems: "center",
          }}
        >
          <Typography>Groups</Typography>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              background: "var(--red)",
            }}
            onClick={handleOpenGroup}
          >
            + Add
          </Button>
        </div>
        <Box
          sx={{
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "40px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SearchBar
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
              placeholder={"Search"}
            />
          </Box>
        </Box>
        <Divider orientation="horizontal" flexItem />

        {allGroups
          ?.filter((item) =>
            keys.some((key) => item[key]?.toLowerCase()?.includes(query))
          )
          ?.map((item) => (
            <>
              <Box
                sx={{
                  background: item._id == selectedGroupId ? "#F3F6FB" : "",
                  borderBottom: "solid var(--border-gray) 1px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StyledBox
                  key={item._id}
                  onClick={() => {
                    setselectedGroupId(item._id);
                    fetchSingleGroup(item._id);
                  }}
                >
                  <Box
                    sx={{
                      ml: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AdminBrandName>{item.groupName}</AdminBrandName>
                  </Box>
                </StyledBox>
                <AlertButton
                  element={<DeleteOutlineIcon />}
                  type={"confirmation"}
                  message={"Are you sure? You won't be able to revert this!"}
                  onConfirm={deleteGroupFunction}
                  data={item._id}
                  style={{ color: "black" }}
                />
              </Box>
            </>
          ))}
      </Box>
      <Modal
        open={openAddGroup}
        onClose={handleCloseGroup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddGroup
            handleCloseGroup={handleCloseGroup}
            sentMessage={sentMessage}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default AllGroups;
