import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const getAllUnits = async () => {
  try {
    const { data } = await axios.get(`${host}/posGetAllUnits`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllSubUnits = async () => {
  try {
    const { data } = await axios.get(`${host}/posGetAllSubUnits`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getProductName = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetProductName`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
// export const getRawMaterialsWithSearch = async (token, search, pageNo) => {
//   try {
//     const { data } = await axios.get(
//       `${host}/getRawMaterialsWithSearch?search=${search}&pageNo=${pageNo}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return data;
//   } catch (error) {
//     return error.response.data.message;
//   }
// };
export const getRawMaterials = async (token, search = "", pageNo) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetRawMaterials?search=${search}&pageNo=${pageNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteRawMaterial = async (token, id) => {
  try {
    const { data } = await axios.delete(`${host}/posDeleteRawMaterial/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const editRawmaterial = async (token, values, id) => {
  try {
    const { data } = await axios.put(
      `${host}/posEditRawmaterial/${id}`,
      { ...values },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const addRecipe = async (token, recipe) => {
  try {
    const { data } = await axios.post(`${host}/posAddRecipe`, recipe, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const editRecipe = async (token, recipe, id) => {
  try {
    const { data } = await axios.put(`${host}/posEditRecipe/${id}`, recipe, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getProductInRecipe = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetProductInRecipe`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getRecipes = async (token, searchQuery, pageNo) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetRecipes?search=${searchQuery}&pageNo=${pageNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllPurchase = async (token, searchQuery) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetAllPurchase?search=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const newPurchase = async (token, datas) => {
  try {
    const { data } = await axios.post(`${host}/posNewPurchase`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const editPurchase = async (token, datas, id) => {
  try {
    const { data } = await axios.patch(`${host}/posEditPurchase/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllWastage = async (token, searchQuery, pageNo) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetAllWastage?search=${searchQuery}&pageNo=${pageNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const addWastage = async (token, datas) => {
  try {
    const { data } = await axios.post(`${host}/posAddWastage`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const editWastage = async (token, datas, id) => {
  try {
    const { data } = await axios.put(`${host}/posEditWastage/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addMenu = async (token, datas) => {
  try {
    const { data } = await axios.post(`${host}/posAddMenu`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editMenu = async (token, datas, id) => {
  try {
    const { data } = await axios.put(
      `${host}/posEditMenu/${id}`,
      { ...datas },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllMenu = async (token, searchQuery, pageNo) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetAllMenu?search=${searchQuery}&pageNo=${pageNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteStock = async (token, id) => {
  try {
    const { data } = await axios.put(
      `${host}/posDeleteStock/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const editCurrentStock = async (token, id, currentStock) => {
  try {
    const { data } = await axios.put(
      `${host}/posEditCurrentStock/${id}`,
      { currentStock },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
// export const getAddones = async (token) => {
//   try {
//     const { data } = await axios.get(`${host}/getAddones`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return data;
//   } catch (error) {
//     return error.response.data.message;
//   }
// };
export const getWastageAmount = async (type, token) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetWastageAmount?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getRecipeCount = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetRecipeCount`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getMostUsingRawMaterials = async (type, token) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetMostUsingRawMaterials?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const posGetNetProfitPerSales = async (token) => {
  try {
    var type = "week";
    const { data } = await axios.get(
      `${host}/posGetNetProfitPerSales?type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
