import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

//////////////////////// Biller //////////////////////

export const registerBiller = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/posRegisterBiller`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editBiller = async (token, datas, id) => {
  try {
    const { data } = await axios.patch(`${host}/posEditBiller/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getBillers = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetBillers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const billerBlockAndUnblock = async (token, id, status) => {
  try {
    const { data } = await axios.put(
      `${host}/posBillerBlockAndUnblock/${id}`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

//////////////////////// Captain //////////////////////

export const registerCaptain = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/posRegisterCaptain`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editCaptain = async (token, datas, id) => {
  try {
    const { data } = await axios.patch(`${host}/posEditCaptain/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCaptains = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetCaptains`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const captainBlockAndUnblock = async (token, id, status) => {
  try {
    const { data } = await axios.put(
      `${host}/posCaptainBlockAndUnblock/${id}`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

//////////////////////// DeliverBoy //////////////////////

export const registerDeliverBoy = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/posRegisterDeliverBoy`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editDeliveryBoy = async (token, datas, id) => {
  try {
    const { data } = await axios.patch(`${host}/posEditDeliveryBoy/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllDeliveryBoys = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetAllDeliveryBoys`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deliveryBoyBlockAndUnblock = async (token, id, status) => {
  try {
    const { data } = await axios.put(
      `${host}/posDeliveryBoyBlockAndUnblock/${id}`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

//////////////////////// OrderAppUser //////////////////////

export const registerOrderAppUser = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/posRegisterOrderAppUser`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editOrderAppUser = async (token, datas, id) => {
  try {
    const { data } = await axios.patch(`${host}/posEditOrderAppUser/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getOrderAppUsers = async (token) => {
  try {
    const { data } = await axios.get(`${host}/posGetOrderAppUsers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const orderAppUserBlockAndUnblock = async (token, id, status) => {
  try {
    const { data } = await axios.put(
      `${host}/posOrderAppUserBlockAndUnblock/${id}`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};


//////////////////////// Merchant //////////////////////

export const registerMerchant = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/api/v1/merchant/register`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editMerchant = async (token, datas, id) => {
  try {
    const { data } = await axios.put(`${host}/api/v1/merchant/update/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getMerchant = async (token) => {
  try {
    const { data } = await axios.get(`${host}/api/v1/merchant/merchants`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }

}

export const blockUnblock = async (token,id,isActive) => {
  try {
    const { data } = await axios.patch(`${host}/api/v1/merchant//blockUnblockUser/${id}`,{isActive}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
}

