import { useEffect, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field, FieldArray, Form, Formik } from "formik";
import { addVariationArSchema, addVariationSchema } from "../../../schemas";
import { TextField } from "formik-mui";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const emptyRaw = {
  option: "",
  optionAr: "",
};
export default function EditVariation({
  allProperty,
  isEdit,
  SetIsEdit,
  editVariationIndex,
  initialVariationOptionupdation,
  setAllProperty,
  close,
  initialVariationOption,
  setproduct,
  editdata,
  handleOpenVatiationTable

}) {

  const { isLanguageSwitched } = useSelector((state) => state);
  const { t } = useTranslation();

  const [render, setRender] = useState(false);

  const submitdata = async (values, action) => {
    let existingTitleIndex = allProperty.findIndex(property => property[0].title === values.title);
    
    if (existingTitleIndex !== -1) {
        let existingOptions = allProperty[existingTitleIndex].slice(1).map(item => JSON.stringify(item.option));

        let uniqueItems = values.items.filter((item, index) => {
            let stringifiedOption = JSON.stringify(item.option);
            return (
                !existingOptions.includes(stringifiedOption) &&
                values.items.findIndex(i => JSON.stringify(i.option) === stringifiedOption) === index
            );
        });

        allProperty[existingTitleIndex] = [...allProperty[existingTitleIndex], ...uniqueItems];
        setAllProperty([...allProperty]);
        setRender(true);
    } else {
        setAllProperty([...allProperty, [{ title: values.title, titleAr: values.titleAr }, ...values.items]]);
        setRender(true);
    }
};



  const updateData = async (values, action) => {
    let data = values.items.map((item) => {
      return { option: item.option, optionAr: item.optionAr };
    });
    data.unshift({ title: values.title, titleAr: values.titleAr });
    const updatedData = allProperty.map((prop, propIndex) => {
      if (editVariationIndex === propIndex) {
        return data;
      } else {
        return prop;
      }
    });
    SetIsEdit(false);
    setAllProperty(updatedData);
    setRender(true);
  };

  const deleteVariation = () => {
    const updatedData = allProperty.filter((item, propIndex) => {
      if (propIndex !== editVariationIndex) {
        return item;
      }
    });

    SetIsEdit(false);
    setAllProperty(updatedData);
    setRender(true);
  };

  useEffect(() => {
    if (render && editdata) {
      generateProduct();
    }
  }, [allProperty]);

  const generateProduct = () => {
    
    const updatedProduct = { ...editdata };
    
    // Function to generate all combinations of options
    const findCombinations = (arrays, currentCombination = []) => {
      if (!arrays.length) {
        return [currentCombination];
      }
      const [currentArray, ...remainingArrays] = arrays;
      const combinations = [];
      for (const element of currentArray) {
        const newCombination = [...currentCombination, element];
        const subCombinations = findCombinations(remainingArrays, newCombination);
        combinations.push(...subCombinations);
      }
      return combinations;
    };
  
    const newArray = allProperty.map((nestedArray) => nestedArray.slice(1));
    const newArray2 = newArray.filter((nestedArray) => nestedArray.length > 0);
  
    const combinations = findCombinations(newArray2);
    let arrr = [];
  
    combinations.forEach((item, index) => {
      let objValue = {};
      allProperty.forEach((item2, index2) => {
        if (item2.length > 1) {
          let propName = `value${index2 + 1}`;
          let propNameAr = `${propName}Ar`;
          let data = {
            [propName]: item[index2]?.option || "",
            [propNameAr]: item[index2]?.optionAr || "",
          };
          objValue = { ...objValue, ...data };
        }
      });
      arrr.push({
        image:[],
        productName: "",
        sku: "",
        stock: 0,
        unitPrice: 0,
        salePrice: 0,
        ...objValue,
        sellerStatus: true,
        isDeleted: false,
      });
    });
  
    allProperty.forEach((item, index) => {
      if (item.length > 1) {
        let propName = `property${index + 1}`;
        let propNameAr = `${propName}Ar`;
        if (!updatedProduct[propName]) {
          updatedProduct[propName] = item[0]?.title.trim() || "";
          updatedProduct[propNameAr] = item[0]?.titleAr.trim() || "";
        }
      }
    });
    let image = updatedProduct?.varients[0]?.varientId?.image || []
    updatedProduct.varients = arrr.map((item, index) => {
      let property = [
        item.value1,
        item.value2,
        item.value3,
        item.value4,
        item.value5,
      ]
        .filter(Boolean)
        .join(" ");
      let propertyAr = [
        item.value1Ar,
        item.value2Ar,
        item.value3Ar,
        item.value4Ar,
        item.value5Ar,
      ]
        .filter(Boolean)
        .join(", ");
      let proName = property
        ? `${updatedProduct.title.replace(/\s+/g, " ").trim()} ${property}`
        : updatedProduct.title;
      let proNameAr = propertyAr
        ? `${updatedProduct.titleAr.replace(/\s+/g, " ").trim()}, ${propertyAr}`
        : updatedProduct.titleAr;
      let letter = proName.slice(0, 4).toUpperCase();
      let digit = Date.now().toString().slice(8, 13) + index;
      let sku = (letter + digit).replace(/\s/g, "");
      return {
        ...item,
        image:image,
        productName: proName,
        productNameAr: proNameAr,
        sku: sku,
      };
    });
  
    setproduct(updatedProduct);
    handleOpenVatiationTable();
    close()
  };
  
  // Function to generate all combinations of options
  const getCombinations = (arrays, currentCombination = []) => {
    if (!arrays.length) {
      return [currentCombination];
    }
    const [currentArray, ...remainingArrays] = arrays;
    const combinations = [];
    for (const element of currentArray) {
      const newCombination = [...currentCombination, element];
      const subCombinations = getCombinations(remainingArrays, newCombination);
      combinations.push(...subCombinations);
    }
    return combinations;
  };

  return (
    <div>
      <Formik
        initialValues={
          isEdit ? initialVariationOption : initialVariationOptionupdation
        }
        validationSchema={
          isLanguageSwitched ? addVariationArSchema : addVariationSchema
        }
        onSubmit={isEdit ? submitdata : updateData}
      >
        {({ values }) => (
          <Form autoComplete="off">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Add Variants
                  {/* {t('addVariationOptionModal.ADD_VARIANT')} */}
                </Typography>
                {isEdit && (
                  <DeleteIcon
                    sx={{ width: "20px", color: "var(red)" }}
                    onClick={() => deleteVariation()}
                  />
                )}
              </Box>
              <Typography style={{ fontSize: "13px" }}>
                {/* {t("addVariationOptionModal.TITLE_OF_VARIANT")} */}
                Title of the variant
              </Typography>{" "}
              {isEdit ? (
                <>
                  <Field
                    sx={{ mb: "10px", background: "var(--light-gray)" }}
                    size="small"
                    fullWidth
                    name={`title`}
                    component={TextField}
                  />
                  {isLanguageSwitched ? (
                    <Field
                      sx={{ mb: "10px", background: "var(--light-gray)" }}
                      size="small"
                      fullWidth
                      name={`titleAr`}
                      component={TextField}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {values.title}
                </Typography>
              )}
            </Box>
            <FieldArray name="items">
              {({ push, remove }) => (
                <>
                  {values?.items?.map((_, index) => (
                    <>
                      <Box key={index} sx={{ display: "flex" }}>
                        <Box sx={{ width: "100%" }}>
                          <Field
                            sx={{ my: "10px" }}
                            size="small"
                            fullWidth
                            name={`items.${index}.option`}
                            component={TextField}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            ml: "10px",
                          }}
                        >
                          <DeleteIcon
                            sx={{ width: "20px" }}
                            onClick={() => {
                              remove(index);
                            }}
                          />
                        </Box>
                      </Box>

                      {isLanguageSwitched ? (
                        <Box key={index} sx={{ display: "flex" }}>
                          <Box sx={{ width: "100%" }}>
                            <Field
                              sx={{ my: "10px" }}
                              size="small"
                              fullWidth
                              name={`items.${index}.optionAr`}
                              component={TextField}
                              inputProps={{ style: { textAlign: "right" } }}
                              placeholder="في العربية"
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ml: "10px",
                            }}
                          >
                            <DeleteIcon
                              sx={{ width: "20px" }}
                              onClick={() => {
                                remove(index);
                              }}
                            />
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                  <Button
                    onClick={() => {
                      push(emptyRaw);
                    }}
                    variant="text"
                    sx={{
                      textTransform: "none",
                      color: "var(--red)",
                    }}
                  >
                    + Add More Option
                    {/* {t("common.ADD_MORE_OPTION")} */}
                  </Button>
                </>
              )}
            </FieldArray>

            <Box sx={{ display: "flex", justifyContent: "end" }}>
              {isEdit ? (
                <Button
                  sx={{
                    height: "100%",
                    fontSize: "16px",
                    mr: "10px",
                    mt: "3px",
                    textTransform: "none",
                    color: "var(--bg-white)",
                    background: "var(--red)",
                    "&:hover": {
                      backgroundColor: "var(--red)",
                    },
                  }}
                  type="submit"
                  variant="text"
                >
                  {/* {t("common.UPDATE")} */}
                  Update
                </Button>
              ) : (
                <Button
                  sx={{
                    height: "100%",
                    fontSize: "16px",
                    mr: "10px",
                    mt: "3px",
                    textTransform: "none",
                    color: "var(--bg-white)",
                    background: "var(--red)",
                    "&:hover": {
                      backgroundColor: "var(--red)",
                    },
                  }}
                  type="submit"
                  variant="text"
                >
                  {/* {t("common.NEXT")} */}
                  Next
                </Button>
              )}

              <Button
                sx={{
                  height: "100%",
                  fontSize: "16px",
                  mt: "3px",
                  textTransform: "none",
                  color: "var(--font-black)",
                  background: "var(--button-gray)",
                  "&:hover": {
                    backgroundColor: "var(--button-gray)",
                  },
                }}
                variant="text"
                onClick={close}
              >
                {/* {t("common.CANCEL")} */}
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}
