import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";
import { posAdminLogin } from "../sevice/admin";
import { useFormik } from "formik";
import { loginSchema } from "../schemas/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PhoneImage from "../assets/PhoneImage.png";
import Logo from "../assets/Logo.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FindAccount from "../components/posAdminPanel/forgotPassword/FindAccount";
import ResetPassword from "../components/posAdminPanel/forgotPassword/ResetPassword";
import CodeVerification from "../components/posAdminPanel/forgotPassword/CodeVerification";
import ChangePassword from "../components/posAdminPanel/forgotPassword/ChangePassword";

const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles((theme) => ({
  main: {
    height: "100vh",
    width: "100%",
    backgroundColor: "#FFFFFF",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas: `
    'leftPart rightPart'
  `,
  },
  leftPart: {
    gridArea: "leftPart",
    background: "#FFFFFF",
  },
  rightPart: {
    gridArea: "rightPart",
    background: "#FFFFFF",
  },
  leftImage: {
    width: "100%",
    height: "100%",
  },
  rightElem: {
    background: "#FFFFFF",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  error_msg: {
    color: "red",
    fontSize: "1rem",
    margin: 0,
    fontFamily: "poppins",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    pb: "2rem",
  },
}));
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
  },
});
function LoginPage() {
  const classes = useStyles();
  const [state, setState] = useState("login");
  const [data, setData] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values, action) => {
      setIsLogin(true);
      const data = await posAdminLogin(values);
      if (data.status) {
        dispatch({ type: "LOGIN", payload: data });
        Cookies.set("POSadmin", JSON.stringify(data));
        localStorage.setItem(
          "expirationTime",
          Date.now() + 1 * 24 * 60 * 60 * 1000
        ); // 2 days from now
        navigate("/");
        action.resetForm();
      } else {
        setIsLogin(false);
        toast.error(data.message);
      }
    },
  });
  return (
    <>
      <Box className={classes.main}>
        <Box className={classes.leftPart}>
          <Box className={classes.leftImage}>
            <img
              src={Logo}
              alt=""
              style={{
                width: "300px",
                position: "absolute",
                left: "15%",
                top: "10%",
              }}
            />
            <img
              src={PhoneImage}
              alt=""
              style={{ height: "100vh", width: "50vw" }}
            />
            <Typography
              sx={{
                fontFamily: "poppins",
                width: "300px",
                position: "absolute",
                left: "19%",
                top: "93%",
              }}
            >
              www.vatmac.com
            </Typography>
          </Box>
        </Box>
        <Box className={classes.rightPart}>
          <Box className={classes.rightElem}>
            {state === "login" ? (
              <Box>
                <Box sx={{ pb: "2.1rem" }}>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      fontFamily: "poppins",
                    }}
                  >
                    Welcome back!
                  </Typography>
                </Box>
                <Box component="form" onSubmit={formik.handleSubmit} noValidate>
                  <Box sx={{ pb: "1rem", width: "30vw" }}>
                    <Box
                      sx={{
                        border: "1px solid #EEEEEE",
                        width: "100%",
                        height: "60px",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        boxSizing: "border-box",
                        px: "1.5rem",
                        bgcolor: "#F5F5F5",
                      }}
                    >
                      <PersonIcon
                        sx={{ fontSize: "1.7rem", color: "#92929B" }}
                      />
                      <TextField
                        variant="outlined"
                        placeholder="you@example.com"
                        sx={{
                          "& input::placeholder": {
                            fontFamily: "poppins",
                          },
                          "& fieldset": { border: "none" },
                          width: "100%",
                        }}
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                    </Box>
                    {formik.touched.email && formik.errors.email && (
                      <p className={classes.error_msg}>{formik.errors.email}</p>
                    )}
                  </Box>
                  <Box sx={{ pb: "1rem" }}>
                    <Box
                      sx={{
                        border: "1px solid #EEEEEE",
                        width: "100%",
                        height: "60px",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        boxSizing: "border-box",
                        px: "1.5rem",
                        bgcolor: "#F5F5F5",
                      }}
                    >
                      <LockIcon sx={{ fontSize: "1.7rem", color: "#92929B" }} />
                      <TextField
                        variant="outlined"
                        type="password"
                        placeholder="At least 8 character"
                        sx={{
                          "& input::placeholder": {
                            fontFamily: "poppins",
                          },
                          "& fieldset": { border: "none" },
                          width: "100%",
                        }}
                        name="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                    </Box>
                    {formik.touched.password && formik.errors.password && (
                      <p className={classes.error_msg}>
                        {formik.errors.password}
                      </p>
                    )}
                  </Box>

                  <Box sx={{ pb: "1.5rem", pt: "1.1rem" }}>
                    <Button
                      type="submit"
                      disabled={isLogin}
                      sx={{
                        background: "#F40000",
                        width: "100%",
                        height: "60px",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        boxSizing: "border-box",
                        px: "1.5rem",
                        color: "white",
                        "&:hover": {
                          color: "white",
                          background: "#F40000",
                        },
                      }}
                    >
                      {isLogin ? (
                        <ThemeProvider theme={customTheme}>
                          <CircularProgress color="primary" />
                        </ThemeProvider>
                      ) : (
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "1rem",
                            fontFamily: "poppins",
                          }}
                        >
                          Login
                        </Typography>
                      )}
                    </Button>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Typography
                      onClick={() => setState("find")}
                      sx={{
                        color: "#333333",
                        fontSize: ".9rem",
                        fontFamily: "poppins",
                        cursor: "pointer",
                      }}
                    >
                      Forgot Password
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : state === "find" ? (
              <FindAccount setState={setState} setData={setData} />
            ) : state === "reset" ? (
              <ResetPassword setState={setState} data={data} />
            ) : state === "code-vrfy" ? (
              <CodeVerification setState={setState} data={data} />
            ) : (
              <ChangePassword setState={setState} data={data} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default LoginPage;
