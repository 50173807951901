import React from "react";
import { Box } from "@mui/material";
import FlowDirection from "./../components/posAdminPanel/navBarAndSideBar/FlowDirection";
import SaleStats from "./../components/posAdminPanel/dashboard/SaleStats";
import SaleChart from "./../components/posAdminPanel/dashboard/SaleChart";

function Dashboard() {
  return (
    <Box>
      <FlowDirection />
      <Box
        sx={{
          bgcolor: "#f6f5f8",
          width: "96vw",
          minHeight: "88vh",
          marginTop: "100px",
          padding: 5,
        }}
      >
        <SaleStats />
        <SaleChart />
        
      </Box>
    </Box>
  );
}

export default Dashboard;
