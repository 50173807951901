import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const posGetSalesReport = async (
  token,
  status,
  sortby,
  search,
  startDate,
  endDate,
  pageNo
) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetSalesReport?status=${status}&sortby=${sortby}&search=${search}&startDate=${startDate}&endDate=${endDate}&pageNo=${pageNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getTotalSales = async (type, token) => {
  try {
    const { data } = await axios.get(
      `${host}/getTotalSales?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.data.response.message;
  }
};
export const posAddAccountEntry = async (token, values) => {
  try {
    const { data } = await axios.post(`${host}/posAddAccountEntry`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.data.response.message;
  }
};
export const posGetAllEntries = async (token, startDate, endDate, pageNo) => {
  try {
    const { data } = await axios.get(
      `${host}/posGetAllEntries?startDate=${startDate}&endDate=${endDate}&pageNo=${pageNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.data.response.message;
  }
};
export const getMostSellingProducts = async (type, token) => {
  try {
    const { data } = await axios.get(
      `${host}/mostSellingProduct?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.data.response.message;
  }
};
export const getClosingBalance = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getClosingBalance`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.data.response.message;
  }
};

export const getProductsBasedOnPreviousSales = async (type, token) => {
  try {
    // var type = "week";
    const { data } = await axios.get(
      `${host}/posProductsBasedOnPreviousSales?type=${type}&time=${new Date().toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
