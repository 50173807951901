import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import EditPrintingIp from "./EditPrintingIp";
import AddPrintingIp from "./AddPrintingIp";
import SearchBar from "../../common/SearchBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getPrintingIps } from "../../../sevice/settings";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));

const style = {
  width: 444,
  position: "absolute",
  top: "54%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "6px",
  px: 4,
  py: 4,
};

function PrintingIps({ setPage }) {
  const { POSadmin } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [editOpen, setEditOpen] = useState(false);
  const editHandleOpen = () => setEditOpen(true);
  const editHandleClose = () => setEditOpen(false);
  const [pritingIps, setPritingIps] = useState([]);
  const [editItem, setEditItem] = useState({});

  const getDatas = async () => {
    const data = await getPrintingIps(POSadmin.token);
    setPritingIps(data);
  };
  useEffect(() => {
    getDatas();
  }, [open, editOpen]);

  return (
    <>
      <Box sx={{ margin: 6 }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 10,
          }}
        >
          <SearchBar searchBarWidth={"200px"} placeholder={"Search"} />
          <Box>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                background: "var(--red)",
                "&:hover": {
                  backgroundColor: "var(--red)",
                },
                width: "130px",
              }}
              onClick={() => setOpen(true)}
            >
              + Add
            </Button>
          </Box>
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: "100%" }} aria-label="simple table">
            <TableHead sx={{ background: "var(--table-head)" }}>
              <TableRow>
                <StyledTableCell align="center">Printer Name</StyledTableCell>
                <StyledTableCell align="center">Printer Ip</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pritingIps?.map((item, index) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{item.name}</TableCell>
                  <TableCell align="center">{item.ip}</TableCell>
                  <TableCell align="center">
                    <VisibilityIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setEditItem(item);
                        editHandleOpen();
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <AddPrintingIp onClose={handleClose} />
          </Box>
        </Modal>
        <Modal
          open={editOpen}
          onClose={editHandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <EditPrintingIp editItem={editItem} onClose={editHandleClose} />
          </Box>
        </Modal>
      </Box>
    </>
  );
}

export default PrintingIps;
